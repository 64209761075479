export const messages = Object.freeze({
    windowTitle: 'Chart Preview',
    exportButton: 'Export',
    exportPDFButton: 'PDF File',
    exportSVGButton: 'SVG File',
    exportPNGButton: 'PNG File',
    tabChart: 'Chart',
    tabData: 'Data',
    tabFormat: 'Format',
    barChart: 'Bar Chart',
    barChartBar: 'Bar',
    barChartStackedBar: 'Stacked Bar',
    barChart100StackedBar: '100% Stacked Bar',
    pieChart: 'Pie Chart',
    pieChartPie: 'Pie',
    columnChart: 'Column Chart',
    columnChartColumn: 'Column',
    columnChartStackedColumn: 'Stacked Column',
    columnChart100StackedColumn: '100% Stacked Column',
    lineChart: 'Line Chart',
    lineChartLine: 'Line',
    lineChartStackedLine: 'Stacked Line',
    lineChart100StackedLine: '100% Stacked Line',
    scatterChart: 'Scatter Chart',
    scatterChartScatter: 'Scatter',
    configuration: 'Configuration',
    configurationCategoryAxis: 'Category Axis',
    configurationXAxis: 'X Axis',
    configurationValueAxis: 'Value Axis',
    configurationSeries: 'Series',
    configurationSeriesAdd: 'Add',
    formatChartArea: 'Chart Area',
    formatChartAreaMargins: 'Margins',
    formatChartAreaMarginsAuto: 'Auto',
    formatChartAreaMarginsLeft: 'Left',
    formatChartAreaMarginsRight: 'Right',
    formatChartAreaMarginsTop: 'Top',
    formatChartAreaMarginsBottom: 'Bottom',
    formatChartAreaBackground: 'Background',
    formatChartAreaBackgroundColor: 'Color',
    formatTitle: 'Title',
    formatTitleApplyTo: 'Apply to',
    formatTitleChartTitle: 'Chart Title',
    formatTitleChartSubtitle: 'Chart Subtitle',
    formatTitleLabel: 'Title',
    formatTitleFont: 'Font',
    formatTitleFontPlaceholder: '(inherited font)',
    formatTitleSize: 'Size',
    formatTitleSizePlaceholder: 'px',
    formatTitleColor: 'Color',
    formatSeries: 'Series',
    formatSeriesApplyTo: 'Apply to',
    formatSeriesAllSeries: 'All Series',
    formatSeriesColor: 'Color',
    formatSeriesShowLabels: 'Show Labels',
    formatLegend: 'Legend',
    formatLegendShowLegend: 'Show Legend',
    formatLegendFont: 'Font',
    formatLegendFontPlaceholder: '(inherited font)',
    formatLegendSize: 'Size',
    formatLegendSizePlaceholder: 'px',
    formatLegendColor: 'Color',
    formatLegendPosition: 'Position',
    formatLegendPositionTop: 'Top',
    formatLegendPositionBottom: 'Bottom',
    formatLegendPositionLeft: 'Left',
    formatLegendPositionRight: 'Right',
    formatCategoryAxis: 'Category Axis',
    formatXAxis: 'X Axis',
    formatCategoryAxisTitle: 'Title',
    formatCategoryAxisTitlePlaceholder: 'Axis Title',
    formatCategoryAxisTitleFont: 'Font',
    formatCategoryAxisTitleFontPlaceholder: '(inherited font)',
    formatCategoryAxisTitleSize: 'Size',
    formatCategoryAxisTitleSizePlaceholder: 'px',
    formatCategoryAxisTitleColor: 'Color',
    formatCategoryAxisLabels: 'Labels',
    formatCategoryAxisLabelsFont: 'Font',
    formatCategoryAxisLabelsFontPlaceholder: '(inherited font)',
    formatCategoryAxisLabelsSize: 'Size',
    formatCategoryAxisLabelsSizePlaceholder: 'px',
    formatCategoryAxisLabelsColor: 'Color',
    formatCategoryAxisLabelsRotation: 'Rotation',
    formatCategoryAxisLabelsRotationAuto: 'Auto',
    formatCategoryAxisLabelsReverseOrder: 'Reverse Order',
    formatValueAxis: 'Value Axis',
    formatYAxis: 'Y Axis',
    formatValueAxisTitle: 'Title',
    formatValueAxisTitlePlaceholder: 'Axis Title',
    formatValueAxisTitleFont: 'Font',
    formatValueAxisTitleFontPlaceholder: '(inherited font)',
    formatValueAxisTitleSize: 'Size',
    formatValueAxisTitleSizePlaceholder: 'px',
    formatValueAxisTitleColor: 'Color',
    formatValueAxisLabels: 'Labels',
    formatValueAxisLabelsFormat: 'Label Format',
    formatValueAxisLabelsFormatText: 'Text',
    formatValueAxisLabelsFormatNumber: 'Number',
    formatValueAxisLabelsFormatCurrency: 'Currency',
    formatValueAxisLabelsFormatPercent: 'Percent',
    formatValueAxisLabelsFont: 'Font',
    formatValueAxisLabelsFontPlaceholder: '(inherited font)',
    formatValueAxisLabelsSize: 'Size',
    formatValueAxisLabelsSizePlaceholder: 'px',
    formatValueAxisLabelsColor: 'Color',
    formatValueAxisLabelsRotation: 'Rotation',
    formatValueAxisLabelsRotationAuto: 'Auto'
});
