<form [formGroup]="energyForm" class="k-form k-form-md">
  <kendo-formfield class="mb-20">
    <div class="d-flex gap-15">
      <kendo-label
        [for]="kwh"
        text="KWH"
        labelCssClass="k-form-label"
        class="w-100 d-flex align-items-center"
        ><span class="required">*</span></kendo-label
      >
      <kendo-textbox
        formControlName="kwh"
        placeholder="Enter Value (0-9)"
        #kwh
        (keypress)="commonService.numberOnly($event)"
      ></kendo-textbox>
    </div>
    <div
      *ngIf="energyForm.get('kwh').invalid && energyForm.get('kwh').touched"
      class="d-flex gap-15"
    >
      <div class="w-80"></div>
      <div>
        <p *ngIf="energyForm.get('kwh').errors?.required" class="required mb-0">
          KWH is required.
        </p>
        <p *ngIf="energyForm.get('kwh').errors?.pattern" class="required mb-0">
          Please enter a valid number for Energy KWH.
        </p>
      </div>
    </div>
  </kendo-formfield>
  <kendo-formfield class="mb-20">
    <div class="d-flex gap-15">
      <kendo-label
        [for]="kwah"
        text="KWAH"
        labelCssClass="k-form-label"
        class="w-100 d-flex align-items-center"
        ><span class="required">*</span></kendo-label
      >
      <kendo-textbox
        formControlName="kwah"
        placeholder="Enter Value (0-9)"
        #kwah
        (keypress)="commonService.numberOnly($event)"
      ></kendo-textbox>
    </div>
    <div
      *ngIf="energyForm.get('kwah').invalid && energyForm.get('kwah').touched"
      class="d-flex gap-15"
    >
      <div class="w-80"></div>
      <div>
        <p
          *ngIf="energyForm.get('kwah').errors?.required"
          class="required mb-0"
        >
          KWAH is required.
        </p>
        <p *ngIf="energyForm.get('kwah').errors?.pattern" class="required mb-0">
          Please enter a valid number for Energy KWAH.
        </p>
      </div>
    </div>
  </kendo-formfield>
</form>
