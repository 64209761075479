<div class="q-content">
  <div class="d-flex gap-15 flex-wrap justify-content-between mb-20">
    <app-common-header-text
      [triggerGetUpdatedTime]="triggerGetUpdatedTime"
    ></app-common-header-text>
    <div class="d-flex gap-20 flex-wrap">
      <kendo-dropdownlist
        [data]="rolesList"
        textField="name"
        valueField="id"
        [(ngModel)]="selectedRole"
        [ngModelOptions]="{ standalone: true }"
        [value]="selectedRole"
        [valuePrimitive]="true"
        (valueChange)="getRoleModulePermissionList()"
        class="w-200"
      ></kendo-dropdownlist>
    </div>
  </div>
  <div class="q-content-bg page-permission-height">
    <kendo-treelist
      [kendoTreeListFlatBinding]="moduleList"
      kendoTreeListExpandable="true"
      [fetchChildren]="fetchChildren"
      [hasChildren]="hasChildren"
      class="mb-20"
    >
      <kendo-treelist-column
        field="moduleName"
        title="Module Name"
        [expandable]="true"
        [width]="120"
      ></kendo-treelist-column>
      <kendo-treelist-column field="isAddCheck" title="Add" [width]="120">
        <ng-template
          kendoTreeListHeaderTemplate
          let-aggregates="aggregates"
          let-dataItem="dataItem"
        >
          <div class="d-flex gap-5 align-items-center">
            <input
              kendoCheckBox
              kendoCheckBox
              type="checkbox"
              [checked]="isAddCheckAll"
              (change)="onActionHeaderSelected($event, headerActionType.Add)"
            />
            <span>Add</span>
          </div>
        </ng-template>
        <ng-template
          kendoTreeListCellTemplate
          let-dataItem="dataItem"
          let-column="column"
          let-formGroup="formGroup"
          let-isNew="isNew"
        >
          <div
            class="display-inline"
            [ngClass]="{ 'curser-not-allowd': dataItem.isViewOnly }"
          >
            <input
              kendoCheckBox
              type="checkbox"
              [(ngModel)]="dataItem.isAddCheck"
              [disabled]="
                dataItem.isViewOnly || checkIsSuperAdmin(dataItem.moduleId)
              "
              (ngModelChange)="setViewValue(dataItem, headerActionType.Add)"
            />
          </div>
        </ng-template>
      </kendo-treelist-column>
      <kendo-treelist-column field="isUpdateCheck" title="Update" [width]="120">
        <ng-template
          kendoTreeListHeaderTemplate
          let-aggregates="aggregates"
          let-dataItem="dataItem"
        >
          <div class="d-flex gap-5 align-items-center">
            <input
              kendoCheckBox
              type="checkbox"
              [checked]="isUpdateCheckAll"
              (change)="onActionHeaderSelected($event, headerActionType.Edit)"
            />
            <span>Update</span>
          </div>
        </ng-template>
        <ng-template
          kendoTreeListCellTemplate
          let-dataItem="dataItem"
          let-column="column"
          let-formGroup="formGroup"
          let-isNew="isNew"
        >
          <div
            class="display-inline"
            [ngClass]="{ 'curser-not-allowd': dataItem.isViewOnly }"
          >
            <input
              kendoCheckBox
              type="checkbox"
              [(ngModel)]="dataItem.isUpdateCheck"
              [disabled]="
                dataItem.isViewOnly || checkIsSuperAdmin(dataItem.moduleId)
              "
              (ngModelChange)="setViewValue(dataItem, headerActionType.Edit)"
            />
          </div>
        </ng-template>
      </kendo-treelist-column>
      <kendo-treelist-column field="isDeleteCheck" title="Delete" [width]="120">
        <ng-template
          kendoTreeListHeaderTemplate
          let-aggregates="aggregates"
          let-dataItem="dataItem"
        >
          <div class="d-flex gap-5 align-items-center">
            <input
              kendoCheckBox
              type="checkbox"
              [checked]="isDeleteCheckAll"
              (change)="onActionHeaderSelected($event, headerActionType.Delete)"
            />
            <span>Delete</span>
          </div>
        </ng-template>
        <ng-template
          kendoTreeListCellTemplate
          let-dataItem="dataItem"
          let-column="column"
          let-formGroup="formGroup"
          let-isNew="isNew"
        >
          <div
            class="display-inline"
            [ngClass]="{ 'curser-not-allowd': dataItem.isViewOnly }"
          >
            <input
              kendoCheckBox
              type="checkbox"
              [(ngModel)]="dataItem.isDeleteCheck"
              [disabled]="
                dataItem.isViewOnly || checkIsSuperAdmin(dataItem.moduleId)
              "
              (ngModelChange)="setViewValue(dataItem, headerActionType.Delete)"
            />
          </div>
        </ng-template>
      </kendo-treelist-column>
      <kendo-treelist-column field="isViewCheck" title="View" [width]="120">
        <ng-template
          kendoTreeListHeaderTemplate
          let-aggregates="aggregates"
          let-dataItem="dataItem"
        >
          <div class="d-flex gap-5 align-items-center">
            <input
              kendoCheckBox
              type="checkbox"
              [(ngModel)]="isViewHeaderSelected"
              [checked]="isViewCheckAll"
              (change)="onActionHeaderSelected($event, headerActionType.View)"
            />
            <span>View</span>
          </div>
        </ng-template>
        <ng-template
          kendoTreeListCellTemplate
          let-dataItem="dataItem"
          let-column="column"
          let-formGroup="formGroup"
          let-isNew="isNew"
        >
          <input
            kendoCheckBox
            type="checkbox"
            [(ngModel)]="dataItem.isViewCheck"
            [disabled]="checkIsSuperAdmin(dataItem.moduleId)"
            (ngModelChange)="
              setViewValue(dataItem, headerActionType.View, $event)
            "
          />
        </ng-template>
      </kendo-treelist-column>
    </kendo-treelist>
    <div class="d-flex justify-content-end">
      <button kendoButton id="create" type="button" (click)="onSubmit()">
        Submit
      </button>
    </div>
  </div>
</div>
