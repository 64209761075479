import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import {
  AuthGuard,
  PTRInterruptionComponent,
  PTRLoadsComponent,
  SsReportComponent,
} from '@app/index'

const routes: Routes = [
  { path: '', component: SsReportComponent, canActivate: [AuthGuard] },
  { path: 'ptr-loads', component: PTRLoadsComponent, canActivate: [AuthGuard] },
  {
    path: 'ptr-interruption',
    component: PTRInterruptionComponent,
    canActivate: [AuthGuard],
  },
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ssRoutingModule {}
