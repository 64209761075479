import { Component } from '@angular/core'
import { FormControl, FormGroup } from '@angular/forms'
import { PeriodEnum } from '@app/enums'
import { CommonService } from '@app/services'
import { GetCAIDIData, GetLossAmountData } from '@app/store'
import { Store } from '@ngxs/store'
import { NgxSpinnerService } from 'ngx-spinner'
import { Subject } from 'rxjs'

@Component({
  selector: 'app-caidi-details',
  templateUrl: './caidi-details.component.html',
  styleUrl: './caidi-details.component.scss',
})
export class CaidiDetailsComponent {
  triggerGetUpdatedTime: Subject<any> = new Subject<any>()
  caidiCardDetails: any
  lossAmountCardDetails: any
  lossAmtForm: FormGroup | any
  deviceId: any

  constructor(
    private store: Store,
    private spinner: NgxSpinnerService,
    public commonService: CommonService,
  ) {
    this.setForm()
  }

  ngOnInit(): void {}

  setForm(): void {
    this.lossAmtForm = new FormGroup({
      price: new FormControl('6.5'),
      avgUnit: new FormControl(''),
    })
  }

  getCaidiPeriodLabel(week: number): string {
    return PeriodEnum[week]
  }

  getCAIDIDetails(): void {
    this.spinner.show()
    this.store.dispatch(new GetCAIDIData(this.deviceId)).subscribe(
      (res: any) => {
        if (res.caidi.caidiData.length) {
          this.caidiCardDetails = res.caidi.caidiData
        } else {
          this.caidiCardDetails = []
        }
      },
      (error) => {
        this.spinner.hide()
      },
    )
  }

  getLossAmount(): void {
    const param = {
      deviceId: this.deviceId,
      pricePerUnit: +this.lossAmtForm.controls.price.value,
      unitPerHour: +this.lossAmtForm.controls.avgUnit.value,
    }
    this.spinner.show()
    this.store.dispatch(new GetLossAmountData(param)).subscribe(
      (res: any) => {
        this.spinner.hide()
        if (res.caidi.lossAmountData.length) {
          this.lossAmountCardDetails = res.caidi.lossAmountData
        } else {
          this.lossAmountCardDetails = []
        }
      },
      (error) => {
        this.spinner.hide()
      },
    )
  }

  getDataByHeaderData(event: any) {
    this.deviceId = event.deviceId
    this.triggerGetUpdatedTime.next(true)
    this.getCAIDIDetails()
    this.getLossAmount()
  }
}
