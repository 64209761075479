import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable, of } from 'rxjs'
import { environment } from 'src/environments'

@Injectable()
export class OverloadService {
  gridData = [
    {
      Alert: '100% Exceeded',
      Load: 0.775,
      time: '17:15:25 09-05-2024',
    },
    {
      Alert: '100% Exceeded',
      Load: 0.775,
      time: '16:15:00 09-05-2024',
    },
  ]

  overloadCardData = [
    { peroid: 'Today', LOAD: '0' },
    { peroid: 'This Week', LOAD: '0' },
    { peroid: 'This Month', LOAD: '0' },
    { peroid: 'Last 3 Months', LOAD: '0' },
    { peroid: 'last 6 Months', LOAD: '190' },
    { peroid: 'last 1 year', LOAD: '190' },
  ]

  constructor(private http: HttpClient) {}

  getAlertOverload(queryParams: any): Observable<HttpResponse<any>> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      observe: 'response' as 'response',
    }

    return this.http.post<any>(
      `${environment.apiVersionUrl}Alert/getAlertOverload`,
      queryParams,
      httpOptions,
    )
  }

  getAlertOverLoadCount(
    deviceId: string,
    percentage: number,
  ): Observable<any[]> {
    const url = `${environment.apiVersionUrl}Alert/GetAlertOverLoadCount/${deviceId}/${percentage}`
    return this.http.post<any>(url, null)
  }
}
