import { Component, OnInit } from '@angular/core'
import { DialogRef } from '@progress/kendo-angular-dialog'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { CommonService } from '@app/services'
import { Store } from '@ngxs/store'
import { NgxSpinnerService } from 'ngx-spinner'
import { CreateFeeder } from '@app/store'
import { ToastrService } from 'ngx-toastr'
import { NotificationMessage } from '@app/enums/notification'
import { noSpacesOrSpecialCharsValidator } from '@app/directive'
import { FeederType } from '@app/enums'
import swal from 'sweetalert'

@Component({
  selector: 'app-add-feeder',
  templateUrl: './add-feeder.component.html',
  styleUrl: './add-feeder.component.scss',
})
export class AddFeederComponent implements OnInit {
  feederForm: FormGroup | any
  feederId = 0
  feederData: any
  feederType = FeederType
  selectedFeederType: any
  constructor(
    public dialogRef: DialogRef,
    public commonService: CommonService,
    private store: Store,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
  ) {
    this.setForm()
  }

  setForm(): void {
    this.feederForm = new FormGroup({
      feeder_AreaName: new FormControl('', [
        Validators.required,
        noSpacesOrSpecialCharsValidator(),
      ]),
    })
  }

  ngOnInit(): void {
    if (this.feederId > 0) {
      this.feederForm.controls.feeder_AreaName.setValue(
        this.feederData.feederName,
      )
    }
  }

  onSave(): void {
    let textMessage = `You want to ${this.feederId === 0 ? 'add new' : 'update'} Feeder - ${this.feederForm.get('feeder_AreaName').value}.`

    swal({
      title: 'Are you sure ?',
      text: textMessage,
      icon: 'warning',
      buttons: {
        cancel: {
          text: 'Cancel',
          visible: true,
          closeModal: true,
        },
        confirm: {
          text: 'Yes',
        },
      },
      dangerMode: true,
    }).then((confirmed) => {
      if (confirmed) {
        const param: any = {
          feederId: this.feederId,
          feederName: this.feederForm.get('feeder_AreaName').value,
          feederType: this.selectedFeederType,
        }
        this.spinner.show()
        this.store.dispatch(new CreateFeeder(param)).subscribe(
          (res: any) => {
            this.spinner.hide()
            if (res.feeder.isFeederSuccess) {
              this.toastr.success(
                this.feederId === 0
                  ? NotificationMessage.createFeederSuccessMsg
                  : NotificationMessage.updateFeederSuccessMsg,
              )
              this.commonService.onDialogClose(this.dialogRef, true)
            }
          },
          (error) => {
            this.spinner.hide()
          },
        )
      }
    })
  }

  onCloseDialog(): void {
    this.commonService.onDialogClose(this.dialogRef, false)
  }
}
