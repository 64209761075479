import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { environment } from 'src/environments/environment'

@Injectable()
export class DashboardService {
  constructor(private http: HttpClient) {}

  dashboardData(deviceId: string | undefined): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    }

    return this.http.get<any>(
      `${environment.apiVersionUrl}Dashboard/GetDashboardData/${deviceId === '' ? null : deviceId}`,
      headers,
    )
  }

  getUpdatedTime(deviceId: string | undefined): Observable<any> {
    const url = `${environment.apiVersionUrl}Dashboard/GetUpdatedTime/${deviceId === '' ? null : deviceId}`

    const headers = new HttpHeaders({ 'Content-Type': 'application/json' })
    return this.http.get(url, { headers, responseType: 'text' })
  }
}
