import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable, of } from 'rxjs'
import { environment } from 'src/environments'

@Injectable()
export class SystemBatteryService {
  constructor(private http: HttpClient) {}

  getAllSystemBattery(queryParams: any): Observable<HttpResponse<any>> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      observe: 'response' as 'response',
    }

    return this.http.post<any>(
      `${environment.apiVersionUrl}SystemBattery/GetAllSystemBattery`,
      queryParams,
      httpOptions,
    )
  }
}
