<div class="q-content">
  <div
    class="d-flex gap-15 flex-wrap justify-content-between align-items-center mb-20"
  >
    <app-common-header-text
      [triggerGetUpdatedTime]="triggerGetUpdatedTime"
    ></app-common-header-text>
    <app-common-header
      (triggerDataFromHeader)="getDataByHeaderData($event)"
      [showDateFilter]="false"
    ></app-common-header>
  </div>
  <div class="d-flex-row">
    <div
      class="d-flex-col-xl-6 d-flex-col-md-12 d-flex-col-xs-12 d-flex-col first-card"
    >
      <div class="q-card">
        <div class="fw-500 font-size-16 mb-20">
          CAIDI
          <i
            class="far fa-circle-info font-size-16 ml-5 cursor-pointer fw-500 info-icon"
            title="The number of times a power failure has occured and its Duration of the DTR."
          ></i>
        </div>
        <div>
          <p class="fw-500 font-size-16 text-secondary mb-20">
            Customer Average Interruption Duration Index
          </p>
        </div>
        <div class="d-flex-row">
          <div
            *ngFor="let card of caidiCardDetails; let i = index"
            class="d-flex-col-lg-6 d-flex-col-xs-12 d-flex-col d-flex"
          >
            <div
              class="q-card flex-1 border"
              [ngClass]="[
                'q-card-bg-' +
                  commonService.getPeroidName(getCaidiPeriodLabel(card.week)),
                i < caidiCardDetails.length - 2 ? 'mb-20' : ''
              ]"
            >
              <div class="q-card-header d-flex justify-content-between">
                <div>
                  <p class="d-flex flex-column gap-5 mb-15">
                    <span>Count: </span
                    ><span class="q-card-value">{{ card?.countValue }}</span>
                  </p>
                  <p class="d-flex flex-wrap flex-column gap-5 mb-0">
                    <span>Duration:</span
                    ><span class="q-card-value"
                      >{{ card?.time | number: '1.2-2' }} Min(s)
                    </span>
                  </p>
                </div>
                <div
                  class="q-card-icon"
                  [ngClass]="
                    'q-card-icon-' +
                    commonService.getPeroidName(getCaidiPeriodLabel(card.week))
                  "
                >
                  <i
                    [class]="
                      commonService.getIconClass(getCaidiPeriodLabel(card.week))
                    "
                  ></i>
                </div>
              </div>
              <div class="q-card-body pb-0">
                <p class="m-0"></p>
                <p class="q-card-title mb-0">
                  {{ getCaidiPeriodLabel(card.week) }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex-col-xl-6 d-flex-col-md-12 d-flex-col-xs-12 d-flex-col">
      <div class="q-card h-100">
        <div class="fw-500 font-size-16 mb-20">
          Loss Amount
          <i
            class="far fa-circle-info font-size-16 ml-5 cursor-pointer fw-500 info-icon"
            title="Total loss is calculated from the average units of DTR over a period."
          ></i>
        </div>
        <div>
          <div>
            <form [formGroup]="lossAmtForm" class="k-form k-form-md">
              <div class="d-flex-row">
                <kendo-formfield
                  class="mt-0 d-flex-col-lg-6 d-flex-col-xs-12 d-flex-col mb-20"
                >
                  <kendo-label
                    [for]="price"
                    text="Price/Unit"
                    labelCssClass="k-form-label"
                  ></kendo-label>
                  <kendo-textbox
                    formControlName="price"
                    (blur)="getLossAmount()"
                    (keydown)="commonService.onlyNumericInputValidation($event)"
                    #price
                  ></kendo-textbox>
                </kendo-formfield>

                <kendo-formfield
                  class="mt-0 d-flex-col-lg-6 d-flex-col-xs-12 d-flex-col mb-20"
                >
                  <kendo-label
                    [for]="hr"
                    text="Avg. Units/hr"
                    labelCssClass="k-form-label"
                  ></kendo-label>
                  <kendo-textbox
                    formControlName="avgUnit"
                    (blur)="getLossAmount()"
                    (keydown)="commonService.onlyNumericInputValidation($event)"
                    #hr
                  ></kendo-textbox>
                </kendo-formfield>
              </div>
            </form>
          </div>
          <div class="d-flex-row">
            <div
              *ngFor="let card of lossAmountCardDetails; let i = index"
              class="d-flex-col-lg-6 d-flex-col-xs-12 d-flex-col d-flex"
            >
              <div
                class="q-card flex-1 border"
                [ngClass]="[
                  'q-card-bg-' +
                    commonService.getPeroidName(getCaidiPeriodLabel(card.week)),
                  i < lossAmountCardDetails.length - 2 ? 'mb-20' : ''
                ]"
              >
                <div class="q-card-header d-flex justify-content-between">
                  <div>
                    <p class="q-card-value">₹{{ card?.load }}</p>
                  </div>
                  <div
                    class="q-card-icon"
                    [ngClass]="
                      'q-card-icon-' +
                      commonService.getPeroidName(
                        getCaidiPeriodLabel(card.week)
                      )
                    "
                  >
                    <i
                      [class]="
                        commonService.getIconClass(
                          getCaidiPeriodLabel(card.week)
                        )
                      "
                    ></i>
                  </div>
                </div>
                <div class="q-card-body pb-0">
                  <p class="m-0"></p>
                  <p class="q-card-title mb-0">
                    {{ getCaidiPeriodLabel(card.week) }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
