import { Component, Input, OnInit } from '@angular/core'
import { Dashboard } from '@app/store'
import { Store } from '@ngxs/store'
import { NgxSpinnerService } from 'ngx-spinner'
import { Observable } from 'rxjs'

@Component({
  selector: 'app-common-analytics-card',
  templateUrl: './common-analytics-card.component.html',
  styleUrl: './common-analytics-card.component.scss',
})
export class CommonAnalyticsCardComponent implements OnInit {
  @Input() isCurrentGraph: boolean = true
  @Input() triggerCurrentCardApi: Observable<any> | undefined
  current_ph1 = 0
  current_ph2 = 0
  current_ph3 = 0

  title = ''

  constructor(
    private store: Store,
    private spinner: NgxSpinnerService,
  ) {}

  ngOnInit(): void {
    this.title = this.isCurrentGraph ? 'Current (A)' : 'Voltage (V)'
    this.triggerCurrentCardApi?.subscribe((res: any) => {
      this.getCurrentCardData(res)
    })
  }

  getCurrentCardData(deviceId: any): void {
    this.store.dispatch(new Dashboard(deviceId)).subscribe(
      (res: any) => {
        if (res.dashboard.dashboardData) {
          const dashboardCardData = res.dashboard.dashboardData
          this.current_ph1 = this.isCurrentGraph
            ? dashboardCardData.currentR
            : dashboardCardData.voltageR
          this.current_ph2 = this.isCurrentGraph
            ? dashboardCardData.currentY
            : dashboardCardData.voltageY
          this.current_ph3 = this.isCurrentGraph
            ? dashboardCardData.currentB
            : dashboardCardData.voltageB
        }
      },
      (error) => {
        this.spinner.hide()
      },
    )
    // this.store.dispatch(new GetCurrentACardData(this.isCurrentGraph)).subscribe(
    //   (res: any) => {
    //     this.spinner.hide()
    //     if (
    //       res.analytics.current_A_CardData !== null &&
    //       res.analytics.current_A_CardData !== undefined
    //     ) {
    //       this.current_ph1 = this.isCurrentGraph
    //         ? res.analytics.current_A_CardData.current_ph1
    //         : res.analytics.current_A_CardData.voltage_ph1
    //       this.current_ph2 = this.isCurrentGraph
    //         ? res.analytics.current_A_CardData.current_ph2
    //         : res.analytics.current_A_CardData.voltage_ph2
    //       this.current_ph3 = this.isCurrentGraph
    //         ? res.analytics.current_A_CardData.current_ph3
    //         : res.analytics.current_A_CardData.voltage_ph3
    //     }
    //   },
    //   (error) => {
    //     this.spinner.hide()
    //   },
    // )
  }
}
