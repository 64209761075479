<kendo-dialog-titlebar (close)="onCloseDialog()">
  <span>OTP Verification</span>
</kendo-dialog-titlebar>

<form class="k-form k-form-md">
  <kendo-formfield class="mb-20 mt-10 pt-10">
    <kendo-textbox
      [(ngModel)]="otpValue"
      [ngModelOptions]="{ standalone: true }"
      placeholder="Enter OTP"
      (keydown)="commonService.onlyNumericInputValidation($event)"
    ></kendo-textbox>
  </kendo-formfield>
</form>

<kendo-dialog-actions>
  <button kendoButton (click)="onCloseDialog()" fillMode="outline">
    Cancel
  </button>
  <div class="display-inline flex-0">
    <button kendoButton (click)="onVerifyOtp()">Verify OTP</button>
  </div>
</kendo-dialog-actions>
