import { Injectable } from '@angular/core'
import { AnalyticsService, DeviceService } from '@app/services'
import { Selector, Action, StateContext, State } from '@ngxs/store'
import { tap } from 'rxjs'
import {
  GetAnalyticsChart,
  GetAnalyticsVoltageChart,
  GetCurrentACardData,
  GetCurrentGraphChart,
  GetLoadTempGraphChart,
} from '.'

export class AnalyticsStateInfo {
  analyticsChartData?: any
  current_A_CardData?: any
  currentGraphLineChart?: any
  analyticsValtageChartData?: any
  analyticsLoadTempGraphData?: any
}

@State<AnalyticsStateInfo>({
  name: 'analytics',
  defaults: {},
})
@Injectable()
export class AnalyticsState {
  constructor(private analyticsService: AnalyticsService) {}

  @Selector()
  static getAnalyticsChartData(state: AnalyticsStateInfo) {
    return state.analyticsChartData
  }

  @Selector()
  static getAnalyticsVoltageChart(state: AnalyticsStateInfo) {
    return state.analyticsValtageChartData
  }

  @Selector()
  static getLoadTempGraphChart(state: AnalyticsStateInfo) {
    return state.analyticsLoadTempGraphData
  }

  @Action(GetAnalyticsChart)
  getAnalyticsChart(
    { getState, patchState }: StateContext<AnalyticsStateInfo>,
    action: any,
  ) {
    return this.analyticsService.getAnalyticsChart(action.analyticsData).pipe(
      tap((res: any) => {
        patchState({
          analyticsChartData: res,
        })
      }),
    )
  }

  @Action(GetAnalyticsVoltageChart)
  getAnalyticsVoltageChart(
    { getState, patchState }: StateContext<AnalyticsStateInfo>,
    action: any,
  ) {
    return this.analyticsService
      .getAnalyticsVoltageChart(action.analyticsVoltageData)
      .pipe(
        tap((res: any) => {
          patchState({
            analyticsValtageChartData: res,
          })
        }),
      )
  }

  @Action(GetCurrentGraphChart)
  getCurrentGraphChart(
    { getState, patchState }: StateContext<AnalyticsStateInfo>,
    action: GetCurrentGraphChart,
  ) {
    return this.analyticsService
      .getCurrentGraphChart(action.currentGraphData)
      .pipe(
        tap((res: any) => {
          patchState({
            currentGraphLineChart: res,
          })
        }),
      )
  }

  @Action(GetCurrentACardData)
  getCurrentACardData(
    { getState, patchState }: StateContext<AnalyticsStateInfo>,
    action: GetCurrentACardData,
  ) {
    return this.analyticsService
      .getCurrentACardData(action.isCurrentGraph)
      .pipe(
        tap((res: any) => {
          patchState({
            current_A_CardData: res,
          })
        }),
      )
  }

  @Action(GetLoadTempGraphChart)
  getLoadTempGraphChart(
    { getState, patchState }: StateContext<AnalyticsStateInfo>,
    action: any,
  ) {
    return this.analyticsService
      .getLoadTempGraphChart(action.analyticsLoadTempGraphData)
      .pipe(
        tap((res: any) => {
          patchState({
            analyticsLoadTempGraphData: res,
          })
        }),
      )
  }
}
