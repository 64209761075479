import { Component } from '@angular/core'
import { SeparateDateEnum } from '@app/enums'
import { GetLoadTempGraphChart } from '@app/store'
import { Store } from '@ngxs/store'
import { CategoryAxis } from '@progress/kendo-angular-charts'
import { NgxSpinnerService } from 'ngx-spinner'
import { Subject } from 'rxjs'

@Component({
  selector: 'app-load-temp-graph',
  templateUrl: './load-temp-graph.component.html',
  styleUrl: './load-temp-graph.component.scss',
})
export class LoadTempGraphComponent {
  triggerGetUpdatedTime: Subject<any> = new Subject<any>()
  categories: any[] = []
  categoryAxis: CategoryAxis | CategoryAxis[] = []
  lineAndBarChartData: any[] = []
  seriesData: any[] = [
    {
      name: 'Load-R',
      type: 'column',
      field: 'bar1',
      visible: true,
      axis: 'leftAxis',
    },
    {
      name: 'Load-Y',
      type: 'column',
      field: 'bar2',
      visible: true,
      axis: 'leftAxis',
    },
    {
      name: 'Load-B',
      type: 'column',
      field: 'bar3',
      visible: true,
      axis: 'leftAxis',
    },
    {
      name: 'Temp-R',
      type: 'line',
      field: 'lin0',
      visible: true,
      axis: 'rightAxis',
    },
    {
      name: 'Temp-Y',
      type: 'line',
      field: 'lin1',
      visible: true,
      axis: 'rightAxis',
    },
    {
      name: 'Temp-B',
      type: 'line',
      field: 'lin2',
      visible: true,
      axis: 'rightAxis',
    },
    {
      name: 'Oil',
      type: 'line',
      field: 'lin3',
      visible: true,
      axis: 'rightAxis',
    },
  ]
  chartData: any[] = []
  deviceId: any
  param: any = {
    deviceId: 0,
    year: undefined,
    month: undefined,
    day: undefined,
    type: undefined,
  }
  isLineAndBarChartData = false
  showLoader: boolean = true
  isNoRecordFound = false
  roundedMaxNumber: number | any
  constructor(
    private store: Store,
    private spinner: NgxSpinnerService,
  ) {}

  ngOnInit(): void {}

  onAnalyticsFilterChange(event: any) {
    const param = {
      deviceId: this.deviceId,
      year: event.year,
      month: event.month,
      day: event.day,
      type: event.type,
    }
    this.getLoadTempGraphData(param)
  }

  getLoadTempGraphData(param: any): void {
    this.showLoader = true

    this.store.dispatch(new GetLoadTempGraphChart(param)).subscribe(
      (res: any) => {
        this.showLoader = false
        this.lineAndBarChartData = []
        if (res.analytics.analyticsLoadTempGraphData.length > 0) {
          this.lineAndBarChartData = res.analytics.analyticsLoadTempGraphData
          this.categoryAxis = {
            labels: {
              rotation: -99,
            },
          }

          // Extract all numeric values from lineAndBarChartData
          let allValues: number[] = this.lineAndBarChartData.flatMap(
            (item: any) =>
              Object.values(item).filter(
                (value): value is number => typeof value === 'number',
              ),
          )
          this.roundedMaxNumber = 0
          // Get the maximum value
          // let maxNumber = Math.max(...allValues)
          // maxNumber += 10
          // const roundedValue = Math.ceil(maxNumber / 10) * 10

          this.roundedMaxNumber = this.lineAndBarChartData.length
        }
        this.isNoRecordFound =
          res.analytics.analyticsLoadTempGraphData.length <= 0
        this.isLineAndBarChartData = true
      },
      (error) => {
        this.spinner.hide()
      },
    )
  }

  toggleVisibility(index: number) {
    this.seriesData[index].visible = !this.seriesData[index].visible
  }

  getDataByHeaderData(event: any): void {
    this.deviceId = event.deviceId
    this.param.deviceId = this.deviceId

    this.getLoadTempGraphData(this.param)
    this.triggerGetUpdatedTime.next(true)
  }

  onAllButtonClick() {
    this.param = {
      deviceId: this.deviceId,
      year: undefined,
      month: undefined,
      day: undefined,
      type: undefined,
    }

    this.getLoadTempGraphData(this.param)
  }

  getAllYearData(): void {
    this.param = {
      deviceId: this.deviceId,
      year: undefined,
      month: undefined,
      day: undefined,
      type: SeparateDateEnum.YEAR,
    }
    this.getLoadTempGraphData(this.param)
  }
}
