export class SSSLDDashboardtList {
  static readonly type = '[sslddashboard] Get SS SLD Dashboard Report List'

  constructor(
    public queryParams: any,
    public name: any,
  ) {}
}

export class GetAllSubStation {
  static readonly type = '[sslddashboard] Get All SubStation'
}
