import { Component, EventEmitter, OnInit, Output } from '@angular/core'
import { AnalysisInfo } from '@app/models'
import { CommonService } from '@app/services'
import {
  GetDeviceIdByFeederNotCommon,
  GetDTRPredictionDetail,
  GetGraphDetails,
  GetNotCommonFeederAreaList,
} from '@app/store'
import { Store } from '@ngxs/store'
import { NgxSpinnerService } from 'ngx-spinner'
interface ChartData {
  time: number
  tempPhaseR: number
  tempPhaseY: number
  tempPhaseB: number
  oilTemp: number
  loadFactor: number
  tempAmbient: number
}
@Component({
  selector: 'app-dtr-prediction-analysis',
  templateUrl: './dtr-prediction-analysis.component.html',
  styleUrl: './dtr-prediction-analysis.component.scss',
})
export class DtrPredictionAnalysisComponent implements OnInit {
  chartData: ChartData[] = []
  series: any[] = []
  public openChartInfo = false

  graphDate: any[] = []

  deviceList: { key: string; value: string }[] = []
  analysisInfo: AnalysisInfo | undefined
  feederAreaList: { key: string; value: string }[] = []

  deviceId: any
  feederId: any
  deviceLocation = 'Panchkula'

  isAnalysisInfoVisible = false
  showLoader: boolean = true
  @Output()
  readonly triggerDataFromHeader = new EventEmitter<any>()

  constructor(
    public store: Store,
    private spinner: NgxSpinnerService,
    public commonService: CommonService,
  ) {}

  ngOnInit(): void {
    this.getFeederList()
  }

  getFeederList(): void {
    this.spinner.show()
    this.store.dispatch(new GetNotCommonFeederAreaList()).subscribe(
      (res) => {
        if (res.common.feederAreaListNotCommon.length > 0) {
          this.feederAreaList = res.common.feederAreaListNotCommon
          this.feederId = this.feederAreaList[0]
          this.onFeederAreaChange(this.feederId)
        }
      },
      (error) => {
        this.spinner.hide()
      },
    )
  }

  onFeederAreaChange(feederAreaId: any) {
    this.store
      .dispatch(new GetDeviceIdByFeederNotCommon(feederAreaId.key))
      .subscribe(
        (x) => {
          this.spinner.hide()
          this.deviceList = x.common.deviceListNotCommon
          this.deviceId = this.deviceList[0]
          if (this.deviceList.length > 0) {
            this.onGetAnalysis()
          }
        },
        (error) => {
          this.spinner.hide()
        },
      )
  }

  onGetAnalysis(): void {
    this.spinner.show()
    this.store
      .dispatch(new GetDTRPredictionDetail(this.deviceId.value))
      .subscribe((res: any) => {
        this.spinner.hide()

        this.isAnalysisInfoVisible = true
        if (res.dtrprediction.itmsDetailsData) {
          this.analysisInfo = res.dtrprediction.itmsDetailsData
          this.getGraphDetails()
        }
      })
  }

  convertToDate(dateString: string): any {
    if (dateString) {
      const [time, date] = dateString?.split(' ')
      const [hours, minutes, seconds] = time?.split(':').map(Number)
      const [day, month, year] = date.split('-')?.map(Number)
      return new Date(year, month - 1, day, hours, minutes, seconds)
    }
    return ''
  }

  getGraphDetails() {
    this.showLoader = true
    this.store
      .dispatch(new GetGraphDetails(this.deviceId.value))
      .subscribe((res) => {
        this.showLoader = false
        const data = res.dtrprediction.graphDetails
        this.series = []
        const interval = 1000
        const aggregatedData = {
          date_Time: this.aggregateData(data.date_Time, interval),
          temp_r: this.aggregateData(data.temp_r, interval),
          temp_y: this.aggregateData(data.temp_y, interval),
          temp_b: this.aggregateData(data.temp_b, interval),
          temp_oil: this.aggregateData(data.temp_oil, interval),
          load_factor: this.aggregateData(data.load_factor, interval),
          temp_ambient: this.aggregateData(data.temp_ambient, interval),
        }

        this.series = [
          { name: 'date_Time', data: aggregatedData.date_Time },
          { name: 'temp_r', data: aggregatedData.temp_r },
          { name: 'temp_y', data: aggregatedData.temp_y },
          { name: 'temp_b', data: aggregatedData.temp_b },
          { name: 'temp_oil', data: aggregatedData.temp_oil },
          { name: 'load_factor', data: aggregatedData.load_factor },
          { name: 'temp_ambient', data: aggregatedData.temp_ambient },
        ]
      })
  }

  aggregateData(data: number[], interval: number): number[] {
    const aggregatedData = []
    for (let i = 0; i < data.length; i += interval) {
      const chunk = data.slice(i, i + interval)
      const sum = chunk.reduce((acc, val) => acc + val, 0)
      const avg = sum / chunk.length
      aggregatedData.push(avg)
    }
    return aggregatedData
  }

  ondeviceChange(): void {
    this.onGetAnalysis()
  }

  chartInfoClick() {
    this.openChartInfo = true
  }

  onCloseDialog(): void {
    this.openChartInfo = false
  }
}
