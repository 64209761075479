import { Injectable } from '@angular/core'
import { Selector, Action, StateContext, State } from '@ngxs/store'
import { tap } from 'rxjs'
import { GetAllAlertSettings, UpdateAlertSettings } from '.'
import { AlertSettingsService } from '@app/services/alert-settings'

export class AlertSettingsStateInfo {
  alertSettingsData?: any
  isAlertSettingsUpdated?: boolean
}

@State<AlertSettingsStateInfo>({
  name: 'alertSettings',
  defaults: {},
})
@Injectable()
export class AlertSettingsState {
  constructor(private alertSettingsService: AlertSettingsService) {}

  @Selector()
  static getAllAlertSettings(state: AlertSettingsStateInfo) {
    return state.alertSettingsData
  }

  @Action(GetAllAlertSettings)
  getAllAlertSettings(
    { getState, patchState }: StateContext<AlertSettingsStateInfo>,
    action: GetAllAlertSettings,
  ) {
    return this.alertSettingsService
      .getAllAlertSettings(action.queryParams)
      .pipe(
        tap((res: any) => {
          patchState({
            alertSettingsData: res,
          })
        }),
      )
  }

  @Action(UpdateAlertSettings)
  updateAlertSettings(
    { getState, patchState }: StateContext<AlertSettingsStateInfo>,
    action: UpdateAlertSettings,
  ) {
    return this.alertSettingsService
      .updateAlertSettings(action.alertSettingsData, action.deviceId)
      .pipe(
        tap((res: any) => {
          patchState({
            isAlertSettingsUpdated: res,
          })
        }),
      )
  }
}
