import { ChangeDetectorRef, Component, OnInit } from '@angular/core'
import { NotificationMessage } from '@app/enums/notification'
import {
  CommonState,
  GetDeviceIdByFeederNotCommon,
  GetNotCommonFeederAreaList,
  SoftwareUpload,
} from '@app/store'
import { Select, Store } from '@ngxs/store'
import { NgxSpinnerService } from 'ngx-spinner'
import { ToastrService } from 'ngx-toastr'
import { Observable } from 'rxjs'
import swal from 'sweetalert'

@Component({
  selector: 'app-ota-update',
  templateUrl: './ota-update.component.html',
  styleUrl: './ota-update.component.scss',
})
export class OtaUpdateComponent implements OnInit {
  areaArray: [] = []
  deviceArray: [] = []
  softwareData: any = {}
  selectedFiles: any[] = []
  imageUrl: any
  softwareUploadList: any[] = []

  formData: FormData | null = new FormData()
  selectedDeviceId: any
  selectedFeederAreaId: any

  isUploadButtonDisabled: boolean = true

  deviceList: { key: string; value: string }[] = []
  feederAreaList: { key: string; value: string }[] = []

  @Select(CommonState.hasAddPermission)
  hasAddPermission$!: Observable<boolean>

  @Select(CommonState.hasEditPermission)
  hasEditPermission$!: Observable<boolean>
  constructor(
    private store: Store,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.setFeederAreaListBinding()
  }

  setFeederAreaListBinding() {
    this.spinner.show()
    this.store.dispatch(new GetNotCommonFeederAreaList()).subscribe(
      (res) => {
        this.spinner.hide()
        if (res.common.feederAreaListNotCommon.length > 0) {
          this.feederAreaList = res.common.feederAreaListNotCommon
        }
      },
      (error) => {
        this.spinner.hide()
      },
    )
  }

  onSubmit(): void {
    let textMessage = `You want to update OTA status.`

    swal({
      title: 'Are you sure ?',
      text: textMessage,
      icon: 'warning',
      buttons: {
        cancel: {
          text: 'Cancel',
          visible: true,
          closeModal: true,
        },
        confirm: {
          text: 'Yes',
        },
      },
      dangerMode: true,
    }).then((confirmed) => {
      if (confirmed) {
        if (
          this.selectedDeviceId !== null &&
          this.selectedDeviceId !== undefined
        ) {
          this.spinner.show()

          this.store
            .dispatch(
              new SoftwareUpload(this.selectedDeviceId.value, this.formData),
            )
            .subscribe(
              (res) => {
                this.spinner.hide()
                this.softwareUploadList = []
                this.deviceList = []
                this.formData = null
                this.selectedDeviceId = null
                this.selectedFeederAreaId = null
                this.isUploadButtonDisabled = true
                this.toastr.success(NotificationMessage.fileUploadSuccessMsg)
                this.setFeederAreaListBinding()
              },
              (error) => {
                this.spinner.hide()
                console.error(error)
              },
            )
        }
      }
    })
  }

  onFeederAreaChange(feederAreaId: any) {
    this.store
      .dispatch(new GetDeviceIdByFeederNotCommon(feederAreaId.key))
      .subscribe((x) => {
        this.deviceList = x.common.deviceListNotCommon
        this.selectedDeviceId = this.deviceList[0]
        this.onUploadValidation()
      })
  }

  onFileSelect(event: any): void {
    if (
      event &&
      event.target &&
      event.target.files &&
      event.target.files.length > 0
    ) {
      const file = event.target.files[0]
      this.softwareUploadList = [{ fileName: file.name }]
      this.formData?.append('file', file, file.name)
      this.onUploadValidation()
    }
  }

  getFileObjectURL(file: any): any {
    return URL.createObjectURL(file[0].rawFile)
  }

  onDeleteUploadFileFromListFile(index: any) {
    this.softwareUploadList.splice(index, 1)
    this.onUploadValidation()
  }

  onUploadValidation(): void {
    const isFeederAreaIdValid =
      this.selectedFeederAreaId !== undefined &&
      this.selectedFeederAreaId !== null
    const isDeviceIdValid =
      this.selectedDeviceId !== undefined && this.selectedDeviceId !== null

    if (
      isFeederAreaIdValid &&
      isDeviceIdValid &&
      this.softwareUploadList.length != 0
    ) {
      this.isUploadButtonDisabled = false
    } else {
      this.isUploadButtonDisabled = true
    }

    setTimeout(() => this.cdr.detectChanges(), 10)
  }
}
