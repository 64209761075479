import { Component, EventEmitter, Output, ViewChild } from '@angular/core'
import { SeparateDateEnum } from '@app/enums'
import { GetAnalyticsVoltageChart } from '@app/store'
import { Store } from '@ngxs/store'
import { Subject } from 'rxjs'
import { CommonAnalyticsGraphComponent } from '../common-analytics-graph'

@Component({
  selector: 'app-voltage-graph',
  templateUrl: './voltage-graph.component.html',
  styleUrl: './voltage-graph.component.scss',
})
export class VoltageGraphComponent {
  triggerGetUpdatedTime: Subject<any> = new Subject<any>()
  isCurrentGraph = false
  chartData: any[] = []
  isCurrentGraphData = false
  deviceId: any
  param: any = {
    deviceId: 0,
    year: undefined,
    month: undefined,
    day: undefined,
    type: undefined,
  }
  triggerCurrentCardApi: Subject<any> = new Subject<any>()
  @Output() chartDataChange = new EventEmitter<any[]>()
  infoText: string =
    'The graph displays the real time color waveforms of each phases voltages wrt data-time.'
  @ViewChild(CommonAnalyticsGraphComponent)
  commonAnalyticsGraphComponent!: CommonAnalyticsGraphComponent
  showLoader: boolean = true
  isNoRecordFound = false
  roundedMaxValue: number | any
  constructor(private store: Store) {}

  ngOnInit(): void {}

  onAnalyticsFilterChange(event: any) {
    const param = {
      deviceId: this.deviceId,
      year: event.year,
      month: event.month,
      day: event.day,
      type: event.type,
    }
    this.getChartData(param)
  }

  getDataByHeaderData(event: any): void {
    this.deviceId = event.deviceId

    this.param.deviceId = this.deviceId

    this.getChartData(this.param)
    this.triggerCurrentCardApi.next(this.deviceId)
    this.triggerGetUpdatedTime.next(true)
  }

  getChartData(param: any): void {
    this.showLoader = true
    this.store.dispatch(new GetAnalyticsVoltageChart(param)).subscribe(
      (res: any) => {
        this.showLoader = false
        this.chartData = []
        if (res.analytics.analyticsValtageChartData.length > 0) {
          this.chartData = res.analytics.analyticsValtageChartData
          this.chartDataChange.emit(this.chartData)

          // Find the maximum value from voltagePhase_R, voltagePhase_Y, voltagePhase_B
          let maxVoltage = Math.max(
            ...this.chartData.map((data: any) =>
              Math.max(
                data.voltagePhase_B,
                data.voltagePhase_R,
                data.voltagePhase_Y,
              ),
            ),
          )
          maxVoltage += 10
          const roundedValue = Math.ceil(maxVoltage / 10) * 10

          this.roundedMaxValue = roundedValue
        }
        this.commonAnalyticsGraphComponent.isCurrentGraphData = true
        this.isNoRecordFound =
          res.analytics.analyticsValtageChartData.length <= 0
      },
      (error) => {
        this.showLoader = false
      },
    )
  }

  onAllButtonClick() {
    this.param = {
      deviceId: this.deviceId,
      year: undefined,
      month: undefined,
      day: undefined,
      type: undefined,
    }

    this.getChartData(this.param)
  }

  getAllYearData(): void {
    this.param = {
      deviceId: this.deviceId,
      year: undefined,
      month: undefined,
      day: undefined,
      type: SeparateDateEnum.YEAR,
    }
    this.getChartData(this.param)
  }
}
