import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable, of } from 'rxjs'
import { environment } from 'src/environments'

@Injectable()
export class FeederService {
  feederResponse: any = {
    columns: [
      {
        field: 'feederId',
        name: 'Feeder Id',
        hasColumn: [],
        isSelected: true,
        isLocked: false,
      },
      {
        field: 'feederName',
        name: 'Feeder name',
        hasColumn: [],
        isSelected: true,
        isLocked: false,
      },
    ],
    data: [
      {
        feederId: '123',
        feederName: '123112',
      },
      {
        feederId: '111',
        feederName: '1211312',
      },
      {
        feederId: '11223',
        feederName: '1211111312',
      },
    ],
  }
  constructor(private http: HttpClient) {}

  saveFeederDevice(feederDeviceRequestBody: any): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    }

    return this.http.post<any>(
      `${environment.apiVersionUrl}FeederDevice`,
      JSON.stringify(feederDeviceRequestBody),
      headers,
    )
  }

  confirmationDeviceForFeeder(feederDeviceRequestBody: any): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    }

    return this.http.post<any>(
      `${environment.apiVersionUrl}FeederDevice/ConfirmationDeviceForFeeder`,
      JSON.stringify(feederDeviceRequestBody),
      headers,
    )
  }

  GetFeederListWithDevice(queryParams: any): Observable<HttpResponse<any>> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    }
    return this.http.post<any>(
      `${environment.apiVersionUrl}FeederDevice/GetFeederListWithDevice`,
      JSON.stringify(queryParams),
      headers,
    )
  }

  createFeeder(param: any): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    }
    return this.http.post<any>(
      `${environment.apiVersionUrl}Feeder/AddOrUpdateFeeder`,
      param,
      headers,
    )
  }

  getFeederList(queryParam: any): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    }
    return this.http.post<any>(
      `${environment.apiVersionUrl}Feeder/GetFeederDetails`,
      queryParam,
      headers,
    )
  }

  deleteFeeder(feederId: any): Observable<any> {
    return this.http.delete(
      `${environment.apiVersionUrl}Feeder/DeleteFeeder/${feederId}`,
    )
  }
}
