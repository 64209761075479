import { Component, OnInit, ViewChild } from '@angular/core'
import {
  CommonGridComponent,
  CommonService,
  CommonState,
  GetAllSubStation,
  SSSLDDashboardEnum,
  SSSLDDashboardtList,
} from '@app/index'
import { Select, Store } from '@ngxs/store'
import { GridDataResult } from '@progress/kendo-angular-grid'
import { NgxSpinnerService } from 'ngx-spinner'
import { interval, Observable, Subject, takeUntil } from 'rxjs'

@Component({
  selector: 'app-sslddashboard',
  templateUrl: './sslddashboard.component.html',
  styleUrls: ['./sslddashboard.component.scss'],
})
export class SslddashboardComponent implements OnInit {
  triggerGetUpdatedTime: Subject<any> = new Subject<any>()
  @ViewChild(CommonGridComponent) commonGridComponent:
    | CommonGridComponent
    | undefined

  SSLDDashboardData: GridDataResult | any
  columns: any
  @Select(CommonState.moduleId)
  moduleId$!: Observable<number>
  isDataLoaded = false
  subStationList: any[] = []
  selectedName = { substationId: '', substationName: '' }
  filterQueryJson: string | any
  private destroy$ = new Subject<void>()

  constructor(
    private store: Store,
    private spinner: NgxSpinnerService,
    private commonService: CommonService,
  ) {}

  ngOnInit(): void {
    setTimeout(() => {
      this.triggerGetUpdatedTime.next(true)
    }, 0)
    this.getAllSubStationList()
    this.setupInterval()
  }

  onNameChange(data: any) {
    this.selectedName = data
    this.getSSSLDDashboardtList()
  }

  setupInterval() {
    interval(60000)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.getSSSLDDashboardtList()
      })
  }

  ngOnDestroy() {
    this.destroy$.next()
    this.destroy$.complete()
  }

  onFilterChangeSQL(sqlQuery: any) {
    this.filterQueryJson =
      this.commonService.convertFilterToDateFormat(sqlQuery)
    this.getSSSLDDashboardtList()
  }

  getAllSubStationList(): void {
    this.spinner.show()
    this.store.dispatch(new GetAllSubStation()).subscribe(
      (res: any) => {
        if (res.ssslddashboard.subStationList.length > 0) {
          this.subStationList = res.ssslddashboard.subStationList
          this.selectedName = this.subStationList[0]
          this.getSSSLDDashboardtList()
        }
      },
      (error) => {
        this.spinner.hide()
      },
    )
  }

  getSSSLDDashboardtList(): void {
    this.spinner.show()
    let queryParams = {
      moduleId: this.store.selectSnapshot(CommonState.moduleId),
      filterQueryJson: this.filterQueryJson,
    }
    this.store
      .dispatch(
        new SSSLDDashboardtList(queryParams, this.selectedName.substationId),
      )
      .subscribe(
        (res) => {
          this.spinner.hide()
          const { data } = res.ssslddashboard.ssslddashboardListData

          this.isDataLoaded = true
          this.SSLDDashboardData = {
            data: data.rows ? data.rows : [],
            total: 0,
            columns: data.columns,
          }
        },
        (error) => {
          this.spinner.hide()
        },
      )
  }

  objectKeys(obj: any): string[] {
    return Object.keys(obj)
  }

  onSortChange(sortData: any): void {}

  onPaginationChange({ skip, take }: any): void {}

  onColumnSelector(): void {
    this.commonGridComponent?.onColumnSelector()
  }
}
