import { Injectable } from '@angular/core'
import { Selector, Action, StateContext, State } from '@ngxs/store'
import { tap } from 'rxjs'
import {
  GetSoftwareStatus,
  SaveSoftware,
  SoftwareUpload,
} from './software.action'
import { SoftwareService } from '@app/services'

export class SoftwareStateInfo {
  softwareStatusData?: any
  columns?: any[] = []
  id?: any
  isSuccess?: boolean
  uploadSoftwareString?: any
}

@State<SoftwareStateInfo>({
  name: 'software',
  defaults: {
    isSuccess: false,
  },
})
@Injectable()
export class SoftwareState {
  constructor(private softwareService: SoftwareService) {}

  @Selector()
  static getAlertSettingData(state: SoftwareStateInfo) {
    return state.softwareStatusData
  }

  @Action(GetSoftwareStatus)
  getSoftwareStatus(
    { getState, patchState }: StateContext<SoftwareStateInfo>,
    action: GetSoftwareStatus,
  ) {
    return this.softwareService.getSoftwareStatus(action.queryParams).pipe(
      tap((res: any) => {
        patchState({
          softwareStatusData: res,
          columns: this.softwareService.columns,
        })
      }),
    )
  }

  @Action(SaveSoftware)
  saveThresholdSettings(
    { getState, patchState }: StateContext<SoftwareStateInfo>,
    action: SaveSoftware,
  ) {
    return this.softwareService.saveSoftware(action.data).pipe(
      tap((res) => {
        patchState({
          isSuccess: res.isSuccess,
          id: res.data,
        })
      }),
    )
  }

  @Action(SoftwareUpload)
  softwareUpload(
    { getState, patchState }: StateContext<SoftwareStateInfo>,
    action: SoftwareUpload,
  ) {
    return this.softwareService
      .softwareUpload(action.deviceId, action.file)
      .pipe(
        tap((res: any) => {
          patchState({
            uploadSoftwareString: res,
          })
        }),
      )
  }
}
