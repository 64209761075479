const style = (name, value) => {
    return { name: name, value: value };
};
export const alignLeftRules = [
    { node: 'paragraph', style: [style('text-align', 'left')] },
    { node: 'heading', style: [style('text-align', 'left')] },
    { node: 'table_caption_external', style: [style('text-align', 'left')] }
];
export const alignRightRules = [
    { node: 'paragraph', style: [style('text-align', 'right')] },
    { node: 'heading', style: [style('text-align', 'right')] },
    { node: 'table_caption_external', style: [style('text-align', 'right')] }
];
export const alignCenterRules = [
    { node: 'paragraph', style: [style('text-align', 'center')] },
    { node: 'heading', style: [style('text-align', 'center')] },
    { node: 'table_caption_external', style: [style('text-align', 'center')] }
];
export const alignJustifyRules = [
    { node: 'paragraph', style: [style('text-align', 'justify')] },
    { node: 'heading', style: [style('text-align', 'justify')] },
    { node: 'table_caption_external', style: [style('text-align', 'justify')] }
];
export const alignRemoveRules = [
    { node: 'paragraph', style: [style('text-align', '')] },
    { node: 'heading', style: [style('text-align', '')] },
    { node: 'table_caption_external', style: [style('text-align', '')] }
];
