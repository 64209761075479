import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { DataTypeEnum } from '@app/enums'
import { Observable } from 'rxjs'
import { environment } from 'src/environments/environment'

@Injectable()
export class DataReportService {
  constructor(private http: HttpClient) {}

  columns = [
    { field: 'deviceId', title: 'Device Id', hasColumn: [] },
    {
      field: 'updatedOn',
      title: 'Updated On',
      hasColumn: [],
      dataTypeId: DataTypeEnum.DateTime,
    },
    {
      field: 'loadFactor',
      title: 'load Factor',
      hasColumn: [
        { field: 'loadFactorColor', title: 'load Factor Color' },
        { field: 'loadFactorValue', title: 'load Factor Value' },
      ],
    },
    {
      field: 'phaseCurrent',
      title: 'Phase Current',
      hasColumn: [
        { field: 'phaseCurrentB', title: 'B' },
        { field: 'phaseCurrentColorB', title: 'B' },
        { field: 'phaseCurrentColorR', title: 'R' },
        { field: 'phaseCurrentColorY', title: 'Y' },
        { field: 'phaseCurrentR', title: 'Y' },
        { field: 'phaseCurrentY', title: 'Y' },
      ],
    },
    {
      field: 'phaseLoadFactor',
      title: 'Phase Load Factor',
      hasColumn: [
        { field: 'phaseLoadFactorB', title: 'B' },
        { field: 'phaseLoadFactorColorB', title: 'B' },
        { field: 'phaseLoadFactorColorR', title: 'R' },
        { field: 'phaseLoadFactorColorY', title: 'Y' },
        { field: 'phaseLoadFactorColorY', title: 'Y' },
        { field: 'phaseLoadFactorY', title: 'Y' },
      ],
    },
    {
      field: 'phaseVoltage',
      title: 'Phase Voltage',
      hasColumn: [
        { field: 'phaseVoltageB', title: 'B' },
        { field: 'phaseVoltageColorB', title: 'B' },
        { field: 'phaseVoltageColorR', title: 'R' },
        { field: 'phaseVoltageColorY', title: 'Y' },
        { field: 'phaseVoltageR', title: 'R' },
        { field: 'phaseVoltageY', title: 'Y' },
      ],
    },
  ]

  getDataReportList(queryParam: any): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    }
    return this.http.post<any>(
      `${environment.apiVersionUrl}DataReport/GetDataReport`,
      queryParam,
      headers,
    )
  }
}
