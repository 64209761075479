import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import {
  AnalyticsComponent,
  AuthGuard,
  CurrentGraphComponent,
  LoadComponent,
  LoadTempGraphComponent,
  VoltageGraphComponent,
} from '@app/index'

const routes: Routes = [
  { path: '', component: AnalyticsComponent, canActivate: [AuthGuard] },
  { path: 'load', component: LoadComponent, canActivate: [AuthGuard] },
  {
    path: 'current-graph',
    component: CurrentGraphComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'voltage-graph',
    component: VoltageGraphComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'load-temp-graph',
    component: LoadTempGraphComponent,
    canActivate: [AuthGuard],
  },
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AnalyticsRoutingModule {}
