import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import {
  CommonsModule,
  ModuleHistoryComponent,
  ModuleHistoryRoutingModule,
} from '@app/index'
import { SharedModule } from '@shared//shared.module'
import { PipeModule } from '@app/pipe/pipe.module'

@NgModule({
  declarations: [ModuleHistoryComponent],
  imports: [
    CommonModule,
    ModuleHistoryRoutingModule,
    SharedModule,
    PipeModule,
    CommonsModule,
  ],
})
export class ModuleHistoryModule {}
