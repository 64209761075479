import { Injectable } from '@angular/core'
import {
  GetDeviceIdByFeeder,
  GetDeviceIdByFeederAll,
  GetDeviceIdByFeederNotCommon,
  GetFeederAreaList,
  GetFeederAreaListBasedOnFeederType,
  GetLoginToken,
  GetMenu,
  GetMenuWithColumns,
  GetNotCommonFeederAreaList,
  GetRefreshLoginToken,
  MenuList,
  ResetStateData,
  SaveUserModuleGridColumn,
  SetDeviceId,
  SetDeviceIdAll,
  SetFeederAreaId,
  SetModulePermission,
} from '@app/index'
import { CommonService, DeviceService } from '@app/services'
import { Action, Selector, State, StateContext } from '@ngxs/store'
import { map, Observable, tap } from 'rxjs'

export class CommonStateInfo {
  loginToken?: any
  feederAreaList?: any[]
  feederAreaListByFeederType?: any[]
  feederAreaId?: any = {}
  deviceList?: any[]
  deviceListAll?: any[]
  deviceId?: any = {}
  deviceIdAll?: any = {}
  Menu: any[] = []
  accountListPermissionMenu: any[] = []
  MenuWithColumns?: any[] = []
  deviceListNotCommon?: any[]
  feederAreaListNotCommon?: any[]
  deviceIdNotCommon?: any = {}
  analysisInfo?: any
  hasAddPermission?: boolean
  hasEditPermission?: boolean
  hasDeletePermission?: boolean
  moduleId?: number
  isUserModuleGridColumnUpdate?: boolean
}

@State<CommonStateInfo>({
  name: 'common',
  defaults: {
    Menu: [],
    accountListPermissionMenu: [],
    feederAreaId: null,
    deviceIdNotCommon: null,
    hasAddPermission: false,
    hasEditPermission: false,
    hasDeletePermission: false,
    isUserModuleGridColumnUpdate: false,
  },
})
@Injectable()
export class CommonState {
  constructor(
    private commonService: CommonService,
    private deviceService: DeviceService,
  ) {}

  @Selector()
  static hasEditPermission(state: CommonStateInfo): boolean {
    return state.hasEditPermission ?? false
  }

  @Selector()
  static hasAddPermission(state: CommonStateInfo): boolean {
    return state.hasAddPermission ?? false
  }

  @Selector()
  static moduleId(state: CommonStateInfo): number {
    return state.moduleId ?? 0
  }

  @Selector()
  static hasDeletePermission(state: CommonStateInfo): boolean {
    return state.hasDeletePermission ?? false
  }

  @Selector()
  static menu(state: CommonStateInfo): any {
    return state.Menu
  }

  @Selector()
  static accountListPermission(state: CommonStateInfo): any {
    return state.accountListPermissionMenu
  }

  @Selector()
  static getFeederAreaList(state: CommonStateInfo): any {
    return state.feederAreaList
  }

  @Selector()
  static getDeviceList(state: CommonStateInfo): any {
    return state.deviceList
  }

  @Selector()
  static getDeviceListAll(state: CommonStateInfo): any {
    return state.deviceListAll
  }

  @Selector()
  static getFeederAreaId(state: CommonStateInfo): any {
    return state.feederAreaId
  }

  @Selector()
  static getDeviceId(state: CommonStateInfo): any {
    return state.deviceId
  }

  @Selector()
  static getDeviceIdAll(state: CommonStateInfo): any {
    return state.deviceIdAll
  }

  @Selector()
  static getDeviceIdNotCommon(state: CommonStateInfo): any {
    return state.deviceListNotCommon
  }

  @Action(SetFeederAreaId)
  setFeeder(
    { getState, setState }: StateContext<CommonStateInfo>,
    action: SetFeederAreaId,
  ) {
    const state = getState()
    setState({
      ...state,
      feederAreaId: action.feederAreaId,
    })
  }

  @Action(SetDeviceId)
  setDevice(
    { getState, setState }: StateContext<CommonStateInfo>,
    action: SetDeviceId,
  ) {
    const state = getState()
    setState({
      ...state,
      deviceId: action.deviceId,
    })
  }

  @Action(SetDeviceIdAll)
  setDeviceAll(
    { getState, setState }: StateContext<CommonStateInfo>,
    action: SetDeviceIdAll,
  ) {
    const state = getState()
    setState({
      ...state,
      deviceIdAll: action.deviceIdAll,
    })
  }

  @Action(ResetStateData)
  resetStateData(
    { getState, setState }: StateContext<CommonStateInfo>,
    action: ResetStateData,
  ) {
    const state = getState()
    setState({
      ...state,
      feederAreaId: null,
      deviceId: null,
    })
  }

  @Action(GetLoginToken)
  getLoginToken(
    { getState, patchState }: StateContext<CommonStateInfo>,
    action: GetLoginToken,
  ) {
    return this.commonService
      .getLoginToken(action.userName, action.password)
      .pipe(
        tap((res) => {
          patchState({
            loginToken: res,
          })
        }),
      )
  }

  @Action(GetRefreshLoginToken)
  getRefreshLoginToken(
    { getState, patchState }: StateContext<CommonStateInfo>,
    action: GetRefreshLoginToken,
  ) {
    return this.commonService.getRefreshLoginToken(action.refreshToken).pipe(
      tap((res) => {
        patchState({
          loginToken: res,
        })
      }),
    )
  }

  @Action(GetMenu)
  getMenu(
    { patchState }: StateContext<CommonStateInfo>,
    action: GetMenu,
  ): Observable<any> {
    return this.commonService.getMenu().pipe(
      map((res) => {
        const accountListPermission = res.filter(
          (item) => item.id === MenuList['Account List'],
        )

        // Assuming MenuList is an enumeration or object with the ID you want to filter
        const filteredRes = res.filter(
          (item) => item.id !== MenuList['Account List'],
        )

        return { filteredRes, accountListPermission: accountListPermission }
      }),
      tap(({ filteredRes, accountListPermission }) => {
        patchState({
          Menu: filteredRes,
          accountListPermissionMenu: accountListPermission,
        })
      }),
    )
  }

  @Action(GetMenuWithColumns)
  getMenuWithColumns(
    { patchState }: StateContext<CommonStateInfo>,
    action: GetMenuWithColumns,
  ): Observable<any> {
    return this.commonService.getMenuWithColumns().pipe(
      tap((res) => {
        patchState({
          MenuWithColumns: res,
        })
      }),
    )
  }

  @Action(GetFeederAreaListBasedOnFeederType)
  GetFeederAreaListBasedOnFeederType(
    { getState, setState }: StateContext<CommonStateInfo>,
    action: GetFeederAreaListBasedOnFeederType,
  ) {
    return this.commonService
      .getFeederAreaListBasedOnFeederType(action.isDTR)
      .pipe(
        tap((res) => {
          let feederAreaListByFeederTypeList: any[] = []
          if (res && res.length > 0) {
            feederAreaListByFeederTypeList = [
              { key: 'All', value: 'All' },
              ...res.map((feeder: any) => ({
                key: feeder.feederName,
                value: feeder.feederName,
              })),
            ]
          }
          setState({
            ...getState(),
            feederAreaListByFeederType: feederAreaListByFeederTypeList,
          })
        }),
      )
  }

  @Action(GetFeederAreaList)
  getFeederAreaList(
    { getState, setState }: StateContext<CommonStateInfo>,
    action: GetFeederAreaList,
  ) {
    return this.commonService.getFeederAreaList().pipe(
      tap((res) => {
        const state = getState()
        let feederAreaList: any[] = []
        if (res !== null && res !== undefined && res.length > 0) {
          feederAreaList = [
            { key: 'All', value: 'All' },
            ...res.map((feeder: any) => ({
              key: feeder.feederName,
              value: feeder.feederName,
            })),
          ]
        }
        setState({
          ...state,
          feederAreaList: feederAreaList,
          feederAreaId: feederAreaList[0],
        })
      }),
    )
  }

  @Action(GetDeviceIdByFeeder)
  getDeviceIdByFeeder(
    { getState, patchState }: StateContext<CommonStateInfo>,
    action: GetDeviceIdByFeeder,
  ) {
    const state = getState()

    return this.deviceService.getDeviceIdByFeeder(state.feederAreaId?.key).pipe(
      tap((res: any) => {
        let deviceList: any[] = []

        if (res !== null && res !== undefined && res.length > 0) {
          deviceList = [
            ...res.map((device: any) => ({
              key: device.name + '_' + device.kva + ' KVA',
              value: device.id,
            })),
          ]
        }

        patchState({
          deviceList: deviceList,
          deviceId: deviceList.length > 0 ? deviceList[0] : null,
        })
      }),
    )
  }

  @Action(GetDeviceIdByFeederAll)
  getDeviceIdByFeederAll(
    { getState, patchState }: StateContext<CommonStateInfo>,
    action: GetDeviceIdByFeederAll,
  ) {
    const state = getState()

    return this.deviceService.getDeviceIdByFeeder(state.feederAreaId?.key).pipe(
      tap((res: any) => {
        let deviceList: any[] = []

        if (res !== null && res !== undefined && res.length > 0) {
          deviceList = [
            ...res.map((device: any) => ({
              key: device.name + '_' + device.kva + ' KVA',
              value: device.id,
            })),
          ]
          deviceList = [...deviceList, { key: 'All', value: 'All' }]
        }
        patchState({
          deviceListAll: deviceList,
          deviceIdAll: deviceList.length > 0 ? deviceList[0] : null,
        })
      }),
    )
  }

  @Action(GetNotCommonFeederAreaList)
  getNotCommonFeederAreaList(
    { getState, patchState }: StateContext<CommonStateInfo>,
    action: GetNotCommonFeederAreaList,
  ) {
    return this.commonService.getFeederAreaList().pipe(
      tap((res: any) => {
        const feederAreaList = [
          ...res.map((feeder: any) => ({
            key: feeder.feederName,
            value: feeder.feederName,
          })),
        ]

        patchState({
          feederAreaListNotCommon: feederAreaList,
          // deviceIdNotCommon: feederAreaList[0],
        })
      }),
    )
  }

  @Action(GetDeviceIdByFeederNotCommon)
  getDeviceIdByFeederNotCommon(
    { getState, patchState }: StateContext<CommonStateInfo>,
    action: GetDeviceIdByFeederNotCommon,
  ) {
    return this.deviceService.getDeviceIdByFeeder(action.feederAreaId).pipe(
      tap((res: any) => {
        const deviceList = [
          ...res.map((device: any) => ({
            key: device.id,
            value: device.name,
          })),
        ]

        patchState({
          deviceListNotCommon: deviceList,
          deviceIdNotCommon: deviceList[0],
        })
      }),
    )
  }

  @Action(SetModulePermission)
  setModulePermission(
    { patchState }: StateContext<CommonStateInfo>,
    action: SetModulePermission,
  ) {
    patchState({
      moduleId: action.moduleData.id,
      hasAddPermission: action.moduleData.hasAddPermission,
      hasEditPermission: action.moduleData.hasEditPermission,
      hasDeletePermission: action.moduleData.hasDeletePermission,
    })
  }

  @Action(SaveUserModuleGridColumn)
  saveUserModuleGridColumn(
    { patchState }: StateContext<CommonStateInfo>,
    action: SaveUserModuleGridColumn,
  ): Observable<any> {
    return this.commonService
      .saveUserModuleGridColumn(action.saveUserModuleGridColumnList)
      .pipe(
        tap((res) => {
          patchState({
            isUserModuleGridColumnUpdate: res,
          })
        }),
      )
  }
}
