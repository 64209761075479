import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { UseEnumValuePipe } from './use-enum-value.pipe'

@NgModule({
  declarations: [UseEnumValuePipe],
  imports: [CommonModule],
  exports: [UseEnumValuePipe],
})
export class PipeModule {}
