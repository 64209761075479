import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable, of, switchMap } from 'rxjs'
import { environment } from 'src/environments'

@Injectable()
export class DTRDataService {
  constructor(private http: HttpClient) {}

  dtrDataDownload(dtrDowloadData: any): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    }
    return this.postExportRequest(
      `${environment.apiVersionUrl}Download/DownloadDTRData`,
      dtrDowloadData,
      headers,
    ).pipe(
      switchMap((response) => {
        this.download(response)
        return of()
      }),
    )
  }

  backupDataInExcel(deviceId: string, formateType: any): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    }
    return this.postExportRequest(
      `${environment.apiVersionUrl}Backup/BackupDatabase/${deviceId}/${formateType}`,
      { deviceId, formateType },
      headers,
    ).pipe(
      switchMap((response) => {
        this.download(response)
        return of()
      }),
    )
  }

  deleteDatabase(deviceId: any): Observable<any> {
    return this.http.delete<any>(
      `${environment.apiVersionUrl}Backup/DeleteDatabase/${deviceId}`,
    )
  }

  postExportRequest(
    endpoint: string,
    params?: any | null,
    options?: {
      headers?: HttpHeaders | { [header: string]: string | string[] }
    },
  ): Observable<HttpResponse<Blob>> {
    return this.http.post(endpoint, params, {
      ...options,
      responseType: 'blob',
      observe: 'response',
    })
  }

  download(response: HttpResponse<Blob>): void {
    let fileName = response.headers
      .get('Content-Disposition')
      ?.split(';')
      .map((x) => (x ?? '').trimLeft().split('='))
      .find((x) => x[0] === 'filename')
      ?.pop()

    const a = document.createElement('a')
    let navigator: any
    navigator = window.navigator

    if (fileName !== undefined && fileName !== null) {
      fileName = fileName.replace(/"/g, '')
    }

    if (navigator && navigator.msSaveOrOpenBlob) {
      navigator.msSaveOrOpenBlob(response, fileName)
    } else {
      a.href = URL.createObjectURL(response.body || new Blob())
      a.download = fileName ?? ''
      a.click()
    }
  }
}
