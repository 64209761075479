import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { AuthGuard, ColumnPermissionComponent } from '@app/index'

const routes: Routes = [
  { path: '', component: ColumnPermissionComponent, canActivate: [AuthGuard] },
  {
    path: 'column-permission',
    component: ColumnPermissionComponent,
    canActivate: [AuthGuard],
  },
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ColumnPermissionRoutingModule {}
