import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { environment } from 'src/environments'

@Injectable()
export class GisInfoService {
  constructor(private http: HttpClient) {}

  getDeviceList(queryParam: any): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    }
    return this.http.post<any>(
      `${environment.apiVersionUrl}Device/deviceList`,
      queryParam,
      headers,
    )
  }
}
