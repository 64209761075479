import { Injectable } from '@angular/core'
import { Action, Selector, State, StateContext } from '@ngxs/store'
import { tap } from 'rxjs'
import { SSReportService } from '@app/services'
import {
  GetPTRInterruptionReporttList,
  GetPTRLoadsReporttList,
} from '@app/index'
export class SSReportStateInfo {
  ptrLoadsListData?: any
  columns?: any
  ptrInterruptionListData?: any
  columnsPTRInterruption?: any
}

@State<SSReportStateInfo>({
  name: 'ss',
  defaults: {},
})
@Injectable()
export class SSReportState {
  constructor(private ssreportService: SSReportService) {}

  @Selector()
  static getSSReportData(state: SSReportStateInfo) {
    return state.ptrLoadsListData
  }

  @Selector()
  static getPTRInterruptionReportData(state: SSReportStateInfo) {
    return state.ptrInterruptionListData
  }

  @Action(GetPTRInterruptionReporttList)
  getPTRInterruptionReportList(
    { getState, patchState }: StateContext<SSReportStateInfo>,
    action: any,
  ) {
    return this.ssreportService.getPTRInterruptionReportList().pipe(
      tap((res: any) => {
        patchState({
          ptrInterruptionListData: res.ptrInterruptionreportLGridData,
          columnsPTRInterruption: res.columnsPTRInterruption,
        })
      }),
    )
  }

  @Action(GetPTRLoadsReporttList)
  getPTRLoadsReporttList(
    { getState, patchState }: StateContext<SSReportStateInfo>,
    action: any,
  ) {
    return this.ssreportService.getPTRLoadsReporttList().pipe(
      tap((res: any) => {
        patchState({
          ptrLoadsListData: res.ptrLoadsListData,
          columns: res.columns,
        })
      }),
    )
  }
}
