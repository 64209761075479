import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable, of } from 'rxjs'
import { environment } from 'src/environments'

@Injectable()
export class SubstationService {
  substationList: any = {
    columns: [
      {
        field: 'substationId',
        name: 'Substation Id',
        hasColumn: [],
        isSelected: true,
        isLocked: false,
      },
      {
        field: 'substationName',
        name: 'Substation name',
        hasColumn: [],
        isSelected: true,
        isLocked: false,
      },
    ],
    data: [
      {
        substationId: '123',
        substationName: '123112',
      },
      {
        substationId: '111',
        substationName: '1211312',
      },
      {
        substationId: '11223',
        substationName: '1211111312',
      },
    ],
  }
  constructor(private http: HttpClient) {}

  createSubstation(param: any): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    }
    return this.http.post<any>(
      `${environment.apiVersionUrl}Substation/addOrUpdateSubstation`,
      param,
      headers,
    )
  }

  getSubstationList(queryParam: any): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    }
    return this.http.post<any>(
      `${environment.apiVersionUrl}Substation/getSubstationList`,
      queryParam,
      headers,
    )
  }

  deleteSubstation(substationId: any): Observable<any> {
    return this.http.delete(
      `${environment.apiVersionUrl}Substation/deleteSubstation/${substationId}`,
    )
  }

  checkSubStationNameExist(
    subStationId: any,
    substationName: string,
  ): Observable<boolean> {
    return this.http.get<boolean>(
      `${environment.apiVersionUrl}Substation/CheckSubstationExistsByName/${subStationId}/${substationName}`,
    )
  }

  getSubStationListWithFeeder(
    queryParams: any,
    substationId: any,
  ): Observable<HttpResponse<any>> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    }
    return this.http.post<any>(
      `${environment.apiVersionUrl}Substation/getAssignFedderToSubstation/${substationId}`,
      JSON.stringify(queryParams),
      headers,
    )
  }

  saveFeederDevice(
    subStationfeederRequestBody: any,
    subStationId: any,
  ): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    }

    return this.http.post<any>(
      `${environment.apiVersionUrl}Substation/assignFedderToSubstation/${subStationId}`,
      JSON.stringify(subStationfeederRequestBody),
      headers,
    )
  }

  confirmationFeederForSubstation(requestBody: any): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    }
    return this.http.post<any>(
      `${environment.apiVersionUrl}Substation/ConfirmationFeederForSubstation`,
      JSON.stringify(requestBody),
      headers,
    )
  }
}
