<kendo-dialog-titlebar (close)="onCloseDialog()">
  <span>{{ substationId === 0 ? 'Create' : 'Update' }} Substation</span>
</kendo-dialog-titlebar>

<div>
  <form [formGroup]="substationForm" class="k-form k-form-md">
    <kendo-formfield class="mb-20">
      <kendo-label
        [for]="substation_AreaName"
        text="Substation Name"
        labelCssClass="k-form-label"
        ><span class="required">*</span></kendo-label
      >
      <kendo-textbox
        formControlName="substation_AreaName"
        #substation_AreaName
        appNoSpacesOrSpecialChars
      ></kendo-textbox>
    </kendo-formfield>
  </form>
</div>
<kendo-dialog-actions>
  <button kendoButton (click)="onCloseDialog()" fillMode="outline">
    Cancel
  </button>
  <div
    [ngClass]="{ 'curser-not-allowd': substationForm.invalid }"
    class="display-inline flex-0"
  >
    <button
      kendoButton
      (click)="onSubmit()"
      [disabled]="substationForm.invalid"
    >
      Save
    </button>
  </div>
</kendo-dialog-actions>
