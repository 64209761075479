import { Injectable } from '@angular/core'
import { Action, Selector, State, StateContext } from '@ngxs/store'
import { tap } from 'rxjs'
import { DeviceService } from '@app/services'
import {
  AddDevice,
  CreateDatabase,
  DeleteDeviceByDeviceId,
  DeleteUserDeviceLogs,
  GetDeviceDetailByDeviceId,
  GetDeviceList,
  GetDeviceListForAccount,
  GetAllByPagination,
  GetGpsLocation,
  SendOtp,
  UpdateDeviceByDeviceId,
  ImageFileUpload,
  CheckIfDbExistsForDevice,
  DownloadFileUpload,
  CheckActivationDeviceExistsByDeviceId,
  GetDeviceListWithPagination,
  UpdateInstallDateByDeviceId,
} from '@app/index'
import { HttpResponse } from '@angular/common/http'
export class DeviceStateInfo {
  deviceListData?: any
  deviceFeederList?: []
  deviceDetailByDeviceId?: any
  isDeviceAdded?: boolean
  deviceListForAccount?: []
  deviceListForLoginedAccount?: any
  isUserDeviceLogDelete?: boolean
  isOtpSend?: boolean
  locationData?: any
  isDatabaseCreated?: boolean
  uploadedImageDetails?: any
  uploadedImageList?: []
  isDbExistsForDevice?: boolean
  downloadedUploadFileURL?: any
  isActivationDeviceExists?: boolean
  deviceList?: any
}

@State<DeviceStateInfo>({
  name: 'device',
  defaults: {},
})
@Injectable()
export class DeviceState {
  constructor(private deviceService: DeviceService) {}

  @Selector()
  static getDeviceData(state: DeviceStateInfo) {
    return state.deviceListData
  }

  @Selector()
  static getDeviceIdByFeeder(state: DeviceStateInfo) {
    return state.deviceFeederList
  }

  @Action(GetDeviceList)
  getDeviceList(
    { getState, patchState }: StateContext<DeviceStateInfo>,
    action: GetDeviceList,
  ) {
    return this.deviceService.getDeviceList(action.queryParam).pipe(
      tap((res: any) => {
        patchState({
          deviceListData: res,
        })
      }),
    )
  }

  @Action(CheckIfDbExistsForDevice)
  checkIfDbExistsForDevice(
    { getState, patchState }: StateContext<DeviceStateInfo>,
    action: CheckIfDbExistsForDevice,
  ) {
    return this.deviceService.checkIfDbExistsForDevice(action.deviceId).pipe(
      tap((res: any) => {
        patchState({
          isDbExistsForDevice: res,
        })
      }),
    )
  }

  @Action(CheckActivationDeviceExistsByDeviceId)
  checkActivationDeviceExistsByDeviceId(
    { getState, patchState }: StateContext<DeviceStateInfo>,
    action: CheckActivationDeviceExistsByDeviceId,
  ) {
    return this.deviceService
      .checkActivationDeviceExistsByDeviceId(action.deviceId)
      .pipe(
        tap((res: any) => {
          patchState({
            isActivationDeviceExists: res,
          })
        }),
      )
  }

  @Action(GetDeviceDetailByDeviceId)
  getDeviceDetailByDeviceId(
    { getState, patchState }: StateContext<DeviceStateInfo>,
    action: GetDeviceDetailByDeviceId,
  ) {
    return this.deviceService.getDeviceDetailByDeviceId(action.deviceId).pipe(
      tap((res: any) => {
        patchState({
          deviceDetailByDeviceId: res,
        })
      }),
    )
  }

  @Action(DeleteDeviceByDeviceId)
  deleteDeviceById(
    { getState, patchState }: StateContext<DeviceStateInfo>,
    action: DeleteDeviceByDeviceId,
  ) {
    return this.deviceService.deleteDeviceById(
      action.deleteDeviceByIdRequestBody,
    )
  }

  @Action(UpdateDeviceByDeviceId)
  updateDeviceByDeviceId(
    { getState, patchState }: StateContext<DeviceStateInfo>,
    action: UpdateDeviceByDeviceId,
  ) {
    return this.deviceService.updateDeviceByDeviceId(
      action.deviceId,
      action.updateDeviceRequestBody,
    )
  }

  @Action(AddDevice)
  saveRoleModulePermissionList(
    { getState, patchState }: StateContext<DeviceStateInfo>,
    action: AddDevice,
  ) {
    return this.deviceService.addDevice(action.addDeviceRequestBody).pipe(
      tap((res: HttpResponse<string>) => {
        patchState({
          isDeviceAdded: res.body ? true : false,
        })
      }),
    )
  }

  @Action(GetDeviceListForAccount)
  getDeviceListForAccount(
    { getState, patchState }: StateContext<DeviceStateInfo>,
    action: GetDeviceListForAccount,
  ) {
    return this.deviceService.getDeviceListForAccount(action.queryParams).pipe(
      tap((res: any) => {
        patchState({
          deviceListForAccount: res,
        })
      }),
    )
  }

  @Action(GetAllByPagination)
  GetAllByPagination(
    { getState, patchState }: StateContext<DeviceStateInfo>,
    action: GetAllByPagination,
  ) {
    return this.deviceService
      .GetAllByPagination(
        action.queryParam,
        action.mobileNumber,
        action.emailId,
      )
      .pipe(
        tap((res: any) => {
          patchState({
            deviceListForLoginedAccount: res,
          })
        }),
      )
  }

  @Action(DeleteUserDeviceLogs)
  deleteUserDeviceLogs(
    { setState }: StateContext<DeviceStateInfo>,
    action: DeleteUserDeviceLogs,
  ) {
    return this.deviceService.deleteUserDeviceLogs(action.queryParam).pipe(
      tap((res) => {
        setState({
          isUserDeviceLogDelete: res,
        })
      }),
    )
  }

  @Action(GetDeviceListWithPagination)
  getDeviceListWithPagination(
    { getState, patchState }: StateContext<DeviceStateInfo>,
    action: GetDeviceListWithPagination,
  ) {
    return this.deviceService.getDeviceListWithPagination().pipe(
      tap((res: any) => {
        patchState({
          deviceList: res,
        })
      }),
    )
  }

  @Action(SendOtp)
  sendOtpVerification(
    { getState, patchState }: StateContext<DeviceStateInfo>,
    action: SendOtp,
  ) {
    return this.deviceService.sendOtpVerification().pipe(
      tap((res: any) => {
        patchState({
          isOtpSend: res,
        })
      }),
    )
  }

  @Action(GetGpsLocation)
  getGpsLocation(
    { getState, patchState }: StateContext<DeviceStateInfo>,
    action: GetGpsLocation,
  ) {
    return this.deviceService.getGpsLocation(action.location).pipe(
      tap((res: any) => {
        patchState({
          locationData: res,
        })
      }),
    )
  }

  @Action(CreateDatabase)
  createDatabase(
    { getState, patchState }: StateContext<DeviceStateInfo>,
    action: CreateDatabase,
  ) {
    return this.deviceService.createDatabase(action.deviceList).pipe(
      tap((res: any) => {
        patchState({
          isDatabaseCreated: res,
        })
      }),
    )
  }

  @Action(ImageFileUpload)
  uploadImage(
    { getState, patchState }: StateContext<DeviceStateInfo>,
    action: ImageFileUpload,
  ) {
    return this.deviceService.uploadImage(action.deviceId, action.file).pipe(
      tap((res: any) => {
        patchState({
          uploadedImageDetails: res,
        })
      }),
    )
  }

  @Action(DownloadFileUpload)
  downloadFileUpload(
    { getState, patchState }: StateContext<DeviceStateInfo>,
    action: DownloadFileUpload,
  ) {
    return this.deviceService
      .downloadFileUpload(action.dowloadFileRequestBody)
      .pipe(
        tap((res) => {
          patchState({
            downloadedUploadFileURL: res,
          })
        }),
      )
  }

  @Action(UpdateInstallDateByDeviceId)
  updateInstallDateByDeviceId(
    { getState, patchState }: StateContext<DeviceStateInfo>,
    action: UpdateInstallDateByDeviceId,
  ) {
    return this.deviceService.updateInstallDateByDeviceId(
      action.updateInstallDateRequestBody,
    )
  }
}
