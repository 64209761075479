import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { SharedModule } from 'src/shared/shared.module'
import {
  CommonsModule,
  MeterDataListComponent,
  MeterDataRoutingModule,
} from '@app/index'

@NgModule({
  declarations: [MeterDataListComponent],
  imports: [CommonModule, MeterDataRoutingModule, CommonsModule, SharedModule],
})
export class MeterDataModule {}
