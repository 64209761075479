import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core'
import { ButtonsModule } from '@progress/kendo-angular-buttons'
import {
  DropDownListModule,
  DropDownsModule,
} from '@progress/kendo-angular-dropdowns'
import { LayoutModule } from '@progress/kendo-angular-layout'
import { IconsModule, SVGIconModule } from '@progress/kendo-angular-icons'
import { LabelModule } from '@progress/kendo-angular-label'
import { InputsModule, SwitchModule } from '@progress/kendo-angular-inputs'
import { GridModule } from '@progress/kendo-angular-grid'
import { FilterModule } from '@progress/kendo-angular-filter'
import { TreeListModule } from '@progress/kendo-angular-treelist'
import { MenuModule } from '@progress/kendo-angular-menu'
import { PopupModule } from '@progress/kendo-angular-popup'
import { DialogsModule } from '@progress/kendo-angular-dialog'
import { DateInputsModule } from '@progress/kendo-angular-dateinputs'
import { TreeViewModule } from '@progress/kendo-angular-treeview'
import { UploadsModule } from '@progress/kendo-angular-upload'
import { ListViewModule } from '@progress/kendo-angular-listview'
import { EditorModule } from '@progress/kendo-angular-editor'
import { IntlModule } from '@progress/kendo-angular-intl'
import { ProgressBarModule } from '@progress/kendo-angular-progressbar'
import { ChartsModule } from '@progress/kendo-angular-charts'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { DragDropModule } from '@angular/cdk/drag-drop'
import { SlickCarouselModule } from 'ngx-slick-carousel'
import { LightboxModule } from 'ngx-lightbox'
import { NoSpacesOrSpecialCharsDirective } from '@app/directive'
import { IndicatorsModule } from '@progress/kendo-angular-indicators'
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material'

@NgModule({
  declarations: [NoSpacesOrSpecialCharsDirective],
  imports: [
    InputsModule,
    LabelModule,
    IconsModule,
    LayoutModule,
    DropDownsModule,
    TreeViewModule,
    TreeListModule,
    ButtonsModule,
    GridModule,
    DropDownListModule,
    FilterModule,
    SwitchModule,
    MenuModule,
    PopupModule,
    DialogsModule,
    TreeViewModule,
    UploadsModule,
    ListViewModule,
    EditorModule,
    DateInputsModule,
    IntlModule,
    ProgressBarModule,
    SVGIconModule,
    ChartsModule,
    ReactiveFormsModule,
    FormsModule,
    DragDropModule,
    SlickCarouselModule,
    LightboxModule,
    IndicatorsModule,
    NgxDaterangepickerMd.forRoot(),
  ],
  exports: [
    InputsModule,
    LabelModule,
    IconsModule,
    LayoutModule,
    DropDownsModule,
    DateInputsModule,
    TreeListModule,
    ButtonsModule,
    GridModule,
    DropDownListModule,
    FilterModule,
    SwitchModule,
    MenuModule,
    PopupModule,
    DialogsModule,
    TreeViewModule,
    IntlModule,
    ListViewModule,
    EditorModule,
    UploadsModule,
    ProgressBarModule,
    SVGIconModule,
    ReactiveFormsModule,
    FormsModule,
    ChartsModule,
    DragDropModule,
    SlickCarouselModule,
    LightboxModule,
    IndicatorsModule,
    NgxDaterangepickerMd,
  ],
  providers: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {}
