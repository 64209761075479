import { Injectable } from '@angular/core'
import { Action, State, StateContext } from '@ngxs/store'
import { BackupDataInExcel, DTRDataDownload, DeleteDatabase } from '.'
import { tap } from 'rxjs'
import { DTRDataService } from '@app/services'
export class DTRDataStateInfo {
  isUserAccoutAdded?: any
}

@State<DTRDataStateInfo>({
  name: 'dtrData',
  defaults: {},
})
@Injectable()
export class DTRDataState {
  constructor(private dtrDataService: DTRDataService) {}

  @Action(DTRDataDownload)
  dtrDataDownload(
    { getState, patchState }: StateContext<DTRDataStateInfo>,
    action: DTRDataDownload,
  ) {
    return this.dtrDataService.dtrDataDownload(action.dtrDowloadData).pipe(
      tap((res) => {
        patchState({
          isUserAccoutAdded: res,
        })
      }),
    )
  }

  @Action(BackupDataInExcel)
  backupDataInExcel(
    { getState, patchState }: StateContext<DTRDataStateInfo>,
    action: BackupDataInExcel,
  ) {
    return this.dtrDataService
      .backupDataInExcel(action.deviceId, action.formateType)
      .pipe(
        tap((res) => {
          patchState({})
        }),
      )
  }

  @Action(DeleteDatabase)
  deleteDatabase(
    { getState, patchState }: StateContext<DTRDataStateInfo>,
    action: DeleteDatabase,
  ) {
    return this.dtrDataService.deleteDatabase(action.deviceId).pipe(
      tap((res) => {
        patchState({})
      }),
    )
  }
}
