import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable, of } from 'rxjs'
import { environment } from 'src/environments'

@Injectable()
export class PagePermissionService {
  constructor(private http: HttpClient) {}

  getRoleList(): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    }

    return this.http.post<any>(
      `${environment.apiVersionUrl}Role/GetAll`,
      headers,
    )
  }

  getRoleModulePermissionList(roleId: any): Observable<any> {
    return this.http.get<any>(
      `${environment.apiVersionUrl}ModuleRolePermission/permissionForRole/${roleId}`,
    )
  }

  saveRoleModulePermissionList(
    roleId: any,
    saveRoleModulePermissionRequestBody: any,
  ): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    }

    return this.http.post<any>(
      `${environment.apiVersionUrl}ModuleRolePermission/saveRolePermission/${roleId}`,
      saveRoleModulePermissionRequestBody,
      headers,
    )
  }
}
