import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { DeviceHistoryComponent } from './device-history.component'
import { AuthGuard } from '@app/auth-guards'

const routes: Routes = [
  { path: '', component: DeviceHistoryComponent, canActivate: [AuthGuard] },
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DeviceHistoryRoutingModule {}
