import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable, of } from 'rxjs'
import { environment } from 'src/environments'

@Injectable()
export class DeviceHistoryService {
  constructor(private http: HttpClient) {}

  getDeviceHistory(queryParam: any): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    }
    return this.http.post<any>(
      `${environment.apiVersionUrl}device/GetDeviceHistoryByDeviceId`,
      queryParam,
      headers,
    )
  }
}
