<div class="q-card border dtr-graph-container">
  <div *ngIf="showLoader">
    <div class="k-loading-panel">
      <div class="k-loading-panel-mask"></div>
      <div class="k-loading-panel-wrapper">
        <kendo-loader *ngIf="showLoader" type="infinite-spinner" size="large">
        </kendo-loader>
        <div class="k-loading-panel-text">Loading...</div>
      </div>
    </div>
  </div>
  <div>
    {{ roundedMaxValue }}
    <kendo-chart
      [pannable]="{ lock: 'y' }"
      [zoomable]="{ mousewheel: { lock: 'y' }, selection: { lock: 'y' } }"
      *ngIf="!isNoRecordFound"
    >
      <kendo-chart-series>
        <kendo-chart-series-item
          type="line"
          [data]="currentGraphData"
          field="voltagePhase_B"
          categoryField="date"
          color="#3a82f4"
        >
        </kendo-chart-series-item>
        <kendo-chart-series-item
          type="line"
          [data]="currentGraphData"
          field="voltagePhase_R"
          categoryField="date"
          color="#ed6572"
        >
        </kendo-chart-series-item>
        <kendo-chart-series-item
          type="line"
          [data]="currentGraphData"
          field="voltagePhase_Y"
          categoryField="date"
          color="#f9bc43"
        >
        </kendo-chart-series-item>
      </kendo-chart-series>
      <kendo-chart-value-axis>
        <kendo-chart-value-axis-item
          [title]="{ text: title }"
          [min]="0"
          [max]="roundedMaxValue"
        >
        </kendo-chart-value-axis-item>
      </kendo-chart-value-axis>
      <kendo-chart-category-axis>
        <kendo-chart-category-axis-item
          [title]="{ text: 'Time' }"
          [labels]="{ rotation: -45 }"
        ></kendo-chart-category-axis-item>
      </kendo-chart-category-axis>
      <kendo-chart-tooltip [shared]="true">
        <ng-template kendoChartTooltipTemplate let-tooltip="tooltip">
          <div class="tooltip-container">
            <div *ngFor="let point of tooltip.points" class="tooltip-item">
              <div class="tooltip-series-name">{{ point.series.name }}:</div>
              <div class="tooltip-value">{{ point.value }}</div>
            </div>
          </div>
        </ng-template>
      </kendo-chart-tooltip>
    </kendo-chart>
  </div>
  <div *ngIf="isNoRecordFound">
    <div
      *ngIf="isCurrentGraphData"
      class="no-data-message d-flex align-items-center justify-content-center flex-column gap-20"
    >
      <img src="assets/images/no-data-line-chart.png" alt="data" />
      No data available yet!!
    </div>
  </div>
</div>
