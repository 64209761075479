import { Component, OnInit } from '@angular/core'
import { NotificationMessage } from '@app/enums/notification'
import {
  ActionTypeEnum,
  AddFeederComponent,
  LinkDeviceWithFeederComponent,
} from '@app/index'
import {
  CommonState,
  DeleteFeeder,
  GetDeviceIdByFeeder,
  GetFeederAreaList,
  GetFeederList,
} from '@app/store'
import { Select, Store } from '@ngxs/store'
import { DialogService } from '@progress/kendo-angular-dialog'
import {
  GridDataResult,
  MultipleSortSettings,
  PagerPosition,
  PagerType,
} from '@progress/kendo-angular-grid'
import { CompositeFilterDescriptor } from '@progress/kendo-data-query'
import { NgxSpinnerService } from 'ngx-spinner'
import { ToastrService } from 'ngx-toastr'
import { Observable, Subject, switchMap } from 'rxjs'

@Component({
  selector: 'app-feeder',
  templateUrl: './feeder.component.html',
  styleUrl: './feeder.component.scss',
})
export class FeederComponent implements OnInit {
  triggerGetUpdatedTime: Subject<any> = new Subject<any>()
  feederAreaList: any[] = []
  feederAreaId?: any
  deviceList: any[] = []

  gridHeight: number = 0
  pagerposition: PagerPosition = 'bottom'
  skip = 0
  sorting = null
  type: PagerType = 'numeric'
  sort: any
  previousSort: any = null
  pageNumber = 1
  pageSize = 10

  isAllSelected = false
  sortOrder = false
  isDataLoaded = false
  isPaginationAllowed: boolean = true
  buttonCount = 5
  info = true
  pageSizes = true
  previousNext = true
  multiple = false
  allowUnsort = true
  isSortingAllowed: boolean = true

  filterQuery: string | any
  sortSettings: MultipleSortSettings = {
    mode: 'multiple',
    initialDirection: 'desc',
    allowUnsort: true,
    showIndexes: true,
  }

  public filter: CompositeFilterDescriptor = {
    logic: 'and',
    filters: [],
  }

  @Select(CommonState.moduleId)
  moduleId$!: Observable<number>

  gridView: GridDataResult | any
  actions: any[] = [
    {
      title: 'Edit',
      icon: 'far fa-pen',
      actionTypeId: ActionTypeEnum.Edit,
    },
    {
      title: 'Delete',
      icon: 'far fa-trash-alt',
      actionTypeId: ActionTypeEnum.Delete,
    },
    {
      title: 'Assign Devices',
      icon: 'far fa-eye',
      actionTypeId: ActionTypeEnum.View,
    },
  ]

  @Select(CommonState.hasAddPermission)
  hasAddPermission$!: Observable<boolean>

  constructor(
    private store: Store,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private dialogService: DialogService,
  ) {}

  ngOnInit(): void {
    setTimeout(() => {
      this.triggerGetUpdatedTime.next(true)
    }, 0)
    this.getFeederList()
    this.calculateGridHeight()

    window.addEventListener('resize', () => {
      this.calculateGridHeight()
    })
  }

  onDeleteFeeder(event: any): void {
    this.spinner.show()
    this.store.dispatch(new DeleteFeeder(event.feederId)).subscribe(
      (res: any) => {
        this.spinner.hide()
        if (res.feeder.isFeederDeleted) {
          this.toastr.success(NotificationMessage.deleteFeederSuccessMsg)
          this.store
            .dispatch(new GetFeederAreaList())
            .pipe(
              switchMap((res: any) =>
                this.store.dispatch(new GetDeviceIdByFeeder()),
              ),
            )
            .subscribe()
          this.getFeederList()
        }
      },
      (error) => {
        this.spinner.hide()
      },
    )
  }

  onFilterChangeSQL(sqlQuery: any) {
    this.skip = 0
    this.pageNumber = 1
    this.filterQuery = sqlQuery
    this.getFeederList()
  }

  onActionClick(event: any): void {
    if (event.actionTypeId === ActionTypeEnum.Edit) {
      this.onAddFeeder(false, event)
    } else if (event.actionTypeId === ActionTypeEnum.Delete) {
      this.onDeleteFeeder(event.data)
    } else if (event.actionTypeId === ActionTypeEnum.View) {
      this.onViewDeviceList(event.data)
    }
  }

  onPaginationChange({ skip, take }: any) {
    this.skip = skip
    this.pageSize = take
    this.pageNumber = this.skip / this.pageSize + 1

    this.getFeederList()
  }

  onSortChange(sortData: any): void {
    const currentSortField = sortData.sort[0].field
    if (this.previousSort === currentSortField) {
      this.sortOrder = !this.sortOrder
    } else {
      this.sortOrder = true
    }
    this.sort = currentSortField
    this.previousSort = currentSortField
    this.getFeederList()
  }

  calculateGridHeight(): void {
    const screenHeight = window.innerHeight
    const headerHeight = 81
    const gridHeaderHeight = 160
    const wrapperpadding = 20

    this.gridHeight =
      screenHeight - (headerHeight + gridHeaderHeight + wrapperpadding)
  }

  getFeederList() {
    const param = {
      pageNumber: this.pageNumber,
      pageSize: this.pageSize,
      sortBy: this.sort,
      sortOrder: this.sortOrder,
      filterQuery: this.filterQuery,
      moduleId: this.store.selectSnapshot(CommonState.moduleId),
    }
    this.spinner.show()
    this.store.dispatch(new GetFeederList(param)).subscribe((res) => {
      this.spinner.hide()
      const { data, totalCount } = res.feeder.feederList
      this.gridView = {
        data: data.rows,
        total: totalCount,
        columns: data.columns,
      }
    })
  }

  onViewDeviceList(data: any): void {
    const dialogRef = this.dialogService.open({
      content: LinkDeviceWithFeederComponent,
      width: 800,
    })
    const component: any = dialogRef.content
      .instance as LinkDeviceWithFeederComponent

    component.feederId = data.feederId
    component.feederName = data.feederName
    component.feederType = data.feederType

    dialogRef.result.subscribe((res: any) => {
      if (res && res.confirmed) {
      }
    })
  }

  onAddFeeder(isAddMode: boolean, event?: any): void {
    const dialogRef = this.dialogService.open({
      content: AddFeederComponent,
      width: 450,
    })

    const component: any = dialogRef.content.instance as AddFeederComponent
    component.feederId = isAddMode ? 0 : event.data.feederId
    component.feederData =
      event !== null && event !== undefined ? event.data : null
    component.selectedFeederType = isAddMode ? 0 : event.data.feederType

    dialogRef.result.subscribe((res: any) => {
      if (res && res.confirmed) {
        this.store
          .dispatch(new GetFeederAreaList())
          .pipe(
            switchMap((res: any) =>
              this.store.dispatch(new GetDeviceIdByFeeder()),
            ),
          )
          .subscribe()
        this.getFeederList()
      }
    })
  }
}
