import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable, of } from 'rxjs'
import { environment } from 'src/environments'

@Injectable()
export class ColumnPermissionService {
  constructor(private http: HttpClient) {}

  saveColumnModulePermissionList(
    moduleId: any,
    roleId: any,
    saveRoleModulePermissionRequestBody: any,
  ): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    }

    return this.http.post<any>(
      `${environment.apiVersionUrl}ModuleGridColumn/SaveColumnBasedOnModuleIdAndRoleId/${moduleId}/${roleId}`,
      saveRoleModulePermissionRequestBody,
      headers,
    )
  }

  getColumnPermissionList(columnsPermissionData: any): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    }

    return this.http.post<any>(
      `${environment.apiVersionUrl}ModuleGridColumn/GetColumnBasedOnModuleIdAndRoleId`,
      columnsPermissionData,
      headers,
    )
  }
}
