import { Component, OnInit } from '@angular/core'
import { DialogRef } from '@progress/kendo-angular-dialog'

@Component({
  selector: 'app-common-map-marker-details',
  templateUrl: './common-map-marker-details.component.html',
  styleUrl: './common-map-marker-details.component.scss',
})
export class CommonMapMarkerDetailsComponent implements OnInit {
  data: any

  constructor(public dialogRef: DialogRef) {}

  ngOnInit(): void {}

  closePopup(): void {
    this.dialogRef.close()
  }
}
