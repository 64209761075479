export enum ThresholdSettings {
  MinVoltage = 1,
  MaxVoltage = 2,
  MaxCurrent = 3,
  KVA = 4,
  CTSettings = 5,
  Temperature = 6,
  PFSettings = 7,
  UnityPF = 8,
}

export enum OfficeChange {
  Energy = 1,
  UnbalancedLoad = 2,
  WIFI = 3,
  Angle = 4,
  DeviceSerialId = 5,
  ReadIoTSettings = 6,
}
