<form [formGroup]="deviceSerialIdForm" class="k-form k-form-md">
  <kendo-formfield class="mb-20">
    <div class="d-flex gap-15">
      <kendo-label
        [for]="deviceSerialId"
        text="New Id"
        labelCssClass="k-form-label"
        class="w-100 d-flex align-items-center"
        ><span class="required">*</span></kendo-label
      >
      <kendo-textbox
        formControlName="newDeviceSerialId"
        #deviceSerialId
        placeholder="New Serial ID"
        (keypress)="commonService.numberOnly($event)"
      ></kendo-textbox>
    </div>
    <div
      *ngIf="
        deviceSerialIdForm.get('newDeviceSerialId').invalid &&
        deviceSerialIdForm.get('newDeviceSerialId').touched
      "
      class="d-flex gap-15"
    >
      <div class="w-80"></div>
      <div>
        <p
          *ngIf="deviceSerialIdForm.get('newDeviceSerialId').errors?.required"
          class="required mb-0"
        >
          New Id is required.
        </p>
        <p
          *ngIf="deviceSerialIdForm.get('newDeviceSerialId').errors?.pattern"
          class="required mb-0"
        >
          Please enter a valid number for Device Serial New Id.
        </p>
      </div>
    </div>
  </kendo-formfield>
</form>
