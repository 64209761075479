import { Directive } from '@angular/core'
import {
  AbstractControl,
  NG_VALIDATORS,
  Validator,
  ValidatorFn,
  ValidationErrors,
} from '@angular/forms'

export function noSpacesOrSpecialCharsValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value

    if (
      value &&
      (value?.trim() === '' || /^[!@#\$%\^\&*\)\(+=._-]+$/.test(value))
    ) {
      return { noSpacesOrSpecialChars: true }
    } else if (/^[!@#\$%\^\&*\)\(+=._-]+$/.test(value?.trim())) {
      return { noSpacesOrSpecialChars: true }
    }
    return null
  }
}

@Directive({
  selector: '[appNoSpacesOrSpecialChars]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: NoSpacesOrSpecialCharsDirective,
      multi: true,
    },
  ],
})
export class NoSpacesOrSpecialCharsDirective implements Validator {
  validate(control: AbstractControl): ValidationErrors | null {
    return noSpacesOrSpecialCharsValidator()(control)
  }
}
