export class PagePermissionGetRoleList {
  static readonly type = '[PAGE PERMISSION] Page Permission Role List'
}

export class GetRoleModulePermissionList {
  static readonly type = '[PAGE PERMISSION] Get Role Module Permission List'
  constructor(public roleId: any) {}
}

export class SaveRoleModulePermissionList {
  static readonly type = '[PAGE PERMISSION] Save Role Module Permission List'
  constructor(
    public roleId: any,
    public saveRoleModulePermissionRequestBody: any,
  ) {}
}
