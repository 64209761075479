<form [formGroup]="unbalancedForm" class="k-form k-form-md">
  <kendo-formfield class="mb-20">
    <div class="d-flex gap-15">
      <kendo-label
        [for]="deviation"
        text="Deviation"
        labelCssClass="k-form-label"
        class="w-100 d-flex align-items-center"
        ><span class="required">*</span></kendo-label
      >
      <kendo-textbox
        formControlName="deviation"
        placeholder="Enter Value (0-9)"
        #deviation
        (keypress)="commonService.numberOnly($event)"
      ></kendo-textbox>
    </div>
    <div
      *ngIf="
        unbalancedForm.get('deviation').invalid &&
        unbalancedForm.get('deviation').touched
      "
      class="d-flex gap-15"
    >
      <div class="w-80"></div>
      <div>
        <p
          *ngIf="unbalancedForm.get('deviation').errors?.required"
          class="required mb-0"
        >
          Deviation is required.
        </p>
        <p
          *ngIf="unbalancedForm.get('deviation').errors?.pattern"
          class="required mb-0"
        >
          Please enter a valid number for Unbalanced Load Deviation.
        </p>
      </div>
    </div>
  </kendo-formfield>
  <kendo-formfield class="mb-20">
    <div class="d-flex gap-15">
      <kendo-label
        [for]="ratedKva"
        text="Rated KVA"
        labelCssClass="k-form-label"
        class="w-100 d-flex align-items-center"
        ><span class="required">*</span></kendo-label
      >
      <kendo-textbox
        formControlName="kva"
        placeholder="Enter Value (0-9)"
        #ratedKva
        (keypress)="commonService.numberOnly($event)"
      ></kendo-textbox>
    </div>
    <div
      *ngIf="
        unbalancedForm.get('kva').invalid && unbalancedForm.get('kva').touched
      "
      class="d-flex gap-15"
    >
      <div class="w-80"></div>
      <div>
        <p
          *ngIf="unbalancedForm.get('kva').errors?.required"
          class="required mb-0"
        >
          Rated KVA is required.
        </p>
        <p
          *ngIf="unbalancedForm.get('kva').errors?.pattern"
          class="required mb-0"
        >
          Please enter a valid number for Unbalanced Load Rated KVA.
        </p>
      </div>
    </div>
  </kendo-formfield>
</form>
