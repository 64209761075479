import { Component, ViewChild } from '@angular/core'
import { Router } from '@angular/router'
import { CommonGridComponent } from '@app/components/common'
import { DataTypeEnum, MenuList } from '@app/enums'
import { CommonService } from '@app/services'
import { CommonState } from '@app/store'
import { GetPowerFactorAlert } from '@app/store/alert/pf'
import { Select, Store } from '@ngxs/store'
import { GridDataResult } from '@progress/kendo-angular-grid'
import { NgxSpinnerService } from 'ngx-spinner'
import { Observable, Subject } from 'rxjs'

@Component({
  selector: 'app-pf-list',
  templateUrl: './pf-list.component.html',
  styleUrl: './pf-list.component.scss',
})
export class PfListComponent {
  triggerGetUpdatedTime: Subject<any> = new Subject<any>()

  @ViewChild(CommonGridComponent) commonGridComponent:
    | CommonGridComponent
    | undefined

  gridView: GridDataResult | any

  columns: any
  deviceId: any
  feederAreaId: any
  startDate = null
  endDate = null
  pageNumber = 1
  pageSize = 10
  skip = 0
  sort: any
  sortOrder = false
  previousSort: any = null
  filterQuery: string | any

  @Select(CommonState.moduleId)
  moduleId$!: Observable<number>
  isDataLoaded = false
  constructor(
    private router: Router,
    private store: Store,
    private spinner: NgxSpinnerService,
    private commonService: CommonService,
  ) {
    this.columns = this.commonService.getColumnNameByModule(MenuList.PF)
  }

  ngOnInit(): void {}

  onColumnSelector(): void {
    this.commonGridComponent?.onColumnSelector()
  }

  getList(deviceId: any, feederAreaId: any, startDate: any, endDate: any) {
    let queryParams = {
      deviceId: deviceId,
      feederAreaId: feederAreaId,
      pageNumber: this.pageNumber,
      pageSize: this.pageSize,
      sortBy: this.sort ?? null,
      sortOrder: this.sortOrder,
      filter: 0,
      search: '',
      startDate: startDate,
      endDate: endDate,
      filterQuery: this.filterQuery,
      moduleId: this.store.selectSnapshot(CommonState.moduleId),
    }
    this.spinner.show()
    this.store.dispatch(new GetPowerFactorAlert(queryParams)).subscribe(
      (res: any) => {
        this.spinner.hide()
        if (res.pf.pfData) {
          const { data, totalCount } = res.pf.pfData

          this.gridView = {
            data: data.rows,
            total: totalCount,
            columns: data.columns,
          }
          this.isDataLoaded = true
        }
      },
      (error) => {
        this.spinner.hide()
      },
    )
  }

  getDataFromColumnSelector(event: any) {
    this.getList(this.deviceId, this.feederAreaId, this.startDate, this.endDate)
  }

  onpfAdd(id?: any, isAdd: boolean = true): void {
    var params = {
      isAddMode: btoa(isAdd.toString()),
      pfId: btoa(id),
    }

    this.router.navigate(['add-pf', params])
  }

  onSortChange(sortData: any) {
    const currentSortField = sortData.sort[0].field
    if (this.previousSort === currentSortField) {
      this.sortOrder = !this.sortOrder
    } else {
      this.sortOrder = true
    }
    this.sort = currentSortField
    this.previousSort = currentSortField
    this.getList(this.deviceId, this.feederAreaId, this.startDate, this.endDate)
  }

  onPaginationChange({ skip, take }: any) {
    this.skip = skip
    this.pageSize = take
    this.pageNumber = this.skip / this.pageSize + 1

    this.getList(this.deviceId, this.feederAreaId, this.startDate, this.endDate)
  }

  getDataByHeaderData(event: any) {
    this.deviceId = event.deviceId
    this.feederAreaId = event.feederAreaId
    this.startDate = event.startDate
    this.endDate = event.endDate
    this.getList(this.deviceId, this.feederAreaId, this.startDate, this.endDate)
    this.triggerGetUpdatedTime.next(true)
  }

  onFilterChangeSQL(sqlQuery: any) {
    this.filterQuery = sqlQuery
    this.getList(this.deviceId, this.feederAreaId, this.startDate, this.endDate)
  }
}
