<div class="q-content">
  <div class="d-flex gap-15 flex-wrap justify-content-between mb-20">
    <app-common-header-text
      [infoText]="infoTitle"
      [isInfoVisible]="true"
      [triggerGetUpdatedTime]="triggerGetUpdatedTime"
    ></app-common-header-text>
    <div class="d-flex gap-10 flex-wrap">
      <app-common-header
        (triggerDataFromHeader)="getDataByHeaderData($event)"
      ></app-common-header>

      <kendo-dropdownlist
        [data]="electricType | useEnumValues"
        textField="value"
        valueField="key"
        placeholder="value"
        [(ngModel)]="selectedElectricType"
        [ngModelOptions]="{ standalone: true }"
        [value]="selectedElectricType"
        [valuePrimitive]="true"
        (valueChange)="getSettingHistoryTypeList(true)"
        class="w-260"
      ></kendo-dropdownlist>
      <div class="drag-icon-container" (click)="onColumnSelector()">
        <a class="drag-icon">
          <img src="assets/images/custom-columns.svg" alt="column-select" />
        </a>
      </div>
    </div>
  </div>
  <div class="q-content-bg">
    <app-common-grid
      [gridView]="gridView"
      [columns]="gridView?.columns ?? []"
      [pageSize]="this.pageSize"
      (sortChange)="onSortChange($event)"
      (paginationChange)="onPaginationChange($event)"
      (filterChangeSQL)="onFilterChangeSQL($event)"
      (triggerDataFromColumnSelector)="getSettingHistoryTypeList()"
      [triggerToClearAllFilter]="triggerToClearAllFilter"
    ></app-common-grid>
  </div>
</div>

<div kendoDialogContainer></div>
