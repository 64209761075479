import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { DownloadComponent } from './download.component'
import { DtrDataComponent } from './dtr-data'
import { AuthGuard } from '@app/index'

const routes: Routes = [
  { path: '', component: DownloadComponent, canActivate: [AuthGuard] },
  { path: 'dtr-data', component: DtrDataComponent, canActivate: [AuthGuard] },
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DownloadRoutingModule {}
