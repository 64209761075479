import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable, of } from 'rxjs'
import { environment } from 'src/environments'

@Injectable()
export class AccountService {
  constructor(private http: HttpClient) {}

  deviceData: any[] = [
    {
      D_ID: 'PTMS_181',
      D_Name: 'PTMS_181',
      INSTALLED_DATE: '17-11-2022',
      ADDR: ' Panchkula, Cluster_dera Bassi 6, Building-17, Panchkula, 21 Panchkula  Haryana 134116 India',
      LMARK: 'Sector 20',
      DATE_TIME: '12:33:03 08-05-2024',
      R_STATUS: 0,
      KVA: '200',
    },
    {
      D_ID: 'PTMS_182',
      D_Name: 'PTMS_181',
      INSTALLED_DATE: '18-11-2022',
      ADDR: ' Panchkula, Cluster_dera Bassi 6, Building-17, Panchkula, 21 Panchkula  Haryana 134116 India',
      LMARK: 'Sector 21',
      DATE_TIME: '13:33:03 08-05-2024',
      R_STATUS: 0,
      KVA: '201',
    },
  ]

  accountList: any = [
    {
      id: '1',
      SNo: '1',
      firstName: 'firstName',
      role: 'ADMIN',
      mobileNumber: '9849043346',
      emailId: 'sunenergy@gmail.com',
      Device: 'View',
    },
    {
      AcocuntId: '1',
      SNo: '1',
      Name: 'SUN ENERGY',
      Role: 'ADMIN',
      MobileNo: '9849043346',
      Email: 'sunenergy@gmail.com',
      Device: 'View',
    },
    {
      AcocuntId: '1',
      SNo: '1',
      Name: 'SUN ENERGY',
      Role: 'ADMIN',
      MobileNo: '9849043346',
      Email: 'sunenergy@gmail.com',
      Device: 'View',
    },
    {
      AcocuntId: '1',
      SNo: '1',
      Name: 'SUN ENERGY',
      Role: 'ADMIN',
      MobileNo: '9849043346',
      Email: 'sunenergy@gmail.com',
      Device: 'View',
    },
  ]

  getAccountList(queryParam: any): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    }
    return this.http.post<any>(
      `${environment.apiVersionUrl}Account/GetAllUserDetails`,
      queryParam,
      headers,
    )
  }

  createUserAccount(user: any): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    }

    return this.http.post<any>(
      `${environment.apiVersionUrl}Account`,
      JSON.stringify(user),
      headers,
    )
  }

  getUserDetailsWithId(emailId: any, mobileNumber: any): Observable<any> {
    return this.http.get<any>(
      `${environment.apiVersionUrl}Account/${mobileNumber}/${emailId}`,
    )
  }

  deleteAccount(emailId: any, mobileNumber: any): Observable<any> {
    return this.http.delete<any>(
      `${environment.apiVersionUrl}Account/${mobileNumber}/${emailId}`,
    )
  }

  getDeviceListByAccountId(accountId: any): Observable<any> {
    return this.http.get<any>(
      `${environment.apiVersionUrl}device/GetAccountDevices/${accountId}`,
    )
  }

  saveAccountDevice(requestBody: any): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    }

    return this.http.post<any>(
      `${environment.apiVersionUrl}device/saveAccountDevice`,
      JSON.stringify(requestBody),
      headers,
    )
  }

  updateUserAccount(userAccountData: any): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    }

    return this.http.put<any>(
      `${environment.apiVersionUrl}Account`,
      JSON.stringify(userAccountData),
      headers,
    )
  }

  updateLoginUserPassword(password: string): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    }
    const url = `${environment.apiVersionUrl}Account/UpdateLoginUserPassword/${password}`
    return this.http.put<any>(url, null, headers)
  }

  updateForgotPassword(emailMobileNo: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    })
    const url = `${environment.apiVersionUrl}Account/ForgotPassword/${emailMobileNo}`
    return this.http.put<any>(url, null, { headers })
  }

  checkAccountExistsByFirstName(
    accountId: any,
    firstName: string,
  ): Observable<boolean> {
    return this.http.get<boolean>(
      `${environment.apiVersionUrl}Account/CheckAccountExistsByFirstName/${accountId}/${firstName}`,
    )
  }
}
