import { wrapIn } from 'prosemirror-commands';
export const blockquote = (state, dispatch) => {
    return wrapIn(state.schema.nodes.blockquote)(state, dispatch);
};
export const liftBlockquote = (state, dispatch) => {
    const { $from, $to } = state.selection;
    const nodeType = state.schema.nodes.blockquote;
    const doc = state.doc;
    let target = -1;
    const range = $from.blockRange($to);
    if (range) {
        doc.nodesBetween(range.start, range.end, (node, pos, _parent, _index) => {
            if (node.type === nodeType) {
                target = pos;
            }
        });
    }
    const result = target !== -1;
    if (dispatch && result) {
        dispatch(state.tr.lift(range, doc.resolve(target).depth));
    }
    return result;
};
