export enum PercentageEnum {
  hundred = 1,
  eighty = 2,
  fifty = 3,
}

export enum PercentageEnumDesc {
  hundred = '100% and above the count',
  eighty = '80% and above the count',
  fifty = '50% and above the count',
}
