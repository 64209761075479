import { Component } from '@angular/core'
import { GetPTRInterruptionReporttList } from '@app/index'
import { Store } from '@ngxs/store'
import { GridDataResult } from '@progress/kendo-angular-grid'
import { NgxSpinnerService } from 'ngx-spinner'

@Component({
  selector: 'app-ptr-interruption',
  templateUrl: './ptr-interruption.component.html',
  styleUrl: './ptr-interruption.component.scss',
})
export class PTRInterruptionComponent {
  ptrInterruptionListData: any[] = []
  gridView: GridDataResult | any
  columnsPTRInterruption: any[] = []

  constructor(
    private store: Store,
    private spinner: NgxSpinnerService,
  ) {}

  ngOnInit(): void {
    this.getPTRInterruptionReportList()
  }

  onSortChange(sortData: any) {}

  onPaginationChange({ skip, take }: any) {}

  getDataByHeaderData(event: any): void {}

  getPTRInterruptionReportList(): void {
    this.spinner.show()
    this.store.dispatch(new GetPTRInterruptionReporttList()).subscribe(
      (res) => {
        this.spinner.hide()
        this.gridView = {
          data: res.ss.ptrInterruptionListData,
          total: 0,
          columns: res.ss.columnsPTRInterruption,
        }
      },
      (error) => {
        this.spinner.hide()
      },
    )
  }
}
