import { Injectable } from '@angular/core'
import {
  AddComment,
  AddUpdateComplaint,
  ComplaintsService,
  DeleteTicket,
  GenerateTicketNumber,
  GetCommentsByTicketId,
  GetComplaintDataById,
  GetcomplaintstList,
  GetTicketHistoryDataById,
  MultiImageFileUpload,
} from '@app/index'

import { Action, Selector, State, StateContext } from '@ngxs/store'
import { tap } from 'rxjs'

export class ComplaintsStateInfo {
  complaintsList?: any
  imageUploadedList?: any
  isComplaintAdded?: boolean
  ticketNumber?: any
  isComplaintCommentAdded?: boolean
  commentsList?: any
  complaintData?: any
  isTicketDeleted?: any
  ticketHistoryData?: any
}

@State<ComplaintsStateInfo>({
  name: 'complaints',
  defaults: {},
})
@Injectable()
export class ComplaintsState {
  constructor(private complaintsService: ComplaintsService) {}

  @Selector()
  static getComplaintsData(state: ComplaintsStateInfo) {
    return state.complaintsList
  }

  @Action(GetcomplaintstList)
  getComplaintsList(
    { getState, patchState }: StateContext<ComplaintsStateInfo>,
    action: GetcomplaintstList,
  ) {
    return this.complaintsService.getComplaintsList(action.queryParam).pipe(
      tap((res: any) => {
        patchState({
          complaintsList: res,
        })
      }),
    )
  }

  @Action(MultiImageFileUpload)
  multiImageFileUpload(
    { getState, patchState }: StateContext<ComplaintsStateInfo>,
    action: MultiImageFileUpload,
  ) {
    return this.complaintsService.multiImageFileUpload(action.files).pipe(
      tap((res: any) => {
        patchState({
          imageUploadedList: res,
        })
      }),
    )
  }

  @Action(AddUpdateComplaint)
  addUpdateComplaint(
    { getState, patchState }: StateContext<ComplaintsStateInfo>,
    action: AddUpdateComplaint,
  ) {
    return this.complaintsService
      .addUpdateComplaint(action.addRequestBody)
      .pipe(
        tap((res: any) => {
          patchState({
            isComplaintAdded: res.body ? true : false,
          })
        }),
      )
  }

  @Action(AddComment)
  addComment(
    { getState, patchState }: StateContext<ComplaintsStateInfo>,
    action: AddComment,
  ) {
    return this.complaintsService.addComment(action.addCommentRequestBody).pipe(
      tap((res: any) => {
        patchState({
          isComplaintCommentAdded: res.body ? true : false,
        })
      }),
    )
  }

  @Action(GetComplaintDataById)
  getComplaintDataById(
    { getState, patchState }: StateContext<ComplaintsStateInfo>,
    action: GetComplaintDataById,
  ) {
    return this.complaintsService.getComplaintDataById(action.complaintId).pipe(
      tap((res: any) => {
        patchState({
          complaintData: res,
        })
      }),
    )
  }

  @Action(GetTicketHistoryDataById)
  getTicketHistoryDataById(
    { getState, patchState }: StateContext<ComplaintsStateInfo>,
    action: GetTicketHistoryDataById,
  ) {
    return this.complaintsService
      .getTicketHistoryDataById(action.complaintId)
      .pipe(
        tap((res: any) => {
          patchState({
            ticketHistoryData: res,
          })
        }),
      )
  }

  @Action(GetCommentsByTicketId)
  getCommentsByTicketId(
    { getState, patchState }: StateContext<ComplaintsStateInfo>,
    action: GetCommentsByTicketId,
  ) {
    return this.complaintsService.getCommentsByTicketId(action.ticketId).pipe(
      tap((res: any) => {
        patchState({
          commentsList: res,
        })
      }),
    )
  }

  @Action(GenerateTicketNumber)
  generateTicketNumber(
    { getState, patchState }: StateContext<ComplaintsStateInfo>,
    action: GenerateTicketNumber,
  ) {
    return this.complaintsService.generateTicketNumber().pipe(
      tap((res: any) => {
        patchState({
          ticketNumber: res,
        })
      }),
    )
  }

  @Action(DeleteTicket)
  deleteTicket(
    { getState, patchState }: StateContext<ComplaintsStateInfo>,
    action: DeleteTicket,
  ) {
    return this.complaintsService.deleteTicket(action.ticketId).pipe(
      tap((res: any) => {
        patchState({
          isTicketDeleted: res,
        })
      }),
    )
  }
}
