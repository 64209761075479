import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import {
  CommonsModule,
  AssignHierarchicalStructureComponent,
  AssignHierarchicalStructureRoutingModule,
} from '@app/index'
import { SharedModule } from '@shared//shared.module'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'

@NgModule({
  declarations: [AssignHierarchicalStructureComponent],
  imports: [
    CommonModule,
    AssignHierarchicalStructureRoutingModule,
    SharedModule,
    CommonsModule,
    ReactiveFormsModule,
    FormsModule,
  ],
})
export class AssignHierarchicalStructureModule {}
