import { Component, Input, OnInit } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { CommonService } from '@app/services'

@Component({
  selector: 'app-angle-below',
  templateUrl: './angle-below.component.html',
  styleUrl: './angle-below.component.scss',
})
export class AngleBelowComponent implements OnInit {
  angleBelowForm: FormGroup | any
  @Input()
  triggerAngleBelowData: any
  constructor(public commonService: CommonService) {}

  ngOnInit(): void {
    this.setForm()
  }

  setForm(): void {
    this.angleBelowForm = new FormGroup({
      rPhase: new FormControl('', [
        Validators.required,
        Validators.pattern('^[0-9]+(.[0-9]+)?$'),
      ]),
      yPhase: new FormControl('', [
        Validators.required,
        Validators.pattern('^[0-9]+(.[0-9]+)?$'),
      ]),
      bPhase: new FormControl('', [
        Validators.required,
        Validators.pattern('^[0-9]+(.[0-9]+)?$'),
      ]),
    })
  }
}
