import { DatePipe } from '@angular/common'
import { Component, Optional } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { NotificationMessage } from '@app/enums/notification'
import { CommonService } from '@app/services'
import { UpdateInstallDateByDeviceId } from '@app/store'
import { Store } from '@ngxs/store'
import { DialogRef } from '@progress/kendo-angular-dialog'
import { NgxSpinnerService } from 'ngx-spinner'
import { ToastrService } from 'ngx-toastr'

@Component({
  selector: 'app-add-installed-date',
  templateUrl: './add-installed-date.component.html',
  styleUrls: ['./add-installed-date.component.scss'], // Corrected property name styleUrl to styleUrls
})
export class AddInstalledDateComponent {
  deviceData: any
  headerText: string = ''
  installDateForm: FormGroup | any

  constructor(
    private store: Store,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    @Optional() private dialogRef: DialogRef,
    public commonService: CommonService,
    private datePipe: DatePipe,
  ) {
    this.setForm()
  }

  onCancel(): void {
    this.dialogRef.close({ isSave: false })
  }

  onInstallDateSubmit() {
    if (this.installDateForm.invalid) {
      return
    }
    const installDate = this.installDateForm.get('deviceInstallationDate')
      .value as Date
    const installDateInUTCFormat = new Date(
      installDate.getTime() - installDate.getTimezoneOffset() * 60000,
    )

    const param = {
      deviceId: this.deviceData.deviceId,
      installationDate: this.datePipe.transform(
        installDateInUTCFormat,
        "yyyy-MM-ddTHH:mm:ss.SSS'Z'",
        'UTC',
      ),
    }

    this.store.dispatch(new UpdateInstallDateByDeviceId(param)).subscribe(
      (res) => {
        this.spinner.hide()
        this.toastr.success(
          NotificationMessage.DeviceInstallDateUpdatedSuccessMsg,
        )
        this.commonService.onDialogClose(this.dialogRef, true)
      },
      (error) => {
        this.spinner.hide()
      },
    )
  }

  setForm(): void {
    this.installDateForm = new FormGroup({
      deviceInstallationDate: new FormControl('', [Validators.required]),
    })
  }
}
