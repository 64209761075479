import { Component, OnInit, ViewChild } from '@angular/core'
import {
  GetColumnPermissionList,
  GetMenuWithColumns,
  GetRoleList,
  SaveColumnModulePermissionList,
} from '@app/store'
import { Store } from '@ngxs/store'
import { NgxSpinnerService } from 'ngx-spinner'
import { ToastrService } from 'ngx-toastr'
import { switchMap, catchError } from 'rxjs/operators'
import { Subject, of } from 'rxjs'
import { NotificationMessage } from '@app/enums/notification'
import {
  CommonColumnPermissionComponent,
  ElectricType,
  MenuList,
  OfficeTypeListEmun,
} from '@app/index'
import { Roles } from '@app/enums/roles'
import swal from 'sweetalert'

@Component({
  selector: 'app-column-permission',
  templateUrl: './column-permission.component.html',
  styleUrl: './column-permission.component.scss',
})
export class ColumnPermissionComponent implements OnInit {
  @ViewChild(CommonColumnPermissionComponent)
  commonColumnPermissionComponent!: CommonColumnPermissionComponent
  triggerGetUpdatedTime: Subject<any> = new Subject<any>()
  coulmnList: any[] = []
  selectedRole: any
  selectedModule: any
  rolesList: any[] = []
  moduleList: any[] = []
  electricType = ElectricType
  officeTypeList: any = {}
  selectedElectricType: any = ElectricType['Voltage']
  selectedOfficeTypeList: any = OfficeTypeListEmun['Device Reports']
  isSettingHistory = false
  isOfficeReport = false

  constructor(
    private store: Store,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
  ) {}

  ngOnInit(): void {
    this.initializeOfficeTypeList()
    this.getPagePermissionRoleList()
    setTimeout(() => {
      this.triggerGetUpdatedTime.next(true)
    }, 0)
  }

  initializeOfficeTypeList() {
    this.officeTypeList = { ...OfficeTypeListEmun }
    delete this.officeTypeList[3]
    delete this.officeTypeList['Loaded Settings']
  }

  getPagePermissionRoleList(event = null) {
    this.spinner.show()
    this.store
      .dispatch(new GetRoleList(null))
      .pipe(
        switchMap((res: any) => {
          this.spinner.hide()
          const roleList = res.role.roleList.data.rows.map((x: any) => ({
            id: x.id,
            name: x.name,
          }))

          this.rolesList = roleList.filter(
            (role: { id: number }) => role.id !== Roles.SuperAdmin,
          )

          this.selectedRole = event === null ? this.rolesList[0].id : event
          if (this.selectedRole !== undefined) {
            return this.store.dispatch(new GetMenuWithColumns())
          } else {
            return of(null)
          }
        }),
        catchError((error) => {
          this.spinner.hide()
          return of(null)
        }),
      )
      .pipe(
        switchMap((res: any) => {
          if (res) {
            this.spinner.hide()
            this.moduleList = res.common.MenuWithColumns.map((x: any) => ({
              id: x.id,
              name: x.text,
            }))
            this.selectedModule = this.moduleList[0]?.id
            if (this.selectedModule !== undefined) {
              const params = {
                moduleId: this.selectedModule,
                roleId: this.selectedRole,
                settingHistoryType: this.selectedElectricType,
                officeSettingType: this.selectedOfficeTypeList,
              }
              return this.store.dispatch(new GetColumnPermissionList(params))
            } else {
              return of(null)
            }
          } else {
            return of(null)
          }
        }),
        catchError((error) => {
          this.spinner.hide()
          return of(null)
        }),
      )
      .subscribe(
        (res: any) => {
          this.spinner.hide()
          this.coulmnList = res.columnPermission.columnModulePermissionList
          this.commonColumnPermissionComponent.checkAllSelectedPermission(
            this.coulmnList,
          )
        },
        (error) => {
          this.spinner.hide()
        },
      )
  }

  onRoleChange(event: any) {
    this.getPagePermissionRoleList(event)
  }

  onModuleChange() {
    if (this.selectedModule !== undefined) {
      if (this.selectedModule === MenuList['Settings History']) {
        this.isSettingHistory = true
        this.isOfficeReport = false
      } else if (this.selectedModule === MenuList['Office Report']) {
        this.isOfficeReport = true
        this.isSettingHistory = false
      } else {
        this.isSettingHistory = false
        this.isOfficeReport = false
        this.selectedElectricType = ElectricType['Voltage']
        this.selectedOfficeTypeList = OfficeTypeListEmun['Device Reports']
      }
      this.getColumnList(this.selectedModule)
    }
  }

  getColumnList(selectedModule: any): void {
    this.spinner.show()
    const params = {
      moduleId: selectedModule,
      roleId: this.selectedRole,
      settingHistoryType: this.selectedElectricType,
      officeSettingType: this.selectedOfficeTypeList,
    }
    this.store.dispatch(new GetColumnPermissionList(params)).subscribe(
      (res) => {
        this.coulmnList = res.columnPermission.columnModulePermissionList
        this.commonColumnPermissionComponent.checkAllSelectedPermission(
          this.coulmnList,
        )
        this.spinner.hide()
      },
      (error) => {
        this.spinner.hide()
      },
    )
  }

  handleColumnDataChange(updatedData: any[]) {
    this.coulmnList = updatedData
  }

  onSubmit() {
    let textMessage = `You want to update Columns Permissions.`

    swal({
      title: 'Are you sure ?',
      text: textMessage,
      icon: 'warning',
      buttons: {
        cancel: {
          text: 'Cancel',
          visible: true,
          closeModal: true,
        },
        confirm: {
          text: 'Yes',
        },
      },
      dangerMode: true,
    }).then((confirmed) => {
      if (confirmed) {
        this.spinner.show()
        this.store
          .dispatch(
            new SaveColumnModulePermissionList(
              this.selectedModule,
              this.selectedRole,
              this.coulmnList,
            ),
          )
          .subscribe(
            (res) => {
              this.spinner.hide()
              this.toastr.success(
                NotificationMessage.ColumnPermissionSaveSuccessMsg,
              )
            },
            (error) => {
              this.spinner.hide()
            },
          )
      }
    })
  }

  getSettingHistoryTypeList() {
    this.getColumnList(this.selectedModule)
  }

  onChangeOfficeType() {
    this.getColumnList(this.selectedModule)
  }
}
