import { Injectable } from '@angular/core'
import { Action, State, StateContext } from '@ngxs/store'
import { SettingHistoryService } from '@app/services'
import { GetSettingHistoryTypeList } from '.'
import { tap } from 'rxjs'
export class SettingHistoryStateInfo {
  settingHistoryTypeList?: any
}

@State<SettingHistoryStateInfo>({
  name: 'historySetting',
  defaults: {},
})
@Injectable()
export class SettingHistoryState {
  constructor(private settingHistoryService: SettingHistoryService) {}

  @Action(GetSettingHistoryTypeList)
  getSettingHistoryTypeList(
    { getState, patchState }: StateContext<SettingHistoryStateInfo>,
    action: GetSettingHistoryTypeList,
  ) {
    return this.settingHistoryService
      .getSettingHistoryTypeList(
        action.settingHistoryType,
        action.settingHistoryRequestBody,
      )
      .pipe(
        tap((res: any) => {
          patchState({
            settingHistoryTypeList: res,
          })
        }),
      )
  }
}
