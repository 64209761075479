import { Component } from '@angular/core'
import { DialogRef } from '@progress/kendo-angular-dialog'

@Component({
  selector: 'app-common-color-info',
  templateUrl: './common-color-info.component.html',
  styleUrl: './common-color-info.component.scss',
})
export class CommonColorInfoComponent {
  constructor(public dialogRef: DialogRef) {}

  closePopup(): void {
    this.dialogRef.close()
  }
}
