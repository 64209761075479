export enum HeaderDataEnum {
  'Account List' = 1,
  'Log out' = 2,
  'Setting' = 3,
  'Notification' = 4,
  'Log In' = 5,
  'Manage Devices' = 6,
  'Change Password' = 7,
  'Profile' = 8,
}
