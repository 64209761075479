import { Injectable } from '@angular/core'
import { Selector, Action, StateContext, State } from '@ngxs/store'
import { tap } from 'rxjs'
import {
  CancelDeviceSetting,
  CreateLoadedSetting,
  GetDeviceReportsData,
  GetOfficeLoadSetting,
} from '.'
import { OfficeSettingsService } from '@app/services/office-setting'

export class OfficeSettingsStateInfo {
  deviceReportsData?: any
  reviewSettingData?: any
  loadedSettingData?: any
  simModuleOffReportData?: any
  simcomStatusData?: any
  sysyemStatusData?: any
  isSaved?: any
  officeLoadSetting?: any
  cancelDeviceSetting?: any
}

@State<OfficeSettingsStateInfo>({
  name: 'officeSettings',
  defaults: {},
})
@Injectable()
export class OfficeSettingsState {
  constructor(private officeSettingsService: OfficeSettingsService) {}

  @Selector()
  static getDeviceReportsData(state: OfficeSettingsStateInfo) {
    return state.deviceReportsData
  }

  @Selector()
  static getReviewSettingsData(state: OfficeSettingsStateInfo) {
    return state.reviewSettingData
  }

  @Selector()
  static getLoadedSettingsStaticData(state: OfficeSettingsStateInfo) {
    return state.loadedSettingData
  }

  @Selector()
  static getSIMModuleOffReportData(state: OfficeSettingsStateInfo) {
    return state.simModuleOffReportData
  }

  @Selector()
  static getSimcomStatusData(state: OfficeSettingsStateInfo) {
    return state.simcomStatusData
  }

  @Selector()
  static getSystemStatusData(state: OfficeSettingsStateInfo) {
    return state.sysyemStatusData
  }

  @Action(GetOfficeLoadSetting)
  getOfficeLoadSetting(
    { getState, patchState }: StateContext<OfficeSettingsStateInfo>,
    action: GetOfficeLoadSetting,
  ) {
    return this.officeSettingsService
      .getOfficeLoadSetting(action.deviceId)
      .pipe(
        tap((res: any) => {
          patchState({
            officeLoadSetting: res,
          })
        }),
      )
  }

  @Action(GetDeviceReportsData)
  getDeviceReportsData(
    { getState, patchState }: StateContext<OfficeSettingsStateInfo>,
    action: any,
  ) {
    return this.officeSettingsService
      .getDeviceReportsData(action.officeSettingType, action.parameters)
      .pipe(
        tap((res: any) => {
          patchState({
            deviceReportsData: res,
          })
        }),
      )
  }

  @Action(CreateLoadedSetting)
  createLoadedSetting(
    { patchState }: StateContext<OfficeSettingsStateInfo>,
    action: CreateLoadedSetting,
  ) {
    return this.officeSettingsService
      .createLoadedSetting(action.loadedSettingData)
      .pipe(
        tap((res) => {
          patchState({
            isSaved: res,
          })
        }),
      )
  }

  @Action(CancelDeviceSetting)
  updateAlertSettings(
    { getState, patchState }: StateContext<OfficeSettingsStateInfo>,
    action: CancelDeviceSetting,
  ) {
    return this.officeSettingsService.cancelDeviceSetting(action.reqBody).pipe(
      tap((res: any) => {
        patchState({
          cancelDeviceSetting: res,
        })
      }),
    )
  }
}
