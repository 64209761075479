import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { SettingHistoryComponent } from './setting-history.component'
import { AuthGuard } from '@app/index'

const routes: Routes = [
  { path: '', component: SettingHistoryComponent, canActivate: [AuthGuard] },
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SettingHistoryRoutingModule {}
