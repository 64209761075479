import { Component, OnInit, ViewChild } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { noSpacesOrSpecialCharsValidator } from '@app/directive'
import {
  AddUserActivation,
  CreateDatabase,
  GetDeviceListWithoutDb,
} from '@app/store'
import { Store } from '@ngxs/store'
import { MultiSelectComponent } from '@progress/kendo-angular-dropdowns'
import { NgxSpinnerService } from 'ngx-spinner'
import { ToastrService } from 'ngx-toastr'
import { Subject } from 'rxjs'

@Component({
  selector: 'app-create-database',
  templateUrl: './create-database.component.html',
  styleUrl: './create-database.component.scss',
})
export class CreateDatabaseComponent implements OnInit {
  triggerGetUpdatedTime: Subject<any> = new Subject<any>()
  listItems: any[] = []
  deviceData: Array<{ text: string; value: number }> | any
  activationCodeForm: FormGroup | any
  createDatabaseForm: FormGroup | any
  @ViewChild('device', { static: false })
  deviceMultiSelect!: MultiSelectComponent

  isChecked = false
  constructor(
    public store: Store,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
  ) {}

  ngOnInit(): void {
    this.setForm()
    this.getDeviceListWithoutDb()
    setTimeout(() => {
      this.triggerGetUpdatedTime.next(true)
    }, 0)
  }

  tagMapper = (tags: any[]) => {
    if (tags.length > 2) {
      return [
        ...tags.slice(0, 2),
        {
          text: `+ ${tags.length - 2}`,
          value: null,
        },
      ]
    } else {
      return tags
    }
  }

  addActivation(): void {
    if (this.activationCodeForm.invalid) {
      this.activationCodeForm.markAllAsTouched()
      return
    }
    this.spinner.show()
    const deviceId = this.activationCodeForm.get('deviceId')?.value
    const activationCode = this.activationCodeForm.get('activationCode')?.value
    this.store
      .dispatch(new AddUserActivation(activationCode, deviceId))
      .subscribe(
        (res) => {
          if (res.useractivation.isSuccess) {
            this.toastr.success('User activation code added successfully')
            this.activationCodeForm.reset()
            this.getDeviceListWithoutDb()
          } else {
            this.toastr.error('Failed to add user activation code')
          }
          this.spinner.hide()
        },
        (error) => {
          this.spinner.hide()
        },
      )
  }

  filterChange(searchTerm: string): void {
    const contains =
      (value: string) => (item: { text: string; value: number }) =>
        item.text.toLowerCase().includes(value.toLowerCase())
    this.deviceData = this.listItems.filter(contains(searchTerm))
  }

  setForm(): void {
    this.activationCodeForm = new FormGroup({
      deviceId: new FormControl('', [
        Validators.required,
        noSpacesOrSpecialCharsValidator(),
      ]),
      activationCode: new FormControl('', [
        Validators.required,
        noSpacesOrSpecialCharsValidator(),
      ]),
    })

    this.createDatabaseForm = new FormGroup({
      device: new FormControl([], [Validators.required]),
    })
  }

  createDatabase(): void {
    if (this.createDatabaseForm.invalid) {
      this.createDatabaseForm.markAllAsTouched()
      return
    }
    this.spinner.show()
    const deviceIds = this.createDatabaseForm.controls.device.value.map(
      (item: any) => item.value,
    )
    this.store.dispatch(new CreateDatabase(deviceIds)).subscribe(
      (res) => {
        if (res.device.isDatabaseCreated) {
          this.toastr.success('Database Create successfully')
          this.createDatabaseForm.reset()
          this.createDatabaseForm.get('device')?.setValue([])
          this.isChecked = false
          this.getDeviceListWithoutDb()
        } else {
          this.toastr.error('Failed to Create Database')
        }
        this.spinner.hide()
      },
      (error) => {
        this.spinner.hide()
      },
    )
  }

  getDeviceListWithoutDb() {
    this.spinner.show()
    this.store.dispatch(new GetDeviceListWithoutDb()).subscribe((res) => {
      let deviceList = res.useractivation.deviceListWithoutDb
      this.listItems = deviceList
        .filter((device: any) => device.key !== 'All')
        .map((device: any) => ({ text: device.key, value: device.value }))

      this.deviceData = this.listItems.slice()
      this.spinner.hide()
    })
  }

  public get isIndet() {
    const value = this.createDatabaseForm.controls.device.value
    return value?.length !== 0 && value.length !== this.deviceData.length
  }

  public get toggleAllText() {
    return this.isChecked ? 'Deselect All' : 'Select All'
  }

  isItemSelected(item: any) {
    const value = this.createDatabaseForm.controls.device.value
    return value.some((x: any) => x.value === item.value)
  }

  onClick() {
    this.isChecked = !this.isChecked
    this.createDatabaseForm
      .get('device')
      ?.setValue(this.isChecked ? this.deviceData.slice() : [])
    this.deviceMultiSelect.clearFilter()
  }

  onValueChange() {
    const value = this.createDatabaseForm.controls.device.value
    this.isChecked = value.length === this.deviceData.length
    this.deviceMultiSelect.clearFilter()
  }

  onTabSelect(event: any): void {}
}
