import { Component, OnInit } from '@angular/core'
import {
  FormGroup,
  FormControl,
  Validators,
  ValidatorFn,
  AbstractControl,
  ValidationErrors,
} from '@angular/forms'
import { Router } from '@angular/router'
import { noSpacesOrSpecialCharsValidator } from '@app/directive'
import { UpdateLoginUserPassword } from '@app/store'
import { Store } from '@ngxs/store'
import { InputType } from '@progress/kendo-angular-inputs/common/models'
import { eyeIcon, SVGIcon } from '@progress/kendo-svg-icons'
import { NgxSpinnerService } from 'ngx-spinner'
import { ToastrService } from 'ngx-toastr'
import { Subject } from 'rxjs'

@Component({
  selector: 'app-password-change',
  templateUrl: './password-change.component.html',
  styleUrls: ['./password-change.component.scss'],
})
export class PasswordChangeComponent implements OnInit {
  triggerGetUpdatedTime: Subject<any> = new Subject<any>()
  passwordChangeForm!: FormGroup
  public eyeIcon: SVGIcon = eyeIcon
  formName = 'Change'
  passwordFieldType: InputType = 'password'
  confirmPasswordFieldType: InputType = 'password'
  showPassword: boolean = false
  showConfirmPassword: boolean = false

  constructor(
    private router: Router,
    private toastr: ToastrService,
    private store: Store,
    private spinner: NgxSpinnerService,
  ) {}

  ngOnInit(): void {
    this.setForm()
    setTimeout(() => {
      this.triggerGetUpdatedTime.next(true)
    }, 0)
  }

  setForm(): void {
    this.passwordChangeForm = new FormGroup(
      {
        password: new FormControl('', [
          Validators.required,
          noSpacesOrSpecialCharsValidator(),
          this.strongPasswordValidator,
        ]),
        confirmPassword: new FormControl('', [
          Validators.required,
          noSpacesOrSpecialCharsValidator(),
        ]),
      },
      { validators: this.passwordMatchValidator },
    )
  }

  passwordMatchValidator: ValidatorFn = (
    control: AbstractControl,
  ): { [key: string]: boolean } | null => {
    const password = control.get('password')?.value
    const confirmPassword = control.get('confirmPassword')?.value
    if (password && confirmPassword && password !== confirmPassword) {
      return { mismatch: true }
    }
    return null
  }

  passwordchange(): void {
    if (this.passwordChangeForm.invalid) {
      this.passwordChangeForm.markAllAsTouched()
      return
    }
    const password = this.passwordChangeForm.get('password')?.value
    this.spinner.show()
    this.store.dispatch(new UpdateLoginUserPassword(password)).subscribe(
      (res) => {
        this.spinner.hide()
        if (res.isUpdatedUserPassword) {
          this.toastr.success('Password changed successfully')
          this.passwordChangeForm.reset()
        } else {
          this.passwordChangeForm.reset()
          this.toastr.error('Failed to change password')
        }
      },
      (error) => {
        this.spinner.hide()
      },
    )
  }

  strongPasswordValidator: ValidatorFn = (
    control: AbstractControl,
  ): ValidationErrors | null => {
    const value = control.value
    if (!value) {
      return null
    }

    const hasNumber = /[0-9]/.test(value)
    const hasUpper = /[A-Z]/.test(value)
    const hasLower = /[a-z]/.test(value)
    const hasSpecial = /[!@#$%^&*(),.?":{}|<>_-]/.test(value) // Added _ and - to special characters
    const isValidLength = value.length >= 8

    const passwordValid =
      hasNumber && hasUpper && hasLower && hasSpecial && isValidLength

    if (!passwordValid) {
      return { strongPassword: true }
    }
    return null
  }

  onCancel(): void {
    this.router.navigate(['change-password'])
  }

  toggleVisibility(field: 'password' | 'confirmPassword'): void {
    if (field === 'password') {
      this.showPassword = !this.showPassword
      this.passwordFieldType = this.showPassword ? 'text' : 'password'
    } else if (field === 'confirmPassword') {
      this.showConfirmPassword = !this.showConfirmPassword
      this.confirmPasswordFieldType = this.showConfirmPassword
        ? 'text'
        : 'password'
    }
  }
}
