import { Injectable } from '@angular/core'
import { Action, Selector, State, StateContext } from '@ngxs/store'
import {
  CheckAccountExistsByFirstName,
  CreateUserAccount,
  DeleteAccount,
  ForgotPassword,
  GetAccountList,
  GetDeviceListByAccountId,
  GetUserDetailsWithId,
  SaveAccountDevice,
  UpdateLoginUserPassword,
  UpdateUserAccount,
} from '.'
import { tap } from 'rxjs'
import { AccountService } from '@app/services'
export class AccountStateInfo {
  accountList?: any
  deviceListByAccout?: any
  isUserAccoutAdded?: any
  isDelete?: any
  userDetails?: any
  isUpdated?: any
  passwordDetails?: any
  isUpdatedUserPassword?: any
  isUpdatedForgotPassword?: any
  isAccountExists?: boolean
}

@State<AccountStateInfo>({
  name: 'account',
  defaults: {},
})
@Injectable()
export class AccountState {
  constructor(private accountService: AccountService) {}

  @Selector()
  static getAllAccountList(state: AccountStateInfo): any {
    return state.accountList ?? []
  }
  @Action(GetAccountList)
  getAccountList(
    { getState, patchState }: StateContext<AccountStateInfo>,
    action: any,
  ) {
    return this.accountService.getAccountList(action.queryParam).pipe(
      tap((res: any) => {
        patchState({
          accountList: res,
        })
      }),
    )
  }

  @Action(CreateUserAccount)
  createBankAccount(
    { getState, patchState }: StateContext<AccountStateInfo>,
    action: CreateUserAccount,
  ) {
    return this.accountService.createUserAccount(action.userAccount).pipe(
      tap((res) => {
        patchState({
          isUserAccoutAdded: res,
        })
      }),
    )
  }

  @Action(GetUserDetailsWithId)
  getUserDetailsWithId(
    { patchState }: StateContext<AccountStateInfo>,
    action: GetUserDetailsWithId,
  ) {
    return this.accountService
      .getUserDetailsWithId(action.emailId, action.mobileNumber)
      .pipe(
        tap((res) => {
          patchState({
            userDetails: res,
          })
        }),
      )
  }

  @Action(UpdateUserAccount)
  updateUserAccount(
    { patchState }: StateContext<AccountStateInfo>,
    action: UpdateUserAccount,
  ) {
    return this.accountService.updateUserAccount(action.userAccountData).pipe(
      tap((res) => {
        patchState({
          isUpdated: res,
        })
      }),
    )
  }

  @Action(DeleteAccount)
  deleteAccount(
    { setState }: StateContext<AccountStateInfo>,
    action: DeleteAccount,
  ) {
    return this.accountService
      .deleteAccount(action.emailId, action.mobileNumber)
      .pipe(
        tap((res) => {
          setState({
            isDelete: res,
          })
        }),
      )
  }

  @Action(GetDeviceListByAccountId)
  getDeviceListByAccountId(
    { getState, patchState }: StateContext<AccountStateInfo>,
    action: any,
  ) {
    return this.accountService
      .getDeviceListByAccountId(action.queryParams.accountId)
      .pipe(
        tap((res: any) => {
          patchState({
            deviceListByAccout: res,
          })
        }),
      )
  }

  @Action(SaveAccountDevice)
  saveAccountDevice(
    { getState, patchState }: StateContext<AccountStateInfo>,
    action: SaveAccountDevice,
  ) {
    return this.accountService.saveAccountDevice(action.requestBody)
  }

  @Action(UpdateLoginUserPassword)
  updateLoginUserPassword(
    { patchState }: StateContext<AccountStateInfo>,
    action: UpdateLoginUserPassword,
  ) {
    return this.accountService.updateLoginUserPassword(action.password).pipe(
      tap((res) => {
        patchState({
          isUpdatedUserPassword: res,
        })
      }),
    )
  }

  @Action(ForgotPassword)
  updateForgotPassword(
    { patchState }: StateContext<AccountStateInfo>,
    action: ForgotPassword,
  ) {
    return this.accountService.updateForgotPassword(action.emailMobileNo).pipe(
      tap((res) => {
        patchState({
          isUpdatedForgotPassword: res,
        })
      }),
    )
  }

  @Action(CheckAccountExistsByFirstName)
  checkAccountExistsByFirstName(
    { getState, patchState }: StateContext<AccountStateInfo>,
    action: CheckAccountExistsByFirstName,
  ) {
    return this.accountService
      .checkAccountExistsByFirstName(action.accountId, action.firstName)
      .pipe(
        tap((res: boolean) => {
          patchState({
            isAccountExists: res,
          })
        }),
      )
  }
}
