import { FileUploadRequestModel } from '@app/models'

export class CheckIfDbExistsForDevice {
  static readonly type = '[DEVICE] Check If Db Exists For Device'
  constructor(public deviceId: any) {}
}

export class GetDeviceList {
  static readonly type = '[DEVICE] Get Device List'
  constructor(public queryParam: any) {}
}

export class DeleteDeviceByDeviceId {
  static readonly type = '[DEVICE] Delete Device By Id'
  constructor(public deleteDeviceByIdRequestBody: any) {}
}

export class UpdateDeviceByDeviceId {
  static readonly type = '[DEVICE] Update Device By Id'
  constructor(
    public deviceId: any,
    public updateDeviceRequestBody: any,
  ) {}
}

export class GetDeviceDetailByDeviceId {
  static readonly type = '[DEVICE] Get Device Details By Id'
  constructor(public deviceId: any) {}
}

export class AddDevice {
  static readonly type = '[DEVICE] Add Device'
  constructor(public addDeviceRequestBody: any) {}
}

export class GetDeviceListForAccount {
  static readonly type = '[DEVICE] Get DeviceList For Account'
  constructor(public queryParams: any) {}
}

export class GetAllByPagination {
  static readonly type = '[DEVICE] Get DeviceList For Logined Account'
  constructor(
    public queryParam: any,
    public mobileNumber: any,
    public emailId: any,
  ) {}
}

export class DeleteUserDeviceLogs {
  static readonly type = '[ACCOUNT] Delete User Device Logs'

  constructor(public queryParam: any) {}
}

export class SendOtp {
  static readonly type = '[Device] Send OTP'
}

export class GetDeviceListWithPagination {
  static readonly type = '[Device] Get Device List With Pagination'
}

export class CheckActivationDeviceExistsByDeviceId {
  static readonly type = '[DEVICE] Check Activation Device Exists By DeviceId'

  constructor(public deviceId: any) {}
}

export class GetGpsLocation {
  static readonly type = '[DEVICE] Get Gps Location'

  constructor(public location: any) {}
}

export class CreateDatabase {
  static readonly type = '[DEVICE] Create Database'

  constructor(public deviceList: any) {}
}

export class ImageFileUpload {
  static readonly type = '[FILE] File Upload'

  constructor(
    public deviceId: any,
    public file: any,
  ) {}
}

export class DownloadFileUpload {
  static readonly type = '[FILE] Download File Upload'

  constructor(public dowloadFileRequestBody: any) {}
}

export class UpdateInstallDateByDeviceId {
  static readonly type = '[DEVICE] Update install date By Id'
  constructor(public updateInstallDateRequestBody: any) {}
}
