<kendo-dialog-titlebar (close)="onCloseDialog()">
  <span>Add Devices To Feeder - {{ feederName }}</span>
</kendo-dialog-titlebar>
<kendo-grid
  [kendoGridBinding]="deviceList"
  [selectable]="{ mode: 'multiple', drag: true }"
  kendoGridSelectBy="deviceId"
  class="mb-20"
  [sortable]="isSortingAllowed"
  [sort]="sort"
  (sortChange)="onSortChange($event)"
  filterable="menu"
  [filter]="filter"
  (filterChange)="filterChange($event)"
>
  <kendo-grid-checkbox-column
    [width]="45"
    [headerClass]="{ 'text-center': true }"
    [class]="{ 'text-center': true }"
    [resizable]="false"
    [columnMenu]="false"
    [showSelectAll]="false"
  >
    <ng-template kendoGridHeaderTemplate>
      <input
        type="checkbox"
        (change)="toggleSelectAll($event)"
        [checked]="isAllSelected"
        kendoCheckBox
        class="vertical-align-middle"
      />
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <input
        type="checkbox"
        [checked]="dataItem.isSelected"
        (change)="onCheckboxChange(dataItem, $event)"
        kendoCheckBox
        class="vertical-align-middle"
      />
    </ng-template>
  </kendo-grid-checkbox-column>

  <kendo-grid-column field="deviceId" title="Device Id">
    <ng-template kendoGridCellTemplate let-dataItem>
      <div>{{ dataItem.deviceId }}</div>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="deviceName" title="Device Name">
    <ng-template kendoGridCellTemplate let-dataItem>
      <div>{{ dataItem.deviceName }}</div>
    </ng-template>
  </kendo-grid-column>
</kendo-grid>
<kendo-dialog-actions>
  <button kendoButton (click)="onCloseDialog()" fillMode="outline">
    Cancel
  </button>
  <div class="display-inline flex-0">
    <button kendoButton (click)="checkConfirmation()">Submit</button>
  </div>
</kendo-dialog-actions>
