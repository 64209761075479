import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable, of } from 'rxjs'
import { environment } from 'src/environments'

@Injectable()
export class PFService {
  gridData = [
    {
      Alert: '100% Exceeded',
      PF: 0.775,
      time: '17:15:25 09-05-2024',
    },
    {
      Alert: '100% Exceeded',
      PF: 0.775,
      time: '16:15:00 09-05-2024',
    },
  ]
  constructor(private http: HttpClient) {}

  getPowerFactorAlert(queryParams: any): Observable<HttpResponse<any>> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      observe: 'response' as 'response',
    }

    return this.http.post<any>(
      `${environment.apiVersionUrl}Alert/getPowerFactorAlert`,
      queryParams,
      httpOptions,
    )
  }
}
