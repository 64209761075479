import { Component, Input, OnInit } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { CommonService } from '@app/services'

@Component({
  selector: 'app-device-serial-id',
  templateUrl: './device-serial-id.component.html',
  styleUrl: './device-serial-id.component.scss',
})
export class DeviceSerialIdComponent implements OnInit {
  deviceSerialIdForm: FormGroup | any
  @Input()
  triggerDeviceSerialIdData: any
  constructor(public commonService: CommonService) {}

  ngOnInit(): void {
    this.setForm()
  }

  setForm(): void {
    this.deviceSerialIdForm = new FormGroup({
      newDeviceSerialId: new FormControl('', [
        Validators.required,
        Validators.pattern('^[0-9]+(.[0-9]+)?$'),
      ]),
    })
  }
}
