import { Injectable } from '@angular/core'
import { Action, Selector, State, StateContext } from '@ngxs/store'
import { GisInfoGetDeviceList } from '.'
import { tap } from 'rxjs'
import { DeviceService, GisInfoService } from '@app/services'
export class GisInfoStateInfo {
  deviceListData?: any
  deviceFeederList?: []
  deviceDetailById?: any
}

@State<GisInfoStateInfo>({
  name: 'gisInfo',
  defaults: {},
})
@Injectable()
export class GisInfoState {
  constructor(private gisInfoService: GisInfoService) {}

  @Selector()
  static getDeviceData(state: GisInfoStateInfo) {
    return state.deviceListData
  }

  @Action(GisInfoGetDeviceList)
  getDeviceList(
    { getState, patchState }: StateContext<GisInfoStateInfo>,
    action: GisInfoGetDeviceList,
  ) {
    return this.gisInfoService.getDeviceList(action.queryParam).pipe(
      tap((res: any) => {
        patchState({
          deviceListData: res,
        })
      }),
    )
  }
}
