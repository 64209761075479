import { Component } from '@angular/core'
import { Store } from '@ngxs/store'
import { NgxSpinnerService } from 'ngx-spinner'
import { MenuList, ModuleHistoryModuleList } from '@app/enums'
import { CommonService } from '@app/services'
import {
  GridDataResult,
  MultipleSortSettings,
  PagerPosition,
  PagerType,
} from '@progress/kendo-angular-grid'
import {
  CompositeFilterDescriptor,
  SortDescriptor,
} from '@progress/kendo-data-query'
import { GetModuleHistoryByModuleId } from '@app/store'
import { Subject } from 'rxjs'

@Component({
  selector: 'app-module-history',
  templateUrl: './module-history.component.html',
  styleUrl: './module-history.component.scss',
})
export class ModuleHistoryComponent {
  triggerGetUpdatedTime: Subject<any> = new Subject<any>()

  gridView: GridDataResult | any
  moduleId: any = MenuList['Module-History']
  columns: any[] = []
  moduleList = ModuleHistoryModuleList

  selectedModuleId: any = ModuleHistoryModuleList['Roles']

  gridHeight: number = 0
  pagerposition: PagerPosition = 'bottom'
  skip = 0
  type: PagerType = 'numeric'
  sort: SortDescriptor[] = []
  previousSort: any = null
  pageNumber = 1
  pageSize = 10

  sortOrder = false
  sortBy: any
  isPaginationAllowed: boolean = true
  buttonCount = 5
  info = true
  pageSizes = [5, 10, 20, 50, 100]

  previousNext = true
  multiple = false
  allowUnsort = true
  isSortingAllowed: boolean = true

  filterQuery: string | any
  sortSettings: MultipleSortSettings = {
    mode: 'multiple',
    initialDirection: 'desc',
    allowUnsort: true,
    showIndexes: true,
  }

  public filter: CompositeFilterDescriptor = {
    logic: 'and',
    filters: [],
  }

  constructor(
    private store: Store,
    private spinner: NgxSpinnerService,
    private commonService: CommonService,
  ) {
    this.columns = this.commonService.getColumnNameByModule(this.moduleId)
  }

  ngOnInit(): void {
    this.getModuleList()
    setTimeout(() => {
      this.triggerGetUpdatedTime.next(true)
    }, 0)

    this.calculateGridHeight()

    window.addEventListener('resize', () => {
      this.calculateGridHeight()
    })
  }

  calculateGridHeight() {
    const screenHeight = window.innerHeight
    const headerHeight = 81
    const gridHeaderHeight = 46
    const wrapperpadding = 20
    const gridPagination = 56

    this.gridHeight =
      screenHeight -
      (headerHeight + gridHeaderHeight + wrapperpadding + gridPagination)
  }

  getModuleList() {
    this.spinner.show()

    const queryParam = {
      moduleId: this.selectedModuleId,
      sortBy: this.sortBy,
      sortOrder: this.sortOrder,
      filterQuery: this.filterQuery,
      pageNumber: this.pageNumber,
      pageSize: this.pageSize,
    }

    this.store.dispatch(new GetModuleHistoryByModuleId(queryParam)).subscribe(
      (x) => {
        this.spinner.hide()
        const moduleHistoryData = x.moduleHistory.moduleHistoryData
        this.gridView = {
          data: moduleHistoryData.data.rows,
          total: moduleHistoryData.totalCount,
        }
      },
      (error) => {
        this.spinner.hide()
      },
    )
  }

  onModuletSelectionChange() {
    this.filterQuery = undefined
    this.filter = { logic: 'and', filters: [] }
    this.getModuleList()
    this.triggerGetUpdatedTime.next(true)
  }

  onFilterChangeSQL(sqlQuery: any) {
    this.skip = 0
    this.pageNumber = 1
    this.filter = sqlQuery
    this.filterQuery = this.commonService.toSQLExpression(sqlQuery)
    this.getModuleList()
  }

  onPaginationChange({ skip, take }: any) {
    this.skip = skip
    this.pageSize = take
    this.pageNumber = this.skip / this.pageSize + 1
    this.getModuleList()
  }

  onSortChange(sortData: any): void {
    this.sort = sortData
    const currentSortField = sortData[0].field
    this.sortBy = currentSortField
    if (this.previousSort === currentSortField) {
      this.sortOrder = !this.sortOrder
    } else {
      this.sortOrder = true
    }
    this.previousSort = currentSortField
    this.getModuleList()
  }
}
