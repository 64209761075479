import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { SharedModule } from 'src/shared/shared.module'
import {
  SoftwareRoutingModule,
  CommonsModule,
  SoftwareComponent,
  OtaUpdateComponent,
  OtaStatusComponent,
} from '@app/index'

@NgModule({
  declarations: [SoftwareComponent, OtaUpdateComponent, OtaStatusComponent],
  imports: [CommonModule, SoftwareRoutingModule, SharedModule, CommonsModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SoftwareModule {}
