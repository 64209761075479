import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable, of } from 'rxjs'
import { environment } from 'src/environments'

@Injectable()
export class ThresholdSettingsService {
  data = {
    min_Voltage: {
      R_Phase: 50,
      Y_Phase: 50,
      B_Phase: 50,
    },
    max_Voltage: {
      R_Phase: 50,
      Y_Phase: 50,
      B_Phase: 50,
    },
    max_Current: {
      R_Phase: 50,
      Y_Phase: 50,
      B_Phase: 50,
    },
    rated_kVA: 200,
    cT_Ratio: 500,
    temperature: 500,
    pF: 0.85,
    unity_PF: 1,
  }
  constructor(private http: HttpClient) {}
  getThresholdSettingsData(): Observable<any> {
    // Return an observable of alert setting data
    return of(this.data)
  }

  saveThresholdSettings(data: any): Observable<any> {
    return of(this.data) // temprory
  }

  getAllThresholdSettingsData(deviceId: string): Observable<any> {
    const url = `${environment.apiVersionUrl}Setting/GetAllThresholdSettings/${deviceId}`
    return this.http.get<any>(url)
  }

  updateMinMaxThresholdVoltage(voltageData: any): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    }
    const url = `${environment.apiVersionUrl}Setting/UpdateThresholdVoltageSettings`
    return this.http.put<any>(url, JSON.stringify(voltageData), headers)
  }

  updateCurrentThreshold(cuurentData: any): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    }
    const url = `${environment.apiVersionUrl}Setting/UpdateThresholdCurrentSettings`
    return this.http.put<any>(url, JSON.stringify(cuurentData), headers)
  }

  updateKVAThreshold(ratedKVA: any, deviceIds: any): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    }
    const url = `${environment.apiVersionUrl}Setting/UpdateThresholdkVASettings/${ratedKVA}`
    return this.http.put<any>(url, JSON.stringify(deviceIds), headers)
  }

  updateCtThreshold(cTRatio: any, deviceIds: any): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    }
    const url = `${environment.apiVersionUrl}Setting/UpdateThresholdCTSettings/${cTRatio}`
    return this.http.put<any>(url, JSON.stringify(deviceIds), headers)
  }

  updaterTemperatureThreshold(
    temperature: any,
    deviceIds: any,
  ): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    }
    const url = `${environment.apiVersionUrl}Setting/UpdateThresholdTempSettings/${temperature}`
    return this.http.put<any>(url, JSON.stringify(deviceIds), headers)
  }

  updaterPfThreshold(powerFactor: any, deviceIds: any): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    }
    const url = `${environment.apiVersionUrl}Setting/UpdateThresholdPFSettings/${powerFactor}`
    return this.http.put<any>(url, JSON.stringify(deviceIds), headers)
  }

  updateUnityPFThreshold(isUnityPF: any, deviceIds: any): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    }
    const url = `${environment.apiVersionUrl}Setting/UpdateThresholdUnityPFSettings/${isUnityPF}`
    return this.http.put<any>(url, JSON.stringify(deviceIds), headers)
  }
}
