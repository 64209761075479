import { Component, ViewChild } from '@angular/core'
import { CommonGridComponent } from '@app/components/common'
import { MenuList, PeriodEnum } from '@app/enums'
import { PercentageEnum, PercentageEnumDesc } from '@app/enums/overload-enum'
import { CommonService } from '@app/services'
import { CommonState } from '@app/store'
import {
  GetAlertOverLoadCount,
  GetAlertOverload,
} from '@app/store/alert/overload'
import { Select, Store } from '@ngxs/store'
import { GridDataResult } from '@progress/kendo-angular-grid'
import { NgxSpinnerService } from 'ngx-spinner'
import { Observable, Subject } from 'rxjs'

@Component({
  selector: 'app-overload-list',
  templateUrl: './overload-list.component.html',
  styleUrl: './overload-list.component.scss',
})
export class OverloadListComponent {
  triggerGetUpdatedTime: Subject<any> = new Subject<any>()
  @ViewChild(CommonGridComponent) commonGridComponent:
    | CommonGridComponent
    | undefined

  overloadCardDetails: any
  gridView: GridDataResult | any
  deviceId: any
  feederAreaId: any
  startDate = null
  endDate = null
  overloadCountText = PercentageEnumDesc.eighty
  overloadCount = PercentageEnum.eighty.toString()
  sort: any
  sortOrder = false
  pageNumber = 1
  pageSize = 10
  skip = 0
  previousSort: any = null

  @Select(CommonState.moduleId)
  moduleId$!: Observable<number>
  isDataLoaded = false
  filterQuery: string | any
  constructor(
    private store: Store,
    private spinner: NgxSpinnerService,
    public commonService: CommonService,
  ) {}

  ngOnInit(): void {}

  getCaidiPeriodLabel(week: number): string {
    return PeriodEnum[week]
  }

  getDataFromColumnSelector(event: any) {
    this.getList(this.deviceId, this.feederAreaId, this.startDate, this.endDate)
  }

  percentageChange(e: any): void {
    this.overloadCount = e.target.value

    switch (+e.target.value) {
      case PercentageEnum.hundred:
        this.overloadCountText = PercentageEnumDesc.hundred
        break
      case PercentageEnum.eighty:
        this.overloadCountText = PercentageEnumDesc.eighty
        break
      case PercentageEnum.fifty:
        this.overloadCountText = PercentageEnumDesc.fifty
        break
      default:
        break
    }

    this.getOverloadCardDetails()
  }

  getList(deviceId: any, feederAreaId: any, startDate: any, endDate: any) {
    let queryParams = {
      deviceId: deviceId,
      feederAreaId: feederAreaId,
      filter: 0,
      pageNumber: this.pageNumber,
      pageSize: this.pageSize,
      sortBy: this.sort ?? null,
      sortOrder: this.sortOrder,
      search: '',
      startDate: startDate,
      endDate: endDate,
      moduleId: this.store.selectSnapshot(CommonState.moduleId),
      filterQuery: this.filterQuery,
    }

    this.spinner.show()
    this.store.dispatch(new GetAlertOverload(queryParams)).subscribe(
      (res: any) => {
        this.spinner.hide()
        if (res.overload.overloadAlertData) {
          const { data, totalCount } = res.overload.overloadAlertData

          this.gridView = {
            data: data.rows,
            total: totalCount,
            columns: data.columns,
          }

          this.isDataLoaded = true
        }
      },
      (error) => {
        this.spinner.hide()
      },
    )
  }

  onColumnSelector(): void {
    this.commonGridComponent?.onColumnSelector()
  }

  getOverloadCardDetails() {
    this.spinner.show()
    this.store
      .dispatch(new GetAlertOverLoadCount(this.deviceId, +this.overloadCount))
      .subscribe(
        (res: any) => {
          this.spinner.hide()
          if (res.overload.overloadCardData.length) {
            this.overloadCardDetails = res.overload.overloadCardData
          } else {
            this.overloadCardDetails = []
          }
        },
        (error) => {
          this.spinner.hide()
        },
      )
  }

  onPaginationChange({ skip, take }: any) {
    this.skip = skip
    this.pageSize = take
    this.pageNumber = this.skip / this.pageSize + 1

    this.getList(this.deviceId, this.feederAreaId, this.startDate, this.endDate)
  }

  onSortChange(sortData: any) {
    const currentSortField = sortData.sort[0].field
    if (this.previousSort === currentSortField) {
      this.sortOrder = !this.sortOrder
    } else {
      this.sortOrder = true
    }
    this.sort = currentSortField
    this.previousSort = currentSortField
    this.getList(this.deviceId, this.feederAreaId, this.startDate, this.endDate)
  }

  getDataByHeaderData(event: any) {
    this.deviceId = event.deviceId
    this.feederAreaId = event.feederAreaId
    this.startDate = event.startDate
    this.endDate = event.endDate
    this.getOverloadCardDetails()
    this.getList(this.deviceId, this.feederAreaId, this.startDate, this.endDate)
    this.triggerGetUpdatedTime.next(true)
  }

  onFilterChangeSQL(sqlQuery: any) {
    this.filterQuery = sqlQuery
    this.getList(this.deviceId, this.feederAreaId, this.startDate, this.endDate)
  }
}
