export enum Roles {
  SuperAdmin = 1,
  Admin = 2,
  CountryLevelRole = 19,
  StateLevelRole = 20,
  RegionLevelRole = 21,
  DistrictLevelRole = 22,
  CityLevelRole = 23,
  ZoneLevelRole = 24,
  TechnicianLevelRole = 31,
}
