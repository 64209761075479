import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import {
  CommonsModule,
  PagePermissionComponent,
  PagePermissionRoutingModule,
} from '@app/index'
import { SharedModule } from '@shared//shared.module'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'

@NgModule({
  declarations: [PagePermissionComponent],
  imports: [
    CommonModule,
    PagePermissionRoutingModule,
    SharedModule,
    CommonsModule,
    ReactiveFormsModule,
    FormsModule,
  ],
})
export class PagePermissionModule {}
