import { Component, OnInit } from '@angular/core'
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { noSpacesOrSpecialCharsValidator } from '@app/directive'
import { NotificationMessage } from '@app/enums/notification'
import { CreateUserLoginDetailModel } from '@app/models'
import { CommonService } from '@app/services'
import {
  AccountState,
  CheckAccountExistsByFirstName,
  CreateUserAccount,
  GetRoleList,
  GetUserDetailsWithId,
  UpdateUserAccount,
} from '@app/store'
import { Store } from '@ngxs/store'
import { NgxSpinnerService } from 'ngx-spinner'
import { ToastrService } from 'ngx-toastr'
import { Subscription } from 'rxjs'
import swal from 'sweetalert'

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrl: './registration.component.scss',
})
export class RegistrationComponent implements OnInit {
  accountForm: FormGroup | any

  roles: any[] = []
  isProfile = false
  isAddMode: boolean | any
  subscriptionRouting: Subscription | any
  userId = 0
  pageNumber = 1
  pageSize = 10
  skip = 0
  sort: any
  sortOrder = false
  formName = 'Create'
  listItems: any[] = []
  passwordVisible = false
  confirmPasswordVisible = false
  accountList: any[] = []

  constructor(
    private router: Router,
    public commonService: CommonService,
    private store: Store,
    private _Activatedroute: ActivatedRoute,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
  ) {}

  ngOnInit(): void {
    this.accountList =
      this.store.selectSnapshot(AccountState.getAllAccountList)?.data?.rows ??
      []
    this.setForm()

    this.getRoleList()
    this.subscriptionRouting = this._Activatedroute.queryParams.subscribe(
      (params) => {
        if (params) {
          this.isAddMode = params['isAddMode'] === 'true'
          this.isProfile = params['isProfile'] === 'true'
          if (!this.isAddMode) {
            const emailId = params['emailId']
            const mobileNumber = params['mobileNumber']
            const emailIdDecoded = atob(emailId)
            const mobileNumberDecoded = atob(mobileNumber)
            this.formName = 'Update'
            this.getUserDetailsWithId(emailIdDecoded, mobileNumberDecoded)
          }
        }
      },
    )
  }

  checkNameExist(event: any): void {
    const isNameExist = this.accountList.find(
      (x: any) =>
        x.firstName.toLowerCase() === event.target.value.toLowerCase(),
    )
    if (isNameExist !== null && isNameExist !== undefined) {
      const nameControl = this.accountForm.get('firstName')
      nameControl.setErrors({ duplicate: true })
      nameControl.markAsTouched()
      nameControl.markAsDirty()
      this.toastr.error(NotificationMessage.sameAccountNameErrorMsg)
    }
  }

  togglePasswordVisibility(): void {
    this.passwordVisible = !this.passwordVisible
  }

  toggleConfirmPasswordVisibility(): void {
    this.confirmPasswordVisible = !this.confirmPasswordVisible
  }

  getRoleList() {
    this.spinner.show()
    this.store.dispatch(new GetRoleList(null)).subscribe(
      (res) => {
        this.spinner.hide()

        let roles = res.role.roleList.data.rows
        this.roles = roles.map((role: any) => ({
          id: role.id,
          name: role.name,
        }))
      },
      (error) => {
        this.spinner.hide()
      },
    )
  }

  getUserDetailsWithId(emailId: any, mobileNumber: any): void {
    this.spinner.show()
    this.store
      .dispatch(new GetUserDetailsWithId(emailId, mobileNumber))
      .subscribe(
        (res) => {
          this.userId = res.account.userDetails.id
          this.spinner.hide()

          this.accountForm.patchValue({
            firstName: res.account.userDetails.firstName,
            roleId: {
              id: res.account.userDetails.roleId,
              name: res.account.userDetails.role,
            },
            phoneNumber: res.account.userDetails.mobileNumber,
            emailAddress: res.account.userDetails.emailId,
            password: res.account.userDetails.password,
            confirmPassword: res.account.userDetails.password,
          })
        },
        (error) => {
          this.spinner.hide()
        },
      )
  }

  setForm(): void {
    this.accountForm = new FormGroup(
      {
        firstName: new FormControl('', [
          Validators.required,
          Validators.maxLength(50),
          noSpacesOrSpecialCharsValidator(),
        ]),
        roleId: new FormControl(null, [Validators.required]),
        phoneNumber: new FormControl('', [
          Validators.required,
          Validators.pattern('^[0-9]*$'),
          Validators.minLength(10),
          Validators.maxLength(10),
        ]),
        emailAddress: new FormControl('', [
          Validators.required,
          Validators.email,
        ]),
        password: new FormControl('', [
          Validators.required,
          this.strongPasswordValidator,
        ]),
        confirmPassword: new FormControl('', [Validators.required]),
      },
      { validators: this.passwordMatchValidator },
    )
  }

  passwordMatchValidator: ValidatorFn = (
    control: AbstractControl,
  ): { [key: string]: boolean } | null => {
    const password = control.get('password')?.value
    const confirmPassword = control.get('confirmPassword')?.value
    if (password && confirmPassword && password !== confirmPassword) {
      return { passwordMismatch: true }
    }
    return null
  }

  strongPasswordValidator: ValidatorFn = (
    control: AbstractControl,
  ): ValidationErrors | null => {
    const value = control.value
    if (!value) {
      return null
    }

    const hasNumber = /[0-9]/.test(value)
    const hasUpper = /[A-Z]/.test(value)
    const hasLower = /[a-z]/.test(value)
    const hasSpecial = /[!@#$%^&*(),.?":{}|<>_-]/.test(value) // Added _ and - to special characters
    const isValidLength = value.length >= 8

    const passwordValid =
      hasNumber && hasUpper && hasLower && hasSpecial && isValidLength

    if (!passwordValid) {
      return { strongPassword: true }
    }
    return null
  }

  // Other methods and properties...

  onCancel(): void {
    this.router.navigate(['account-list'])
  }

  onSubmit(): void {
    let textMessage = `You want to ${this.isAddMode ? 'create a new' : 'update'} Account - ${this.accountForm.get('firstName').value}.`

    swal({
      title: 'Are you sure ?',
      text: textMessage,
      icon: 'warning',
      buttons: {
        cancel: {
          text: 'Cancel',
          visible: true,
          closeModal: true,
        },
        confirm: {
          text: 'Yes',
        },
      },
      dangerMode: true,
    }).then((confirmed) => {
      if (confirmed) {
        this.spinner.show()
        this.store
          .dispatch(
            new CheckAccountExistsByFirstName(
              this.isAddMode ? 0 : this.userId,
              this.accountForm.get('firstName').value,
            ),
          )
          .subscribe((res) => {
            this.spinner.hide()
            if (!res.account.isAccountExists) {
              this.createAccount()
            } else {
              this.toastr.error(NotificationMessage.sameAccountNameErrorMsg)
            }
          })
      }
    })
  }

  createAccount(): void {
    if (this.accountForm.invalid) {
      this.accountForm.controls.firstName.markAsTouched()
      return
    }

    const userAccountData: CreateUserLoginDetailModel = {
      mobileNumber: this.accountForm.value.phoneNumber,
      emailId: this.accountForm.value.emailAddress,
      password: this.accountForm.value.password,
      firstName: this.accountForm.value.firstName,
      lastName: this.accountForm.value.firstName,
      role: this.accountForm.value.roleId.name,
      roleId: this.accountForm.value.roleId.id,
      id: this.isAddMode ? 0 : this.userId,
    }
    this.spinner.show()
    if (this.isAddMode) {
      this.store.dispatch(new CreateUserAccount(userAccountData)).subscribe(
        (res) => {
          this.spinner.hide()
          if (res.account.isUserAccoutAdded) {
            this.toastr.success(NotificationMessage.AccountCreatedSuccessMsg)
            this.router.navigate(['account-list'])
          } else {
            this.toastr.error('somthing wrong!')
          }
        },
        (error) => {
          this.spinner.hide()
        },
      )
    } else {
      this.store.dispatch(new UpdateUserAccount(userAccountData)).subscribe(
        (res) => {
          this.spinner.hide()
          if (res.account.isUpdated) {
            this.toastr.success(NotificationMessage.AccountUpdatedSuccessMsg)
            if (!this.isProfile) {
              this.router.navigate(['account-list'])
            }
          } else {
            this.toastr.error('somthing wrong!')
          }
        },
        (error) => {
          this.spinner.hide()
        },
      )
    }
  }

  onPhoneNumberInput(event: Event): void {
    const input = event.target as HTMLInputElement
    input.value = input.value.replace(/[^0-9]/g, '')
    this.accountForm
      .get('phoneNumber')
      ?.setValue(input.value, { emitEvent: false })
  }
}
