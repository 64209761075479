import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import {
  CommonsModule,
  DtrPredictionAnalysisComponent,
  DtrPredictionAnalysisRoutingModule,
} from '@app/index'
import { SharedModule } from '@shared//shared.module'

@NgModule({
  declarations: [DtrPredictionAnalysisComponent],
  imports: [
    CommonModule,
    DtrPredictionAnalysisRoutingModule,
    CommonsModule,
    SharedModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class DtrPredictionAnalysisModule {}
