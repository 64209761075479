import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core'
import { ActionTypeEnum } from '@app/enums'

@Component({
  selector: 'app-common-column-permission',
  templateUrl: './common-column-permission.component.html',
  styleUrl: './common-column-permission.component.scss',
})
export class CommonColumnPermissionComponent implements OnInit {
  headerActionType = ActionTypeEnum
  isViewHeaderSeleced?: boolean = false

  rolesList: any = []
  moduleList: any[] = []
  selectedRole: any

  @Input() data: any[] | any
  @Input() columns: any[] | any

  @Output() dataChange = new EventEmitter<any[]>()
  headerCheckboxState: boolean[] = []
  constructor() {}

  ngOnInit(): void {}

  checkAllSelectedPermission(data: any): void {
    this.headerCheckboxState[1] =
      data.length > 0 ? data.every((el: any) => el.isSelected === true) : false
  }

  onDataChange() {
    this.dataChange.emit(this.data)
  }

  setViewValue(dataItem: any, column: any, index: any) {
    if (!dataItem[column.field]) {
      this.headerCheckboxState[index] = false
    } else {
      this.headerCheckboxState[index] = this.data.every(
        (item: any) => item.isViewOnly || item[column.field],
      )
    }
    this.onDataChange()
  }

  onActionHeaderSelected(event: any, column: any, index: any) {
    const checked = event.target.checked
    this.data.forEach((item: any) => {
      if (!item.isViewOnly) {
        item[column.field] = checked
      }
    })
    this.headerCheckboxState[index] = checked
    this.updateAllCheckboxes(this.data, 'isSelected', checked)
    this.onDataChange()
  }

  updateAllCheckboxes(data: any[], field: string, checked: boolean): void {
    data.forEach((item) => {
      item[field] = checked
    })
  }
}
