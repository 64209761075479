export enum DataTypeEnum {
  Unknown = 0,
  String = 1,
  Byte = 2,
  Word = 3,
  Int32 = 4,
  Int64 = 5,
  Bool = 6,
  Float = 7,
  Currency = 8,
  BCD = 9,
  Date = 10,
  Time = 11,
  DateTime = 12,
  Autoinc = 13,
  Memo = 14,
  Blob = 15,
  FixedChar = 16,
  Guid = 17,
  Geometry = 18,
  Geography = 19,
  URL = 20,
  InputDecimal = 21,
  InputCalculator = 22,
  DropDown = 23,
  CurrencySymbol = 24,
  Link = 25,
  StatusBox = 26,
  UpperCase = 27,
  TicketStatus = 28,
  ReviewSettingStatus = 29,
  FeederType = 30,
}
