import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'

import { SharedModule } from '@shared//shared.module'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { CommonsModule } from '../common'
import {
  ColumnPermissionComponent,
  ColumnPermissionRoutingModule,
} from '@app/index'
import { PipeModule } from '@app/index'

@NgModule({
  declarations: [ColumnPermissionComponent],
  imports: [
    CommonModule,
    ColumnPermissionRoutingModule,
    SharedModule,
    CommonsModule,
    ReactiveFormsModule,
    FormsModule,
    PipeModule,
  ],
})
export class ColumnPermissionModule {}
