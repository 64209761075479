import { Component, OnInit, OnDestroy } from '@angular/core'
import { DashboardData } from '@app/models'
import { Dashboard } from '@app/store'
import { Store } from '@ngxs/store'
import { NgxSpinnerService } from 'ngx-spinner'
import { interval, Subject, takeUntil, timer } from 'rxjs'

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.scss',
})
export class DashboardComponent implements OnInit, OnDestroy {
  triggerGetUpdatedTime: Subject<any> = new Subject<any>()
  dashboardCardData: DashboardData | undefined
  infoText: string =
    'The above parameters are the latest updated sample values of the selected device.'

  private destroy$ = new Subject<void>()
  deviceId: string | undefined

  constructor(
    private store: Store,
    private spinner: NgxSpinnerService,
  ) {}

  ngOnInit() {
    this.setupInterval()
  }

  setupInterval() {
    interval(60000)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        if (this.deviceId !== null || this.deviceId !== undefined) {
          this.getDashboardData(this.deviceId)
        }
      })
  }

  ngOnDestroy() {
    this.destroy$.next()
    this.destroy$.complete()
  }

  getDashboardData(deviceId: any): void {
    this.spinner.show()
    this.store.dispatch(new Dashboard(deviceId)).subscribe(
      (res: any) => {
        this.spinner.hide()
        if (res.dashboard.dashboardData) {
          this.dashboardCardData = res.dashboard.dashboardData
        }
      },
      (error) => {
        this.spinner.hide()
      },
    )
  }

  getDataByHeaderData(data: any): void {
    this.triggerGetUpdatedTime.next(true)
    this.deviceId = data.deviceId
    this.getDashboardData(data.deviceId)
  }
}
