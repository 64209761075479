import { Component, EventEmitter, Output, ViewChild } from '@angular/core'
import { SeparateDateEnum } from '@app/enums'
import { GetCurrentGraphChart } from '@app/store'
import { Store } from '@ngxs/store'
import { Subject } from 'rxjs'
import { CommonAnalyticsGraphComponent } from '../common-analytics-graph'

@Component({
  selector: 'app-current-graph',
  templateUrl: './current-graph.component.html',
  styleUrl: './current-graph.component.scss',
})
export class CurrentGraphComponent {
  triggerGetUpdatedTime: Subject<any> = new Subject<any>()
  triggerCurrentCardApi: Subject<any> = new Subject<any>()
  isCurrentGraph = true
  chartData: any[] = []
  deviceId: any
  param: any = {
    deviceId: 0,
    year: undefined,
    month: undefined,
    day: undefined,
    type: undefined,
  }

  @Output() chartDataChange = new EventEmitter<any[]>()
  infoText: string =
    'The graph displays the real time color waveforms of each phases Current wrt data-time.'

  @ViewChild(CommonAnalyticsGraphComponent)
  commonAnalyticsGraphComponent!: CommonAnalyticsGraphComponent
  showLoader: boolean = true
  isNoRecordFound = false
  roundedMaxValue: number | any
  constructor(private store: Store) {}

  ngOnInit(): void {}

  getCurrentGraphData(param: any): void {
    this.showLoader = true
    this.store.dispatch(new GetCurrentGraphChart(param)).subscribe(
      (res: any) => {
        this.showLoader = false
        this.chartData = []
        if (res.analytics.currentGraphLineChart.length > 0) {
          this.chartData = res.analytics.currentGraphLineChart
          this.chartDataChange.emit(this.chartData)
          // Find the maximum value from voltagePhase_R, voltagePhase_Y, voltagePhase_B
          let maxVoltage = Math.max(
            ...this.chartData.map((data: any) =>
              Math.max(
                data.voltagePhase_B,
                data.voltagePhase_R,
                data.voltagePhase_Y,
              ),
            ),
          )

          maxVoltage += 10
          const roundedValue = Math.ceil(maxVoltage / 10) * 10

          this.roundedMaxValue = roundedValue
        }
        this.commonAnalyticsGraphComponent.isCurrentGraphData = true
        this.isNoRecordFound = res.analytics.currentGraphLineChart.length <= 0
      },
      (error) => {
        this.showLoader = false
      },
    )
  }

  getDataByHeaderData(event: any): void {
    this.deviceId = event.deviceId

    this.param.deviceId = this.deviceId

    this.getCurrentGraphData(this.param)
    this.triggerCurrentCardApi.next(this.deviceId)
    this.triggerGetUpdatedTime.next(true)
  }

  onAnalyticsFilterChange(event: any) {
    const param = {
      deviceId: this.deviceId,
      year: event.year,
      month: event.month,
      day: event.day,
      type: event.type,
    }
    this.getCurrentGraphData(param)
  }

  onAllButtonClick() {
    this.param = {
      deviceId: this.deviceId,
      year: undefined,
      month: undefined,
      day: undefined,
      type: undefined,
    }

    this.getCurrentGraphData(this.param)
  }

  getAllYearData(): void {
    this.param = {
      deviceId: this.deviceId,
      year: undefined,
      month: undefined,
      day: undefined,
      type: SeparateDateEnum.YEAR,
    }
    this.getCurrentGraphData(this.param)
  }
}
