import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import {
  AlertComponent,
  AuthGuard,
  CaidiDetailsComponent,
  OverloadListComponent,
  PfListComponent,
  SentNotifcationListComponent,
} from '@app/index'

const routes: Routes = [
  { path: '', component: AlertComponent, canActivate: [AuthGuard] },
  { path: 'pf', component: PfListComponent, canActivate: [AuthGuard] },
  {
    path: 'overload',
    component: OverloadListComponent,
    canActivate: [AuthGuard],
  },
  { path: 'caidi', component: CaidiDetailsComponent, canActivate: [AuthGuard] },
  {
    path: 'sent-notification',
    component: SentNotifcationListComponent,
    canActivate: [AuthGuard],
  },
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AlertRoutingModule {}
