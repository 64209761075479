import { drawing as draw } from '@progress/kendo-drawing';
import ShapeElement from "../../core/shape-element";
import { setDefaultOptions } from '../../common';
import { BOTTOM, RIGHT } from '../../common/constants';

class MarkerLineArea extends ShapeElement {
    getElement() {
        const group = new draw.Group();
        const element = new draw.MultiPath(this.visualStyle());
        const { paddingBox: box } = this;
        const halfHeight = box.height() / 2;

        element
            .moveTo(box.x1, box.y1 + halfHeight)
            .lineTo(box.x1, box.y2)
            .lineTo(box.x2, box.y2);

        group.append(element);

        return group;
    }
}

setDefaultOptions(MarkerLineArea, {
    width: 15,
    height: 15,
    align: RIGHT,
    vAlign: BOTTOM,
    margin: {
        right: -2,
        bottom: 2
    }
});

export default MarkerLineArea;
