export class GetRoleList {
  static readonly type = '[ROLE] Role List'
  constructor(public queryParam: any) {}
}

export class AddRole {
  static readonly type = '[ROLE] Add Role'
  constructor(public addRoleRequestBody: any) {}
}

export class GetRoleDetail {
  static readonly type = '[ROLE] Get Role Detail'
  constructor(public roleId: any) {}
}

export class UpdateRole {
  static readonly type = '[ROLE] Update Role'
  constructor(public updateRoleRequestBody: any) {}
}

export class DeleteRole {
  static readonly type = '[ROLE] Delete Role'
  constructor(public roleId: any) {}
}

export class CheckRoleExistsByName {
  static readonly type = '[ROLE] Check Role Exists By Name'

  constructor(
    public roleId: any,
    public name: string,
  ) {}
}
