export class GetcomplaintstList {
  static readonly type = '[complaints] Get complaintst List'
  constructor(public queryParam: any) {}
}

export class MultiImageFileUpload {
  static readonly type = '[complaints] Multi File Upload'

  constructor(public files: any) {}
}

export class AddUpdateComplaint {
  static readonly type = '[complaints] Add Update Complaint'
  constructor(public addRequestBody: any) {}
}

export class AddComment {
  static readonly type = '[complaints] Add Comment'
  constructor(public addCommentRequestBody: any) {}
}

export class GetComplaintDataById {
  static readonly type = '[complaints] Get Complaint Data By Id'
  constructor(public complaintId: any) {}
}

export class GetTicketHistoryDataById {
  static readonly type = '[complaints] Get Ticket History Data By Id'
  constructor(public complaintId: any) {}
}

export class GetCommentsByTicketId {
  static readonly type = '[complaints] Get Comments By TicketId'
  constructor(public ticketId: any) {}
}

export class GenerateTicketNumber {
  static readonly type = '[complaints] Generate Ticket Number'
}

export class DeleteTicket {
  static readonly type = '[complaints] Delete Ticket'
  constructor(public ticketId: any) {}
}
