export class DTRDataDownload {
  static readonly type = '[dtrData] dtr Data dowload'

  constructor(public dtrDowloadData: any) {}
}

export class BackupDataInExcel {
  static readonly type = '[dtrData] Backup Data in Excel'
  constructor(
    public deviceId: string,
    public formateType: any,
  ) {}
}

export class DeleteDatabase {
  static readonly type = '[dtrData] delete Database'
  constructor(public deviceId: string) {}
}
