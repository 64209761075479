<form [formGroup]="wifiForm" class="k-form k-form-md">
  <kendo-formfield class="mb-20">
    <div class="d-flex gap-10">
      <kendo-label
        [for]="accessPoint"
        text="Access Point"
        labelCssClass="k-form-label"
        class="w-150 d-flex align-items-center"
        ><span class="required">*</span></kendo-label
      >
      <kendo-textbox
        formControlName="accessPoint"
        placeholder="Access Point name"
        #accessPoint
      ></kendo-textbox>
    </div>
    <div
      *ngIf="
        wifiForm.get('accessPoint').invalid &&
        wifiForm.get('accessPoint').touched
      "
    >
      <div class="d-flex gap-10">
        <div class="w-100"></div>
        <p
          *ngIf="wifiForm.get('accessPoint').errors?.required"
          class="required mb-0"
        >
          Access Point is required.
        </p>
      </div>
    </div>
  </kendo-formfield>
  <kendo-formfield class="mb-20">
    <div class="d-flex gap-10">
      <kendo-label
        [for]="pwd"
        text="Password"
        labelCssClass="k-form-label"
        class="w-150 d-flex align-items-center"
        ><span class="required">*</span></kendo-label
      >
      <kendo-textbox
        formControlName="password"
        placeholder="Password"
        #pwd
      ></kendo-textbox>
    </div>
    <div
      *ngIf="
        wifiForm.get('password').invalid && wifiForm.get('password').touched
      "
    >
      <div class="d-flex gap-10">
        <div class="w-100"></div>
        <p
          *ngIf="wifiForm.get('password').errors?.required"
          class="required mb-0"
        >
          password is required.
        </p>
      </div>
    </div>
  </kendo-formfield>
</form>
