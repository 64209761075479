import { Injectable } from '@angular/core'
import { Action, State, StateContext } from '@ngxs/store'
import { tap } from 'rxjs'
import {
  AssignHierarchicalStructureService,
  GetDistrictList,
  GetHierarchicalUserRole,
  GetStateList,
  GetCityList,
  GetZoneList,
  SaveHierarchicalUserRole,
} from '@app/index'
export class AssignHierarchicalStructureStateInfo {
  stateList?: any
  districtList?: any
  cityList?: any
  zoneList?: any
  assignHierarchicalStructureDetail?: any
  hierarchicalUserRole?: any
  isSuccess?: boolean
}

@State<AssignHierarchicalStructureStateInfo>({
  name: 'assignHierarchicalStructure',
  defaults: {},
})
@Injectable()
export class AssignHierarchicalStructureState {
  constructor(
    private assignHierarchicalStructureService: AssignHierarchicalStructureService,
  ) {}

  @Action(GetStateList)
  getStateList(
    {
      getState,
      patchState,
    }: StateContext<AssignHierarchicalStructureStateInfo>,
    action: GetStateList,
  ) {
    return this.assignHierarchicalStructureService
      .getStateList(action.country)
      .pipe(
        tap((res: any) => {
          patchState({
            stateList: res,
          })
        }),
      )
  }

  @Action(GetDistrictList)
  getDistrictList(
    {
      getState,
      patchState,
    }: StateContext<AssignHierarchicalStructureStateInfo>,
    action: GetDistrictList,
  ) {
    return this.assignHierarchicalStructureService
      .getDistrictList(action.region)
      .pipe(
        tap((res: any) => {
          patchState({
            districtList: res,
          })
        }),
      )
  }

  @Action(GetCityList)
  getCityList(
    {
      getState,
      patchState,
    }: StateContext<AssignHierarchicalStructureStateInfo>,
    action: GetCityList,
  ) {
    return this.assignHierarchicalStructureService
      .getCityList(action.destrict)
      .pipe(
        tap((res: any) => {
          patchState({
            cityList: res,
          })
        }),
      )
  }

  @Action(GetZoneList)
  getZoneList(
    {
      getState,
      patchState,
    }: StateContext<AssignHierarchicalStructureStateInfo>,
    action: GetZoneList,
  ) {
    return this.assignHierarchicalStructureService
      .getZoneList(action.city)
      .pipe(
        tap((res: any) => {
          patchState({
            zoneList: res,
          })
        }),
      )
  }

  @Action(GetHierarchicalUserRole)
  getHierarchicalUserRole(
    {
      getState,
      patchState,
    }: StateContext<AssignHierarchicalStructureStateInfo>,
    action: GetHierarchicalUserRole,
  ) {
    return this.assignHierarchicalStructureService
      .getHierarchicalUserRole(action.userId)
      .pipe(
        tap((res: any) => {
          patchState({
            hierarchicalUserRole: res,
          })
        }),
      )
  }

  @Action(SaveHierarchicalUserRole)
  saveHierarchicalUserRole(
    {
      getState,
      patchState,
    }: StateContext<AssignHierarchicalStructureStateInfo>,
    action: SaveHierarchicalUserRole,
  ) {
    return this.assignHierarchicalStructureService
      .saveHierarchicalUserRole(action.data)
      .pipe(
        tap((res: any) => {
          patchState({
            isSuccess: res,
          })
        }),
      )
  }
}
