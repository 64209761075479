import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { CommonsModule } from '@app/index'
import { DeviceHistoryRoutingModule } from './device-history-routing.module'
import { DeviceHistoryComponent } from './device-history.component'
import { SharedModule } from '@shared//shared.module'
import { PipeModule } from '@app/pipe/pipe.module'

@NgModule({
  declarations: [DeviceHistoryComponent],
  imports: [
    CommonModule,
    DeviceHistoryRoutingModule,
    SharedModule,
    PipeModule,
    CommonsModule,
  ],
})
export class DeviceHistoryModule {}
