<form [formGroup]="angleBelowForm" class="k-form k-form-md">
  <kendo-formfield class="mb-20">
    <div class="d-flex gap-15">
      <kendo-label
        [for]="rPhase"
        text="R Phase"
        labelCssClass="k-form-label"
        class="w-100 d-flex align-items-center"
        ><span class="required">*</span></kendo-label
      >
      <kendo-textbox
        formControlName="rPhase"
        placeholder="Enter Value (0-9)"
        #rPhase
        (keypress)="commonService.numberOnly($event)"
      ></kendo-textbox>
    </div>
    <div
      *ngIf="
        angleBelowForm.get('rPhase').invalid &&
        angleBelowForm.get('rPhase').touched
      "
      class="d-flex gap-15"
    >
      <div class="w-80"></div>
      <div>
        <p
          *ngIf="angleBelowForm.get('rPhase').errors?.required"
          class="required mb-0"
        >
          Angle(Below) R Phase is required.
        </p>
        <p
          *ngIf="angleBelowForm.get('rPhase').errors?.pattern"
          class="required mb-0"
        >
          Please enter a valid number for Angle(Below) R Phase.
        </p>
      </div>
    </div>
  </kendo-formfield>
  <kendo-formfield class="mb-20">
    <div class="d-flex gap-15">
      <kendo-label
        [for]="yPhase"
        text="Y Phase"
        labelCssClass="k-form-label"
        class="w-100 d-flex align-items-center"
        ><span class="required">*</span></kendo-label
      >
      <kendo-textbox
        formControlName="yPhase"
        placeholder="Enter Value (0-9)"
        #yPhase
        (keypress)="commonService.numberOnly($event)"
      ></kendo-textbox>
    </div>
    <div
      *ngIf="
        angleBelowForm.get('yPhase').invalid &&
        angleBelowForm.get('yPhase').touched
      "
      class="d-flex gap-15"
    >
      <div class="w-80"></div>
      <div>
        <p
          *ngIf="angleBelowForm.get('yPhase').errors?.required"
          class="required mb-0"
        >
          Angle(Below) Y Phase is required.
        </p>
        <p
          *ngIf="angleBelowForm.get('yPhase').errors?.pattern"
          class="required mb-0"
        >
          Please enter a valid number for Angle(Below) Y Phase.
        </p>
      </div>
    </div>
  </kendo-formfield>
  <kendo-formfield class="mb-20">
    <div class="d-flex gap-15">
      <kendo-label
        [for]="bPhase"
        text="B Phase"
        labelCssClass="k-form-label"
        class="w-100 d-flex align-items-center"
        ><span class="required">*</span></kendo-label
      >
      <kendo-textbox
        formControlName="bPhase"
        placeholder="Enter Value (0-9)"
        #bPhase
        (keypress)="commonService.numberOnly($event)"
      ></kendo-textbox>
    </div>
    <div
      *ngIf="
        angleBelowForm.get('bPhase').invalid &&
        angleBelowForm.get('bPhase').touched
      "
      class="d-flex gap-15"
    >
      <div class="w-80"></div>
      <div>
        <p
          *ngIf="angleBelowForm.get('bPhase').errors?.required"
          class="required mb-0"
        >
          Angle(Below) B Phase is required.
        </p>
        <p
          *ngIf="angleBelowForm.get('bPhase').errors?.pattern"
          class="required mb-0"
        >
          Please enter a valid number for Angle(Below) B Phase.
        </p>
      </div>
    </div>
  </kendo-formfield>
</form>
