<div class="q-content">
  <div class="d-flex gap-15 flex-wrap justify-content-between mb-20">
    <app-common-header-text
      [triggerGetUpdatedTime]="triggerGetUpdatedTime"
    ></app-common-header-text>
    <div class="d-flex gap-15 flex-wrap">
      <app-common-header
        (triggerDataFromHeader)="getDataByHeaderData($event)"
      ></app-common-header>
      <div class="drag-icon-container" (click)="onColumnSelector()">
        <a class="drag-icon">
          <img src="assets/images/custom-columns.svg" alt="column-select" />
        </a>
      </div>
    </div>
  </div>
  <div>
    <div class="q-content-bg">
      <div class="mb-20">
        <div class="d-flex justify-content-between gap-20 flex-wrap">
          <div class="font-size-16 fw-500">
            Overload Count
            <i
              class="far fa-circle-info font-size-16 ml-5 cursor-pointer fw-500 info-icon"
              title="The maximum KVA recorded count of the DTR."
            ></i>
          </div>
          <div class="d-flex gap-10 align-items-center">
            <div class="d-flex align-items-center">
              <kendo-label
                [for]="hundred"
                text="100%"
                class="mr-5"
              ></kendo-label>
              <input
                type="radio"
                name="per"
                value="1"
                [(ngModel)]="overloadCount"
                #hundred
                kendoRadioButton
                (change)="percentageChange($event)"
              />
            </div>
            <div class="d-flex align-items-center">
              <kendo-label [for]="eighty" text="80%" class="mr-5"></kendo-label>
              <input
                type="radio"
                name="per"
                value="2"
                [(ngModel)]="overloadCount"
                #eighty
                kendoRadioButton
                (change)="percentageChange($event)"
              />
            </div>
            <div class="d-flex align-items-center">
              <kendo-label [for]="fifty" text="50%" class="mr-5"></kendo-label>
              <input
                type="radio"
                name="per"
                value="3"
                [(ngModel)]="overloadCount"
                #fifty
                kendoRadioButton
                (change)="percentageChange($event)"
              />
            </div>
          </div>
        </div>
        <div class="mb-20 mt-10 text-secondary">
          {{ overloadCountText }}
        </div>
        <div class="d-flex-row">
          <div
            *ngFor="let card of overloadCardDetails"
            class="d-flex-col-lg-4 d-flex-col-xs-12 d-flex-col"
          >
            <div
              class="q-card mb-20 flex-1 border"
              [ngClass]="
                'q-card-bg-' +
                commonService.getPeroidName(getCaidiPeriodLabel(card?.peroid))
              "
            >
              <div
                class="q-card-header d-flex justify-content-between no-border pb-0"
              >
                <div>
                  <p class="q-card-title text-secondary mb-12">
                    {{ getCaidiPeriodLabel(card?.peroid) }}
                  </p>
                  <p class="q-card-value m-0 font-size-24">{{ card?.load }}</p>
                </div>
                <div
                  class="q-card-icon"
                  [ngClass]="
                    'q-card-icon-' +
                    commonService.getPeroidName(
                      getCaidiPeriodLabel(card?.peroid)
                    )
                  "
                >
                  <i [class]="commonService.getIconClass(card?.peroid)"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="fw-500 font-size-16">
        Overload Alert
        <i
          class="far fa-circle-info font-size-16 ml-5 cursor-pointer fw-500 info-icon"
          title="The table display the overload alerts of the selected device."
        ></i>
      </div>
      <hr class="mb-15" />
      <div *ngIf="isDataLoaded">
        <ng-container *ngIf="moduleId$ | async as moduleId">
          <app-common-grid
            [gridView]="gridView"
            [columns]="gridView?.columns ?? []"
            [pageSize]="this.pageSize"
            [moduleId]="moduleId"
            (sortChange)="onSortChange($event)"
            (filterChangeSQL)="onFilterChangeSQL($event)"
            (paginationChange)="onPaginationChange($event)"
            (triggerDataFromColumnSelector)="getDataFromColumnSelector($event)"
          ></app-common-grid>
        </ng-container>
      </div>
    </div>
  </div>
</div>
<div kendoDialogContainer></div>
