import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { SharedModule } from 'src/shared/shared.module'
import {
  AlertsSettingsComponent,
  CommonsModule,
  CtSettingsComponent,
  KvaComponent,
  MaxCurrentComponent,
  MaxVoltageComponent,
  MinVoltageComponent,
  PfSettingsComponent,
  SettingsComponent,
  SettingsRoutingModule,
  TemperatureComponent,
  ThresholdSettingsComponent,
  UnityPfComponent,
} from '@app/index'

@NgModule({
  declarations: [
    SettingsComponent,
    ThresholdSettingsComponent,
    MinVoltageComponent,
    MaxVoltageComponent,
    MaxCurrentComponent,
    KvaComponent,
    CtSettingsComponent,
    TemperatureComponent,
    PfSettingsComponent,
    UnityPfComponent,
    AlertsSettingsComponent,
  ],
  imports: [CommonModule, SettingsRoutingModule, SharedModule, CommonsModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SettingsModule {}
