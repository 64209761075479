export enum ElectricType {
  'Voltage' = 1,
  'Current' = 2,
  'CT' = 3,
  'KVA' = 4,
  'PF' = 5,
  'Unity PF' = 6,
  'Temp Settings Logs' = 7,
}

export enum ElectricTypeInfo {
  Voltage = 'This page shows the history of Updated Voltage threshold settings.',
  Current = 'This page shows the history of Current threshold settings.',
  CT = 'This page shows the history of CT-Ratio Updated settings.',
  KVA = 'This page shows the history of updated rated kVA values.',
  PF = 'This page shows the history of PF threshold settings.',
  UnityPF = 'This page shows the history of Unity PF settings.',
  TempSettingsLogs = 'This page shows the history of temperature threshold settings.',
}
