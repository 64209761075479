import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import {
  SystemBatteryRoutingModule,
  CommonsModule,
  StatusComponent,
} from '@app/index'
import { SharedModule } from 'src/shared/shared.module'

@NgModule({
  declarations: [StatusComponent],
  imports: [
    CommonModule,
    SystemBatteryRoutingModule,
    CommonsModule,
    SharedModule,
  ],
})
export class SystemBatteryModule {}
