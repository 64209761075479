import { Injectable } from '@angular/core'
import { Selector, Action, StateContext, State } from '@ngxs/store'
import { tap } from 'rxjs'
import { GetModuleHistoryByModuleId } from '.'
import { ModuleHistoryService } from '@app/services'

export class ModuleHistoryStateInfo {
  moduleHistoryData?: any
}

@State<ModuleHistoryStateInfo>({
  name: 'moduleHistory',
  defaults: {},
})
@Injectable()
export class ModuleHistoryState {
  constructor(private moduleHistoryService: ModuleHistoryService) {}

  @Action(GetModuleHistoryByModuleId)
  getOfficeLoadSetting(
    { getState, patchState }: StateContext<ModuleHistoryStateInfo>,
    action: GetModuleHistoryByModuleId,
  ) {
    return this.moduleHistoryService
      .getModuleHistoryByModuleId(action.queryParams)
      .pipe(
        tap((res: any) => {
          patchState({
            moduleHistoryData: res,
          })
        }),
      )
  }
}
