import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable, of } from 'rxjs'
import { environment } from 'src/environments'

@Injectable()
export class RoleService {
  constructor(private http: HttpClient) {}

  getRoleList(queryParam: any): Observable<any> {
    let url = ''
    if (queryParam !== null && queryParam !== undefined) {
      url = `${environment.apiVersionUrl}Role/GetAllRoleWithPagination`
    } else {
      url = `${environment.apiVersionUrl}Role/GetAllRole`
    }

    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    }
    return this.http.post<any>(url, queryParam, headers)
  }

  addRole(addRoleRequestBody: any): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    }

    return this.http.post<any>(
      `${environment.apiVersionUrl}Role/add`,
      JSON.stringify(addRoleRequestBody),
      headers,
    )
  }

  getRoleDetails(roleId: any): Observable<any> {
    return this.http.get<any>(`${environment.apiVersionUrl}Role/${roleId}`)
  }

  updateRole(updateRoleRequestBody: any): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    }

    return this.http.post<any>(
      `${environment.apiVersionUrl}Role/update`,
      JSON.stringify(updateRoleRequestBody),
      headers,
    )
  }

  deleteRole(roleId: any): Observable<any> {
    return this.http.delete(`${environment.apiVersionUrl}Role/delete/${roleId}`)
  }

  checkRoleExistsByName(roleId: any, name: string): Observable<boolean> {
    return this.http.get<boolean>(
      `${environment.apiVersionUrl}Role/CheckRoleExistsByName/${roleId}/${name}`,
    )
  }
}
