import { Injectable } from '@angular/core'
import { Selector, Action, StateContext, State } from '@ngxs/store'
import { tap } from 'rxjs'
import { GetSentNotificationData } from '.'
import { SentNotificationService } from '@app/services/alert/sent-notification/sent-notification.service'

export class SentNotificationStateInfo {
  sentNotificationData?: any
}

@State<SentNotificationStateInfo>({
  name: 'sentNotification',
  defaults: {},
})
@Injectable()
export class SentNotificationState {
  constructor(private sentNotificationService: SentNotificationService) {}

  @Selector()
  static getSentNotificationData(state: SentNotificationStateInfo) {
    return state.sentNotificationData
  }

  @Action(GetSentNotificationData)
  getSentNotificationData(
    { getState, patchState }: StateContext<SentNotificationStateInfo>,
    action: any,
  ) {
    return this.sentNotificationService
      .getSentNotificationData(action.sendNotificationData)
      .pipe(
        tap((res: any) => {
          patchState({
            sentNotificationData: res,
          })
        }),
      )
  }
}
