import { Component } from '@angular/core'
import { Roles } from '@app/enums/roles'
import {
  GetAccountList,
  GetCityList,
  GetDistrictList,
  GetHierarchicalUserRole,
  GetStateList,
  GetZoneList,
  SaveHierarchicalUserRole,
} from '@app/store'
import { Store } from '@ngxs/store'
import { NgxSpinnerService } from 'ngx-spinner'
import { ToastrService } from 'ngx-toastr'
import { Subject } from 'rxjs'
import swal from 'sweetalert'

@Component({
  selector: 'app-assign-hierarchical-structure',
  templateUrl: './assign-hierarchical-structure.component.html',
  styleUrl: './assign-hierarchical-structure.component.scss',
})
export class AssignHierarchicalStructureComponent {
  isDisabledStates = true
  isDisabledDistricts = true
  isDisabledCity = true
  isDisabledZone = true

  triggerGetUpdatedTime: Subject<any> = new Subject<any>()

  defaultItemZone: { zoneName: string; zoneId: any } = {
    zoneName: 'Select zone',
    zoneId: '',
  }

  defaultItemCountrys: { countryName: string; countryId: any } = {
    countryName: 'Select country',
    countryId: '',
  }

  defaultItemStates: { stateName: string; stateId: any } = {
    stateName: 'Select state',
    stateId: '',
  }

  defaultItemDistrict: { districtName: string; districtId: any } = {
    districtName: 'Select district',
    districtId: '',
  }

  defaultItemCity: { cityName: string; cityId: any } = {
    cityName: 'Select city',
    cityId: '',
  }

  dataCountry: Array<{ countryName: string; countryId: number }> = []

  public dataStates: Array<{
    stateName: string
    stateId: string
  }> = []

  public dataDistricts: Array<{
    districtName: string
    districtId: string
  }> = []

  public dataCity: Array<{
    cityName: string
    cityId: string
  }> = []

  public dataZone: Array<{
    cityName: string
    cityId: string
  }> = []

  public dataResultStates:
    | Array<{
        stateName: string
        stateId: string
      }>
    | any

  public dataResultDistricts:
    | Array<{
        districtName: string
        districtId: number
      }>
    | any

  public dataResultCity:
    | Array<{
        cityName: string
        cityId: number
      }>
    | any

  public dataResultZone:
    | Array<{
        zoneName: string
        zoneId: number
      }>
    | any
  selectedUser: { id: string; name: number } | any
  selectedCountry: { countryName: string; countryId: number } | any
  selectedState: Array<{ stateName: string; stateId: string }> | any
  selectedDistrict: Array<{ districtName: string; districtId: string }> | any
  selectedCity: Array<{ cityName: string; cityId: string }> | any
  selectedZone: Array<{ zoneName: string; zoneId: string }> | any

  userId: any
  permissionData: any
  userList: any[] = []
  tempUserList: any[] = []
  assignData: any
  showUserError: boolean = false
  showStateError: boolean = false
  showDistrictError: boolean = false
  showCityError: boolean = false
  showZoneError: boolean = false
  tempStateList: any[] = []
  tempDistrictList: any[] = []
  tempCityList: any[] = []
  tempZoneList: any[] = []
  constructor(
    private store: Store,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
  ) {}

  ngOnInit(): void {
    this.getAccountListData()
    setTimeout(() => {
      this.triggerGetUpdatedTime.next(true)
    }, 0)
  }

  onFilterChange(searchTerm: any): void {
    if (searchTerm) {
      this.userList = this.tempUserList.filter((user) =>
        user.name.toLowerCase().includes(searchTerm.toLowerCase()),
      )
    } else {
      this.userList = this.tempUserList
    }
  }

  onUserChange(user: any) {
    this.spinner.show()
    this.userId = user.id
    this.isDisabledStates = true
    this.isDisabledDistricts = true
    this.isDisabledCity = true
    this.isDisabledZone = true

    this.showStateError = false
    this.showCityError = false
    this.showDistrictError = false
    this.showZoneError = false

    if (this.userId !== '') {
      this.getHierarchicalUserRole()
      this.showUserError = false
    } else {
      this.permissionData.state.isVisible = false
      this.permissionData.district.isVisible = false
      this.permissionData.city.isVisible = false
      this.permissionData.zone.isVisible = false
      this.setDefault()
    }
  }

  onStateChange(state: any) {
    this.selectedState = state

    if (
      this.permissionData.state.isVisible &&
      (this.selectedState.stateId !== '' || this.selectedState.length !== 0)
    ) {
      this.showStateError = false
    } else {
      this.setDefault(false)
    }

    if (
      this.permissionData.district.isVisible &&
      this.selectedState.stateId !== ''
    ) {
      this.getDistrictList(this.selectedState.stateId)
    } else {
      this.setDefault(false)
      this.isDisabledDistricts = true
      this.isDisabledCity = true
      this.isDisabledZone = true
      this.resetErrors()
    }
  }

  resetErrors() {
    this.showDistrictError = false
    this.showCityError = false
    this.showZoneError = false
  }

  resetDistrictCityZone() {
    if (this.permissionData.district.isMultipleSelect === false) {
      this.selectedDistrict = {
        districtName: 'Select district',
        districtId: '',
      }
    } else {
      this.selectedDistrict = []
    }
    if (this.permissionData.city.isMultipleSelect === false) {
      this.selectedCity = { cityName: 'Select city', cityId: '' }
    } else {
      this.selectedCity = []
    }
    if (this.permissionData.zone.isMultipleSelect === false) {
      this.selectedZone = { zoneName: 'Select zone', zoneId: '' }
    } else {
      this.selectedZone = []
    }
  }

  onDistrictChange(district: any) {
    this.selectedDistrict = district

    if (
      this.permissionData.district.isVisible &&
      (this.selectedDistrict.districtId !== '' ||
        this.selectedDistrict.length !== 0)
    ) {
      this.showDistrictError = false
    } else {
      this.resetCityZone()
    }

    if (
      this.permissionData.city.isVisible &&
      this.selectedDistrict.districtId !== ''
    ) {
      this.getCityList(this.selectedDistrict.districtId)
    } else {
      this.resetCityZone()
      this.isDisabledCity = true
      this.isDisabledZone = true
      this.resetErrors()
    }
  }

  resetCityZone() {
    if (this.permissionData.city.isMultipleSelect === false) {
      this.selectedCity = { cityName: 'Select city', cityId: '' }
    } else {
      this.selectedCity = []
    }
    if (this.permissionData.zone.isMultipleSelect === false) {
      this.selectedZone = { zoneName: 'Select zone', zoneId: '' }
    } else {
      this.selectedZone = []
    }
  }

  onCityChange(city: any) {
    this.selectedCity = city
    if (
      this.permissionData.city.isVisible &&
      (this.selectedCity.cityId !== '' || this.selectedCity.length !== 0)
    ) {
      this.showCityError = false
    } else {
      this.resetZone()
    }

    if (this.permissionData.zone.isVisible && this.selectedCity.cityId !== '') {
      this.getZoneList(this.selectedCity.cityId)
    } else {
      this.resetZone()
      this.isDisabledZone = true
      this.resetErrors()
    }
  }

  resetZone() {
    if (this.permissionData.zone.isMultipleSelect === false) {
      this.selectedZone = { zoneName: 'Select zone', zoneId: '' }
    } else {
      this.selectedZone = []
    }
  }

  onZoneChange(zone: any) {
    this.selectedZone = zone
    if (this.permissionData.zone.isVisible && this.selectedZone.length !== 0) {
      this.showZoneError = false
    } else {
      this.resetErrors()
    }
  }

  getZoneIds(zones: { zoneId: string; zoneName: string }[]): string {
    return zones.map((zone) => zone.zoneId).join(';')
  }

  getCityIds(cities: { cityId: string; cityName: string }[]): string {
    return cities.map((city) => city.cityId).join(';')
  }

  getDistrictIds(
    districts: { districtId: string; districtName: string }[],
  ): string {
    return districts.map((district) => district.districtId).join(';')
  }

  getStateIds(states: { stateId: string; stateName: string }[]): string {
    return states.map((state) => state.stateId).join(';')
  }

  dataSubmit() {
    this.spinner.show()
    try {
      this.assignData = {
        userId: this.userId.toString(),
        country: this.selectedCountry.countryId,
        state:
          this.permissionData.state.isVisible &&
          this.permissionData.state.isMultipleSelect
            ? this.getStateIds(this.selectedState)
            : this.selectedState.stateId,

        district:
          this.permissionData.district.isVisible &&
          this.permissionData.district.isMultipleSelect
            ? this.getDistrictIds(this.selectedDistrict)
            : this.selectedDistrict.districtId,
        city:
          this.permissionData.city.isVisible &&
          this.permissionData.city.isMultipleSelect
            ? this.getCityIds(this.selectedCity)
            : this.selectedCity.cityId,
        zone:
          this.permissionData.zone.isVisible &&
          this.permissionData.zone.isMultipleSelect
            ? this.getZoneIds(this.selectedZone)
            : this.selectedZone.zoneId,
      }
    } catch (error) {
      this.spinner.hide()
      this.toastr.error('Something went wrong')
      return false
    }
    return true
  }

  validationCheck() {
    if (
      this.permissionData.state.isVisible === true &&
      (this.selectedState.stateId === '' || this.selectedState.length === 0)
    ) {
      this.showStateError = true
    }
    if (
      this.permissionData.district.isVisible === true &&
      (this.selectedDistrict.districtId === '' ||
        this.selectedDistrict.length === 0)
    ) {
      this.showDistrictError = true
    }
    if (
      this.permissionData.city.isVisible === true &&
      (this.selectedCity.cityId === '' || this.selectedCity.length === 0)
    ) {
      this.showCityError = true
    }
    if (
      this.permissionData.zone.isVisible === true &&
      (this.selectedZone.zoneId === '' || this.selectedZone.length === 0)
    ) {
      this.showZoneError = true
    }
  }

  onSubmit(): void {
    let textMessage = `You want to update Hierarchical Permissions.`

    swal({
      title: 'Are you sure ?',
      text: textMessage,
      icon: 'warning',
      buttons: {
        cancel: {
          text: 'Cancel',
          visible: true,
          closeModal: true,
        },
        confirm: {
          text: 'Yes',
        },
      },
      dangerMode: true,
    }).then((confirmed) => {
      if (confirmed) {
        if (this.userId === undefined || this.userId === '') {
          this.showUserError = true
        } else {
          this.validationCheck()
          if (
            !this.showStateError &&
            !this.showCityError &&
            !this.showDistrictError &&
            !this.showZoneError
          ) {
            if (this.dataSubmit()) {
              this.store
                .dispatch(new SaveHierarchicalUserRole(this.assignData))
                .subscribe((res: any) => {
                  this.spinner.hide()
                  if (res.assignHierarchicalStructure.isSuccess) {
                    this.toastr.success(
                      'Hierarchical permission saved Successfully',
                    )
                  } else {
                    this.toastr.error('Failed to save hierarchical permission')
                  }
                })
            }
          }
        }
      }
    })
  }

  tagMapper = (tags: any[]) => {
    if (tags.length > 2) {
      return [
        ...tags.slice(0, 2),
        {
          stateName: `+ ${tags.length - 2}`,
          stateId: null,
          districtName: `+ ${tags.length - 2}`,
          districtId: null,
          cityName: `+ ${tags.length - 2}`,
          cityId: null,
          zoneName: `+ ${tags.length - 2}`,
          zoneId: null,
        },
      ]
    } else {
      return tags
    }
  }

  getAccountListData() {
    const param = {
      pageNumber: 1,
      pageSize: 50,
      sortBy: null,
      sortOrder: true,
      search: null,
    }
    this.spinner.show()
    this.store.dispatch(new GetAccountList(param)).subscribe(
      (res) => {
        this.spinner.hide()
        if (res.account.accountList.data.rows.length > 0) {
          const data = res.account.accountList.data.rows.filter(
            (role: any) => role.roleId !== Roles.SuperAdmin,
          )

          let userList = data.map((x: any) => ({
            id: x.id,
            name: x.firstName,
          }))
          this.userList = userList
          this.tempUserList = this.userList
          if (userList.length > 0) {
            this.selectedUser = {
              name: this.userList[0].name,
              id: this.userList[0].id,
            }

            this.onUserChange(this.selectedUser)
          }
        }
      },
      (error) => {
        this.spinner.hide()
      },
    )
  }

  getHierarchicalUserRole() {
    this.spinner.show()
    this.store
      .dispatch(new GetHierarchicalUserRole(this.userId))
      .subscribe((res: any) => {
        this.permissionData =
          res.assignHierarchicalStructure.hierarchicalUserRole
        this.spinner.hide()
        this.dataCountry = this.permissionData.country.value.map(
          (country: any) => ({
            countryId: country,
            countryName: country,
          }),
        )
        this.selectedCountry = this.dataCountry[0]
        this.isDisabledStates = true
        this.spinner.hide()
        this.setDefault()
        this.getStateList(this.selectedCountry.countryId)
      })
  }

  setDefault(isAllReset: boolean = true) {
    if (isAllReset) {
      if (this.permissionData.state.isMultipleSelect === false) {
        // single dd
        this.selectedState = { stateName: 'Select state', stateId: '' }
      } else {
        this.selectedState = []
      }
    }

    if (this.permissionData.district.isMultipleSelect === false) {
      // single dd
      this.selectedDistrict = {
        districtName: 'Select district',
        districtId: '',
      }
    } else {
      this.selectedDistrict = []
    }

    if (this.permissionData.city.isMultipleSelect === false) {
      // single dd
      this.selectedCity = { cityName: 'Select city', cityId: '' }
    } else {
      this.selectedCity = []
    }

    if (this.permissionData.zone.isMultipleSelect === false) {
      // single dd
      this.selectedZone = { zoneName: 'Select zone', zoneId: '' }
    } else {
      this.selectedZone = []
    }
  }

  getStateList(country: any) {
    this.spinner.show()
    this.store.dispatch(new GetStateList(country)).subscribe((res: any) => {
      this.dataStates = res.assignHierarchicalStructure.stateList
      this.dataResultStates = this.dataStates.map((state: any) => ({
        stateId: state,
        stateName: state,
      }))
      this.tempStateList = this.dataResultStates
      this.spinner.hide()
      if (
        this.permissionData.state.savedValue !== null &&
        this.permissionData.state.savedValue !== ''
      ) {
        if (this.permissionData.state.isMultipleSelect === true) {
          let savedValue = this.permissionData.state.savedValue
            .split(';')
            .map((element: any) => element.trim())
          this.selectedState = savedValue.map((state: any) => ({
            stateId: state,
            stateName: state,
          }))
        } else {
          this.selectedState = {
            stateId: this.permissionData.state.savedValue,
            stateName: this.permissionData.state.savedValue,
          }
          this.getDistrictList(this.selectedState.stateId)
        }
      }
      this.isDisabledStates = false
    })
  }

  getDistrictList(state: any) {
    this.spinner.show()
    this.store.dispatch(new GetDistrictList(state)).subscribe((res: any) => {
      this.dataDistricts = res.assignHierarchicalStructure.districtList

      this.spinner.hide()
      this.dataResultDistricts = this.dataDistricts.map((district: any) => ({
        districtId: district,
        districtName: district,
      }))
      this.tempDistrictList = this.dataResultDistricts
      if (this.selectedState.stateId !== this.permissionData.state.savedValue) {
        this.resetDistrictCityZone()
        this.isDisabledCity = true
        this.isDisabledZone = true
      } else {
        if (
          this.permissionData.district.savedValue !== null &&
          this.permissionData.district.savedValue !== ''
        ) {
          if (this.permissionData.district.isMultipleSelect === true) {
            let savedValue = this.permissionData.district.savedValue
              .split(';')
              .map((element: any) => element.trim())
            this.selectedDistrict = savedValue.map((district: any) => ({
              districtId: district,
              districtName: district,
            }))
          } else {
            this.selectedDistrict = {
              districtId: this.permissionData.district.savedValue,
              districtName: this.permissionData.district.savedValue,
            }
            this.getCityList(this.selectedDistrict.districtId)
          }
        }
      }
      this.isDisabledDistricts = false
    })
  }

  getCityList(district: any) {
    this.spinner.show()
    this.store.dispatch(new GetCityList(district)).subscribe((res: any) => {
      this.dataCity = res.assignHierarchicalStructure.cityList
      this.spinner.hide()
      this.dataResultCity = this.dataCity.map((district: any) => ({
        cityId: district,
        cityName: district,
      }))
      this.tempCityList = this.dataResultCity
      if (
        this.selectedDistrict.districtId !==
        this.permissionData.district.savedValue
      ) {
        this.resetCityZone()
        this.isDisabledZone = true
      } else {
        if (
          this.permissionData.city.savedValue !== null &&
          this.permissionData.city.savedValue !== ''
        ) {
          if (this.permissionData.city.isMultipleSelect === true) {
            let savedValue = this.permissionData.city.savedValue
              .split(';')
              .map((element: any) => element.trim())

            this.selectedCity = savedValue.map((city: any) => ({
              cityId: city,
              cityName: city,
            }))
          } else {
            this.selectedCity = {
              cityId: this.permissionData.city.savedValue,
              cityName: this.permissionData.city.savedValue,
            }
            this.getZoneList(this.selectedCity.cityId)
          }
        }
      }
      this.isDisabledCity = false
    })
  }

  getZoneList(city: any) {
    this.spinner.show()
    this.store.dispatch(new GetZoneList(city)).subscribe((res: any) => {
      this.dataZone = res.assignHierarchicalStructure.zoneList
      this.spinner.hide()
      this.dataResultZone = this.dataZone.map((zone: any) => ({
        zoneId: zone,
        zoneName: zone,
      }))
      this.tempZoneList = this.dataResultZone
      if (this.selectedCity.cityId !== this.permissionData.city.savedValue) {
        this.resetZone()
      } else {
        if (
          this.permissionData.zone.savedValue !== null &&
          this.permissionData.zone.savedValue !== ''
        ) {
          if (this.permissionData.zone.isMultipleSelect === true) {
            let savedValue = this.permissionData.zone.savedValue
              .split(';')
              .map((element: any) => element.trim())

            this.selectedZone = savedValue.map((zone: any) => ({
              zoneId: zone,
              zoneName: zone,
            }))
          } else {
            this.selectedZone = {
              zoneId: this.permissionData.zone.savedValue,
              zoneName: this.permissionData.zone.savedValue,
            }
          }
        }
      }
      this.isDisabledZone = false
    })
  }

  stateFilterChange(searchTerm: string): void {
    const contains =
      (value: string) => (item: { stateName: string; stateId: string }) =>
        item.stateName.toLowerCase().includes(value.toLowerCase())
    this.dataResultStates = this.tempStateList.filter(contains(searchTerm))
  }

  districtFilterChange(searchTerm: string): void {
    const contains =
      (value: string) => (item: { districtName: string; districtId: string }) =>
        item.districtName.toLowerCase().includes(value.toLowerCase())
    this.dataResultDistricts = this.tempDistrictList.filter(
      contains(searchTerm),
    )
  }

  cityFilterChange(searchTerm: string): void {
    const contains =
      (value: string) => (item: { cityName: string; cityId: string }) =>
        item.cityName.toLowerCase().includes(value.toLowerCase())
    this.dataResultCity = this.tempCityList.filter(contains(searchTerm))
  }

  zoneFilterChange(searchTerm: string): void {
    const contains =
      (value: string) => (item: { zoneName: string; zoneId: string }) =>
        item.zoneName.toLowerCase().includes(value.toLowerCase())
    this.dataResultZone = this.tempZoneList.filter(contains(searchTerm))
  }
}
