<div class="q-content">
  <div class="d-flex gap-15 flex-wrap justify-content-between mb-20">
    <app-common-header-text
      [triggerGetUpdatedTime]="triggerGetUpdatedTime"
    ></app-common-header-text>
    <div class="d-flex gap-20 flex-wrap">
      <kendo-formfield class="mt-0">
        <kendo-dropdownlist
          [data]="userList"
          [value]="selectedUser"
          textField="name"
          valueField="id"
          (valueChange)="onUserChange($event)"
          [(ngModel)]="selectedUser"
          [filterable]="true"
          (filterChange)="onFilterChange($event)"
          [ngClass]="{ 'invalid-dropdown': showUserError }"
        >
        </kendo-dropdownlist>
      </kendo-formfield>
    </div>
  </div>

  <div class="q-content-bg">
    <div class="k-form k-form-md section-height-with-header-update-time">
      <div class="d-flex flex-wrap gap-15 mb-20">
        <div *ngIf="permissionData?.country?.isVisible" class="wmax-280">
          <kendo-formfield class="mt-0">
            <kendo-label
              [for]="country"
              text="Country"
              labelCssClass="k-form-label"
              ><span class="required">*</span></kendo-label
            >
            <kendo-dropdownlist
              [data]="dataCountry"
              [value]="selectedCountry"
              [defaultItem]="defaultItemCountrys"
              textField="countryName"
              valueField="countryId"
              #country
            >
            </kendo-dropdownlist>
          </kendo-formfield>
        </div>

        <div *ngIf="permissionData?.state?.isVisible" class="wmax-280">
          <div [ngClass]="{ 'curser-not-allowd': isDisabledStates }">
            <ng-container
              *ngIf="permissionData.state.isMultipleSelect; else stateDropdown"
            >
              <kendo-formfield class="mt-0">
                <kendo-label text="State" labelCssClass="k-form-label">
                  <span class="required">*</span>
                </kendo-label>
                <kendo-multiselect
                  [disabled]="isDisabledStates"
                  [data]="dataResultStates"
                  [value]="selectedState"
                  textField="stateName"
                  valueField="stateId"
                  [checkboxes]="true"
                  (valueChange)="onStateChange($event)"
                  placeholder="Select State"
                  [(ngModel)]="selectedState"
                  [ngClass]="{ 'invalid-dropdown': showStateError }"
                  [tagMapper]="tagMapper"
                  [filterable]="true"
                  (filterChange)="stateFilterChange($event)"
                ></kendo-multiselect>
              </kendo-formfield>
            </ng-container>
            <ng-template #stateDropdown>
              <kendo-formfield class="mt-0">
                <kendo-label text="State" labelCssClass="k-form-label">
                  <span class="required">*</span>
                </kendo-label>
                <kendo-dropdownlist
                  [disabled]="isDisabledStates"
                  [defaultItem]="defaultItemStates"
                  [data]="dataResultStates"
                  [value]="selectedState"
                  textField="stateName"
                  valueField="stateId"
                  (valueChange)="onStateChange($event)"
                  [(ngModel)]="selectedState"
                  [ngClass]="{ 'invalid-dropdown': showStateError }"
                ></kendo-dropdownlist>
              </kendo-formfield>
            </ng-template>
          </div>
        </div>
      </div>

      <div class="d-flex flex-wrap gap-15 mb-20">
        <div *ngIf="permissionData?.district?.isVisible" class="wmax-280">
          <div [ngClass]="{ 'curser-not-allowd': isDisabledDistricts }">
            <ng-container
              *ngIf="
                permissionData?.district?.isMultipleSelect;
                else districtDropdown
              "
            >
              <kendo-formfield class="mt-0">
                <kendo-label text="District" labelCssClass="k-form-label">
                  <span class="required">*</span>
                </kendo-label>
                <kendo-multiselect
                  [disabled]="isDisabledDistricts"
                  [data]="dataResultDistricts"
                  [value]="selectedDistrict"
                  textField="districtName"
                  valueField="districtId"
                  [checkboxes]="true"
                  (valueChange)="onDistrictChange($event)"
                  placeholder="Select district"
                  [(ngModel)]="selectedDistrict"
                  [ngClass]="{ 'invalid-dropdown': showDistrictError }"
                  [tagMapper]="tagMapper"
                  [filterable]="true"
                  (filterChange)="districtFilterChange($event)"
                ></kendo-multiselect>
              </kendo-formfield>
            </ng-container>
            <ng-template #districtDropdown>
              <kendo-formfield class="mt-0">
                <kendo-label text="District" labelCssClass="k-form-label">
                  <span class="required">*</span>
                </kendo-label>
                <kendo-dropdownlist
                  [disabled]="isDisabledDistricts"
                  [defaultItem]="defaultItemDistrict"
                  [data]="dataResultDistricts"
                  [value]="selectedDistrict"
                  textField="districtName"
                  valueField="districtId"
                  (valueChange)="onDistrictChange($event)"
                  [(ngModel)]="selectedDistrict"
                  [ngClass]="{ 'invalid-dropdown': showDistrictError }"
                ></kendo-dropdownlist>
              </kendo-formfield>
            </ng-template>
          </div>
        </div>
        <div *ngIf="permissionData?.city?.isVisible" class="wmax-280">
          <div [ngClass]="{ 'curser-not-allowd': isDisabledCity }">
            <ng-container
              *ngIf="permissionData?.city?.isMultipleSelect; else cityDropdown"
            >
              <kendo-formfield class="mt-0">
                <kendo-label text="City" labelCssClass="k-form-label">
                  <span class="required">*</span>
                </kendo-label>
                <kendo-multiselect
                  [disabled]="isDisabledCity"
                  [data]="dataResultCity"
                  [value]="selectedCity"
                  textField="cityName"
                  valueField="cityId"
                  [checkboxes]="true"
                  (valueChange)="onCityChange($event)"
                  placeholder="Select city"
                  [(ngModel)]="selectedCity"
                  [ngClass]="{ 'invalid-dropdown': showCityError }"
                  [tagMapper]="tagMapper"
                  [filterable]="true"
                  (filterChange)="cityFilterChange($event)"
                ></kendo-multiselect>
              </kendo-formfield>
            </ng-container>
            <ng-template #cityDropdown>
              <kendo-formfield class="mt-0">
                <kendo-label text="City" labelCssClass="k-form-label">
                  <span class="required">*</span>
                </kendo-label>
                <kendo-dropdownlist
                  [disabled]="isDisabledCity"
                  [defaultItem]="defaultItemCity"
                  [data]="dataResultCity"
                  [value]="selectedCity"
                  textField="cityName"
                  valueField="cityId"
                  (valueChange)="onCityChange($event)"
                  [(ngModel)]="selectedCity"
                  [ngClass]="{ 'invalid-dropdown': showCityError }"
                ></kendo-dropdownlist>
              </kendo-formfield>
            </ng-template>
          </div>
        </div>
      </div>

      <div class="d-flex flex-wrap gap-15">
        <div *ngIf="permissionData?.zone?.isVisible" class="wmax-280">
          <div [ngClass]="{ 'curser-not-allowd': isDisabledZone }">
            <ng-container
              *ngIf="permissionData?.zone?.isMultipleSelect; else zoneDropdown"
            >
              <kendo-formfield class="mt-0">
                <kendo-label text="Zone" labelCssClass="k-form-label">
                  <span class="required">*</span>
                </kendo-label>
                <kendo-multiselect
                  [disabled]="isDisabledZone"
                  [data]="dataResultZone"
                  [value]="selectedZone"
                  textField="zoneName"
                  valueField="zoneId"
                  [checkboxes]="true"
                  (valueChange)="onZoneChange($event)"
                  placeholder="Select zone"
                  [(ngModel)]="selectedZone"
                  [ngClass]="{ 'invalid-dropdown': showZoneError }"
                  [tagMapper]="tagMapper"
                  [filterable]="true"
                  (filterChange)="zoneFilterChange($event)"
                ></kendo-multiselect>
              </kendo-formfield>
            </ng-container>
            <ng-template #zoneDropdown>
              <kendo-formfield class="mt-0">
                <kendo-label text="Zone" labelCssClass="k-form-label">
                  <span class="required">*</span>
                </kendo-label>
                <kendo-dropdownlist
                  [disabled]="isDisabledZone"
                  [defaultItem]="defaultItemZone"
                  [data]="dataResultZone"
                  [value]="selectedZone"
                  textField="zoneName"
                  valueField="zoneId"
                  (valueChange)="onZoneChange($event)"
                  [(ngModel)]="selectedZone"
                  [ngClass]="{ 'invalid-dropdown': showZoneError }"
                ></kendo-dropdownlist>
              </kendo-formfield>
            </ng-template>
          </div>
        </div>
      </div>
    </div>

    <div
      class="d-flex justify-content-end"
      [ngClass]="{ 'curser-not-allowd': userId == '' }"
    >
      <button
        kendoButton
        id="create"
        type="button"
        [disabled]="userList.length <= 0"
        (click)="onSubmit()"
      >
        Submit
      </button>
    </div>
  </div>
</div>
