<div class="q-content">
  <div class="mb-20">
    <app-common-header-text
      [triggerGetUpdatedTime]="triggerGetUpdatedTime"
    ></app-common-header-text>
  </div>
  <div class="q-content-bg dtr-data-section">
    <kendo-tabstrip (tabSelect)="onTabSelect($event)">
      <kendo-tabstrip-tab title="DTR's Data Download" [selected]="true">
        <ng-template kendoTabContent>
          <div class="tabs-content">
            <form class="k-form k-form-md" [formGroup]="downloadForm">
              <fieldset class="k-form-fieldset mb-24">
                <div class="d-flex gap-15 flex-wrap">
                  <kendo-formfield class="wmax-280 mt-0">
                    <kendo-label
                      text="Select Area"
                      labelCssClass="k-form-label"
                    ></kendo-label>
                    <kendo-dropdownlist
                      [data]="feederAreaList"
                      formControlName="feederAreaId"
                      [textField]="'key'"
                      [valueField]="'value'"
                      [ngModelOptions]="{ standalone: true }"
                      (selectionChange)="onFeederAreaChange($event)"
                    ></kendo-dropdownlist>
                  </kendo-formfield>
                  <kendo-formfield class="wmax-280 mt-0">
                    <kendo-label
                      text="Select Device"
                      labelCssClass="k-form-label"
                    ></kendo-label>
                    <kendo-dropdownlist
                      [data]="deviceList"
                      formControlName="deviceId"
                      [textField]="'key'"
                      [valueField]="'value'"
                      [ngModelOptions]="{ standalone: true }"
                    ></kendo-dropdownlist>
                  </kendo-formfield>
                </div>
                <div class="d-flex gap-15 flex-wrap">
                  <kendo-formfield class="wmax-280">
                    <kendo-label text="Start Date" labelCssClass="k-form-label"
                      ><span class="required">*</span></kendo-label
                    >
                    <kendo-datepicker
                      calendarType="classic"
                      formControlName="startDate"
                      format="dd/MM/yyyy"
                    ></kendo-datepicker>
                  </kendo-formfield>
                  <kendo-formfield class="wmax-280">
                    <kendo-label text="End Date" labelCssClass="k-form-label"
                      ><span class="required">*</span></kendo-label
                    >
                    <kendo-datepicker
                      calendarType="classic"
                      formControlName="toDate"
                      format="dd/MM/yyyy"
                    ></kendo-datepicker>
                  </kendo-formfield>
                </div>
              </fieldset>
            </form>
            <div class="info-text-container">
              <p class="font-size-14 text-secondary mb-0">
                <span>*</span>The Data can be dowloaded to your pc or exported
                to a excel sheet by entering the from date and to date.
              </p>
              <p class="mt-5 text-secondary font-size-14">
                <span>*</span>30 days data can only be downloaded in one
                instance.
              </p>
            </div>
          </div>
          <div
            class="footer-button-container"
            [ngClass]="{
              'curser-not-allowd': !(hasEditPermission$ | async)
            }"
          >
            <button
              kendoButton
              (click)="onSubmit()"
              [disabled]="!(hasEditPermission$ | async)"
            >
              Download
            </button>
          </div>
        </ng-template>
      </kendo-tabstrip-tab>
      <kendo-tabstrip-tab title="Backup & Delete">
        <ng-template kendoTabContent>
          <div class="tabs-content">
            <form class="k-form k-form-md mb-20" [formGroup]="backUpForm">
              <fieldset class="k-form-fieldset mb-24">
                <div class="d-flex gap-15 flex-wrap">
                  <kendo-formfield class="wmax-280 mt-0">
                    <kendo-label
                      text="Select Area"
                      labelCssClass="k-form-label"
                    ></kendo-label>

                    <kendo-dropdownlist
                      [data]="feederAreaListBackup"
                      formControlName="feederAreaIdBackUp"
                      [textField]="'key'"
                      [valueField]="'value'"
                      [ngModelOptions]="{ standalone: true }"
                      (selectionChange)="onFeederAreaBackUpChange($event)"
                    ></kendo-dropdownlist>
                  </kendo-formfield>
                  <kendo-formfield class="wmax-280 mt-0">
                    <kendo-label
                      text="Select Device"
                      labelCssClass="k-form-label"
                    ></kendo-label>

                    <kendo-dropdownlist
                      [data]="deviceListBackUp"
                      formControlName="deviceIdBackUp"
                      [textField]="'key'"
                      [valueField]="'value'"
                      [ngModelOptions]="{ standalone: true }"
                    ></kendo-dropdownlist>
                  </kendo-formfield>
                </div>
              </fieldset>
            </form>
            <div class="info-text-container">
              <p class="font-size-14 text-secondary">
                <span>*</span>For Office use only.
              </p>
            </div>
          </div>
          <div
            class="footer-button-container"
            [ngClass]="{
              'curser-not-allowd': !(hasEditPermission$ | async)
            }"
          >
            <kendo-dropdownbutton
              [data]="downloadOption"
              class="downloadData-btn"
              (itemClick)="onDownloadOptionSelected($event)"
              [disabled]="!(hasEditPermission$ | async)"
              [ngClass]="{ disabled: !(hasEditPermission$ | async) }"
            >
              Backup <i class="far fa-chevron-down ml-10"></i>
            </kendo-dropdownbutton>
          </div>
        </ng-template>
      </kendo-tabstrip-tab>
    </kendo-tabstrip>
  </div>
</div>
