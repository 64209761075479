import { Component, OnInit, ViewChild } from '@angular/core'
import {
  CommonState,
  UpdateAngleData,
  UpdateDeviceIdData,
  UpdateDeviceSerialIdData,
  UpdateEnergyData,
  UpdateUnbalancedLoadDataSubmit,
  UpdateWifiDataSubmit,
} from '@app/store'
import { Select, Store } from '@ngxs/store'
import { BehaviorSubject, Observable, Subject } from 'rxjs'
import { DeviceIdChangeComponent } from './device-id-change/device-id-change.component'
import { EnergyComponent } from './energy/energy.component'
import { UnbalancedLoadComponent } from './unbalanced-load/unbalanced-load.component'
import { WifiComponent } from './wifi/wifi.component'
import { AngleBelowComponent } from './angle-below/angle-below.component'
import { AngleAboveComponent } from './angle-above/angle-above.component'
import { DeviceSerialIdComponent } from './device-serial-id/device-serial-id.component'
import { ToastrService } from 'ngx-toastr'
import { NotificationMessage } from '@app/enums/notification'
import { NgxSpinnerService } from 'ngx-spinner'
import { OfficeUseChangesService } from '@app/index'
import { OfficeChange } from '@app/enums/threshold-settings-enum'
import swal from 'sweetalert'
@Component({
  selector: 'app-changes',
  templateUrl: './changes.component.html',
  styleUrl: './changes.component.scss',
})
export class ChangesComponent implements OnInit {
  officeUseChangeData: any = {}
  triggerGetUpdatedTime: Subject<any> = new Subject<any>()
  triggerDeviceIdData: BehaviorSubject<any> = new BehaviorSubject<any>({})
  triggerEnergyData: BehaviorSubject<any> = new BehaviorSubject<any>({})
  triggerUnbalancedLoadData: BehaviorSubject<any> = new BehaviorSubject<any>({})
  triggerWifiData: BehaviorSubject<any> = new BehaviorSubject<any>({})
  triggerAngleBelowData: BehaviorSubject<any> = new BehaviorSubject<any>({})
  triggerAngleAboveData: BehaviorSubject<any> = new BehaviorSubject<any>({})
  triggerDeviceSerialIdData: BehaviorSubject<any> = new BehaviorSubject<any>({})
  @ViewChild(DeviceIdChangeComponent, { static: false })
  deviceIdChangeDetails: any
  OfficeChangeEnum = OfficeChange

  @ViewChild(EnergyComponent, { static: false })
  energyDetails: any

  @ViewChild(UnbalancedLoadComponent, { static: false })
  unbalancedLoadDetails: any

  @ViewChild(WifiComponent, { static: false })
  wifiDetails: any

  @ViewChild(AngleBelowComponent, { static: false })
  angleBelowDetails: any

  @ViewChild(AngleAboveComponent, { static: false })
  angleAboveDetails: any

  @ViewChild(DeviceSerialIdComponent, { static: false })
  deviceSerialIdDetails: any

  @Select(CommonState.hasEditPermission)
  hasEditPermission$!: Observable<boolean>

  deviceId = ''
  constructor(
    private store: Store,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private officeUseChangesService: OfficeUseChangesService,
  ) {}

  ngOnInit(): void {}

  getDataByHeaderData(data: any): void {
    this.deviceId = data.deviceId
    this.triggerGetUpdatedTime.next(true)
  }

  onDeviceIdDataSubmit(): void {
    if (this.deviceIdChangeDetails.deviceIdForm.invalid) {
      this.deviceIdChangeDetails.deviceIdForm.controls.newDeviceId.markAsTouched()
      return
    }
    const currentData = {
      new_Id:
        this.deviceIdChangeDetails.deviceIdForm.controls.newDeviceId.value,
    }
    this.spinner.show()
    this.store
      .dispatch(new UpdateDeviceIdData(currentData, this.deviceId))
      .subscribe(
        (res: any) => {
          this.spinner.hide()
          if (res.officeUseChanges.isDevideIdDatatUpdated) {
            this.toastr.success(NotificationMessage.deviceIdSuccessMsg)
          }
        },
        (error) => {
          this.spinner.hide()
        },
      )
  }

  onEnergyDataSubmit(): void {
    const currentData = {
      kwh: this.energyDetails.energyForm.controls.kwh.value,
      kwah: this.energyDetails.energyForm.controls.kwah.value,
    }
    this.spinner.show()
    this.store
      .dispatch(new UpdateEnergyData(currentData, this.deviceId))
      .subscribe(
        (res: any) => {
          this.spinner.hide()
          if (res.officeUseChanges.isEnergyDatatUpdated) {
            this.toastr.success(NotificationMessage.energyDataSuccessMsg)
          }
        },
        (error) => {
          this.spinner.hide()
        },
      )
  }

  onUnbalancedLoadDataSubmit(): void {
    const currentData = {
      deviation:
        this.unbalancedLoadDetails.unbalancedForm.controls.deviation.value,
      ratedKva: this.unbalancedLoadDetails.unbalancedForm.controls.kva.value,
    }
    this.spinner.show()
    this.store
      .dispatch(new UpdateUnbalancedLoadDataSubmit(currentData, this.deviceId))
      .subscribe(
        (res: any) => {
          this.spinner.hide()
          if (res.officeUseChanges.isUnbalancedLoadDataUpdated) {
            this.toastr.success(
              NotificationMessage.UnbalancedLoadDataSuccessMsg,
            )
          }
        },
        (error) => {
          this.spinner.hide()
        },
      )
  }

  onWifiDataSubmit(): void {
    const currentData = {
      access_point: this.wifiDetails.wifiForm.controls.accessPoint.value,
      password: this.wifiDetails.wifiForm.controls.password.value,
    }
    this.spinner.show()
    this.store
      .dispatch(new UpdateWifiDataSubmit(currentData, this.deviceId))
      .subscribe(
        (res: any) => {
          this.spinner.hide()
          if (res.officeUseChanges.isWifiDataUpdated) {
            this.toastr.success(NotificationMessage.wifiDataSuccessMsg)
          }
        },
        (error) => {
          this.spinner.hide()
        },
      )
  }

  onAngleDataSubmit(): void {
    const currentData = {
      belowAngleR: this.angleBelowDetails.angleBelowForm.controls.rPhase.value,
      belowAngleY: this.angleBelowDetails.angleBelowForm.controls.yPhase.value,
      belowAngleB: this.angleBelowDetails.angleBelowForm.controls.bPhase.value,
      aboveAngleR: this.angleAboveDetails.angleAboveForm.controls.rPhase.value,
      aboveAngleY: this.angleAboveDetails.angleAboveForm.controls.yPhase.value,
      aboveAngleB: this.angleAboveDetails.angleAboveForm.controls.bPhase.value,
    }
    this.spinner.show()
    this.store
      .dispatch(new UpdateAngleData(currentData, this.deviceId))
      .subscribe(
        (res: any) => {
          this.spinner.hide()
          if (res.officeUseChanges.isAngelBelowDataUpdated) {
            this.toastr.success(NotificationMessage.angleDataSuccessMsg)
          }
        },
        (error) => {
          this.spinner.hide()
        },
      )
  }

  confirmPopupUp(OfficeChangeEnum: any) {
    let textMessage = ''

    if (OfficeChangeEnum === OfficeChange.Energy) {
      if (this.energyDetails.energyForm.invalid) {
        this.energyDetails.energyForm.controls.kwh.markAsTouched()
        this.energyDetails.energyForm.controls.kwah.markAsTouched()
        return
      }
      textMessage = `You want to update Energy.`
    } else if (OfficeChangeEnum === OfficeChange.UnbalancedLoad) {
      if (this.unbalancedLoadDetails.unbalancedForm.invalid) {
        this.unbalancedLoadDetails.unbalancedForm.controls.deviation.markAsTouched()
        this.unbalancedLoadDetails.unbalancedForm.controls.kva.markAsTouched()
        return
      }
      textMessage = `You want to update Unbalanced Load.`
    } else if (OfficeChangeEnum === OfficeChange.WIFI) {
      if (this.wifiDetails.wifiForm.invalid) {
        this.wifiDetails.wifiForm.controls.accessPoint.markAsTouched()
        this.wifiDetails.wifiForm.controls.password.markAsTouched()
        return
      }
      textMessage = `You want to update WIFI.`
    } else if (OfficeChangeEnum === OfficeChange.Angle) {
      if (this.angleBelowDetails.angleBelowForm.invalid) {
        this.angleBelowDetails.angleBelowForm.controls.rPhase.markAsTouched()
        this.angleBelowDetails.angleBelowForm.controls.yPhase.markAsTouched()
        this.angleBelowDetails.angleBelowForm.controls.bPhase.markAsTouched()
        this.angleAboveDetails.angleAboveForm.controls.rPhase.markAsTouched()
        this.angleAboveDetails.angleAboveForm.controls.yPhase.markAsTouched()
        this.angleAboveDetails.angleAboveForm.controls.bPhase.markAsTouched()
        return
      }
      textMessage = `You want to update Angle(Below) & Angle(Above).`
    } else if (OfficeChangeEnum === OfficeChange.DeviceSerialId) {
      if (this.deviceSerialIdDetails.deviceSerialIdForm.invalid) {
        this.deviceSerialIdDetails.deviceSerialIdForm.controls.newDeviceSerialId.markAsTouched()
        return
      }
      textMessage = `You want to update Device Serial Id.`
    } else if (OfficeChangeEnum === OfficeChange.ReadIoTSettings) {
      textMessage = `You want to Read IoT Settings.`
    }

    swal({
      title: 'Are you sure ?',
      text: textMessage,
      icon: 'warning',
      buttons: {
        cancel: {
          text: 'Cancel',
          visible: true,
          closeModal: true,
        },
        confirm: {
          text: 'Yes',
        },
      },
      dangerMode: true,
    }).then((confirmed) => {
      if (confirmed) {
        if (OfficeChangeEnum === OfficeChange.Energy) {
          this.onEnergyDataSubmit()
        } else if (OfficeChangeEnum === OfficeChange.UnbalancedLoad) {
          this.onUnbalancedLoadDataSubmit()
        } else if (OfficeChangeEnum === OfficeChange.WIFI) {
          this.onWifiDataSubmit()
        } else if (OfficeChangeEnum === OfficeChange.Angle) {
          this.onAngleDataSubmit()
        } else if (OfficeChangeEnum === OfficeChange.DeviceSerialId) {
          this.onDeviceSerialIdDataSubmit()
        } else if (OfficeChangeEnum === OfficeChange.ReadIoTSettings) {
          this.OnReadIoTSettings()
        }
      }
    })
  }

  onDeviceSerialIdDataSubmit(): void {
    const currentData = {
      serial_Id:
        this.deviceSerialIdDetails.deviceSerialIdForm.controls.newDeviceSerialId
          .value,
    }
    this.spinner.show()
    this.store
      .dispatch(new UpdateDeviceSerialIdData(currentData, this.deviceId))
      .subscribe(
        (res: any) => {
          this.spinner.hide()
          if (res.officeUseChanges.isDeviceSerialIdUpdated) {
            this.toastr.success(NotificationMessage.deviceSerialDataSuccessMsg)
          }
        },
        (error) => {
          this.spinner.hide()
        },
      )
  }

  OnReadIoTSettings(): void {
    this.spinner.show()
    this.officeUseChangesService.readDataFromDevice(this.deviceId).subscribe(
      (res: any) => {
        this.spinner.hide()
        if (res) {
          this.toastr.success(NotificationMessage.readDataSuccessMsg)
        } else {
          this.toastr.error(NotificationMessage.readDataErrorMsg)
        }
      },
      (error) => {
        this.spinner.hide()
      },
    )
  }
}
