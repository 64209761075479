import { Component, OnInit, ViewChild } from '@angular/core'
import { DialogService } from '@progress/kendo-angular-dialog'
import { CommonColorInfoComponent, CommonGridComponent } from '../common'
import { Select, Store } from '@ngxs/store'
import { CommonState, GetMeterDataList } from '@app/store'
import { NgxSpinnerService } from 'ngx-spinner'
import { GridDataResult } from '@progress/kendo-angular-grid'
import { interval, Observable, Subject, takeUntil } from 'rxjs'
import { DatePipe } from '@angular/common'

@Component({
  selector: 'app-meter-data-list',
  templateUrl: './meter-data-list.component.html',
  styleUrl: './meter-data-list.component.scss',
})
export class MeterDataListComponent implements OnInit {
  triggerGetUpdatedTimeWithData: Subject<any> = new Subject<any>()
  triggerGetUpdatedTime: Subject<any> = new Subject<any>()
  @ViewChild(CommonGridComponent) commonGridComponent:
    | CommonGridComponent
    | undefined
  gridView: GridDataResult | any
  pageNumber = 1
  pageSize = 10
  skip = 0
  startDate: string | null = null
  endDate: string | null = null
  totalRecords = 0
  deviceId: any
  feederAreaId: any
  sort: any
  sortOrder = false
  previousSort: any = null

  private destroy$ = new Subject<void>()

  @Select(CommonState.moduleId)
  moduleId$!: Observable<number>
  isDataLoaded = false
  filterQuery: string | any
  constructor(
    private dialogService: DialogService,
    private store: Store,
    private spinner: NgxSpinnerService,
    private datePipe: DatePipe,
  ) {}

  ngOnInit(): void {
    this.setupInterval()
  }

  setupInterval() {
    interval(60000)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.getMeterData(
          this.deviceId,
          this.feederAreaId,
          this.startDate,
          this.endDate,
        )
      })
  }

  onPaginationChange({ skip, take }: any) {
    this.skip = skip
    this.pageSize = take
    this.pageNumber = this.skip / this.pageSize + 1

    this.getMeterData(
      this.deviceId,
      this.feederAreaId,
      this.startDate,
      this.endDate,
    )
  }
  onSortChange(sortData: any) {
    const currentSortField = sortData.sort[0].field
    if (this.previousSort === currentSortField) {
      this.sortOrder = !this.sortOrder
    } else {
      this.sortOrder = true
    }
    this.sort = currentSortField
    this.previousSort = currentSortField
    this.getMeterData(
      this.deviceId,
      this.feederAreaId,
      this.startDate,
      this.endDate,
    )
  }

  ngOnDestroy() {
    this.destroy$.next()
    this.destroy$.complete()
  }

  colorInfoClick(): void {
    const dialogRef = this.dialogService.open({
      content: CommonColorInfoComponent,
      width: 450,
    })
    dialogRef.content.instance as CommonColorInfoComponent
    dialogRef.result.subscribe()
  }

  onColumnSelector(): void {
    this.commonGridComponent?.onColumnSelector()
  }

  onFilterChangeSQL(sqlQuery: any) {
    this.filterQuery = sqlQuery
    this.getMeterData(
      this.deviceId,
      this.feederAreaId,
      this.startDate,
      this.endDate,
    )
  }

  getMeterData(
    deviceId: any,
    feederAreaId: any,
    startDate: any,
    endDate: any,
  ): void {
    const param = {
      deviceId: deviceId,
      feederAreaId: feederAreaId,
      pageNumber: this.pageNumber,
      pageSize: this.pageSize,
      filter: 0,
      sortBy: this.sort ?? null,
      sortOrder: this.sortOrder,
      search: null,
      startDate: startDate,
      endDate: endDate,
      moduleId: this.store.selectSnapshot(CommonState.moduleId),
      filterQuery: this.filterQuery,
    }
    this.spinner.show()
    this.store.dispatch(new GetMeterDataList(param)).subscribe(
      (res: any) => {
        this.spinner.hide()
        if (res.meterData.meterDataList) {
          const { data, totalCount } = res.meterData.meterDataList

          this.gridView = {
            data: data.rows,
            total: totalCount,
            columns: data.columns,
          }

          this.isDataLoaded = true
        }
      },
      (error) => {
        this.spinner.hide()
      },
    )
  }

  getDataFromColumnSelector(event: any) {
    this.getMeterData(
      this.deviceId,
      this.feederAreaId,
      this.startDate,
      this.endDate,
    )
  }

  getDataByHeaderData(event: any) {
    this.deviceId = event.deviceId
    this.feederAreaId = event.feederAreaId
    this.startDate = event.startDate
    this.endDate = event.endDate
    this.getMeterData(
      event.deviceId,
      event.feederAreaId,
      event.startDate,
      event.endDate,
    )
    if (this.deviceId !== 'All') {
      this.triggerGetUpdatedTimeWithData.next(event)
    }

    if (this.deviceId === 'All') {
      this.triggerGetUpdatedTime.next(false)
    }
  }
}
