import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import {
  AddFeederComponent,
  CommonsModule,
  FeederComponent,
  FeederRoutingModule,
  LinkDeviceWithFeederComponent,
  PipeModule,
} from '@app/index'
import { SharedModule } from 'src/shared/shared.module'

@NgModule({
  declarations: [
    FeederComponent,
    AddFeederComponent,
    LinkDeviceWithFeederComponent,
  ],
  imports: [
    CommonModule,
    FeederRoutingModule,
    CommonsModule,
    SharedModule,
    PipeModule,
  ],
  providers: [],
})
export class FeederModule {}
