import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import {
  CommonsModule,
  SettingHistoryComponent,
  SettingHistoryRoutingModule,
} from '@app/index'
import { SharedModule } from '@shared//shared.module'
import { PipeModule } from '@app/pipe/pipe.module'

@NgModule({
  declarations: [SettingHistoryComponent],
  imports: [
    CommonModule,
    SettingHistoryRoutingModule,
    SharedModule,
    PipeModule,
    CommonsModule,
  ],
})
export class SettingHistoryModule {}
