import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { AlertSettingsModel } from '@app/models/alert-settings'
import { Observable, of } from 'rxjs'
import { environment } from 'src/environments'

@Injectable()
export class AlertSettingsService {
  gridData = [
    {
      id: '4',
      alert_type: 'PF',
      alert_en_dis: '1',
      mobile_notify: '1',
      email_notify: '0',
      alert_time_interval: '6',
      date_time: '2022-11-17 10:29:50',
    },
    {
      id: '5',
      alert_type: 'OVERLOAD',
      alert_en_dis: '1',
      mobile_notify: '1',
      email_notify: '0',
      alert_time_interval: '4',
      date_time: '2022-11-17 10:29:50',
    },
    {
      id: '6',
      alert_type: 'VOLTAGE',
      alert_en_dis: '1',
      mobile_notify: '1',
      email_notify: '0',
      alert_time_interval: '2',
      date_time: '2022-11-17 10:29:50',
    },
    {
      id: '2',
      alert_type: 'UNBLOAD',
      alert_en_dis: '1',
      mobile_notify: '1',
      email_notify: '0',
      alert_time_interval: '6',
      date_time: '2022-11-17 10:29:50',
    },
    {
      id: '1',
      alert_type: 'SMPS',
      alert_en_dis: '1',
      mobile_notify: '1',
      email_notify: '0',
      alert_time_interval: '2',
      date_time: '2022-11-17 10:29:50',
    },
    {
      id: '3',
      alert_type: 'TEMPERATURE',
      alert_en_dis: '1',
      mobile_notify: '1',
      email_notify: '0',
      alert_time_interval: '2',
      date_time: '2022-11-17 10:29:50',
    },
  ]

  constructor(private http: HttpClient) {}

  getAllAlertSettings(queryParams: any): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    }
    const url = `${environment.apiVersionUrl}Setting/GetAllAlertSettings`
    return this.http.post<any>(url, JSON.stringify(queryParams), headers)
  }

  updateAlertSettings(
    alertSettingsData: Array<AlertSettingsModel>,
    deviceId: any,
  ): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    }
    const url = `${environment.apiVersionUrl}Setting/UpdateAlertSettings/${deviceId}`
    return this.http.put<any>(url, JSON.stringify(alertSettingsData), headers)
  }
}
