import { Injectable } from '@angular/core'
import { Action, StateContext, State } from '@ngxs/store'
import { tap } from 'rxjs'
import { GetDeviceHistory } from '.'
import { DeviceHistoryService } from '@app/services'

export class DeviceHistoryStateInfo {
  deviceHistoryData?: any
}

@State<DeviceHistoryStateInfo>({
  name: 'deviceHistory',
  defaults: {},
})
@Injectable()
export class DeviceHistoryState {
  constructor(private deviceHistoryService: DeviceHistoryService) {}

  @Action(GetDeviceHistory)
  getDeviceHistory(
    { getState, patchState }: StateContext<DeviceHistoryStateInfo>,
    action: GetDeviceHistory,
  ) {
    return this.deviceHistoryService.getDeviceHistory(action.queryParams).pipe(
      tap((res: any) => {
        patchState({
          deviceHistoryData: res,
        })
      }),
    )
  }
}
