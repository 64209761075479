import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'

import { ComplaintsRoutingModule } from './complaints-routing.module'
import {
  AddComplaintComponent,
  CommonsModule,
  ComplaintsComponent,
} from '@app/index'
import { SharedModule } from 'src/shared/shared.module'

@NgModule({
  declarations: [ComplaintsComponent, AddComplaintComponent],
  imports: [CommonModule, ComplaintsRoutingModule, CommonsModule, SharedModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ComplaintsModule {}
