import { Injectable } from '@angular/core'
import { Action, State, StateContext } from '@ngxs/store'
import { PagePermissionService } from '@app/services'
import { tap } from 'rxjs'
import {
  GetRoleModulePermissionList,
  PagePermissionGetRoleList,
  SaveRoleModulePermissionList,
} from '@app/index'
export class PagePermissionStateInfo {
  pagePermissionRoleList?: any
  roleModulePermissionList?: any
}

@State<PagePermissionStateInfo>({
  name: 'pagePermission',
  defaults: {},
})
@Injectable()
export class PagePermissionState {
  constructor(private pagePermissionService: PagePermissionService) {}

  @Action(PagePermissionGetRoleList)
  getRoleList(
    { getState, patchState }: StateContext<PagePermissionStateInfo>,
    action: PagePermissionGetRoleList,
  ) {
    return this.pagePermissionService.getRoleList().pipe(
      tap((res: any) => {
        patchState({
          pagePermissionRoleList: res,
        })
      }),
    )
  }

  @Action(GetRoleModulePermissionList)
  getRoleModulePermissionList(
    { getState, patchState }: StateContext<PagePermissionStateInfo>,
    action: GetRoleModulePermissionList,
  ) {
    return this.pagePermissionService
      .getRoleModulePermissionList(action.roleId)
      .pipe(
        tap((res: any) => {
          patchState({
            roleModulePermissionList: res,
          })
        }),
      )
  }

  @Action(SaveRoleModulePermissionList)
  saveRoleModulePermissionList(
    { getState, patchState }: StateContext<PagePermissionStateInfo>,
    action: SaveRoleModulePermissionList,
  ) {
    return this.pagePermissionService.saveRoleModulePermissionList(
      action.roleId,
      action.saveRoleModulePermissionRequestBody,
    )
  }
}
