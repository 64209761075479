import { Injectable } from '@angular/core'
import { Selector, Action, StateContext, State } from '@ngxs/store'
import { tap } from 'rxjs'
import { GetCAIDIData, GetLossAmountData } from '.'
import { CAIDIService } from '@app/services'

export class CAIDIStateInfo {
  caidiData?: any
  lossAmountData?: any
}

@State<CAIDIStateInfo>({
  name: 'caidi',
  defaults: {},
})
@Injectable()
export class CAIDIState {
  constructor(private caidiService: CAIDIService) {}

  @Selector()
  static getCAIDIData(state: CAIDIStateInfo) {
    return state.caidiData
  }

  @Selector()
  static getLossAmountData(state: CAIDIStateInfo) {
    return state.lossAmountData
  }

  @Action(GetCAIDIData)
  getCAIDIData(
    { getState, patchState }: StateContext<CAIDIStateInfo>,
    action: GetCAIDIData,
  ) {
    return this.caidiService.getCAIDIData(action.deviceId).pipe(
      tap((res: any) => {
        patchState({
          caidiData: res,
        })
      }),
    )
  }

  @Action(GetLossAmountData)
  getLossAmountData(
    { getState, patchState }: StateContext<CAIDIStateInfo>,
    action: GetLossAmountData,
  ) {
    return this.caidiService.getLossAmountData(action.param).pipe(
      tap((res: any) => {
        patchState({
          lossAmountData: res,
        })
      }),
    )
  }
}
