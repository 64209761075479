import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable, of } from 'rxjs'
import { environment } from 'src/environments'

@Injectable()
export class OfficeUseChangesService {
  data = {
    energy: {
      kwh: 50,
      kwah: 50,
    },
    unbalancedLoad: {
      deviation: 50,
      ratedKva: 50,
    },
    wifi: {
      access_point: 'abs',
      password: 11111,
    },
    angle_below: {
      R_Phase: 50,
      Y_Phase: 50,
      B_Phase: 50,
    },
    angle_above: {
      R_Phase: 50,
      Y_Phase: 50,
      B_Phase: 50,
    },
    new_Id: 200,
    serial_Id: 500,
  }

  constructor(private http: HttpClient) {}

  getOfficeUseChangeData(): Observable<any> {
    return of(this.data)
  }

  saveThresholdSettings(data: any): Observable<any> {
    return of(this.data) // temprory
  }

  updateDeviceIdData(cuurentData: any, deviceId: any): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    }
    const url = `${environment.apiVersionUrl}OfficeSetting/UpdateDeviceId/${deviceId}/${cuurentData.new_Id}`
    return this.http.put<any>(url, null, headers)
  }

  updateEnergyDataSubmit(cuurentData: any, deviceId: any): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    }
    const url = `${environment.apiVersionUrl}OfficeSetting/UpdateEnegry/${deviceId}/${cuurentData.kwh}/${cuurentData.kwah}`
    return this.http.put<any>(url, null, headers)
  }

  updateUnbalancedLoadDataSubmit(
    cuurentData: any,
    deviceId: any,
  ): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    }
    const url = `${environment.apiVersionUrl}OfficeSetting/UpdateUnbalancedLoad/${deviceId}/${cuurentData.deviation}/${cuurentData.ratedKva}`
    return this.http.put<any>(url, null, headers)
  }

  updateWifiDataSubmit(cuurentData: any, deviceId: any): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    }
    const url = `${environment.apiVersionUrl}OfficeSetting/UpdateWIFI/${deviceId}/${cuurentData.access_point}/${cuurentData.password}`
    return this.http.put<any>(url, null, headers)
  }

  updateAngleData(cuurentData: any, deviceId: any): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    }
    const url = `${environment.apiVersionUrl}OfficeSetting/UpdateAngle/${deviceId}`
    return this.http.put<any>(url, cuurentData, headers)
  }

  updateDeviceSerialIdData(cuurentData: any, deviceId: any): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    }
    const url = `${environment.apiVersionUrl}OfficeSetting/UpdateDeviceSerialId/${deviceId}/${cuurentData.serial_Id}`
    return this.http.put<any>(url, null, headers)
  }

  readDataFromDevice(deviceId: string): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    }
    const url = `${environment.apiVersionUrl}OfficeSetting/ReadDataFromDevice/${deviceId}`
    return this.http.get<any>(url, headers)
  }

  resetDevice(deviceId: string): Observable<boolean> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    }
    const url = `${environment.apiVersionUrl}OfficeSetting/resetDevice/${deviceId}`
    return this.http.put<boolean>(url, headers)
  }
}
