export class SaveFeederDevice {
  static readonly type = '[FEEDER] Save Feeder Device'
  constructor(public feederDeviceRequestBody: any) {}
}

export class ConfirmationDeviceForFeeder {
  static readonly type = '[FEEDER] Confirmation Device For Feeder'
  constructor(public feederDeviceRequestBody: any) {}
}

export class GetFeederListWithDevice {
  static readonly type = '[DEVICE] Get Feeder List With Device'

  constructor(public queryParams: any) {}
}

export class GetFeederList {
  static readonly type = '[ACCOUNT] Get Feeder List'
  constructor(public queryParam: any) {}
}

export class CreateFeeder {
  static readonly type = '[ACCOUNT] Create Feeder'
  constructor(public param: any) {}
}

export class DeleteFeeder {
  static readonly type = '[FEEDER] Delete Feeder'
  constructor(public feederId: any) {}
}
