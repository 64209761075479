import { Injectable } from '@angular/core'

@Injectable({
  providedIn: 'root',
})
export class GoogleMapsLoaderService {
  private apiKey: string = 'AIzaSyCvlom5_AlCYoIgXu94yl_VyRRRBc0xSFQ'
  private libraries: string = 'marker'

  load(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (typeof google !== 'undefined' && google.maps) {
        resolve()
        return
      }

      const script = document.createElement('script')
      script.src = `https://maps.googleapis.com/maps/api/js?key=${this.apiKey}&libraries=${this.libraries}`
      script.async = true
      script.defer = true
      script.onload = () => resolve()
      script.onerror = () =>
        reject(new Error('Google Maps API failed to load.'))

      document.head.appendChild(script)
    })
  }
}
