<div class="q-content">
  <div
    class="d-flex justify-content-between mb-20 gap-15 flex-wrap align-items-center"
  >
    <app-common-header-text
      [infoText]="infoText"
      [isInfoVisible]="true"
      [isUpdateDataVisible]="false"
    ></app-common-header-text>
    <div class="d-flex justify-content-end flex-wrap gap-15">
      <app-common-header
        (triggerDataFromHeader)="getDataByHeaderData($event)"
        [showAreaFilter]="true"
      ></app-common-header>
      <div class="drag-icon-container" (click)="onColumnSelector()">
        <a class="drag-icon">
          <img src="assets/images/custom-columns.svg" alt="column-select" />
        </a>
      </div>
    </div>
  </div>
  <div class="google-map mb-40">
    <google-map
      #map
      id="myMap"
      [center]="center"
      [mapId]="mapId"
      [zoom]="zoom"
      width="100%"
    ></google-map>
    <div class="col-md-12 mt-1">
      <small
        >*
        <i class="fa fa-map-marker text-success h5" aria-hidden="true"></i> DTR
        is turned ON
      </small>
      <small
        >*
        <i class="fa fa-map-marker text-danger h5" aria-hidden="true"></i> Power
        Fail Units
      </small>

      <small
        >*
        <i class="fa fa-map-marker text-purple h5" aria-hidden="true"></i>
        Signal Loss Units
      </small>
      <small
        >*
        <i class="fa fa-map-marker text-orange h5" aria-hidden="true"></i>
        Overload Units
      </small>

      <!-- <small>* <i class="fa fa-map-marker text-black h5" aria-hidden="true" ></i> Input power failure units   </small>
			<small>* <i class="fa fa-map-marker text-primary h5" aria-hidden="true" ></i> Faulty units   </small> -->
    </div>
  </div>

  <div class="q-content-bg grid-wrapper" *ngIf="isDataLoaded">
    <ng-container *ngIf="moduleId$ | async as moduleId">
      <app-common-grid
        [gridView]="gridView"
        [columns]="gridView?.columns ?? []"
        [actions]="actions"
        [pageSize]="this.pageSize"
        [showActions]="true"
        [moduleId]="moduleId"
        (triggerActionEvent)="onActionClick($event)"
        (sortChange)="onSortChange($event)"
        (paginationChange)="onPaginationChange($event)"
        (triggerDataFromColumnSelector)="getDataFromColumnSelector($event)"
        (filterChangeSQL)="onFilterChangeSQL($event)"
      ></app-common-grid>
    </ng-container>
  </div>
</div>
<div kendoDialogContainer></div>
