<div class="q-content">
  <div class="d-flex justify-content-between mb-20 gap-15 flex-wrap">
    <app-common-header-text
      [triggerGetUpdatedTime]="triggerGetUpdatedTime"
    ></app-common-header-text>
    <div class="d-flex justify-content-end gap-15">
      <kendo-formfield>
        <kendo-dropdownlist
          [data]="subStationList"
          [(ngModel)]="selectedName"
          [textField]="'substationName'"
          [valueField]="'substationId'"
          [ngModelOptions]="{ standalone: true }"
          (selectionChange)="onNameChange($event)"
          class="w-150"
        ></kendo-dropdownlist>
      </kendo-formfield>
      <div class="drag-icon-container" (click)="onColumnSelector()">
        <a class="drag-icon">
          <img src="assets/images/custom-columns.svg" alt="column-select" />
        </a>
      </div>
    </div>
  </div>
  <!-- <div class="d-flex-row" *ngFor="let item of SSLDDashboardData; let i = index">
    <div
      *ngFor="let key of objectKeys(item)"
      class="d-flex-col-lg-6 d-flex-col-xs-12 d-flex-col"
    > -->
  <div class="q-content-bg" *ngIf="isDataLoaded">
    <div>
      <ng-container *ngIf="moduleId$ | async as moduleId">
        <app-common-grid
          [gridView]="SSLDDashboardData"
          [columns]="SSLDDashboardData?.columns ?? []"
          [isPaginationAllowed]="false"
          [isSortingAllowed]="false"
          [moduleId]="moduleId"
          (sortChange)="onSortChange($event)"
          (paginationChange)="onPaginationChange($event)"
          (filterChangeSQL)="onFilterChangeSQL($event)"
          (triggerDataFromColumnSelector)="getSSSLDDashboardtList()"
        ></app-common-grid>
      </ng-container>
    </div>
  </div>
  <!-- </div>
  </div> -->
</div>

<div kendoDialogContainer></div>
