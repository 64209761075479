<div>
  <form [formGroup]="installDateForm" class="k-form k-form-md">
    <kendo-formfield class="mb-15">
      <kendo-label text="Device Installation Date" labelCssClass="k-form-label">
        <span class="required">*</span>
      </kendo-label>
      <kendo-datepicker
        calendarType="classic"
        formControlName="deviceInstallationDate"
        format="dd/MM/yyyy"
      ></kendo-datepicker>
      <kendo-formerror
        *ngIf="
          installDateForm.get('deviceInstallationDate').touched &&
          installDateForm.get('deviceInstallationDate').invalid
        "
      >
        Device Installation Date is required
      </kendo-formerror>
    </kendo-formfield>
  </form>
</div>

<kendo-dialog-actions>
  <div class="d-flex gap-15 justify-content-end">
    <button kendoButton (click)="onCancel()" fillMode="outline">Cancel</button>
    <div [ngClass]="{ 'curser-not-allowd': installDateForm.invalid }">
      <button
        kendoButton
        (click)="onInstallDateSubmit()"
        [disabled]="installDateForm.invalid"
      >
        Save
      </button>
    </div>
  </div>
</kendo-dialog-actions>
