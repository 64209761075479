import { Component, Input, OnInit } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { CommonService } from '@app/services'
import { Observable } from 'rxjs'

@Component({
  selector: 'app-pf-settings',
  templateUrl: './pf-settings.component.html',
  styleUrl: './pf-settings.component.scss',
})
export class PfSettingsComponent implements OnInit {
  pfSettingForm: FormGroup | any
  @Input()
  triggerPfSettingsData: Observable<any> | undefined
  constructor(public commonService: CommonService) {}

  ngOnInit(): void {
    this.setForm()
    this.triggerPfSettingsData?.subscribe((res: any) => {
      if (res) {
        this.setData(res)
      } else {
        this.setForm()
      }
    })
  }

  setData(data: any): void {
    this.pfSettingForm.patchValue({
      pf: data,
    })
  }

  setForm(): void {
    this.pfSettingForm = new FormGroup({
      pf: new FormControl('', [
        Validators.required,
        Validators.pattern('^[0-9]+(.[0-9]+)?$'),
      ]),
    })
  }
}
