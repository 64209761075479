import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { DataTypeEnum } from '@app/enums'
import { Observable, of } from 'rxjs'
import { environment } from 'src/environments'

@Injectable()
export class SSSLDDashboardService {
  constructor(private http: HttpClient) {}

  getSSSLDDashboardList(queryParams: any, name: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    }
    return this.http.post<any>(
      `${environment.apiVersionUrl}Dashboard/GetDashboardSSSLDData/${name}`,
      queryParams,
      httpOptions,
    )
  }

  getAllSubStation(): Observable<any> {
    return this.http.get<any>(`${environment.apiVersionUrl}Substation/getAll`)
  }
}
