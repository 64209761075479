<div class="q-content">
  <div class="mb-20">
    <app-common-header-text
      [triggerGetUpdatedTime]="triggerGetUpdatedTime"
    ></app-common-header-text>
  </div>
  <div class="q-content-bg">
    <div class="d-flex justify-content-between gap-15 flex-wrap">
      <div
        class="d-flex justify-content-end gap-20 flex-wrap align-items-center"
      >
        <app-common-header
          (triggerDataFromHeader)="getDataByHeaderData($event)"
          [showDateFilter]="false"
        ></app-common-header>
        <div
          class="d-flex gap-15 flex-wrap justify-content-end align-items-center"
        >
          <app-common-separate-date-picker-header
            (onAnalyticsFilterChange)="onAnalyticsFilterChange($event)"
            (onAnalyticsSelectYearFilterChange)="getAllYearData()"
          ></app-common-separate-date-picker-header>
        </div>
      </div>
      <div class="d-flex justify-content-end gap-15 flex-wrap">
        <button kendoButton (click)="getAllYearData()">All Years</button>
        <button kendoButton (click)="onAllButtonClick()">Live</button>
      </div>
    </div>
    <hr />
    <div class="q-card border dtr-graph-container">
      <div *ngIf="showLoader">
        <div class="k-loading-panel">
          <div class="k-loading-panel-mask"></div>
          <div class="k-loading-panel-wrapper">
            <kendo-loader
              *ngIf="showLoader"
              type="infinite-spinner"
              size="large"
            >
            </kendo-loader>
            <div class="k-loading-panel-text">Loading...</div>
          </div>
        </div>
      </div>
      <div *ngIf="!isNoRecordFound">
        <kendo-chart
          [pannable]="{ lock: 'y' }"
          [zoomable]="{ mousewheel: { lock: 'y' }, selection: { lock: 'y' } }"
          [categoryAxis]="categoryAxis"
          class="h-300"
        >
          <kendo-chart-value-axis>
            <kendo-chart-value-axis-item
              [title]="{ text: 'Load (%)' }"
              [max]="roundingFactor"
              [min]="0"
            >
            </kendo-chart-value-axis-item>
          </kendo-chart-value-axis>
          <kendo-chart-series>
            <kendo-chart-series-item
              type="column"
              [data]="chartData"
              field="value"
              categoryField="category"
            >
              <kendo-chart-tooltip>
                <ng-template
                  kendoChartSeriesTooltipTemplate
                  let-value="value"
                  let-category="category"
                  let-seriess="series"
                >
                  Peak Load = {{ category }} : {{ value }}%
                </ng-template>
              </kendo-chart-tooltip>
            </kendo-chart-series-item>
          </kendo-chart-series>
          <kendo-chart-category-axis>
            <kendo-chart-category-axis-item
              [title]="{ text: 'Time' }"
              [labels]="{ rotation: -45, format: '{0:HH:mm MMM dd}' }"
            >
            </kendo-chart-category-axis-item>
          </kendo-chart-category-axis>
        </kendo-chart>
      </div>

      <div *ngIf="isNoRecordFound">
        <div
          *ngIf="isChartData"
          class="no-data-message d-flex align-items-center justify-content-center flex-column gap-20"
        >
          <img src="assets/images/no-data-bar-chart.png" alt="data" />
          No data available yet!!
        </div>
      </div>
    </div>
  </div>
</div>
