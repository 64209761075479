export class SaveColumnModulePermissionList {
  static readonly type = '[Column PERMISSION] Save Role Module Permission List'
  constructor(
    public moduleId: any,
    public roleId: any,
    public saveRoleModulePermissionRequestBody: any,
  ) {}
}

export class GetColumnPermissionList {
  static readonly type =
    '[Column PERMISSION] Get columns Module Permission List'
  constructor(public columnsPermissionData: any) {}
}
