<form [formGroup]="ctSettingForm" class="k-form k-form-md">
  <div class="mb-20">
    <div class="d-flex gap-15">
      <kendo-label
        [for]="ctRatio"
        text="CT Ratio"
        labelCssClass="k-form-label"
        class="w-100 d-flex align-items-center"
      >
        <span class="required">*</span>
      </kendo-label>
      <kendo-textbox
        formControlName="ctRatio"
        #ctRatio
        placeholder="Enter CT Ratio"
        (keypress)="commonService.numberOnly($event)"
      ></kendo-textbox>
    </div>
    <div
      *ngIf="
        ctSettingForm.get('ctRatio')?.invalid &&
        ctSettingForm.get('ctRatio')?.touched
      "
      class="d-flex gap-15"
    >
      <div class="w-80"></div>
      <div>
        <p
          *ngIf="ctSettingForm.get('ctRatio')?.errors?.required"
          class="required"
        >
          CT Ratio is required.
        </p>
        <p
          *ngIf="ctSettingForm.get('ctRatio')?.errors?.pattern"
          class="required"
        >
          Please enter a valid number for CT Ratio.
        </p>
      </div>
    </div>
  </div>
</form>
