import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { AccessDeniedComponent } from './access-denied.component'
import { AuthGuard } from '@app/auth-guards'

const routes: Routes = [
  { path: '', component: AccessDeniedComponent },
  {
    path: 'access-denied',
    component: AccessDeniedComponent,
    canActivate: [AuthGuard],
  },
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AccessDeniedRoutingModule {}
