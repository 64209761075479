import { Injectable } from '@angular/core'
import {
  DashboardDTR,
  DashboarddtrService,
  GetDashboardDTRStatusDeviceList,
} from '@app/index'
import { Action, State, StateContext } from '@ngxs/store'
import { tap } from 'rxjs/operators'

export class DashboardDtrStateInfo {
  dashboardDtrData?: any
  dashboardDtrStateDeviceList?: any[]
}

@State<DashboardDtrStateInfo>({
  name: 'dashboarddtr',
  defaults: {},
})
@Injectable()
export class DashboardDtrState {
  constructor(private dashboarddtrService: DashboarddtrService) {}

  @Action(DashboardDTR)
  DashboardDtrData(
    { getState, patchState }: StateContext<DashboardDtrStateInfo>,
    action: DashboardDTR,
  ) {
    const { feederName, dashboardType } = action
    return this.dashboarddtrService
      .dashboarddtrData(feederName, dashboardType)
      .pipe(
        tap((res) => {
          patchState({
            dashboardDtrData: res,
          })
        }),
      )
  }

  @Action(GetDashboardDTRStatusDeviceList)
  getDashboardDTRStatusDeviceList(
    { getState, patchState }: StateContext<DashboardDtrStateInfo>,
    action: any,
  ) {
    return this.dashboarddtrService
      .getDashboardDTRStatusDeviceList(
        action.status,
        action.queryParam,
        action.feederType,
      )
      .pipe(
        tap((res: any) => {
          patchState({
            dashboardDtrStateDeviceList: res,
          })
        }),
      )
  }
}
