import { Injectable } from '@angular/core'
import { Selector, Action, StateContext, State } from '@ngxs/store'
import { tap } from 'rxjs'
import {
  GetOfficeUseChangeData,
  ReadIoTSettings,
  SaveOfficeUseChange,
  UpdateAngleData,
  UpdateDeviceIdData,
  UpdateDeviceSerialIdData,
  UpdateEnergyData,
  UpdateUnbalancedLoadDataSubmit,
  UpdateWifiDataSubmit,
} from '.'
import { OfficeSettingsService } from '@app/services/office-setting'
import { OfficeUseChangesService } from '@app/services'

export class OfficeUseChangesStateInfo {
  officeUseChangesData?: any
  isSuccess?: boolean
  id?: any
  isDevideIdDatatUpdated?: any
  isEnergyDatatUpdated?: any
  isUnbalancedLoadDataUpdated?: any
  isWifiDataUpdated?: any
  isAngelBelowDataUpdated?: any
  isAngelAboveDataUpdated?: any
  isDeviceSerialIdUpdated?: any
}

@State<OfficeUseChangesStateInfo>({
  name: 'officeUseChanges',
  defaults: {
    isSuccess: false,
  },
})
@Injectable()
export class OfficeUseChangesState {
  constructor(private officeUseChangesService: OfficeUseChangesService) {}

  @Selector()
  static getOfficeUseChangeData(state: OfficeUseChangesStateInfo) {
    return state.officeUseChangesData
  }

  @Action(GetOfficeUseChangeData)
  getOfficeUseChangeData(
    { getState, patchState }: StateContext<OfficeUseChangesStateInfo>,
    action: any,
  ) {
    return this.officeUseChangesService.getOfficeUseChangeData().pipe(
      tap((res: any) => {
        patchState({
          officeUseChangesData: res,
        })
      }),
    )
  }

  @Action(SaveOfficeUseChange)
  saveOfficeUseChange(
    { getState, patchState }: StateContext<OfficeUseChangesStateInfo>,
    action: SaveOfficeUseChange,
  ) {
    return this.officeUseChangesService.saveThresholdSettings(action.data).pipe(
      tap((res) => {
        patchState({
          isSuccess: res.isSuccess,
          id: res.data,
        })
      }),
    )
  }

  @Action(UpdateDeviceIdData)
  updateDeviceIdData(
    { getState, patchState }: StateContext<OfficeUseChangesStateInfo>,
    action: UpdateDeviceIdData,
  ) {
    return this.officeUseChangesService
      .updateDeviceIdData(action.currentData, action.deviceId)
      .pipe(
        tap((res: any) => {
          patchState({
            isDevideIdDatatUpdated: res,
          })
        }),
      )
  }

  @Action(UpdateEnergyData)
  updateEnergyDataSubmit(
    { getState, patchState }: StateContext<OfficeUseChangesStateInfo>,
    action: UpdateEnergyData,
  ) {
    return this.officeUseChangesService
      .updateEnergyDataSubmit(action.currentData, action.deviceId)
      .pipe(
        tap((res: any) => {
          patchState({
            isEnergyDatatUpdated: res,
          })
        }),
      )
  }

  @Action(UpdateUnbalancedLoadDataSubmit)
  updateUnbalancedLoadDataSubmit(
    { getState, patchState }: StateContext<OfficeUseChangesStateInfo>,
    action: UpdateUnbalancedLoadDataSubmit,
  ) {
    return this.officeUseChangesService
      .updateUnbalancedLoadDataSubmit(action.currentData, action.deviceId)
      .pipe(
        tap((res: any) => {
          patchState({
            isUnbalancedLoadDataUpdated: res,
          })
        }),
      )
  }

  @Action(UpdateWifiDataSubmit)
  updateWifiDataSubmit(
    { getState, patchState }: StateContext<OfficeUseChangesStateInfo>,
    action: UpdateWifiDataSubmit,
  ) {
    return this.officeUseChangesService
      .updateWifiDataSubmit(action.currentData, action.deviceId)
      .pipe(
        tap((res: any) => {
          patchState({
            isWifiDataUpdated: res,
          })
        }),
      )
  }

  @Action(UpdateAngleData)
  updateAngleData(
    { getState, patchState }: StateContext<OfficeUseChangesStateInfo>,
    action: UpdateAngleData,
  ) {
    return this.officeUseChangesService
      .updateAngleData(action.currentData, action.deviceId)
      .pipe(
        tap((res: any) => {
          patchState({
            isAngelBelowDataUpdated: res,
          })
        }),
      )
  }

  @Action(UpdateDeviceSerialIdData)
  updateDeviceSerialIdData(
    { getState, patchState }: StateContext<OfficeUseChangesStateInfo>,
    action: UpdateDeviceSerialIdData,
  ) {
    return this.officeUseChangesService
      .updateDeviceSerialIdData(action.currentData, action.deviceId)
      .pipe(
        tap((res: any) => {
          patchState({
            isDeviceSerialIdUpdated: res,
          })
        }),
      )
  }

  @Action(ReadIoTSettings)
  GetReadIoTSettings(
    { getState, patchState }: StateContext<OfficeUseChangesStateInfo>,
    action: ReadIoTSettings,
  ) {
    return this.officeUseChangesService
      .readDataFromDevice(action.deviceId)
      .pipe(
        tap((res) => {
          patchState({})
        }),
      )
  }
}
