import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable, of } from 'rxjs'
import { environment } from 'src/environments'

@Injectable()
export class ComplaintsService {
  complaintsList: any = {
    columns: [
      {
        field: 'ticket',
        name: 'Ticket',
        hasColumn: [],
        isSelected: true,
        isLocked: false,
      },
      {
        field: 'issue',
        name: 'Issue',
        hasColumn: [],
        isSelected: true,
        isLocked: false,
      },
      {
        field: 'device',
        name: 'Device',
        hasColumn: [],
        isSelected: true,
        isLocked: false,
      },
      {
        field: 'receivedOn',
        name: 'Received On',
        hasColumn: [],
        isSelected: true,
        isLocked: false,
      },
      {
        field: 'updatedOn',
        name: 'Updated On',
        hasColumn: [],
        isSelected: true,
        isLocked: false,
      },
      {
        field: 'status',
        name: 'Status',
        hasColumn: [],
        isSelected: true,
        isLocked: false,
      },
    ],
    data: [
      {
        ticket: '123',
        issue: '123112',
        device: '3',
        receivedOn: 'Test',
        updatedOn: 'Test',
        status: 'Active',
      },
      {
        ticket: '123',
        issue: '123112',
        device: '2',
        receivedOn: 'test',
        updatedOn: 'test',
        status: 'Active',
      },
      {
        ticket: '123',
        issue: '123112',
        device: '2',
        receivedOn: 'test',
        updatedOn: 'test',
        status: 'Active',
      },
    ],
  }
  constructor(private http: HttpClient) {}

  getComplaintsList(queryParam: any): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    }
    return this.http.post<any>(
      `${environment.apiVersionUrl}Ticket/GetTicketsDetails`,
      queryParam,
      headers,
    )
  }

  multiImageFileUpload(files: any): Observable<any> {
    const formData: FormData = new FormData()
    for (let file of files) {
      formData.append('files', file, file.name)
    }
    const headers = {
      headers: new HttpHeaders({
        enctype: 'multipart/form-data',
        Accept: 'multipart/form-data',
      }),
    }
    return this.http.post<any>(
      `${environment.apiVersionUrl}FileUpload/multipleupload`,
      formData,
      headers,
    )
  }

  addUpdateComplaint(param: any): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    }
    return this.http.post<any>(
      `${environment.apiVersionUrl}Ticket/AddUpdate`,
      param,
      headers,
    )
  }

  addComment(param: any): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    }
    return this.http.post<any>(
      `${environment.apiVersionUrl}Ticket/addcomment`,
      param,
      headers,
    )
  }

  generateTicketNumber(): Observable<any> {
    const url = `${environment.apiVersionUrl}Ticket/GenerateTicketNumber`

    const headers = new HttpHeaders({ 'Content-Type': 'application/json' })
    return this.http.get(url, { headers, responseType: 'text' })
  }

  getCommentsByTicketId(ticketId: any): Observable<any> {
    return this.http.get<any>(
      `${environment.apiVersionUrl}Ticket/GetComments/${ticketId}`,
    )
  }

  getComplaintDataById(complaintId: any): Observable<any> {
    return this.http.get<any>(
      `${environment.apiVersionUrl}Ticket/GetTicketDetailById/${complaintId}`,
    )
  }

  getTicketHistoryDataById(complaintId: any): Observable<any> {
    return this.http.get<any>(
      `${environment.apiVersionUrl}Ticket/GetTicketHistoryById/${complaintId}`,
    )
  }

  deleteTicket(ticketId: any): Observable<any> {
    return this.http.delete(
      `${environment.apiVersionUrl}Ticket/DeleteTicketById/${ticketId}`,
    )
  }
}
