import { Component, Input, OnInit } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { noSpacesOrSpecialCharsValidator } from '@app/directive'
import { NotificationMessage } from '@app/enums/notification'
import { CommonService } from '@app/services'
import {
  AddRole,
  CheckRoleExistsByName,
  GetRoleDetail,
  UpdateRole,
} from '@app/store'
import { Store } from '@ngxs/store'
import { DialogRef } from '@progress/kendo-angular-dialog'
import { NgxSpinnerService } from 'ngx-spinner'
import { ToastrService } from 'ngx-toastr'
import swal from 'sweetalert'

@Component({
  selector: 'app-update-add-role',
  templateUrl: './add-update-role.component.html',
  styleUrl: './add-update-role.component.scss',
})
export class AddUpdateRoleComponent implements OnInit {
  @Input() isAddRole?: boolean
  @Input() updateRoleId: any
  roleList: any[] = []
  addUpdateRoleForm: FormGroup | any
  headerText?: string

  constructor(
    private dialogRef: DialogRef,
    private store: Store,
    public commonService: CommonService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
  ) {}

  ngOnInit(): void {
    this.headerText = this.isAddRole ? 'Add Role' : 'Update Role'

    this.setForm()

    if (!this.isAddRole) this.getUpdateRoleValue()
  }

  setForm(): FormGroup {
    return (this.addUpdateRoleForm = new FormGroup({
      name: new FormControl('', [
        Validators.required,
        noSpacesOrSpecialCharsValidator(),
      ]),
      description: new FormControl('', noSpacesOrSpecialCharsValidator()),
    }))
  }

  checkNameExist(event: any): void {
    const isNameExist = this.roleList.find(
      (x: any) => x.name.toLowerCase() === event.target.value.toLowerCase(),
    )
    if (isNameExist !== null && isNameExist !== undefined) {
      const nameControl = this.addUpdateRoleForm.get('name')
      nameControl.setErrors({ duplicate: true })
      nameControl.markAsTouched()
      nameControl.markAsDirty()
      this.toastr.error(NotificationMessage.sameRoleNameErrorMsg)
    }
  }

  getUpdateRoleValue() {
    this.spinner.show()
    this.store.dispatch(new GetRoleDetail(this.updateRoleId)).subscribe(
      (res) => {
        this.spinner.hide()
        const roleDetail = res.role.roleDetail

        this.setRoleDetailValue(roleDetail)
      },
      (error) => {
        this.spinner.hide()
      },
    )
  }

  setRoleDetailValue(data: any): FormGroup {
    return this.addUpdateRoleForm.patchValue({
      name: data.name,
      description: data.description,
    })
  }

  onSubmit(): void {
    let textMessage = `You want to ${this.isAddRole ? 'add new' : 'update'} Role - ${this.addUpdateRoleForm.get('name').value}.`

    swal({
      title: 'Are you sure ?',
      text: textMessage,
      icon: 'warning',
      buttons: {
        cancel: {
          text: 'Cancel',
          visible: true,
          closeModal: true,
        },
        confirm: {
          text: 'Yes',
        },
      },
      dangerMode: true,
    }).then((confirmed) => {
      if (confirmed) {
        this.spinner.show()
        if (this.isAddRole) {
          this.store
            .dispatch(
              new CheckRoleExistsByName(
                this.isAddRole ? 0 : this.updateRoleId,
                this.addUpdateRoleForm.get('name').value,
              ),
            )
            .subscribe((res) => {
              this.spinner.hide()
              if (!res.role.isRoleExists) {
                this.onAddRole()
              } else {
                this.toastr.error(NotificationMessage.sameRoleNameErrorMsg)
              }
            })
        } else {
          this.store
            .dispatch(
              new CheckRoleExistsByName(
                this.isAddRole ? 0 : this.updateRoleId,
                this.addUpdateRoleForm.get('name').value,
              ),
            )
            .subscribe((res) => {
              this.spinner.hide()
              if (!res.role.isRoleExists) {
                this.onUpdateRole()
              } else {
                this.toastr.error(NotificationMessage.sameRoleNameErrorMsg)
              }
            })
        }
      }
    })
  }

  onAddRole(): void {
    const params = {
      name: this.addUpdateRoleForm.get('name').value,
      description: this.addUpdateRoleForm.get('description').value,
    }
    this.spinner.show()
    this.store.dispatch(new AddRole(params)).subscribe(
      (res) => {
        this.spinner.hide()
        this.toastr.success(NotificationMessage.RoleCreatedSuccessMsg)
        this.addUpdateRoleForm.reset()
        this.onCloseDialog({ text: 'success' })
      },
      (error) => {
        this.spinner.hide()
      },
    )
  }

  onUpdateRole(): void {
    const params = {
      id: this.updateRoleId,
      name: this.addUpdateRoleForm.get('name').value,
      description: this.addUpdateRoleForm.get('description').value,
    }
    this.spinner.show()
    this.store.dispatch(new UpdateRole(params)).subscribe(
      (res) => {
        this.spinner.hide()
        this.toastr.success(NotificationMessage.RoleUpdatedSuccessMsg)
        this.addUpdateRoleForm.reset()
        this.onCloseDialog({ text: 'success' })
      },
      (error) => {
        this.spinner.hide()
      },
    )
  }

  onCloseDialog(event: any): void {
    this.dialogRef.close(event)
  }
}
