import { Component, OnInit } from '@angular/core'
import { MenuList, ModuleHistoryModuleList } from '@app/enums'
import { CommonService } from '@app/services'
import { GetDeviceHistory } from '@app/store'
import { Store } from '@ngxs/store'
import {
  GridDataResult,
  MultipleSortSettings,
} from '@progress/kendo-angular-grid'
import { PagerPosition } from '@progress/kendo-angular-listview'
import { PagerType } from '@progress/kendo-angular-treelist'
import {
  CompositeFilterDescriptor,
  SortDescriptor,
} from '@progress/kendo-data-query'
import { NgxSpinnerService } from 'ngx-spinner'
import { Subject } from 'rxjs'

@Component({
  selector: 'app-device-history',
  templateUrl: './device-history.component.html',
  styleUrl: './device-history.component.scss',
})
export class DeviceHistoryComponent implements OnInit {
  triggerGetUpdatedTime: Subject<any> = new Subject<any>()
  deviceId: string | undefined
  gridView: GridDataResult | any
  moduleId: any = MenuList['Device-History']
  columns: any[] = []
  moduleList = ModuleHistoryModuleList

  selectedModuleId: any = ModuleHistoryModuleList['Roles']

  gridHeight: number = 0
  pagerposition: PagerPosition = 'bottom'
  skip = 0
  type: PagerType = 'numeric'
  sort: SortDescriptor[] = []
  previousSort: any = null
  pageNumber = 1
  pageSize = 10

  sortOrder = false
  sortBy: any
  isPaginationAllowed: boolean = true
  buttonCount = 5
  info = true
  pageSizes = [5, 10, 20, 50, 100]

  previousNext = true
  multiple = false
  allowUnsort = true
  isSortingAllowed: boolean = true

  filterQuery: string | any
  sortSettings: MultipleSortSettings = {
    mode: 'multiple',
    initialDirection: 'desc',
    allowUnsort: true,
    showIndexes: true,
  }

  public filter: CompositeFilterDescriptor = {
    logic: 'and',
    filters: [],
  }
  constructor(
    private commonService: CommonService,
    private store: Store,
    private spinner: NgxSpinnerService,
  ) {
    this.columns = this.commonService.getColumnNameByModule(this.moduleId)
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.triggerGetUpdatedTime.next(true)
    }, 0)

    this.calculateGridHeight()

    window.addEventListener('resize', () => {
      this.calculateGridHeight()
    })
  }

  calculateGridHeight() {
    const screenHeight = window.innerHeight
    const headerHeight = 81
    const gridHeaderHeight = 46
    const wrapperpadding = 20
    const gridPagination = 56

    this.gridHeight =
      screenHeight -
      (headerHeight + gridHeaderHeight + wrapperpadding + gridPagination)
  }

  getDeviceHistoryList() {
    this.spinner.show()

    const queryParam = {
      deviceId: this.deviceId,
      sortBy: this.sortBy,
      sortOrder: this.sortOrder,
      filterQuery: this.filterQuery,
      pageNumber: this.pageNumber,
      pageSize: this.pageSize,
    }

    this.store.dispatch(new GetDeviceHistory(queryParam)).subscribe(
      (x) => {
        this.spinner.hide()
        const deviceHistoryData = x.deviceHistory.deviceHistoryData
        this.gridView = {
          data: deviceHistoryData?.data.rows,
          total: deviceHistoryData?.totalCount,
        }
      },
      (error) => {
        this.spinner.hide()
      },
    )
  }

  getDataByHeaderData(data: any): void {
    this.triggerGetUpdatedTime.next(true)
    this.deviceId = data.deviceId
    this.getDeviceHistoryList()
  }

  onPaginationChange({ skip, take }: any) {
    this.skip = skip
    this.pageSize = take
    this.pageNumber = this.skip / this.pageSize + 1
    this.getDeviceHistoryList()
  }

  onSortChange(sortData: any): void {
    this.sort = sortData
    const currentSortField = sortData[0].field
    this.sortBy = currentSortField
    if (this.previousSort === currentSortField) {
      this.sortOrder = !this.sortOrder
    } else {
      this.sortOrder = true
    }
    this.previousSort = currentSortField
    this.getDeviceHistoryList()
  }

  onFilterChangeSQL(sqlQuery: any) {
    this.skip = 0
    this.pageNumber = 1
    this.filter = sqlQuery
    this.filterQuery = this.commonService.toSQLExpression(sqlQuery)
    this.getDeviceHistoryList()
  }
}
