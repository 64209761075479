export class DashboardDTR {
  static readonly type = '[DashboardDTR] Fetch DashboardDTR Data'
  constructor(
    public feederName: string,
    public dashboardType: string,
  ) {}
}

export class GetDashboardDTRStatusDeviceList {
  static readonly type = '[DASHBOARDDTR] DashboardDtr Status Device List'
  constructor(
    public status: number,
    public queryParam: any,
    public feederType: number,
  ) {}
}
