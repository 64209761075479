import { Component, ViewChild } from '@angular/core'
import {
  ActionTypeEnum,
  DataTypeEnum,
  OfficeTypeListEmun,
  OfficeTypeListEnumInfo,
} from '@app/enums'
import {
  CommonGridComponent,
  CommonService,
  LoadedSettingDialogComponent,
} from '@app/index'
import {
  CancelDeviceSetting,
  GetDeviceReportsData,
  GetOfficeLoadSetting,
} from '@app/store'
import { Store } from '@ngxs/store'
import { DialogService } from '@progress/kendo-angular-dialog'
import { GridDataResult } from '@progress/kendo-angular-grid'
import { NgxSpinnerService } from 'ngx-spinner'

@Component({
  selector: 'app-office-setting-list',
  templateUrl: './office-setting-list.component.html',
  styleUrl: './office-setting-list.component.scss',
})
export class OfficeSettingListComponent {
  @ViewChild(CommonGridComponent) commonGridComponent!: CommonGridComponent

  officeTypeList = OfficeTypeListEmun

  gridView: GridDataResult | any
  columns: any
  currentView: any[] = []
  deviceReportsData = []
  transformedArray: any[] = []
  loadedSettingArrayData: any[] = []
  officeLoadSettingData: any[] = []
  showDateFilter = false
  showSearch = true
  showSetting = false
  isReviewSettingSelected = false

  selectedOfficeTypeList: any = OfficeTypeListEmun['Device Reports']
  infoTitle = OfficeTypeListEnumInfo['Device Reports']

  search: any = null
  feederAreaId: any
  deviceId: any
  startDate = null
  endDate = null
  pageNumber = 1
  pageSize = 10
  skip = 0
  sortOrder = false
  sort: any
  previousSort: any = null
  filterQuery: string | any
  filterQueryJson: string | any
  deviceReportsColumn = [
    {
      field: 'id',
      title: '#',
      hasColumn: [],
      isSelected: true,
      isLocked: false,
    },
    {
      field: 'field',
      title: 'Parameter',
      hasColumn: [],
      isSelected: true,
      isLocked: false,
    },
    {
      field: 'date_time',
      title: 'Date & Time',
      hasColumn: [],
      isSelected: true,
      isLocked: false,
      dataTypeId: DataTypeEnum.DateTime,
    },
    {
      field: 'status',
      title: 'Status',
      hasColumn: [],
      isSelected: true,
      isLocked: false,
    },
  ]

  reviewSettingsColumn = [
    {
      field: 'settingType',
      title: 'Setting Type',
      hasColumn: [],
      isSelected: true,
      isLocked: false,
    },
    {
      field: 'status',
      title: 'Status',
      hasColumn: [],
      isSelected: true,
      isLocked: false,
    },
  ]

  loadedSettingsColumn = [
    {
      field: 'value1',
      title: 'Device ID',
      hasColumn: [],
      isSelected: true,
      isLocked: false,
    },
    {
      field: 'value2',
      title: 'Mode',
      hasColumn: [],
      isSelected: true,
      isLocked: false,
    },
    {
      field: 'value3',
      title: 'Value 1',
      hasColumn: [],
      isSelected: true,
      isLocked: false,
    },
    // Generate objects for Value2 to Value50
    ...Array.from({ length: 49 }, (_, i) => i + 2).map((i) => ({
      field: `value${i}`,
      title: `Value ${i}`,
      hasColumn: [],
      isSelected: true,
      isLocked: false,
    })),
  ]

  simModuleOffReportColumn = [
    {
      field: 'simcomFailTime',
      title: 'SIM COM Fail Time',
      hasColumn: [],
      isSelected: true,
      isLocked: false,
      dataTypeId: DataTypeEnum.DateTime,
    },
    {
      field: 'serverTime',
      title: 'Server Time',
      hasColumn: [],
      isSelected: true,
      isLocked: false,
      dataTypeId: DataTypeEnum.DateTime,
    },
  ]

  simcomStatusColumn = [
    {
      field: 'updatedOn',
      title: 'Updated On',
      hasColumn: [],
      isSelected: true,
      isLocked: false,
      dataTypeId: DataTypeEnum.DateTime,
    },
    {
      field: 'detection',
      title: 'Detection',
      hasColumn: [],
      isSelected: true,
      isLocked: false,
    },
    {
      field: 'networkStatus',
      title: 'Network Status',
      hasColumn: [],
      isSelected: true,
      isLocked: false,
    },
    {
      field: 'gprsStatus',
      title: 'GPRS Status',
      hasColumn: [],
      isSelected: true,
      isLocked: false,
    },
    {
      field: 'postingStatus',
      title: 'Posting Status',
      hasColumn: [],
      isSelected: true,
      isLocked: false,
    },
    {
      field: 'powerDip',
      title: 'Power Dip',
      hasColumn: [],
      isSelected: true,
      isLocked: false,
    },
    {
      field: 'statusCode',
      title: 'Status Code',
      hasColumn: [],
      isSelected: true,
      isLocked: false,
    },
  ]

  sysyemStatusColumn = [
    {
      field: 'deviceId',
      title: 'device Id',
      hasColumn: [],
      isSelected: true,
      isLocked: false,
    },
    {
      field: 'dateTime',
      title: 'Date & Time',
      hasColumn: [],
      dataTypeId: DataTypeEnum.DateTime,
      isSelected: true,
      isLocked: false,
    },
    {
      field: 'rtc',
      title: 'RTC',
      hasColumn: [],
      isSelected: true,
      isLocked: false,
    },
    {
      field: 'flash',
      title: 'FLASH',
      hasColumn: [],
      isSelected: true,
      isLocked: false,
    },
    {
      field: 'wifi',
      title: 'WIFI',
      hasColumn: [],
      isSelected: true,
      isLocked: false,
    },
    {
      field: 'ade',
      title: 'ADE',
      hasColumn: [],
      isSelected: true,
      isLocked: false,
    },
    {
      field: 'dcsupply',
      title: 'DCSUPPLY',
      hasColumn: [],
      isSelected: true,
      isLocked: false,
    },
    {
      field: 'gps',
      title: 'GPS',
      hasColumn: [],
      isSelected: true,
      isLocked: false,
    },
    {
      field: 'temperaturecard',
      title: 'TEMPERATURE CARD"',
      hasColumn: [],
      isSelected: true,
      isLocked: false,
    },
  ]

  actions: any[] = [
    {
      title: 'Cancel',
      icon: 'fa-solid fa-ban',
      actionTypeId: ActionTypeEnum.Cancel,
    },
  ]
  //#endregion
  constructor(
    private store: Store,
    private spinner: NgxSpinnerService,
    private dialogService: DialogService,
    private commonService: CommonService,
  ) {}

  ngOnInit(): void {
    this.columns = this.deviceReportsColumn
  }

  onColumnSelector(): void {
    this.commonGridComponent?.onColumnSelector()
  }

  setInfoMessage(): void {
    switch (this.selectedOfficeTypeList) {
      case OfficeTypeListEmun['Device Reports']:
        this.infoTitle = OfficeTypeListEnumInfo['Device Reports']
        break
      case OfficeTypeListEmun['Review Settings']:
      case OfficeTypeListEmun['Simcom Status']:
      case OfficeTypeListEmun['System Status']:
      case OfficeTypeListEmun['Loaded Settings']:
        this.infoTitle = OfficeTypeListEnumInfo['Review Settings']
        break
      case OfficeTypeListEmun['SIM Module Off Report']:
        this.infoTitle = OfficeTypeListEnumInfo['SIM Module Off Report']
        break
    }
  }

  isStartEndDate(): boolean {
    let isStartEndDate = true
    switch (this.selectedOfficeTypeList) {
      case OfficeTypeListEmun['Device Reports']:
      case OfficeTypeListEmun['Review Settings']:
      case OfficeTypeListEmun['Loaded Settings']:
        isStartEndDate = false
        break
    }
    return isStartEndDate
  }

  onSearch(): void {
    this.getAllReportsDataFromOfficeType()
  }

  onChangeOfficeType(): void {
    this.commonGridComponent.filter.filters = []
    this.filterQuery = null
    this.filterQueryJson = null
    this.getAllReportsDataFromOfficeType()
  }

  getAllReportsDataFromOfficeType() {
    this.setInfoMessage()
    const params = {
      deviceId: this.deviceId === undefined ? null : this.deviceId,
      feederAreaId: this.feederAreaId === undefined ? null : this.feederAreaId,
      pageNumber: this.pageNumber === undefined ? null : this.pageNumber,
      pageSize: this.pageSize === undefined ? null : this.pageSize,
      filter: 0,
      sortBy: this.sort === undefined ? null : this.sort,
      sortOrder: this.sortOrder,
      search: this.search,
      startDate: this.isStartEndDate() ? this.startDate : null,
      endDate: this.isStartEndDate() ? this.endDate : null,
      filterQuery: this.filterQuery,
      filterQueryJson: this.filterQueryJson,
    }
    this.spinner.show()
    this.store
      .dispatch(new GetDeviceReportsData(this.selectedOfficeTypeList, params))
      .subscribe((res) => {
        this.spinner.hide()
        const response = res.officeSettings.deviceReportsData
        this.columns = response.data.columns
        switch (this.selectedOfficeTypeList) {
          case OfficeTypeListEmun['Device Reports']:
            this.gridView = {
              data: response.data.rows[0].deviceReports,
              total: response.totalCount,
              columns: this.columns,
            }
            this.showDateFilter = false
            this.showSearch = true
            this.showSetting = false
            break
          case OfficeTypeListEmun['Review Settings']:
            this.isReviewSettingSelected = true
            this.gridView = {
              data: response.data.rows[0].reviewSetting,
              total: response.totalCount,
              columns: this.columns,
            }
            this.showDateFilter = false
            this.showSearch = false
            this.showSetting = false
            break
          case OfficeTypeListEmun['Loaded Settings']:
            const data: any = []
            if (response.data.rows[0].loadedSettings.length > 0) {
              response.data.rows[0].loadedSettings.forEach((el: any) => {
                data.push(el.values)
              })
            }
            this.gridView = {
              data: data,
              total: response.totalCount,
              columns: this.columns,
            }
            this.loadedSettingArrayData = this.gridView.data
            this.showDateFilter = false
            this.showSearch = false
            this.showSetting = true
            break
          case OfficeTypeListEmun['SIM Module Off Report']:
            this.gridView = {
              data: response.data.rows[0].simModuleOffReport,
              total: response.totalCount,
              columns: this.columns,
            }
            this.showDateFilter = true
            this.showSearch = false
            this.showSetting = false
            break
          case OfficeTypeListEmun['Simcom Status']:
            this.gridView = {
              data: response.data.rows[0].simcomStatus,
              total: response.totalCount,
              columns: this.columns,
            }
            this.showDateFilter = true
            this.showSearch = false
            this.showSetting = false
            break
          case OfficeTypeListEmun['System Status']:
            this.gridView = {
              data: response.data.rows[0].systemStatus,
              total: response.totalCount,
              columns: this.columns,
            }
            this.showDateFilter = true
            this.showSearch = false
            this.showSetting = false
            break
        }
      })
  }

  getDataByHeaderData(event: any) {
    this.feederAreaId = event.feederAreaId
    this.deviceId = event.deviceId
    this.startDate = event.startDate
    this.endDate = event.endDate

    this.getAllReportsDataFromOfficeType()
  }

  onSortChange(sortData: any) {
    const currentSortField = sortData.sort[0].field
    if (this.previousSort === currentSortField) {
      this.sortOrder = !this.sortOrder
    } else {
      this.sortOrder = true
    }
    this.sort = currentSortField
    this.previousSort = currentSortField

    this.getAllReportsDataFromOfficeType()
  }

  onPaginationChange({ skip, take }: any) {
    this.skip = skip
    this.pageSize = take
    this.pageNumber = this.skip / this.pageSize + 1

    this.getAllReportsDataFromOfficeType()
  }

  convertValueAsColumsValue(loadedSettingsData: any): any[] {
    const transformedArray = loadedSettingsData.map((item: any) => {
      const fields = item.frame.split(';')
      const transformedObject: { [key: string]: string } = {}

      fields.forEach((field: string, index: number) => {
        transformedObject[`value${index + 1}`] = field
      })

      return transformedObject
    })

    return transformedArray
  }
  getOfficeLoadSetting() {
    this.spinner.show()
    this.store
      .dispatch(new GetOfficeLoadSetting(this.deviceId))
      .subscribe((res) => {
        this.officeLoadSettingData = res.officeSettings.officeLoadSetting
        this.spinner.hide()
        this.openDialog()
      })
  }

  openDialog(): void {
    const dialogRef = this.dialogService.open({
      content: LoadedSettingDialogComponent,
      width: 800,
    })
    const deviceInfo = dialogRef.content
      .instance as LoadedSettingDialogComponent
    deviceInfo.loadedSettingArrayData = this.officeLoadSettingData
    deviceInfo.deviceId = this.deviceId
    dialogRef.result.subscribe((res: any) => {
      if (res && res.confirmed) {
        this.getAllReportsDataFromOfficeType()
      }
    })
  }

  onSettingClick(): void {
    this.getOfficeLoadSetting()
  }

  onFilterChangeSQL(sqlQuery: any) {
    this.filterQuery = null
    this.filterQueryJson = null

    if (
      this.selectedOfficeTypeList === OfficeTypeListEmun['Simcom Status'] ||
      this.selectedOfficeTypeList === OfficeTypeListEmun['System Status'] ||
      this.selectedOfficeTypeList === OfficeTypeListEmun['Loaded Settings'] ||
      this.selectedOfficeTypeList === OfficeTypeListEmun['Review Settings']
    ) {
      this.filterQueryJson =
        this.commonService.convertFilterToDateFormat(sqlQuery)
    } else {
      this.filterQuery = sqlQuery
    }

    this.getAllReportsDataFromOfficeType()
  }

  onActionClick(event: any): void {
    if (event.actionTypeId === ActionTypeEnum.Cancel) {
      this.onCancelDeviceSetting(event.data)
    }
  }

  onCancelDeviceSetting(data: any) {
    this.spinner.show()

    const reqBody = {
      deviceId: this.deviceId,
      type: data.settingType,
    }

    this.store.dispatch(new CancelDeviceSetting(reqBody)).subscribe(
      (res) => {
        const cancelDeviceSetting = res.officeSettings.cancelDeviceSetting
        this.spinner.hide()
        if (cancelDeviceSetting) {
          this.getAllReportsDataFromOfficeType()
        }
      },

      (error) => {
        this.spinner.hide()
      },
    )
  }
}
