import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { SharedModule } from 'src/shared/shared.module'
import {
  CommonsModule,
  DataReportListComponent,
  DataReportRoutingModule,
} from '@app/index'

@NgModule({
  declarations: [DataReportListComponent],
  imports: [CommonModule, DataReportRoutingModule, CommonsModule, SharedModule],
})
export class DataReportModule {}
