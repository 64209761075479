import { Component, ViewChild } from '@angular/core'
import { BehaviorSubject, Subject } from 'rxjs'
import { CommonGridComponent } from '../common'

@Component({
  selector: 'app-software',
  templateUrl: './software.component.html',
  styleUrl: './software.component.scss',
})
export class SoftwareComponent {
  @ViewChild(CommonGridComponent) commonGridComponent:
    | CommonGridComponent
    | undefined

  triggerSoftwareStatus: BehaviorSubject<any> = new BehaviorSubject<any>({})
  triggerColumnSelector: Subject<any> = new Subject<any>()
  triggerGetUpdatedTime: Subject<any> = new Subject<any>()

  ngOnInit(): void {
    setTimeout(() => {
      this.triggerGetUpdatedTime.next(true)
    }, 0)
  }

  getDataByHeaderData(event: any): void {
    const param: any = {
      deviceId: event.deviceId,
      feederAreaId: event.feederAreaId,
    }
    this.triggerSoftwareStatus.next(param)
  }

  onColumnSelector(): void {
    this.triggerColumnSelector.next(true)
  }

  onTabSelect(event: any): void {}
}
