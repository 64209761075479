import { Injectable } from '@angular/core'
import { Action, StateContext, State } from '@ngxs/store'
import { tap } from 'rxjs'
import {
  AddUserActivation,
  GetDeviceListWithoutDb,
} from './user-activation.action'
import { UserActivationService } from '@app/services'

export class UserActivationStateInfo {
  softwareStatusData?: any
  columns?: any[] = []
  id?: any
  isSuccess?: boolean
  deviceListWithoutDb?: any[] = []
}

@State<UserActivationStateInfo>({
  name: 'useractivation',
  defaults: {
    isSuccess: false,
  },
})
@Injectable()
export class UserActivationState {
  constructor(private userActivationService: UserActivationService) {}

  @Action(AddUserActivation)
  addUserActivation(
    { getState, patchState }: StateContext<UserActivationStateInfo>,
    action: AddUserActivation,
  ) {
    return this.userActivationService
      .addUserActivation(action.activationCode, action.deviceId)
      .pipe(
        tap((res) => {
          patchState({
            isSuccess: res,
          })
        }),
      )
  }

  @Action(GetDeviceListWithoutDb)
  getDeviceListWithoutDb(
    { getState, patchState }: StateContext<UserActivationStateInfo>,
    action: GetDeviceListWithoutDb,
  ) {
    return this.userActivationService.getDeviceListWithoutDb().pipe(
      tap((res: any) => {
        const deviceList = [
          ...res.map((device: any) => ({
            key: device,
            value: device,
          })),
        ]

        patchState({
          deviceListWithoutDb: deviceList,
        })
      }),
    )
  }
}
