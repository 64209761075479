import { Component, Input } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { CommonService } from '@app/services'

@Component({
  selector: 'app-max-current',
  templateUrl: './max-current.component.html',
  styleUrl: './max-current.component.scss',
})
export class MaxCurrentComponent {
  maxCurrentForm: FormGroup | any
  @Input()
  triggerMaxCurrentData: any
  constructor(public commonService: CommonService) {}

  ngOnInit(): void {
    this.setForm()
    this.triggerMaxCurrentData.subscribe((res: any) => {
      if (res) {
        this.setData(res)
      } else {
        this.setForm()
      }
    })
  }

  setData(data: any): void {
    this.maxCurrentForm.patchValue({
      rPhase: data.rPhase ?? '',
      yPhase: data.yPhase ?? '',
      bPhase: data.bPhase ?? '',
    })
  }

  setForm(): void {
    this.maxCurrentForm = new FormGroup({
      rPhase: new FormControl('', [
        Validators.required,
        Validators.pattern('^[0-9]+(.[0-9]+)?$'),
      ]),
      yPhase: new FormControl('', [
        Validators.required,
        Validators.pattern('^[0-9]+(.[0-9]+)?$'),
      ]),
      bPhase: new FormControl('', [
        Validators.required,
        Validators.pattern('^[0-9]+(.[0-9]+)?$'),
      ]),
    })
  }
}
