import { Injectable } from '@angular/core'
import {
  ConfirmationDeviceForFeeder,
  CreateFeeder,
  DeleteFeeder,
  GetFeederList,
  GetFeederListWithDevice,
  SaveFeederDevice,
} from '@app/index'
import { FeederService } from '@app/services'
import { Action, State, StateContext } from '@ngxs/store'
import { tap } from 'rxjs'
export class FeederStateInfo {}

@State<FeederStateInfo>({
  name: 'feeder',
  defaults: {},
})
@Injectable()
export class FeederState {
  deviceList?: any
  confirmationMessageList?: any
  feederList?: any
  isFeederSuccess: boolean = true
  isFeederDeleted: boolean = true

  constructor(private feederService: FeederService) {}

  @Action(SaveFeederDevice)
  saveFeederDevice(
    { getState, patchState }: StateContext<FeederStateInfo>,
    action: SaveFeederDevice,
  ) {
    return this.feederService.saveFeederDevice(action.feederDeviceRequestBody)
  }

  @Action(ConfirmationDeviceForFeeder)
  confirmationDeviceForFeeder(
    { getState, patchState }: StateContext<FeederStateInfo>,
    action: ConfirmationDeviceForFeeder,
  ) {
    return this.feederService
      .confirmationDeviceForFeeder(action.feederDeviceRequestBody)
      .pipe(
        tap((res) => {
          patchState({
            confirmationMessageList: res,
          })
        }),
      )
  }

  @Action(GetFeederListWithDevice)
  GetFeederListWithDevice(
    { getState, patchState }: StateContext<FeederStateInfo>,
    action: GetFeederListWithDevice,
  ) {
    return this.feederService.GetFeederListWithDevice(action.queryParams).pipe(
      tap((res) => {
        patchState({
          deviceList: res,
        })
      }),
    )
  }

  @Action(CreateFeeder)
  createFeeder(
    { getState, patchState }: StateContext<FeederStateInfo>,
    action: CreateFeeder,
  ) {
    return this.feederService.createFeeder(action.param).pipe(
      tap((res: any) => {
        patchState({
          isFeederSuccess: res,
        })
      }),
    )
  }

  @Action(GetFeederList)
  getFeederList(
    { getState, patchState }: StateContext<FeederStateInfo>,
    action: any,
  ) {
    return this.feederService.getFeederList(action.queryParam).pipe(
      tap((res: any) => {
        patchState({
          feederList: res,
        })
      }),
    )
  }

  @Action(DeleteFeeder)
  deleteFeeder(
    { getState, patchState }: StateContext<FeederStateInfo>,
    action: DeleteFeeder,
  ) {
    return this.feederService.deleteFeeder(action.feederId).pipe(
      tap((res: any) => {
        patchState({
          isFeederDeleted: res,
        })
      }),
    )
  }
}
