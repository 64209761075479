import { Component, Input, OnInit } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { CommonService } from '@app/services'

@Component({
  selector: 'app-energy',
  templateUrl: './energy.component.html',
  styleUrl: './energy.component.scss',
})
export class EnergyComponent implements OnInit {
  energyForm: FormGroup | any
  @Input()
  triggerEnergyData: any
  constructor(public commonService: CommonService) {}

  ngOnInit(): void {
    this.setForm()
  }

  setForm(): void {
    this.energyForm = new FormGroup({
      kwh: new FormControl('', [
        Validators.required,
        Validators.pattern('^[0-9]+(.[0-9]+)?$'),
      ]),
      kwah: new FormControl('', [
        Validators.required,
        Validators.pattern('^[0-9]+(.[0-9]+)?$'),
      ]),
    })
  }

  setData(data: any): void {
    this.energyForm.patchValue({
      kwh: data.kwh,
      kwah: data.kwah,
    })
  }
}
