export class GetDeviceReportsData {
  static readonly type = '[OFFICE] Get Device report Data'
  constructor(
    public officeSettingType: any,
    public parameters: any,
  ) {}
}

export class CreateLoadedSetting {
  static readonly type = '[OFFICE] Create loaded setting '
  constructor(public loadedSettingData: any) {}
}

export class GetOfficeLoadSetting {
  static readonly type = '[OFFICE] Get Office Load Settings'
  constructor(public deviceId: any) {}
}

export class CancelDeviceSetting {
  static readonly type = '[OFFICE] Cancel Device Setting'
  constructor(public reqBody: any) {}
}
