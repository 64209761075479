import { Component, OnInit } from '@angular/core'
import { NavigationExtras, Router } from '@angular/router'
import { ActionTypeEnum, MenuList } from '@app/enums'
import {
  CommonState,
  DeleteAccount,
  GetAccountList,
  SetModulePermission,
} from '@app/store'
import { Select, Store } from '@ngxs/store'
import { DialogService } from '@progress/kendo-angular-dialog'
import { GridDataResult } from '@progress/kendo-angular-grid'
import { NgxSpinnerService } from 'ngx-spinner'
import { Observable, Subject } from 'rxjs'
import { AccountDevicePopupComponent } from '../account-device-popup/account-device-popup.component'

@Component({
  selector: 'app-account-list',
  templateUrl: './account-list.component.html',
  styleUrl: './account-list.component.scss',
})
export class AccountListComponent implements OnInit {
  gridView: GridDataResult | any
  public pageSize = 10
  public skip = 1
  searchText = ''
  public sorting = null
  sort = null
  sortOrder = false
  hasAddPermission = false
  pageNumber = 1
  columns = [
    {
      field: 'firstName',
      name: 'Name',
      hasColumn: [],
      isSelected: true,
      isLocked: false,
    },
    {
      field: 'role',
      name: 'Role',
      hasColumn: [],
      isSelected: true,
      isLocked: false,
    },
    {
      field: 'mobileNumber',
      name: 'Mobile No',
      hasColumn: [],
      isSelected: true,
      isLocked: false,
    },
    {
      field: 'emailId',
      name: 'Email',
      hasColumn: [],
      isSelected: true,
      isLocked: false,
    },
  ]

  actions: any[] = [
    {
      title: 'Edit',
      icon: 'far fa-pen',
      actionTypeId: ActionTypeEnum.Edit,
    },
    {
      title: 'Delete',
      icon: 'far fa-trash-alt',
      actionTypeId: ActionTypeEnum.Delete,
    },
    {
      title: 'Assign Devices',
      icon: 'far fa-eye',
      actionTypeId: ActionTypeEnum.View,
    },
  ]

  moduleId = MenuList.Accounts

  @Select(CommonState.hasAddPermission)
  hasAddPermission$!: Observable<boolean>

  filterQuery: string | any
  triggerGetUpdatedTime: Subject<any> = new Subject<any>()

  @Select(CommonState.accountListPermission)
  accountListPermissionMenu$!: Observable<any>

  constructor(
    private router: Router,
    private store: Store,
    private dialogService: DialogService,
    private spinner: NgxSpinnerService,
  ) {}

  ngOnInit(): void {
    this.accountListPermissionMenu$.subscribe((x) => {
      this.store.dispatch(new SetModulePermission(x[0])).subscribe()
    })
    this.getAccountListData()
    setTimeout(() => {
      this.triggerGetUpdatedTime.next(true)
    }, 0)
  }

  onFilterChangeSQL(sqlQuery: any) {
    this.filterQuery = sqlQuery
    this.getAccountListData()
  }

  getAccountListData() {
    const param = {
      pageNumber: this.pageNumber,
      pageSize: this.pageSize,
      sortBy: this.sort,
      sortOrder: this.sortOrder,
      search: null,
      filterQuery: this.filterQuery,
    }
    this.spinner.show()
    this.store.dispatch(new GetAccountList(param)).subscribe(
      (res) => {
        this.spinner.hide()
        if (res.account.accountList.data.rows.length > 0) {
          this.gridView = {
            data: res.account.accountList.data.rows,
            total: res.account.accountList.totalCount,
            columns: this.columns,
          }
        } else {
          this.gridView = {
            data: [],
            total: res.account.accountList.totalCount,
            columns: this.columns,
          }
        }
      },
      (error) => {
        this.spinner.hide()
      },
    )
  }

  onSortChange(sortData: any) {
    this.sort = sortData.sort[0].field
    this.sortOrder = sortData.sort[0].dir === 'asc' ? true : false
    this.getAccountListData()
  }

  onPaginationChange({ skip, take }: any) {
    this.skip = skip
    this.pageSize = take
    this.pageNumber = this.skip / this.pageSize + 1
    this.searchText = ''
    this.getAccountListData()
  }

  onCreateAccount(
    emailId?: any,
    mobileNumber?: any,
    isAdd: boolean = true,
  ): void {
    let params = {
      isAddMode: isAdd,
      emailId: btoa(emailId),
      mobileNumber: btoa(mobileNumber),
    }

    let navigationExtras: NavigationExtras = {
      queryParams: params,
    }

    this.router.navigate(['create-account'], navigationExtras)
  }

  onDeleteAccount(event: any): void {
    this.spinner.show()
    this.store
      .dispatch(new DeleteAccount(event.emailId, event.mobileNumber))
      .subscribe(
        (data: any) => {
          this.spinner.hide()
          this.getAccountListData()
        },
        (error) => {
          this.spinner.hide()
        },
      )
  }

  onActionClick(event: any): void {
    if (event.actionTypeId === ActionTypeEnum.Edit) {
      this.onCreateAccount(event.data.emailId, event.data.mobileNumber, false)
    } else if (event.actionTypeId === ActionTypeEnum.Delete) {
      this.onDeleteAccount(event.data)
    } else if (event.actionTypeId === ActionTypeEnum.View) {
      this.onViewDevice(event.data)
    }
  }

  onViewDevice(data: any): void {
    const dialogRef = this.dialogService.open({
      content: AccountDevicePopupComponent,
      width: 800,
    })
    const component: any = dialogRef.content
      .instance as AccountDevicePopupComponent

    component.accountId = data.id
    component.accountName = data.firstName

    dialogRef.result.subscribe((res: any) => {
      if (res && res.confirmed) {
      }
    })
  }
}
