<div class="q-content d-flex justify-content-center align-items-center">
  <div class="q-content-bg access-denied-container wp-100">
    <div class="d-flex justify-content-center align-items-center flex-column">
      <img src="assets/images/access-denied.png" alt="error" class="mb-24" />
      <p class="info-text">Access Denied for the page you are looking for.</p>
      <p class="info-desc">
        You don’t have authorization to view this page. HTTP Error 403
      </p>
    </div>
  </div>
</div>
