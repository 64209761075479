export class AddUserActivation {
  static readonly type = '[USERACTIVATION] User Activation'

  constructor(
    public activationCode: string,
    public deviceId: any,
  ) {}
}

export class GetDeviceListWithoutDb {
  static readonly type = '[HEADER] Get DeviceList WithoutDb'
}
