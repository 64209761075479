<kendo-dialog-titlebar (click)="closePopup()">
  <div>Setting</div>
</kendo-dialog-titlebar>

<div>
  <form [formGroup]="loadedSettingForm" class="k-form k-form-md">
    <div class="d-flex-row">
      <div
        class="d-flex-col-xl-6 d-flex-col-md-6 d-flex-col-sm-12 d-flex-col-xs-12 d-flex-col"
      >
        <p class="fw-500 mb-0">Voltages(V)</p>
        <div class="d-flex-row">
          <kendo-formfield
            class="d-flex-col-xl-4 d-flex-col-lg-4 d-flex-col-sm-4 d-flex-col-xs-12 d-flex-col mt-0 mb-20"
          >
            <kendo-label
              [for]="voltagesR"
              text="R"
              labelCssClass="k-form-label"
            ></kendo-label>
            <kendo-textbox
              formControlName="voltagesR"
              placeholder="Enter Value (0-9)"
              #voltagesR
              (keypress)="commonService.numberOnly($event)"
            ></kendo-textbox>
          </kendo-formfield>
          <kendo-formfield
            class="d-flex-col-xl-4 d-flex-col-lg-4 d-flex-col-sm-4 d-flex-col-xs-12 d-flex-col mt-0 mb-20"
          >
            <kendo-label
              [for]="voltagesY"
              text="Y"
              labelCssClass="k-form-label"
            ></kendo-label>
            <kendo-textbox
              formControlName="voltagesY"
              placeholder="Enter Value (0-9)"
              #voltagesY
              (keypress)="commonService.numberOnly($event)"
            ></kendo-textbox>
          </kendo-formfield>
          <kendo-formfield
            class="d-flex-col-xl-4 d-flex-col-lg-4 d-flex-col-sm-4 d-flex-col-xs-12 d-flex-col mt-0 mb-20"
          >
            <kendo-label
              [for]="voltagesB"
              text="B"
              labelCssClass="k-form-label"
            ></kendo-label>
            <kendo-textbox
              formControlName="voltagesB"
              placeholder="Enter Value (0-9)"
              #voltagesB
              (keypress)="commonService.numberOnly($event)"
            ></kendo-textbox>
          </kendo-formfield>
        </div>
      </div>

      <div
        class="d-flex-col-xl-6 d-flex-col-md-6 d-flex-col-sm-12 d-flex-col-xs-12 d-flex-col"
      >
        <div>
          <p class="fw-500 mb-0">Current(A)</p>
          <div class="d-flex-row">
            <kendo-formfield
              class="d-flex-col-lg-4 d-flex-col-sm-4 d-flex-col-xs-12 d-flex-col mt-0 mb-20"
            >
              <kendo-label
                [for]="currentR"
                text="R"
                labelCssClass="k-form-label"
              ></kendo-label>
              <kendo-textbox
                formControlName="currentR"
                placeholder="Enter Value (0-9)"
                #currentR
                (keypress)="commonService.numberOnly($event)"
              ></kendo-textbox>
            </kendo-formfield>
            <kendo-formfield
              class="d-flex-col-lg-4 d-flex-col-sm-4 d-flex-col-xs-12 d-flex-col mt-0 mb-20"
            >
              <kendo-label
                [for]="currentY"
                text="Y"
                labelCssClass="k-form-label"
              ></kendo-label>
              <kendo-textbox
                formControlName="currentY"
                placeholder="Enter Value (0-9)"
                #currentY
                (keypress)="commonService.numberOnly($event)"
              ></kendo-textbox>
            </kendo-formfield>
            <kendo-formfield
              class="d-flex-col-lg-4 d-flex-col-sm-4 d-flex-col-xs-12 d-flex-col mt-0 mb-20"
            >
              <kendo-label
                [for]="currentB"
                text="B"
                labelCssClass="k-form-label"
              ></kendo-label>
              <kendo-textbox
                formControlName="currentB"
                placeholder="Enter Value (0-9)"
                #currentB
                (keypress)="commonService.numberOnly($event)"
              ></kendo-textbox>
            </kendo-formfield>
          </div>
        </div>
      </div>
    </div>

    <div class="d-flex-row">
      <div
        class="d-flex-col-xl-6 d-flex-col-md-6 d-flex-col-sm-12 d-flex-col-xs-12 d-flex-col"
      >
        <p class="fw-500 mb-0">Gain</p>
        <div class="d-flex-row">
          <kendo-formfield
            class="d-flex-col-lg-4 d-flex-col-sm-4 d-flex-col-xs-12 d-flex-col mt-0 mb-20"
          >
            <kendo-label
              [for]="gainR"
              text="R"
              labelCssClass="k-form-label"
            ></kendo-label>
            <kendo-textbox
              formControlName="gainR"
              placeholder="Enter Value (0-9)"
              #gainR
              (keypress)="commonService.numberOnly($event)"
            ></kendo-textbox>
          </kendo-formfield>
          <kendo-formfield
            class="d-flex-col-lg-4 d-flex-col-sm-4 d-flex-col-xs-12 d-flex-col mt-0 mb-20"
          >
            <kendo-label
              [for]="gainY"
              text="Y"
              labelCssClass="k-form-label"
            ></kendo-label>
            <kendo-textbox
              formControlName="gainY"
              placeholder="Enter Value (0-9)"
              #gainY
              (keypress)="commonService.numberOnly($event)"
            ></kendo-textbox>
          </kendo-formfield>
          <kendo-formfield
            class="d-flex-col-lg-4 d-flex-col-sm-4 d-flex-col-xs-12 d-flex-col mt-0 mb-20"
          >
            <kendo-label
              [for]="gainB"
              text="B"
              labelCssClass="k-form-label"
            ></kendo-label>
            <kendo-textbox
              formControlName="gainB"
              placeholder="Enter Value (0-9)"
              #gainB
              (keypress)="commonService.numberOnly($event)"
            ></kendo-textbox>
          </kendo-formfield>
        </div>
      </div>
      <div
        class="d-flex-col-xl-6 d-flex-col-md-6 d-flex-col-sm-12 d-flex-col-xs-12 d-flex-col"
      >
        <p class="fw-500 mb-0">Angel 1</p>
        <div class="d-flex-row">
          <kendo-formfield
            class="d-flex-col-lg-4 d-flex-col-sm-4 d-flex-col-xs-12 d-flex-col mt-0 mb-20"
          >
            <kendo-label
              [for]="angel1R"
              text="R"
              labelCssClass="k-form-label"
            ></kendo-label>
            <kendo-textbox
              formControlName="angel1R"
              placeholder="Enter Value (0-9)"
              #angel1R
              (keypress)="commonService.numberOnly($event)"
            ></kendo-textbox>
          </kendo-formfield>
          <kendo-formfield
            class="d-flex-col-lg-4 d-flex-col-sm-4 d-flex-col-xs-12 d-flex-col mt-0 mb-20"
          >
            <kendo-label
              [for]="angel1Y"
              text="Y"
              labelCssClass="k-form-label"
            ></kendo-label>
            <kendo-textbox
              formControlName="angel1Y"
              placeholder="Enter Value (0-9)"
              #angel1Y
              (keypress)="commonService.numberOnly($event)"
            ></kendo-textbox>
          </kendo-formfield>
          <kendo-formfield
            class="d-flex-col-lg-4 d-flex-col-sm-4 d-flex-col-xs-12 d-flex-col mt-0 mb-20"
          >
            <kendo-label
              [for]="angel1B"
              text="B"
              labelCssClass="k-form-label"
            ></kendo-label>
            <kendo-textbox
              formControlName="angel1B"
              placeholder="Enter Value (0-9)"
              #angel1B
              (keypress)="commonService.numberOnly($event)"
            ></kendo-textbox>
          </kendo-formfield>
        </div>
      </div>
    </div>

    <div class="d-flex-row">
      <div
        class="d-flex-col-xl-6 d-flex-col-md-6 d-flex-col-sm-12 d-flex-col-xs-12 d-flex-col"
      >
        <p class="fw-500 mb-0">Angel 2</p>
        <div class="d-flex-row">
          <kendo-formfield
            class="d-flex-col-lg-4 d-flex-col-sm-4 d-flex-col-xs-12 d-flex-col mt-0 mb-20"
          >
            <kendo-label
              [for]="angel2R"
              text="R"
              labelCssClass="k-form-label"
            ></kendo-label>
            <kendo-textbox
              formControlName="angel2R"
              placeholder="Enter Value (0-9)"
              #angel2R
              (keypress)="commonService.numberOnly($event)"
            ></kendo-textbox>
          </kendo-formfield>
          <kendo-formfield
            class="d-flex-col-lg-4 d-flex-col-sm-4 d-flex-col-xs-12 d-flex-col mt-0 mb-20"
          >
            <kendo-label
              [for]="angel2Y"
              text="Y"
              labelCssClass="k-form-label"
            ></kendo-label>
            <kendo-textbox
              formControlName="angel2Y"
              placeholder="Enter Value (0-9)"
              #angel2Y
              (keypress)="commonService.numberOnly($event)"
            ></kendo-textbox>
          </kendo-formfield>
          <kendo-formfield
            class="d-flex-col-lg-4 d-flex-col-sm-4 d-flex-col-xs-12 d-flex-col mt-0 mb-20"
          >
            <kendo-label
              [for]="angel2B"
              text="B"
              labelCssClass="k-form-label"
            ></kendo-label>
            <kendo-textbox
              formControlName="angel2B"
              placeholder="Enter Value (0-9)"
              #angel2B
              (keypress)="commonService.numberOnly($event)"
            ></kendo-textbox>
          </kendo-formfield>
        </div>
      </div>
      <div
        class="d-flex-col-xl-6 d-flex-col-md-6 d-flex-col-sm-12 d-flex-col-xs-12 d-flex-col"
      >
        <p class="fw-500 mb-0">AWG</p>
        <div class="d-flex-row">
          <kendo-formfield
            class="d-flex-col-lg-4 d-flex-col-sm-4 d-flex-col-xs-12 d-flex-col mt-0 mb-20"
          >
            <kendo-label
              [for]="awgR"
              text="R"
              labelCssClass="k-form-label"
            ></kendo-label>
            <kendo-textbox
              formControlName="awgR"
              placeholder="Enter Value (0-9)"
              #awgR
              (keypress)="commonService.numberOnly($event)"
            ></kendo-textbox>
          </kendo-formfield>
          <kendo-formfield
            class="d-flex-col-lg-4 d-flex-col-sm-4 d-flex-col-xs-12 d-flex-col mt-0 mb-20"
          >
            <kendo-label
              [for]="awgY"
              text="Y"
              labelCssClass="k-form-label"
            ></kendo-label>
            <kendo-textbox
              formControlName="awgY"
              placeholder="Enter Value (0-9)"
              #awgY
              (keypress)="commonService.numberOnly($event)"
            ></kendo-textbox>
          </kendo-formfield>
          <kendo-formfield
            class="d-flex-col-lg-4 d-flex-col-sm-4 d-flex-col-xs-12 d-flex-col mt-0 mb-20"
          >
            <kendo-label
              [for]="awgB"
              text="B"
              labelCssClass="k-form-label"
            ></kendo-label>
            <kendo-textbox
              formControlName="awgB"
              placeholder="Enter Value (0-9)"
              #awgB
              (keypress)="commonService.numberOnly($event)"
            ></kendo-textbox>
          </kendo-formfield>
        </div>
      </div>
    </div>

    <div class="d-flex-row">
      <div
        class="d-flex-col-xl-6 d-flex-col-md-6 d-flex-col-sm-12 d-flex-col-xs-12 d-flex-col"
      >
        <p class="fw-500 mb-0">AVAG</p>
        <div class="d-flex-row">
          <kendo-formfield
            class="d-flex-col-lg-4 d-flex-col-sm-4 d-flex-col-xs-12 d-flex-col mt-0 mb-20"
          >
            <kendo-label
              [for]="avagR"
              text="R"
              labelCssClass="k-form-label"
            ></kendo-label>
            <kendo-textbox
              formControlName="avagR"
              placeholder="Enter Value (0-9)"
              #avagR
              (keypress)="commonService.numberOnly($event)"
            ></kendo-textbox>
          </kendo-formfield>
          <kendo-formfield
            class="d-flex-col-lg-4 d-flex-col-sm-4 d-flex-col-xs-12 d-flex-col mt-0 mb-20"
          >
            <kendo-label
              [for]="avagY"
              text="Y"
              labelCssClass="k-form-label"
            ></kendo-label>
            <kendo-textbox
              formControlName="avagY"
              placeholder="Enter Value (0-9)"
              #avagY
              (keypress)="commonService.numberOnly($event)"
            ></kendo-textbox>
          </kendo-formfield>
          <kendo-formfield
            class="d-flex-col-lg-4 d-flex-col-sm-4 d-flex-col-xs-12 d-flex-col mt-0 mb-20"
          >
            <kendo-label
              [for]="avagB"
              text="B"
              labelCssClass="k-form-label"
            ></kendo-label>
            <kendo-textbox
              formControlName="avagB"
              placeholder="Enter Value (0-9)"
              #avagB
              (keypress)="commonService.numberOnly($event)"
            ></kendo-textbox>
          </kendo-formfield>
        </div>
      </div>
      <div
        class="d-flex-col-xl-6 d-flex-col-md-6 d-flex-col-sm-12 d-flex-col-xs-12 d-flex-col"
      >
        <p class="fw-500 mb-0">AVARG</p>
        <div class="d-flex-row">
          <kendo-formfield
            class="d-flex-col-lg-4 d-flex-col-sm-4 d-flex-col-xs-12 d-flex-col mt-0 mb-20"
          >
            <kendo-label
              [for]="avargR"
              text="R"
              labelCssClass="k-form-label"
            ></kendo-label>
            <kendo-textbox
              formControlName="avargR"
              placeholder="Enter Value (0-9)"
              #avargR
              (keypress)="commonService.numberOnly($event)"
            ></kendo-textbox>
          </kendo-formfield>
          <kendo-formfield
            class="d-flex-col-lg-4 d-flex-col-sm-4 d-flex-col-xs-12 d-flex-col mt-0 mb-20"
          >
            <kendo-label
              [for]="avargY"
              text="Y"
              labelCssClass="k-form-label"
            ></kendo-label>
            <kendo-textbox
              formControlName="avargY"
              placeholder="Enter Value (0-9)"
              #avargY
              (keypress)="commonService.numberOnly($event)"
            ></kendo-textbox>
          </kendo-formfield>
          <kendo-formfield
            class="d-flex-col-lg-4 d-flex-col-sm-4 d-flex-col-xs-12 d-flex-col mt-0 mb-20"
          >
            <kendo-label
              [for]="avaegB"
              text="B"
              labelCssClass="k-form-label"
            ></kendo-label>
            <kendo-textbox
              formControlName="avaegB"
              placeholder="Enter Value (0-9)"
              #avaegB
              (keypress)="commonService.numberOnly($event)"
            ></kendo-textbox>
          </kendo-formfield>
        </div>
      </div>
    </div>
  </form>
</div>
<kendo-dialog-actions>
  <div class="d-flex gap-10 flex-wrap justify-content-end">
    <div
      [ngClass]="{
        'curser-not-allowd': currentIndex === loadedSettingArrayData.length - 1
      }"
      class="display-inline flex-0"
    >
      <button
        kendoButton
        fillMode="outline"
        [disabled]="currentIndex === loadedSettingArrayData.length - 1"
        (click)="previous()"
      >
        Previous
      </button>
    </div>
    <button kendoButton (click)="readFromDevice()">Read From Device</button>
    <button kendoButton (click)="saveChanges()">Save Changes</button>

    <div
      [ngClass]="{ 'curser-not-allowd': currentIndex === 0 }"
      class="display-inline flex-0"
    >
      <button
        kendoButton
        fillMode="outline"
        [disabled]="currentIndex === 0"
        (click)="next()"
      >
        Next
      </button>
    </div>
  </div>
</kendo-dialog-actions>
