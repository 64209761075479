<kendo-dialog-titlebar (close)="onCloseDialog()">
  <span>{{ statusName }}</span>
</kendo-dialog-titlebar>

<div class="grid-wrapper">
  <kendo-grid
    #grid
    [data]="gridView"
    [pageSize]="pageSize"
    [skip]="skip"
    [pageable]="
      gridView === undefined || !isPaginationAllowed
        ? false
        : {
            buttonCount: buttonCount,
            info: info,
            type: type,
            pageSizes: pageSizes,
            previousNext: previousNext,
            position: pagerposition
          }
    "
    [sortable]="isSortingAllowed"
    [sort]="sort"
    (sortChange)="onSortChange($event)"
    (pageChange)="onPaginationChange($event)"
    filterable="menu"
    [filter]="filter"
    (filterChange)="onFilterChangeSQL($event)"
    [height]="450"
  >
    <kendo-grid-column
      *ngFor="let column of columns"
      [field]="column.field"
      [title]="column.title"
      [width]="200"
      [maxResizableWidth]="80"
    >
      <ng-template
        kendoGridFilterMenuTemplate
        let-filter
        let-filterService="filterService"
      >
        <ng-container
          *ngIf="column.field == 'updatedRecord'; else defaultFilter"
        >
          <kendo-grid-date-filter-menu
            [column]="column"
            [filter]="filter"
            [filterService]="filterService"
            format="dd/MM/yyyy"
          >
            <kendo-filter-eq-operator></kendo-filter-eq-operator>
            <kendo-filter-neq-operator></kendo-filter-neq-operator>
            <kendo-filter-gt-operator></kendo-filter-gt-operator>
            <kendo-filter-gte-operator></kendo-filter-gte-operator>
            <kendo-filter-lt-operator></kendo-filter-lt-operator>
            <kendo-filter-lte-operator></kendo-filter-lte-operator>
          </kendo-grid-date-filter-menu>
        </ng-container>

        <ng-template #defaultFilter>
          <kendo-grid-string-filter-menu
            [column]="column"
            [filter]="filter"
            [filterService]="filterService"
          >
            <kendo-filter-startswith-operator></kendo-filter-startswith-operator>
            <kendo-filter-eq-operator></kendo-filter-eq-operator>
          </kendo-grid-string-filter-menu>
        </ng-template>
      </ng-template>

      <ng-template kendoGridCellTemplate let-dataItem>
        <ng-container
          *ngIf="column.field == 'location'; else updatedRecordTemplate"
        >
          <a
            class="wmx-100 clamp text-underline blue-text"
            [title]="dataItem[column.field]"
            (click)="onTrackLocation(dataItem)"
            >Track Location</a
          >
        </ng-container>

        <ng-template #updatedRecordTemplate>
          <ng-container
            *ngIf="column.field == 'updatedRecord'; else defaultTemplate"
          >
            {{ dataItem[column.field] | date: 'dd/MM/yyyy HH:mm:ss' }}
          </ng-container>
        </ng-template>

        <ng-template #defaultTemplate>
          {{ dataItem[column.field] }}
        </ng-template>
      </ng-template>
    </kendo-grid-column>
  </kendo-grid>
</div>

<kendo-dialog-actions></kendo-dialog-actions>
