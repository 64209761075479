import { Injectable } from '@angular/core'
import { Action, State, StateContext } from '@ngxs/store'
import { tap } from 'rxjs/operators'
import { DashboardService } from '@app/index'
import { Dashboard, GetUpdatedTime } from '.'

export class DashboardStateInfo {
  dashboardData?: any
  updatedTime?: any
}

@State<DashboardStateInfo>({
  name: 'dashboard',
  defaults: {},
})
@Injectable()
export class DashboardState {
  constructor(private dashboardService: DashboardService) {}

  @Action(Dashboard)
  DashboardData(
    { getState, patchState }: StateContext<DashboardStateInfo>,
    action: Dashboard,
  ) {
    const deviceId = action.deviceId
    return this.dashboardService.dashboardData(deviceId).pipe(
      tap((res) => {
        patchState({
          dashboardData: res,
        })
      }),
    )
  }

  @Action(GetUpdatedTime)
  getUpdatedTime(
    { getState, patchState }: StateContext<DashboardStateInfo>,
    action: GetUpdatedTime,
  ) {
    const deviceId = action.deviceId
    return this.dashboardService.getUpdatedTime(deviceId).pipe(
      tap((res) => {
        patchState({
          updatedTime: res,
        })
      }),
    )
  }
}
