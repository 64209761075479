import { Component, OnInit } from '@angular/core'
import { MenuList } from '@app/enums'
import { NotificationMessage } from '@app/enums/notification'
import { CommonService } from '@app/services'
import {
  ConfirmationFeederForSubstation,
  GetDeviceIdByFeeder,
  GetFeederAreaList,
  GetSubStationListWithFeeder,
  SaveSubStationFeeder,
} from '@app/store'
import { Store } from '@ngxs/store'
import { DialogRef } from '@progress/kendo-angular-dialog'
import { PageChangeEvent } from '@progress/kendo-angular-grid'
import { CompositeFilterDescriptor } from '@progress/kendo-data-query'
import { NgxSpinnerService } from 'ngx-spinner'
import { ToastrService } from 'ngx-toastr'
import { switchMap } from 'rxjs'
import swal from 'sweetalert'

@Component({
  selector: 'app-link-feeder-with-substation',
  templateUrl: './link-feeder-with-substation.component.html',
  styleUrl: './link-feeder-with-substation.component.scss',
})
export class LinkFeederWithSubstationComponent implements OnInit {
  substationId: any
  substationName: any
  feederList: any[] = []
  isSortingAllowed: boolean = true
  sort: any
  isAllSelected = false
  filterQuery: string | any
  public filter: CompositeFilterDescriptor = {
    logic: 'and',
    filters: [],
  }
  previousSort: any = null
  sortOrder = false
  isDataLoaded = false
  skip = 0
  pageNumber = 1
  pageSize = 10
  constructor(
    public dialogRef: DialogRef,
    public commonService: CommonService,
    private store: Store,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
  ) {}

  ngOnInit(): void {
    this.getFeederList(this.substationId)
  }

  onCloseDialog(): void {
    this.commonService.onDialogClose(this.dialogRef, false)
  }

  getFeederList(substationId: any): void {
    this.spinner.show()
    let queryParams = {
      moduleId: MenuList.Feeder,
      pageNumber: this.pageNumber,
      pageSize: 2147483647,
      sortBy: this.sort ?? null,
      sortOrder: this.sortOrder,
      filter: 0,
      search: null,
      startDate: null,
      endDate: null,
      filterQuery: this.filterQuery,
    }

    this.store
      .dispatch(new GetSubStationListWithFeeder(queryParams, substationId))
      .subscribe(
        (res) => {
          this.spinner.hide()
          if (res.substation.feederList) {
            const { data, totalCount } = res.substation.feederList
            this.feederList = data.rows

            this.isDataLoaded = true

            this.isAllSelected = this.feederList.every(
              (feeder) => feeder.isSelected === true,
            )
          }
        },
        (error) => {
          this.spinner.hide()
        },
      )
  }

  toggleSelectAll(event: any) {
    this.isAllSelected = event.target.checked
    this.feederList.forEach(
      (feeder) => (feeder.isSelected = this.isAllSelected),
    )
  }

  onCheckboxChange(dataItem: any, event: any) {
    dataItem.isSelected = event.target.checked
    this.isAllSelected = this.feederList.every((feeder) => feeder.isSelected)
  }

  onSortChange(sortData: any): void {
    const currentSortField = sortData[0].field
    if (this.previousSort === currentSortField) {
      this.sortOrder = !this.sortOrder
    } else {
      this.sortOrder = true
    }
    this.sort = currentSortField
    this.previousSort = currentSortField
    this.getFeederList(this.substationId)
  }

  onPageChange({ skip, take }: PageChangeEvent): void {
    this.skip = skip
    this.pageSize = take
    this.pageNumber = this.skip / this.pageSize + 1

    this.getFeederList(this.substationId)
  }

  filterChange(filter: CompositeFilterDescriptor): void {
    this.filter = filter
    this.filterQuery = this.toSQLExpression(this.filter)
    this.getFeederList(this.substationId)
  }

  checkConfirmation(): void {
    let selectedFeederIds = this.feederList
      .filter((feeder) => feeder.isSelected)
      .map((feeder) => feeder.feederId)

    if (selectedFeederIds.length === 0) {
      this.toastr.error(NotificationMessage.feederSelectErrorMsg)
      return
    }

    let feederIds = ''
    const totalIndex = selectedFeederIds.length
    selectedFeederIds.forEach((element, i) => {
      feederIds = feederIds + element + (i === totalIndex - 1 ? '' : ',')
    })

    const param = {
      feederIds: feederIds,
      substationId: this.substationId,
    }

    this.store.dispatch(new ConfirmationFeederForSubstation(param)).subscribe(
      (res) => {
        this.spinner.hide()

        const data = res.substation.confirmationMessageList
        const errorMessagelist: any[] = []

        if (data.length > 0) {
          data.forEach((element: any, i: any) => {
            errorMessagelist.push(element.message)
          })
          this.confirmFeederRemove(errorMessagelist)
        } else {
          this.onSubmit()
        }
      },
      (error) => {
        this.spinner.hide()
      },
    )
  }

  confirmFeederRemove(errorMessagelist: string[]) {
    let confirmationMessage = ''

    if (errorMessagelist.length) {
      errorMessagelist.forEach((error) => {
        confirmationMessage += ` ${error}\n`
      })
      confirmationMessage += `\n You want to change it.`
    }

    swal({
      title: 'Are you sure ?',
      text: confirmationMessage,
      icon: 'warning',
      buttons: {
        cancel: {
          text: 'Cancel',
          visible: true,
          closeModal: true,
        },
        confirm: {
          text: 'Yes',
        },
      },
      dangerMode: true,
    }).then((confirmed: any) => {
      if (confirmed) {
        this.onSubmit()
      }
    })
  }

  onSubmit() {
    let selectedFeederIds = this.feederList
      .filter((feeder) => feeder.isSelected)
      .map((feeder) => feeder.feederId)

    if (selectedFeederIds.length === 0) {
      this.toastr.error(NotificationMessage.feederSelectErrorMsg)
      return
    }

    let feederIds = ''
    const totalIndex = selectedFeederIds.length
    selectedFeederIds.forEach((element, i) => {
      feederIds = feederIds + element + (i === totalIndex - 1 ? '' : ',')
    })

    this.store
      .dispatch(new SaveSubStationFeeder(feederIds, this.substationId))
      .subscribe(
        (res) => {
          this.spinner.hide()
          this.store
            .dispatch(new GetFeederAreaList())
            .pipe(
              switchMap((res: any) =>
                this.store.dispatch(new GetDeviceIdByFeeder()),
              ),
            )
            .subscribe()
          this.toastr.success(NotificationMessage.subStationSuccessMsg)
          this.commonService.onDialogClose(this.dialogRef, true)
        },
        (error) => {
          this.spinner.hide()
        },
      )
  }

  toSQLExpression(filter: any) {
    if (!filter || !filter.filters || filter.filters.length === 0) {
      return null
    }

    const operators: any = {
      eq: '=',
      neq: '!=',
      lt: '<',
      lte: '<=',
      gt: '>',
      gte: '>=',
      startswith: 'LIKE',
      contains: 'LIKE',
      doesnotcontain: 'NOT LIKE',
      endswith: 'LIKE',
    }

    const isDate = (value: any): boolean => {
      const dateRegex =
        /^[A-Za-z]{3} [A-Za-z]{3} \d{2} \d{4} \d{2}:\d{2}:\d{2} GMT[+-]\d{4} \(.*\)$/
      return dateRegex.test(value)
    }

    const buildCondition = (condition: any) => {
      const { field, operator, value } = condition
      let sqlOperator = operators[operator]
      let formattedValue = value

      if (operator === 'startswith') {
        return `${field} ${sqlOperator} '${value}%'`
      } else if (operator === 'contains') {
        return `${field} ${sqlOperator} '%${value}%'`
      } else if (operator === 'doesnotcontain') {
        return `${field} ${sqlOperator} '%${value}%'`
      } else if (operator === 'endswith') {
        return `${field} ${sqlOperator} '%${value}'`
      } else {
        return `${field} ${sqlOperator} '${value}'`
      }
    }

    const buildSQL = (filter: any) => {
      if (filter.filters) {
        const expressions = filter.filters.map((f: any) => buildSQL(f))
        return `(${expressions.join(` ${filter.logic.toUpperCase()} `)})`
      } else {
        return buildCondition(filter)
      }
    }

    return buildSQL(filter)
  }
}
