import { Component, OnInit } from '@angular/core'
import { NotificationMessage } from '@app/enums/notification'
import { CommonService } from '@app/services'
import {
  CommonState,
  ConfirmationDeviceForFeeder,
  GetDeviceIdByFeeder,
  GetDeviceListByAccountId,
  GetFeederAreaList,
  GetFeederListWithDevice,
  SaveAccountDevice,
  SaveFeederDevice,
} from '@app/store'
import { Store } from '@ngxs/store'
import { DialogRef } from '@progress/kendo-angular-dialog'
import { PageChangeEvent } from '@progress/kendo-angular-grid'
import { CompositeFilterDescriptor } from '@progress/kendo-data-query'
import { NgxSpinnerService } from 'ngx-spinner'
import { ToastrService } from 'ngx-toastr'
import { switchMap } from 'rxjs'

@Component({
  selector: 'app-account-device-popup',
  templateUrl: './account-device-popup.component.html',
  styleUrl: './account-device-popup.component.scss',
})
export class AccountDevicePopupComponent implements OnInit {
  accountId: any
  accountName: string | any
  deviceList: any[] = []
  isSortingAllowed: boolean = true
  sort: any
  isAllSelected = false
  filterQuery: string | any
  public filter: CompositeFilterDescriptor = {
    logic: 'and',
    filters: [],
  }
  previousSort: any = null
  sortOrder = false
  isDataLoaded = false
  skip = 0
  pageNumber = 1
  pageSize = 10
  constructor(
    public dialogRef: DialogRef,
    public commonService: CommonService,
    private store: Store,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
  ) {}

  ngOnInit(): void {
    this.getDeviceList(this.accountId)
  }

  onCloseDialog(): void {
    this.commonService.onDialogClose(this.dialogRef, false)
  }

  getDeviceList(accountId: any): void {
    this.spinner.show()
    let queryParams = {
      accountId: accountId,
      moduleId: this.store.selectSnapshot(CommonState.moduleId),
      pageNumber: this.pageNumber,
      pageSize: 2147483647,
      sortBy: this.sort ?? null,
      sortOrder: this.sortOrder,
      filter: 0,
      search: null,
      startDate: null,
      endDate: null,
      filterQuery: this.filterQuery,
    }

    this.store.dispatch(new GetDeviceListByAccountId(queryParams)).subscribe(
      (res) => {
        this.spinner.hide()

        if (res.account.deviceListByAccout) {
          const { data, totalCount } = res.account.deviceListByAccout
          this.deviceList = res.account.deviceListByAccout

          this.isDataLoaded = true

          this.isAllSelected = this.deviceList.every(
            (device) => device.isAssigned === true,
          )
        }
      },
      (error) => {
        this.spinner.hide()
      },
    )
  }

  toggleSelectAll(event: any) {
    this.isAllSelected = event.target.checked
    this.deviceList.forEach(
      (device) => (device.isAssigned = this.isAllSelected),
    )
  }

  onCheckboxChange(dataItem: any, event: any) {
    dataItem.isAssigned = event.target.checked
    this.isAllSelected = this.deviceList.every((device) => device.isAssigned)
  }

  onSortChange(sortData: any): void {
    const currentSortField = sortData[0].field
    if (this.previousSort === currentSortField) {
      this.sortOrder = !this.sortOrder
    } else {
      this.sortOrder = true
    }
    this.sort = currentSortField
    this.previousSort = currentSortField
    this.getDeviceList(this.accountId)
  }

  onPageChange({ skip, take }: PageChangeEvent): void {
    this.skip = skip
    this.pageSize = take
    this.pageNumber = this.skip / this.pageSize + 1

    this.getDeviceList(this.accountId)
  }

  filterChange(filter: CompositeFilterDescriptor): void {
    this.filter = filter
    this.filterQuery = this.toSQLExpression(this.filter)
    this.getDeviceList(this.accountId)
  }

  onSubmit() {
    let selectedDeviceIds = this.deviceList
      .filter((device) => device.isAssigned)
      .map((device) => device.devices)

    const param = {
      deviceIds: selectedDeviceIds,
      userId: this.accountId,
    }

    this.store.dispatch(new SaveAccountDevice(param)).subscribe(
      (res) => {
        this.spinner.hide()

        this.store
          .dispatch(new GetFeederAreaList())
          .pipe(
            switchMap((res: any) =>
              this.store.dispatch(new GetDeviceIdByFeeder()),
            ),
          )
          .subscribe()
        this.toastr.success(NotificationMessage.deviceAssignToAccountSuccessMsg)
        this.commonService.onDialogClose(this.dialogRef, true)
      },
      (error) => {
        this.spinner.hide()
      },
    )
  }

  toSQLExpression(filter: any) {
    if (!filter || !filter.filters || filter.filters.length === 0) {
      return null
    }

    const operators: any = {
      eq: '=',
      neq: '!=',
      lt: '<',
      lte: '<=',
      gt: '>',
      gte: '>=',
      startswith: 'LIKE',
      contains: 'LIKE',
      doesnotcontain: 'NOT LIKE',
      endswith: 'LIKE',
    }

    const isDate = (value: any): boolean => {
      const dateRegex =
        /^[A-Za-z]{3} [A-Za-z]{3} \d{2} \d{4} \d{2}:\d{2}:\d{2} GMT[+-]\d{4} \(.*\)$/
      return dateRegex.test(value)
    }

    const buildCondition = (condition: any) => {
      const { field, operator, value } = condition
      let sqlOperator = operators[operator]
      let formattedValue = value

      if (operator === 'startswith') {
        return `${field} ${sqlOperator} '${value}%'`
      } else if (operator === 'contains') {
        return `${field} ${sqlOperator} '%${value}%'`
      } else if (operator === 'doesnotcontain') {
        return `${field} ${sqlOperator} '%${value}%'`
      } else if (operator === 'endswith') {
        return `${field} ${sqlOperator} '%${value}'`
      } else {
        return `${field} ${sqlOperator} '${value}'`
      }
    }

    const buildSQL = (filter: any) => {
      if (filter.filters) {
        const expressions = filter.filters.map((f: any) => buildSQL(f))
        return `(${expressions.join(` ${filter.logic.toUpperCase()} `)})`
      } else {
        return buildCondition(filter)
      }
    }

    return buildSQL(filter)
  }
}
