import { Component, EventEmitter, Input, Output } from '@angular/core'
import { DefaultValueDropdown, SeparateDateEnum } from '@app/enums'

@Component({
  selector: 'app-common-separate-date-picker-header',
  templateUrl: './common-separate-date-picker-header.component.html',
  styleUrl: './common-separate-date-picker-header.component.scss',
})
export class CommonSeparateDatePickerHeaderComponent {
  @Output() onAnalyticsFilterChange = new EventEmitter<any>()
  @Output() onAnalyticsSelectYearFilterChange = new EventEmitter<any>()

  years: { year: number | string }[] = []
  dates: { date: number | string }[] = []
  months: { month: string; monthNumeric: string }[] = [
    { month: 'January', monthNumeric: '1' },
    { month: 'February', monthNumeric: '2' },
    { month: 'March', monthNumeric: '3' },
    { month: 'April', monthNumeric: '4' },
    { month: 'May', monthNumeric: '5' },
    { month: 'June', monthNumeric: '6' },
    { month: 'July', monthNumeric: '7' },
    { month: 'August', monthNumeric: '8' },
    { month: 'September', monthNumeric: '9' },
    { month: 'October', monthNumeric: '10' },
    { month: 'November', monthNumeric: '11' },
    { month: 'December', monthNumeric: '12' },
  ]
  defaultYear: { year: string } = { year: DefaultValueDropdown['Select Year'] }
  defaultMonth: { month: string; monthNumeric: any } = {
    month: DefaultValueDropdown['Select Month'],
    monthNumeric: null,
  }
  defaultDate: { date: string } = { date: DefaultValueDropdown['Select Day'] }
  selectedYear: any
  selectedMonth: any
  selectedDate: any

  showYearError = false
  showMonthError = false
  showDateError = false

  constructor() {
    this.populateYears()
    this.populateDates()
  }

  private populateYears(): void {
    const currentYear = new Date().getFullYear()
    for (let year = currentYear; year >= 2000; year--) {
      this.years.push({ year })
    }
  }

  private populateDates(): void {
    for (let date = 1; date <= 31; date++) {
      this.dates.push({ date })
    }
  }

  onYearChange(event: any) {
    this.showMonthError = false
    this.showYearError = false
    if (event.year === DefaultValueDropdown['Select Year']) {
      this.onAnalyticsSelectYearFilterChange.emit()
      this.selectedYear = undefined
      return
    }
    let type = SeparateDateEnum.YEAR
    if (event.year !== DefaultValueDropdown.Year) {
      this.selectedYear = event.year
      type = SeparateDateEnum.MONTHS
    } else {
      this.selectedYear = undefined
      type = SeparateDateEnum.YEAR
    }

    if (
      this.selectedDate?.date !== 'Select Day' ||
      this.selectedMonth?.monthNumeric !== null
    ) {
      if (
        this.selectedMonth !== null &&
        this.selectedMonth !== undefined &&
        this.selectedDate !== null &&
        this.selectedDate !== undefined &&
        this.selectedDate.date !== 'Select Day'
      ) {
        type = SeparateDateEnum.DAY
      } else if (
        this.selectedMonth !== null &&
        this.selectedMonth !== undefined &&
        this.selectedMonth.month !== 'Select Month' &&
        (this.selectedDate === null ||
          this.selectedDate === undefined ||
          this.selectedDate.date === 'Select Day')
      ) {
        type = SeparateDateEnum.DAYS
      }
    }

    const data = {
      year: this.selectedYear ?? undefined,
      month:
        this.selectedYear !== null && this.selectedYear !== undefined
          ? this.selectedMonth !== null &&
            this.selectedMonth !== undefined &&
            this.selectedMonth?.monthNumeric !== null &&
            this.selectedMonth?.monthNumeric !== undefined
            ? +this.selectedMonth?.monthNumeric
            : undefined
          : undefined,
      day:
        this.selectedYear !== null && this.selectedYear !== undefined
          ? this.selectedDate?.date !== 'Select Day'
            ? this.selectedDate?.date
            : undefined
          : undefined,
      type: type,
    }
    this.onAnalyticsFilterChange.emit(data)
  }

  onMonthChange(event: any) {
    this.showMonthError = false
    this.showYearError =
      !this.selectedYear ||
      this.selectedYear.year === DefaultValueDropdown['Select Year'] ||
      this.selectedYear.year === DefaultValueDropdown.Year

    if (this.showYearError) {
      return
    }
    let type = SeparateDateEnum.DAYS
    if (event.month === DefaultValueDropdown['Select Month']) {
      this.selectedMonth = undefined
      type = SeparateDateEnum.MONTHS
    } else {
      this.selectedMonth = event.monthNumeric
      if (
        this.selectedDate !== null &&
        this.selectedDate !== undefined &&
        this.selectedDate?.date !== 'Select Day'
      ) {
        type = SeparateDateEnum.DAY
      }
    }

    const data = {
      year: this.selectedYear ? this.selectedYear.year : 0,
      month: this.selectedMonth ? +this.selectedMonth : undefined,
      day:
        this.selectedMonth !== null && this.selectedMonth !== undefined
          ? this.selectedDate?.date !== 'Select Day'
            ? this.selectedDate?.date
            : undefined
          : undefined,
      type: type,
    }
    this.onAnalyticsFilterChange.emit(data)
  }

  onDateChange(event: any) {
    this.showMonthError =
      !this.selectedMonth || this.selectedMonth.monthNumeric === null
    this.showYearError =
      !this.selectedYear ||
      this.selectedYear.year === DefaultValueDropdown['Select Year'] ||
      this.selectedYear.year === DefaultValueDropdown.Year

    if (this.showMonthError || this.showYearError) {
      return
    }
    let type = SeparateDateEnum.DAY
    if (event.date === DefaultValueDropdown['Select Day']) {
      this.selectedDate = undefined
      type = SeparateDateEnum.DAYS
    } else {
      this.selectedDate = event.date
    }

    const data = {
      year: this.selectedYear ? this.selectedYear.year : 0,
      month: this.selectedMonth ? +this.selectedMonth.monthNumeric : 0,
      day: this.selectedDate ?? undefined,
      type: type,
    }
    this.onAnalyticsFilterChange.emit(data)
  }

  validateSelections() {
    this.showYearError = !this.selectedYear
    this.showMonthError = !this.selectedMonth

    if (!this.selectedYear && !this.selectedMonth && !this.selectedDate) {
      this.showMonthError = false
      this.showYearError = false
      return
    }

    if (
      this.selectedDate?.date === DefaultValueDropdown.Day &&
      this.selectedMonth?.month === DefaultValueDropdown.Month &&
      !this.selectedYear
    ) {
      this.showYearError = false
      return
    }

    if (
      (!this.selectedMonth ||
        this.selectedMonth?.month === DefaultValueDropdown.Month) &&
      (this.showYearError || this.selectedDate)
    ) {
      this.showMonthError = true
    } else {
      this.showMonthError = false
    }
  }
}
