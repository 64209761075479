export class GetSoftwareStatus {
  static readonly type = '[SOFTWARE] Get Software Status'
  constructor(public queryParams: any) {}
}

export class SaveSoftware {
  static readonly type = '[SOFTWARE] SaveSoftware'

  constructor(public data: any) {}
}

export class SoftwareUpload {
  static readonly type = '[SOFTWARE] Software Upload'
  constructor(
    public deviceId: any,
    public file: any,
  ) {}
}
