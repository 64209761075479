export enum SeparateDateEnum {
  YEAR = 0,
  MONTHS = 1,
  DAYS = 2,
  DAY = 3,
  ALL = 4,
}

export enum DefaultValueDropdown {
  'Select Year' = 'Select Year',
  'Select Month' = 'Select Month',
  'Select Day' = 'Select Day',
  Year = 'Year',
  Month = 'Month',
  Day = 'Day',
}
