import { Injectable } from '@angular/core'
import { MeterDataService } from '@app/index'
import { Action, Selector, State, StateContext } from '@ngxs/store'
import { tap } from 'rxjs'
import { GetMeterDataList } from './meterdata.action'

export class MeterDataStateInfo {
  meterDataList?: any
  columns?: any
}

@State<MeterDataStateInfo>({
  name: 'meterData',
  defaults: {},
})
@Injectable()
export class MeterDataState {
  constructor(private meterDataService: MeterDataService) {}

  @Selector()
  static getMeterData(state: MeterDataStateInfo) {
    return state.meterDataList
  }

  @Action(GetMeterDataList)
  getMeterDataList(
    { getState, patchState }: StateContext<MeterDataStateInfo>,
    action: GetMeterDataList,
  ) {
    return this.meterDataService.getMeterDataList(action.queryParam).pipe(
      tap((res: any) => {
        patchState({
          meterDataList: res,
        })
      }),
    )
  }
}
