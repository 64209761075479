import { Injectable } from '@angular/core'
import { Selector, Action, StateContext, State } from '@ngxs/store'
import { tap } from 'rxjs'
import { GetAllSystemBattery } from './system-battery.action'
import { SystemBatteryService } from '@app/services/system-battery'

export class SystemBatteryStateInfo {
  systemBatteryData?: any
}

@State<SystemBatteryStateInfo>({
  name: 'systemBattery',
  defaults: {},
})
@Injectable()
export class SystemBatteryState {
  constructor(private systemBatteryService: SystemBatteryService) {}

  @Selector()
  static getAllSystemBattery(state: SystemBatteryStateInfo) {
    return state.systemBatteryData
  }

  @Action(GetAllSystemBattery)
  getAllSystemBattery(
    { getState, patchState }: StateContext<SystemBatteryStateInfo>,
    action: any,
  ) {
    return this.systemBatteryService
      .getAllSystemBattery(action.queryParams)
      .pipe(
        tap((res: any) => {
          patchState({
            systemBatteryData: res.body,
          })
        }),
      )
  }
}
