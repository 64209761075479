import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { AuthGuard } from '@app/index'
import { OfficeSettingListComponent } from './office-setting-list/office-setting-list.component'
import { ChangesComponent } from './changes/changes.component'
import { ResetDeviceComponent } from './reset-device/reset-device.component'

const routes: Routes = [
  { path: '', component: OfficeSettingListComponent, canActivate: [AuthGuard] },
  { path: 'changes', component: ChangesComponent, canActivate: [AuthGuard] },
  {
    path: 'reset-device',
    component: ResetDeviceComponent,
    canActivate: [AuthGuard],
  },
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class OfficeSettingRoutingModule {}
