<div class="d-flex gap-20 flex-wrap justify-content-end mb-20">
  <app-common-header
    (triggerDataFromHeader)="getDataByHeaderData($event)"
  ></app-common-header>
  <div class="q-content">
    <div class="q-content-bg grid-wrapper">
      <app-common-grid
        [gridView]="gridView"
        [columns]="gridView?.columns ?? []"
        (sortChange)="onSortChange($event)"
        (paginationChange)="onPaginationChange($event)"
      ></app-common-grid>
    </div>
  </div>
</div>
