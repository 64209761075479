import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import {
  AccountListComponent,
  AuthGuard,
  LoginComponent,
  PasswordChangeComponent,
  RegistrationComponent,
} from '@app/index'

const routes: Routes = [
  { path: '', component: AccountListComponent, canActivate: [AuthGuard] },
  { path: 'create-account', component: RegistrationComponent },
  { path: 'login', component: LoginComponent },
  {
    path: 'change-password',
    component: PasswordChangeComponent,
    canActivate: [AuthGuard],
  },
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AccountRoutingModule {}
