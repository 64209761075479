import { Injectable } from '@angular/core'
import { DataReportList, DataReportService } from '@app/index'
import { Action, Selector, State, StateContext } from '@ngxs/store'
import { tap } from 'rxjs'

export class DataReportStateInfo {
  dataReportListData?: any
  columns?: any
}

@State<DataReportStateInfo>({
  name: 'dataReport',
  defaults: {},
})
@Injectable()
export class DataReportState {
  constructor(private dataReportService: DataReportService) {}

  @Selector()
  static getDataReportData(state: DataReportStateInfo) {
    return state.dataReportListData
  }

  @Action(DataReportList)
  getDataReporttList(
    { getState, patchState }: StateContext<DataReportStateInfo>,
    action: DataReportList,
  ) {
    return this.dataReportService.getDataReportList(action.queryParam).pipe(
      tap((res: any) => {
        patchState({
          dataReportListData: res,
        })
      }),
    )
  }
}
