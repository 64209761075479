<div class="q-content-bg grid-wrapper">
  <app-common-grid
    [gridView]="gridView"
    [columns]="columns"
    [pageSize]="this.pageSize"
    (sortChange)="onSortChange($event)"
    (filterChangeSQL)="OnFilterChange($event)"
    (paginationChange)="onPaginationChange($event)"
  ></app-common-grid>
</div>
