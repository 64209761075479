import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { MapModule } from '@progress/kendo-angular-map'
import {
  CommonsModule,
  GisInfoComponent,
  GisInfoRoutingModule,
} from '@app/index'
import { SharedModule } from 'src/shared/shared.module'
import { GoogleMapsModule } from '@angular/google-maps'
@NgModule({
  declarations: [GisInfoComponent],
  imports: [
    CommonModule,
    GisInfoRoutingModule,
    MapModule,
    CommonsModule,
    SharedModule,
    GoogleMapsModule,
  ],
  providers: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class GisInfoModule {}
