import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { SharedModule } from 'src/shared/shared.module'
import {
  AddSubstationComponent,
  CommonsModule,
  LinkFeederWithSubstationComponent,
  SubstationComponent,
  SubstationRoutingModule,
} from '@app/index'

@NgModule({
  declarations: [
    SubstationComponent,
    AddSubstationComponent,
    LinkFeederWithSubstationComponent,
  ],
  imports: [CommonModule, SubstationRoutingModule, SharedModule, CommonsModule],
  providers: [],
})
export class SubstationModule {}
