export class GetAlertOverload {
  static readonly type = '[OVERLOAD] Get Alert Overload'

  constructor(public qureyParams: any) {}
}

export class GetAlertOverLoadCount {
  static readonly type = '[OVERLOAD] Get overload card Data'

  constructor(
    public deviceId: string,
    public percenatge: number,
  ) {}
}
