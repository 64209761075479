import { Component, EventEmitter, Input, Output } from '@angular/core'

@Component({
  selector: 'app-common-setting-grid',
  templateUrl: './common-setting-grid.component.html',
  styleUrl: './common-setting-grid.component.scss',
})
export class CommonSettingGridComponent {
  @Input() gridView: any
  @Input() columns: any
  @Input() pageSize = 10

  @Output() sortChange = new EventEmitter<any>()
  @Output() paginationChange = new EventEmitter<any>()
  @Output() filterChangeSQL = new EventEmitter<any>()

  onSortChange(event: any) {
    this.sortChange.emit(event)
  }

  onPaginationChange(event: any) {
    this.paginationChange.emit(event)
  }

  OnFilterChange(event: any): void {
    this.filterChangeSQL.emit(event)
  }
}
