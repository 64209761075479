import { Injectable } from '@angular/core'
import { Selector, Action, StateContext, State } from '@ngxs/store'
import { tap } from 'rxjs'
import { GetAlertOverLoadCount, GetAlertOverload } from '.'
import { OverloadService } from '@app/services'

export class OverloadStateInfo {
  overloadAlertData?: any
  overloadCardData?: any
}

@State<OverloadStateInfo>({
  name: 'overload',
  defaults: {},
})
@Injectable()
export class OverloadState {
  constructor(private overloadService: OverloadService) {}

  @Selector()
  static getOverLoadAlertData(state: OverloadStateInfo) {
    return state.overloadAlertData
  }

  @Selector()
  static getOverloadCardData(state: OverloadStateInfo) {
    return state.overloadCardData
  }

  @Action(GetAlertOverload)
  getAlertOverload(
    { getState, patchState }: StateContext<OverloadStateInfo>,
    action: any,
  ) {
    return this.overloadService.getAlertOverload(action.qureyParams).pipe(
      tap((res: any) => {
        patchState({
          overloadAlertData: res.body,
        })
      }),
    )
  }

  @Action(GetAlertOverLoadCount)
  getAlertOverLoadCount(
    { getState, patchState }: StateContext<OverloadStateInfo>,
    action: any,
  ) {
    return this.overloadService
      .getAlertOverLoadCount(action.deviceId, action.percenatge)
      .pipe(
        tap((res: any) => {
          patchState({
            overloadCardData: res,
          })
        }),
      )
  }
}
