export class GetOfficeUseChangeData {
  static readonly type = '[OFFICEUSE] Get Office Use Change Data'
}

export class SaveOfficeUseChange {
  static readonly type = '[OFFICEUSE] Save Office Use Change'

  constructor(public data: any) {}
}

export class UpdateDeviceIdData {
  static readonly type = '[OFFICEUSE] Update device Id data'

  constructor(
    public currentData: any,
    public deviceId: string,
  ) {}
}

export class UpdateEnergyData {
  static readonly type = '[OFFICEUSE] Update energy data'

  constructor(
    public currentData: any,
    public deviceId: string,
  ) {}
}

export class UpdateUnbalancedLoadDataSubmit {
  static readonly type = '[OFFICEUSE] Update Unbalanced Load data'

  constructor(
    public currentData: any,
    public deviceId: string,
  ) {}
}

export class UpdateWifiDataSubmit {
  static readonly type = '[OFFICEUSE] Update Wifi data'

  constructor(
    public currentData: any,
    public deviceId: string,
  ) {}
}

export class UpdateAngleData {
  static readonly type = '[OFFICEUSE] Update Angle Below Data '

  constructor(
    public currentData: any,
    public deviceId: string,
  ) {}
}

export class UpdateDeviceSerialIdData {
  static readonly type = '[OFFICEUSE] Updat eDevice Serial Id Data '

  constructor(
    public currentData: any,
    public deviceId: string,
  ) {}
}

export class ReadIoTSettings {
  static readonly type = '[OFFICEUSE] Read IoT Settings  Data in Excel'
  constructor(public deviceId: string) {}
}
