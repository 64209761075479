import { Injectable } from '@angular/core'
import {
  CheckSubStationNameExist,
  ConfirmationFeederForSubstation,
  CreateSubstation,
  DeleteSubstation,
  GetSubStationListWithFeeder,
  GetSubstationList,
  SaveSubStationFeeder,
  SubstationService,
} from '@app/index'
import { Action, State, StateContext } from '@ngxs/store'
import { tap } from 'rxjs'
export class SubstationStateInfo {}

@State<SubstationStateInfo>({
  name: 'substation',
  defaults: {},
})
@Injectable()
export class SubstationState {
  feederList?: any
  confirmationMessageList?: any
  substationList?: any
  isSubstationSuccess: boolean = true
  isSubstationDeleted: boolean = true
  isSubstationExists?: boolean

  constructor(private substationService: SubstationService) {}

  @Action(CreateSubstation)
  createSubstation(
    { getState, patchState }: StateContext<SubstationStateInfo>,
    action: CreateSubstation,
  ) {
    return this.substationService.createSubstation(action.param).pipe(
      tap((res: any) => {
        patchState({
          isSubstationSuccess: res,
        })
      }),
    )
  }

  @Action(GetSubstationList)
  getSubstationList(
    { getState, patchState }: StateContext<SubstationStateInfo>,
    action: any,
  ) {
    return this.substationService.getSubstationList(action.queryParam).pipe(
      tap((res: any) => {
        patchState({
          substationList: res,
        })
      }),
    )
  }

  @Action(DeleteSubstation)
  deleteSubstation(
    { getState, patchState }: StateContext<SubstationStateInfo>,
    action: DeleteSubstation,
  ) {
    return this.substationService.deleteSubstation(action.substationId).pipe(
      tap((res: any) => {
        patchState({
          isSubstationDeleted: res,
        })
      }),
    )
  }

  @Action(CheckSubStationNameExist)
  checkSubStationNameExist(
    { patchState }: StateContext<SubstationStateInfo>,
    action: CheckSubStationNameExist,
  ) {
    return this.substationService
      .checkSubStationNameExist(action.subStationId, action.substationName)
      .pipe(
        tap((res: boolean) => {
          patchState({
            isSubstationExists: res,
          })
        }),
      )
  }

  @Action(GetSubStationListWithFeeder)
  getSubStationListWithFeeder(
    { getState, patchState }: StateContext<SubstationStateInfo>,
    action: GetSubStationListWithFeeder,
  ) {
    return this.substationService
      .getSubStationListWithFeeder(action.queryParams, action.substationId)
      .pipe(
        tap((res) => {
          patchState({
            feederList: res,
          })
        }),
      )
  }

  @Action(SaveSubStationFeeder)
  saveSubStationFeeder(
    { getState, patchState }: StateContext<SubstationStateInfo>,
    action: SaveSubStationFeeder,
  ) {
    return this.substationService.saveFeederDevice(
      action.feederIds,
      action.subStationId,
    )
  }

  @Action(ConfirmationFeederForSubstation)
  confirmationFeederForSubstation(
    { getState, patchState }: StateContext<SubstationStateInfo>,
    action: ConfirmationFeederForSubstation,
  ) {
    return this.substationService
      .confirmationFeederForSubstation(action.requestBody)
      .pipe(
        tap((res) => {
          patchState({
            confirmationMessageList: res,
          })
        }),
      )
  }
}
