import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { environment } from 'src/environments/environment'

@Injectable()
export class DtrpredictionService {
  constructor(private http: HttpClient) {}

  GetItmsDetails(deviceId: string | undefined): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    }

    return this.http.post<any>(
      `${environment.apiVersionUrl}DtrPrediction/GetItmsDetails?device=${deviceId}`,
      headers,
    )
  }

  getGraphDetails(deviceId: any): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    }

    return this.http.post<any>(
      `${environment.apiVersionUrl}DtrPrediction/GetGraphDetails?deviceId=${deviceId}`,
      headers,
    )
  }
}
