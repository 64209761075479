<div class="q-content">
  <div
    class="d-flex gap-15 flex-wrap mb-20"
    [ngClass]="{
      'justify-content-between': showSearch,
      'justify-content-end': !showSearch
    }"
  >
    <kendo-formfield *ngIf="showSearch">
      <kendo-textbox
        [(ngModel)]="search"
        placeholder="Search"
        (ngModelChange)="onSearch()"
      ></kendo-textbox>
    </kendo-formfield>
    <div class="d-flex gap-15 flex-wrap">
      <app-common-header
        (triggerDataFromHeader)="getDataByHeaderData($event)"
        [showDateFilter]="showDateFilter"
      ></app-common-header>
      <kendo-dropdownlist
        [data]="officeTypeList | useEnumValues"
        textField="value"
        valueField="key"
        placeholder="value"
        [(ngModel)]="selectedOfficeTypeList"
        [ngModelOptions]="{ standalone: true }"
        [value]="selectedOfficeTypeList"
        [valuePrimitive]="true"
        (valueChange)="onChangeOfficeType()"
        class="w-200"
      ></kendo-dropdownlist>
      <div class="info-button-container">
        <i
          class="far fa-circle-info font-size-18 cursor-pointer"
          title="{{ infoTitle }}"
        ></i>
      </div>
      <div class="info-button-container" *ngIf="showSetting">
        <i
          class="far fa-cog font-size-18 cursor-pointer"
          title="{{ infoTitle }}"
          (click)="onSettingClick()"
        ></i>
      </div>
      <div class="drag-icon-container" (click)="onColumnSelector()">
        <a class="drag-icon">
          <img src="assets/images/custom-columns.svg" alt="column-select" />
        </a>
      </div>
    </div>
  </div>
  <div class="q-content-bg grid-wrapper">
    <app-common-grid
      [gridView]="gridView"
      [columns]="gridView?.columns ?? []"
      [pageSize]="this.pageSize"
      [selectedOfficeTypeList]="selectedOfficeTypeList"
      [actions]="isReviewSettingSelected ? actions : []"
      [showActions]="isReviewSettingSelected"
      (sortChange)="onSortChange($event)"
      (filterChangeSQL)="onFilterChangeSQL($event)"
      (paginationChange)="onPaginationChange($event)"
      (triggerActionEvent)="onActionClick($event)"
      (triggerDataFromColumnSelector)="getAllReportsDataFromOfficeType()"
      id="data-report"
    ></app-common-grid>
  </div>
</div>
<div kendoDialogContainer></div>
