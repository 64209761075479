import { SettingHistoryTypeModel } from '@app/models'

export class GetSettingHistoryTypeList {
  static readonly type = '[HISTORY SETTING] Setting History Type List'

  constructor(
    public settingHistoryType: SettingHistoryTypeModel,
    public settingHistoryRequestBody: any,
  ) {}
}
