<form [formGroup]="pfSettingForm" class="k-form k-form-md">
  <div class="mb-20">
    <div class="d-flex gap-15">
      <kendo-label
        [for]="pf"
        text="PF"
        labelCssClass="k-form-label"
        class="w-100 d-flex align-items-center"
      >
        <span class="required">*</span>
      </kendo-label>
      <kendo-textbox
        formControlName="pf"
        #pf
        placeholder="Enter PF 0.1 to 0.99"
        (keypress)="commonService.numberOnly($event)"
      ></kendo-textbox>
    </div>
    <div
      *ngIf="
        pfSettingForm.get('pf')?.invalid && pfSettingForm.get('pf')?.touched
      "
      class="d-flex gap-15"
    >
      <div class="w-80"></div>
      <div>
        <p *ngIf="pfSettingForm.get('pf')?.errors?.required" class="required">
          PF is required.
        </p>
      </div>
    </div>
  </div>
</form>
