<form [formGroup]="unityPfForm" class="k-form k-form-md mb-20">
  <div class="d-flex gap-20">
    <div>
      <kendo-label [for]="enable" text="Enable" class="mr-5"></kendo-label>
      <input
        type="radio"
        name="unity"
        value="1"
        #enable
        formControlName="unity"
        kendoRadioButton
      />
    </div>
    <div>
      <kendo-label [for]="disable" text="Disable" class="mr-5"></kendo-label>
      <input
        type="radio"
        name="unity"
        value="0"
        #disable
        formControlName="unity"
        kendoRadioButton
      />
    </div>
  </div>
</form>
