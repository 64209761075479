<div class="d-flex gap-15 flex-wrap">
  <kendo-formfield>
    <kendo-dropdownlist
      [data]="years"
      [(ngModel)]="selectedYear"
      (selectionChange)="onYearChange($event)"
      [ngModelOptions]="{ standalone: true }"
      textField="year"
      valueField="year"
      [defaultItem]="defaultYear"
      [ngClass]="{ 'invalid-dropdown': showYearError }"
      class="w-150"
    ></kendo-dropdownlist>
  </kendo-formfield>

  <kendo-formfield>
    <kendo-dropdownlist
      [data]="months"
      [(ngModel)]="selectedMonth"
      (selectionChange)="onMonthChange($event)"
      [ngModelOptions]="{ standalone: true }"
      textField="month"
      valueField="monthNumeric"
      [defaultItem]="defaultMonth"
      [ngClass]="{ 'invalid-dropdown': showMonthError }"
      class="w-150"
    ></kendo-dropdownlist>
  </kendo-formfield>

  <kendo-formfield>
    <kendo-dropdownlist
      [data]="dates"
      [(ngModel)]="selectedDate"
      (selectionChange)="onDateChange($event)"
      [ngModelOptions]="{ standalone: true }"
      textField="date"
      valueField="date"
      [defaultItem]="defaultDate"
      class="w-150"
    ></kendo-dropdownlist>
  </kendo-formfield>
</div>
