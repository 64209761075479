import { Injectable } from '@angular/core'
import { Selector, Action, StateContext, State } from '@ngxs/store'
import { tap } from 'rxjs/operators'
import {
  GetAllThresholdSettings,
  GetThresholdSettingsData,
  UpdateCtThreshold,
  UpdateCurrentThreshold,
  UpdateKVAThreshold,
  UpdateMinMaxThresholdVoltage,
  UpdatePFThreshold,
  UpdateTemperatureThreshold,
  UpdateUnityPFThreshold,
} from '.'
import { ThresholdSettingsService } from '@app/services/threshold-settings'

export class ThresholdSettingsStateInfo {
  thresholdSettingsData?: any
  isSuccess?: boolean
  id?: any
  isMinMaxVolatgeUpdated?: boolean
  isMaxCurrentUpdated?: boolean
  isCtUpdated?: boolean
  isTemperatureUpdated?: boolean
  isPfUpdated?: boolean
  isUnityPfUpdated?: boolean
  isKVAUpdated?: boolean
}

@State<ThresholdSettingsStateInfo>({
  name: 'thresholdSettings',
  defaults: {
    isSuccess: false,
  },
})
@Injectable()
export class ThresholdSettingsState {
  constructor(private thresholdSettingsService: ThresholdSettingsService) {}

  @Selector()
  static getAlertSettingData(state: ThresholdSettingsStateInfo) {
    return state.thresholdSettingsData
  }

  @Action(GetThresholdSettingsData)
  getThresholdSettingsData(
    { getState, patchState }: StateContext<ThresholdSettingsStateInfo>,
    action: any,
  ) {
    return this.thresholdSettingsService.getThresholdSettingsData().pipe(
      tap((res: any) => {
        patchState({
          thresholdSettingsData: res,
        })
      }),
    )
  }

  @Action(GetAllThresholdSettings)
  getAllThresholdSettingsData(
    { getState, patchState }: StateContext<ThresholdSettingsStateInfo>,
    action: GetAllThresholdSettings,
  ) {
    return this.thresholdSettingsService
      .getAllThresholdSettingsData(action.deviceId)
      .pipe(
        tap((res: any) => {
          patchState({
            thresholdSettingsData: res,
          })
        }),
      )
  }

  @Action(UpdateMinMaxThresholdVoltage)
  updateMinMaxThresholdVoltage(
    { getState, patchState }: StateContext<ThresholdSettingsStateInfo>,
    action: UpdateMinMaxThresholdVoltage,
  ) {
    return this.thresholdSettingsService
      .updateMinMaxThresholdVoltage(action.voltageData)
      .pipe(
        tap((res: any) => {
          patchState({
            isMinMaxVolatgeUpdated: res,
          })
        }),
      )
  }

  @Action(UpdateCurrentThreshold)
  updateCurrentThreshold(
    { getState, patchState }: StateContext<ThresholdSettingsStateInfo>,
    action: UpdateCurrentThreshold,
  ) {
    return this.thresholdSettingsService
      .updateCurrentThreshold(action.currentData)
      .pipe(
        tap((res: any) => {
          patchState({
            isMaxCurrentUpdated: res,
          })
        }),
      )
  }

  @Action(UpdateKVAThreshold)
  updateKVAThreshold(
    { getState, patchState }: StateContext<ThresholdSettingsStateInfo>,
    action: UpdateKVAThreshold,
  ) {
    return this.thresholdSettingsService
      .updateKVAThreshold(action.ratedKVA, action.deviceIds)
      .pipe(
        tap((res: any) => {
          patchState({
            isKVAUpdated: res,
          })
        }),
      )
  }

  @Action(UpdateCtThreshold)
  updateCtThreshold(
    { getState, patchState }: StateContext<ThresholdSettingsStateInfo>,
    action: UpdateCtThreshold,
  ) {
    return this.thresholdSettingsService
      .updateCtThreshold(action.cTRatio, action.deviceIds)
      .pipe(
        tap((res: any) => {
          patchState({
            isCtUpdated: res,
          })
        }),
      )
  }

  @Action(UpdateTemperatureThreshold)
  updaterTemperatureThreshold(
    { getState, patchState }: StateContext<ThresholdSettingsStateInfo>,
    action: UpdateTemperatureThreshold,
  ) {
    return this.thresholdSettingsService
      .updaterTemperatureThreshold(action.temperature, action.deviceIds)
      .pipe(
        tap((res: any) => {
          patchState({
            isTemperatureUpdated: res,
          })
        }),
      )
  }

  @Action(UpdatePFThreshold)
  updaterPfThreshold(
    { getState, patchState }: StateContext<ThresholdSettingsStateInfo>,
    action: UpdatePFThreshold,
  ) {
    return this.thresholdSettingsService
      .updaterPfThreshold(action.powerFactor, action.deviceIds)
      .pipe(
        tap((res: any) => {
          patchState({
            isPfUpdated: res,
          })
        }),
      )
  }

  @Action(UpdateUnityPFThreshold)
  updateUnityPFThreshold(
    { getState, patchState }: StateContext<ThresholdSettingsStateInfo>,
    action: UpdateUnityPFThreshold,
  ) {
    return this.thresholdSettingsService
      .updateUnityPFThreshold(action.isUnityPF, action.deviceIds)
      .pipe(
        tap((res: any) => {
          patchState({
            isUnityPfUpdated: res,
          })
        }),
      )
  }
}
