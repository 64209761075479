import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { SharedModule } from 'src/shared/shared.module'
import {
  AddDeviceListComponent,
  AddInstalledDateComponent,
  CommonsModule,
  DeviceListComponent,
  DeviceRoutingModule,
  PipeModule,
} from '@app/index'
import { OtpDialogComponent } from './otp-dialog/otp-dialog.component'
import { FormsModule } from '@angular/forms'
@NgModule({
  declarations: [
    DeviceListComponent,
    AddDeviceListComponent,
    OtpDialogComponent,
    AddInstalledDateComponent,
  ],
  imports: [
    CommonModule,
    DeviceRoutingModule,
    CommonsModule,
    SharedModule,
    PipeModule,
    FormsModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class DeviceModule {}
