import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import {
  AddDeviceListComponent,
  AuthGuard,
  DeviceListComponent,
} from '@app/index'

const routes: Routes = [
  { path: '', component: DeviceListComponent, canActivate: [AuthGuard] },
  { path: 'device', component: DeviceListComponent, canActivate: [AuthGuard] },
  {
    path: 'add-Device',
    component: AddDeviceListComponent,
    canActivate: [AuthGuard],
  },
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DeviceRoutingModule {}
