import { Component, OnInit, ViewChild } from '@angular/core'
import { CommonGridComponent } from '@app/components/common'
import { MenuList } from '@app/enums'
import { CommonService } from '@app/services'
import { CommonState } from '@app/store'
import { GetAllSystemBattery } from '@app/store/system-battery'
import { Select, Store } from '@ngxs/store'
import { NgxSpinnerService } from 'ngx-spinner'
import { Observable, Subject } from 'rxjs'

@Component({
  selector: 'app-status',
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.scss'],
})
export class StatusComponent implements OnInit {
  triggerGetUpdatedTime: Subject<any> = new Subject<any>()
  @ViewChild(CommonGridComponent) commonGridComponent:
    | CommonGridComponent
    | undefined

  feederAreaId: any
  deviceId: any
  startDate = null
  endDate = null

  pageNumber: number = 1
  pageSize: number = 10
  sort: any
  sortOrder = false
  previousSort: any = null
  skip = 0
  gridView: any = []
  @Select(CommonState.moduleId)
  moduleId$!: Observable<number>
  isDataLoaded = false
  filterQuery: string | any
  infoText: string =
    'When the power of the DTR is turned OFF the system battery voltage is measured and updated to the server and this continues in an interval of time until the DTR power is ON.'

  constructor(
    public store: Store,
    private spinner: NgxSpinnerService,
    public commonService: CommonService,
  ) {}

  ngOnInit(): void {}

  getDataByHeaderData(event: any) {
    this.feederAreaId = event.feederAreaId
    this.deviceId = event.deviceId
    this.startDate = event.startDate
    this.endDate = event.endDate
    this.getSystemBatteryList(
      this.deviceId,
      this.feederAreaId,
      this.startDate,
      this.endDate,
    )
    this.triggerGetUpdatedTime.next(true)
  }

  onColumnSelector(): void {
    this.commonGridComponent?.onColumnSelector()
  }

  onFilterChangeSQL(sqlQuery: any) {
    this.filterQuery = sqlQuery
    this.getSystemBatteryList(
      this.deviceId,
      this.feederAreaId,
      this.startDate,
      this.endDate,
    )
  }

  getSystemBatteryList(
    deviceId: any,
    feederAreaId: any,
    startDate: any,
    endDate: any,
  ) {
    const queryParams = {
      deviceId: deviceId,
      feederAreaId: feederAreaId,
      pageNumber: this.pageNumber,
      pageSize: this.pageSize,
      filter: 0,
      sortBy: this.sort ?? null,
      sortOrder: this.sortOrder,
      search: '',
      startDate: startDate,
      endDate: endDate,
      moduleId: this.store.selectSnapshot(CommonState.moduleId),
      filterQuery: this.filterQuery,
    }
    this.spinner.show()
    this.store.dispatch(new GetAllSystemBattery(queryParams)).subscribe(
      (res) => {
        this.spinner.hide()

        if (res.systemBattery.systemBatteryData) {
          const { data, totalCount } = res.systemBattery.systemBatteryData

          this.gridView = {
            data: data.rows,
            total: totalCount,
            columns: data.columns,
          }

          this.isDataLoaded = true
        }
      },
      (error) => {
        this.spinner.hide()
      },
    )
  }

  onSortChange(sortData: any) {
    const currentSortField = sortData.sort[0].field
    if (this.previousSort === currentSortField) {
      this.sortOrder = !this.sortOrder
    } else {
      this.sortOrder = true
    }
    this.sort = currentSortField
    this.previousSort = currentSortField

    this.getSystemBatteryList(
      this.deviceId,
      this.feederAreaId,
      this.startDate,
      this.endDate,
    )
  }

  onPaginationChange({ skip, take }: any) {
    this.skip = skip
    this.pageSize = take
    this.pageNumber = this.skip / this.pageSize + 1

    this.getSystemBatteryList(
      this.deviceId,
      this.feederAreaId,
      this.startDate,
      this.endDate,
    )
  }

  getDataFromColumnSelector(event: any) {
    this.getSystemBatteryList(
      this.deviceId,
      this.feederAreaId,
      this.startDate,
      this.endDate,
    )
  }
}
