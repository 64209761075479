<div class="q-content">
  <div class="d-flex gap-15 flex-wrap justify-content-between flex-wrap mb-20">
    <app-common-header-text
      [triggerGetUpdatedTime]="triggerGetUpdatedTime"
    ></app-common-header-text>
    <div class="d-flex gap-15 flex-wrap">
      <kendo-dropdownlist
        [data]="rolesList"
        textField="name"
        valueField="id"
        [(ngModel)]="selectedRole"
        [ngModelOptions]="{ standalone: true }"
        [value]="selectedRole"
        [valuePrimitive]="true"
        (valueChange)="onRoleChange($event)"
        class="w-200"
      ></kendo-dropdownlist>
      <kendo-dropdownlist
        [data]="moduleList"
        textField="name"
        valueField="id"
        [(ngModel)]="selectedModule"
        [ngModelOptions]="{ standalone: true }"
        [value]="selectedModule"
        [valuePrimitive]="true"
        (valueChange)="onModuleChange()"
        class="w-200"
      ></kendo-dropdownlist>
      <kendo-dropdownlist
        *ngIf="isSettingHistory"
        [data]="electricType | useEnumValues"
        textField="value"
        valueField="key"
        placeholder="value"
        [(ngModel)]="selectedElectricType"
        [ngModelOptions]="{ standalone: true }"
        [value]="selectedElectricType"
        [valuePrimitive]="true"
        (valueChange)="getSettingHistoryTypeList()"
        class="w-260"
      ></kendo-dropdownlist>
      <kendo-dropdownlist
        *ngIf="isOfficeReport"
        [data]="officeTypeList | useEnumValues"
        textField="value"
        valueField="key"
        placeholder="value"
        [(ngModel)]="selectedOfficeTypeList"
        [ngModelOptions]="{ standalone: true }"
        [value]="selectedOfficeTypeList"
        [valuePrimitive]="true"
        (valueChange)="onChangeOfficeType()"
        class="w-200"
      ></kendo-dropdownlist>
    </div>
  </div>

  <div class="q-content-bg">
    <app-common-column-permission
      [data]="coulmnList"
      [columns]="[
        {
          field: 'name',
          title: 'Column Name',
          expandable: true,
          width: 150
        },
        { field: 'isSelected', title: '', width: 150, actionType: 'Add' }
      ]"
      (dataChange)="handleColumnDataChange($event)"
    ></app-common-column-permission>
    <div class="d-flex justify-content-end">
      <button kendoButton id="create" type="button" (click)="onSubmit()">
        Submit
      </button>
    </div>
  </div>
</div>
