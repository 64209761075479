import { Component, OnInit, ViewChild } from '@angular/core'
import {
  ActionTypeEnum,
  AddUpdateRoleComponent,
  CommonGridComponent,
  CommonService,
  MenuList,
} from '@app/index'
import { CommonState, DeleteRole, GetRoleList } from '@app/store'
import { Select, Store } from '@ngxs/store'
import { DialogService } from '@progress/kendo-angular-dialog'
import { NgxSpinnerService } from 'ngx-spinner'
import { Observable, Subject } from 'rxjs'

@Component({
  selector: 'app-role',
  templateUrl: './role.component.html',
  styleUrl: './role.component.scss',
})
export class RoleComponent implements OnInit {
  triggerGetUpdatedTime: Subject<any> = new Subject<any>()
  @ViewChild(CommonGridComponent) commonGridComponent:
    | CommonGridComponent
    | undefined

  gridView: any = []
  pageNumber = 1
  pageSize = 10
  skip = 0
  sort: any
  sortOrder = false
  startDate = null
  endDate = null
  deviceId: any
  feederAreaId: any
  previousSort: any = null

  @Select(CommonState.moduleId)
  moduleId$!: Observable<number>
  isDataLoaded = false
  actions: any[] = [
    {
      title: 'Edit',
      icon: 'far fa-pen',
      actionTypeId: ActionTypeEnum.Edit,
    },
    {
      title: 'Delete',
      icon: 'far fa-trash-alt',
      actionTypeId: ActionTypeEnum.Delete,
    },
  ]

  @Select(CommonState.hasAddPermission)
  hasAddPermission$!: Observable<boolean>
  filterQuery: string | any
  constructor(
    private store: Store,
    private dialogService: DialogService,
    private spinner: NgxSpinnerService,
    private commonService: CommonService,
  ) {}

  ngOnInit(): void {
    setTimeout(() => {
      this.triggerGetUpdatedTime.next(true)
    }, 0)
    this.GetRoleList()
  }

  onColumnSelector(): void {
    this.commonGridComponent?.onColumnSelector()
  }

  onFilterChangeSQL(sqlQuery: any) {
    this.filterQuery = sqlQuery
    this.GetRoleList()
  }

  GetRoleList() {
    this.spinner.show()
    const param = {
      deviceId: this.deviceId,
      feederAreaId: this.feederAreaId,
      pageNumber: this.pageNumber,
      pageSize: this.pageSize,
      filter: 0,
      sortBy: this.sort ?? null,
      sortOrder: this.sortOrder,
      search: null,
      startDate: this.startDate,
      endDate: this.endDate,
      moduleId: this.store.selectSnapshot(CommonState.moduleId),
      filterQuery: this.filterQuery,
    }
    this.store.dispatch(new GetRoleList(param)).subscribe(
      (res) => {
        this.spinner.hide()

        const { data, totalCount } = res.role.roleList

        this.gridView = {
          data: data.rows
            ? data.rows?.map((x: any) => ({
                ...x,
                status: x.isArchived ? 'inactive' : 'active',
              }))
            : [],
          total: totalCount,
          columns: data.columns,
        }

        this.isDataLoaded = true
      },
      (error) => {
        this.spinner.hide()
      },
    )
  }

  onActionClick(event: any): void {
    switch (event.actionTypeId) {
      case ActionTypeEnum.Edit:
        this.onUpdate(event.data)
        break
    }
  }

  onAddRole() {
    this.openRoleDialog(true)
  }

  onUpdate(data: any) {
    this.openRoleDialog(false, data.id)
  }

  openRoleDialog(isAddRole: boolean, updateRoleId?: number) {
    const dialogRef = this.dialogService.open({
      content: AddUpdateRoleComponent,
      width: 450,
    })

    const addUpdateRoleInfo = dialogRef.content
      .instance as AddUpdateRoleComponent
    addUpdateRoleInfo.isAddRole = isAddRole
    addUpdateRoleInfo.updateRoleId = updateRoleId
    addUpdateRoleInfo.roleList = this.gridView.data

    dialogRef.result.subscribe((result: any) => {
      if (result?.text === 'success') this.GetRoleList()
    })
  }

  onRoleDelete(data: any) {
    this.spinner.show()
    this.store.dispatch(new DeleteRole(data.id)).subscribe(
      (res) => {
        this.spinner.hide()
        this.GetRoleList()
      },
      (error) => {
        this.spinner.hide()
      },
    )
  }

  onPaginationChange({ skip, take }: any) {
    this.skip = skip
    this.pageSize = take
    this.pageNumber = this.skip / this.pageSize + 1

    this.GetRoleList()
  }

  onSortChange(sortData: any) {
    const currentSortField = sortData.sort[0].field
    if (this.previousSort === currentSortField) {
      this.sortOrder = !this.sortOrder
    } else {
      this.sortOrder = true
    }
    this.sort = currentSortField
    this.previousSort = currentSortField
    this.GetRoleList()
  }

  getDataFromColumnSelector(event: any) {
    this.GetRoleList()
  }
}
