import { drawing } from '@progress/kendo-drawing';
import { SankeyElement } from './element';
import { deepExtend } from '../common';
import { defined } from '../drawing-utils';
import { ARIA_ACTIVE_DESCENDANT } from '../common/constants';

const distanceToLine = (line, point) => {
    const [x1, y1] = line[0];
    const [x2, y2] = line[1];
    const [x3, y3] = point;

    return Math.abs((x2 - x1) * (y3 - y1) - (x3 - x1) * (y2 - y1)) / Math.sqrt(Math.pow( (x2 - x1), 2 ) + Math.pow( (y2 - y1), 2 ));
};

const bezierPoint = (p1, p2, p3, p4, t) => {
    const t1 = 1 - t;
    const t1t1 = t1 * t1;
    const tt = t * t;
    return (p1 * t1t1 * t1) + (3 * p2 * t * t1t1) + (3 * p3 * tt * t1) + (p4 * tt * t);
};

const angelBetweenTwoLines = (line1, line2) => {
    const [x1, y1] = line1[0];
    const [x2, y2] = line1[1];
    const [x3, y3] = line2[0];
    const [x4, y4] = line2[1];

    const a1 = Math.atan2(y2 - y1, x2 - x1);
    const a2 = Math.atan2(y4 - y3, x4 - x3);

    return Math.abs(a1 - a2);
};

const calculateControlPointsOffsetX = (link, rtl) => {
    const { x0, x1, y0, y1 } = link;
    let xC = (x0 + x1) / 2;

    const width = link.width;
    const halfWidth = width / 2;

    // upper curve, t = 0.5
    const upperCurveMiddleLine = [[(x0 + xC) / 2, y0 - halfWidth], [(x1 + xC) / 2, y1 - halfWidth]];

    // for lower curve, bezier-point at t = 0.5
    // for the case t = 0.5, the bezier-point is the middle point of the curve. => ((y0 + halfWidth) + (y1 + halfWidth)) / 2
    const lowerCurveMiddlePoint = [xC, bezierPoint(y0 + halfWidth, y0 + halfWidth, y1 + halfWidth, y1 + halfWidth, 0.5)];

    // The actual width of the link at its middle point as can be seen on the screen.
    const actualWidth = distanceToLine(upperCurveMiddleLine, lowerCurveMiddlePoint);

    const upperNarrowness = (width - actualWidth) / 2;

    // The line `upperCurveMiddleLine` shows the upper border of the link.
    // Assumption 1: Translated to the left to the desired link width and the translate value will be the `offset`.
    // Assumption 2: The translate value is a hypotenuse of a triangle.
    const alpha = angelBetweenTwoLines(upperCurveMiddleLine, [[x0, y0 - halfWidth], [xC, y0 - halfWidth]]);
    const a = upperNarrowness;
    const b = Math.sin(alpha) * a;
    const offset = Math.sqrt(a * a + b * b);
    // Another option is to assume the triangle is isosceles
    // => offset = Math.sqrt(2) * upperNarrowness;

    let sign = y0 - y1 > 0 ? (-1) : 1;
    if (rtl) {
        sign = -sign;
    }

    return sign * offset;
};

export class Link extends SankeyElement {
    getElement() {
        const link = this.options.link;
        const { x0, x1, y0, y1 } = link;
        const xC = (x0 + x1) / 2;

        return new drawing.Path(this.visualOptions())
            .moveTo(x0, y0).curveTo([xC, y0], [xC, y1], [x1, y1]);
    }

    getLabelText(options) {
        let labelTemplate = options.labels.ariaTemplate;

        if (labelTemplate) {
            return labelTemplate({ link: options.link });
        }
    }

    visualOptions() {
        const options = this.options;
        const link = this.options.link;
        const ariaLabel = this.getLabelText(options);

        return {
            stroke: {
                width: options.link.width,
                color: link.color || options.color,
                opacity: defined(link.opacity) ? link.opacity : options.opacity
            },
            role: 'graphics-symbol',
            ariaRoleDescription: 'Link',
            ariaLabel: ariaLabel
        };
    }

    createFocusHighlight() {
        if (!this.options.navigatable) {
            return;
        }
        const { link, rtl } = this.options;
        const { x0, x1, y0, y1 } = link;
        const xC = (x0 + x1) / 2;
        const halfWidth = link.width / 2;

        const offset = calculateControlPointsOffsetX(link, rtl);

        this._highlight = new drawing.Path({ stroke: this.options.focusHighlight, visible: false })
            .moveTo(x0, y0 + halfWidth)
            .lineTo(x0, y0 - halfWidth)
            .curveTo([xC + offset, y0 - halfWidth], [xC + offset, y1 - halfWidth], [x1, y1 - halfWidth])
            .lineTo(x1, y1 + halfWidth)
            .curveTo([xC - offset, y1 + halfWidth], [xC - offset, y0 + halfWidth], [x0, y0 + halfWidth]);
    }

    focus(options) {
        if (this._highlight) {
            const { highlight = true } = options || {};
            if (highlight) {
                this._highlight.options.set('visible', true);
            }
            const id = `${this.options.link.sourceId}->${this.options.link.targetId}`;
            this.visual.options.set('id', id);

            if (this.options.root()) {
                this.options.root().setAttribute(ARIA_ACTIVE_DESCENDANT, id);
            }
        }
    }

    blur() {
        if (this._highlight) {
            this._highlight.options.set('visible', false);
            this.visual.options.set('id', '');

            if (this.options.root()) {
                this.options.root().removeAttribute(ARIA_ACTIVE_DESCENDANT);
            }
        }
    }
}

export const resolveLinkOptions = (link, options, sourceNode, targetNode) => {
    const linkOptions = deepExtend({},
        options,
        {
            link,
            opacity: link.opacity,
            color: link.color,
            colorType: link.colorType,
            visual: link.visual,
            highlight: link.highlight
        }
    );

    if (linkOptions.colorType === 'source') {
        linkOptions.color = sourceNode.options.fill.color;
    } else if (linkOptions.colorType === 'target') {
        linkOptions.color = targetNode.options.fill.color;
    }

    return linkOptions;
};
