import { colgroupAttr, dataResizeDirTable, resizableAttr, resizableWrap, resizeHandle } from '../../config/constants';
import { parseStrColgroup } from '../../config/schema';
import { handles } from './../resize-utils';
import { parseStyle, setAttribute } from './../../utils';
export class TableView {
    constructor(node, view) {
        this.node = node;
        this.view = view;
        this.dom = document.createElement('div');
        this.dom.className = resizableWrap;
        this.table = this.dom.appendChild(document.createElement('table'));
        if (node.attrs[colgroupAttr]) {
            this.renderColgroup(node.attrs[colgroupAttr]);
        }
        const tBody = this.table.appendChild(document.createElement('tbody'));
        this.setAttributes(this.table, node.attrs);
        this.resizeHandles = handles.map(dir => {
            const handle = document.createElement('span');
            handle.className = resizeHandle + ' ' + dir;
            handle.setAttribute(dataResizeDirTable, dir);
            return handle;
        });
        this.contentDOM = tBody;
    }
    update(node) {
        if (node.type !== this.node.type) {
            return false;
        }
        const prev = this.node;
        this.node = node;
        if (node.attrs[resizableAttr]) {
            this.resizeHandles.forEach(handle => {
                this.dom.appendChild(handle);
            });
        }
        else {
            Array.from(this.dom.children)
                .filter((e) => e.classList.contains(resizeHandle))
                .forEach((e) => e.remove());
        }
        this.setAttributes(this.table, node.attrs);
        if (prev.attrs[colgroupAttr] !== node.attrs[colgroupAttr]) {
            this.renderColgroup(node.attrs[colgroupAttr]);
        }
        return true;
    }
    ignoreMutation(record) {
        const result = record.type === 'attributes' &&
            (record.target === this.table ||
                record.target.firstChild === this.table ||
                (this.colgroup && this.colgroup.contains(record.target)));
        return result;
    }
    destroy() {
        this.node = undefined;
        this.view = undefined;
        this.table = undefined;
        this.colgroup = undefined;
        this.resizeHandles = undefined;
    }
    renderColgroup(colgroupStr) {
        if (this.table && this.table.firstChild && this.table.firstChild.nodeName === 'COLGROUP') {
            this.table.removeChild(this.table.firstChild);
        }
        if (colgroupStr) {
            this.colgroup = parseStrColgroup(colgroupStr);
            this.table.insertBefore(this.colgroup, this.table.firstChild);
        }
    }
    setAttributes(table, attrs) {
        const skip = [colgroupAttr, resizableAttr];
        for (let attrName in attrs) {
            if (attrName && skip.indexOf(attrName) === -1) {
                setAttribute(table, attrName, attrs[attrName]);
            }
        }
        if (/%$/.test(table.style.width)) {
            this.dom.style.width = table.style.width;
            table.style.width = '';
        }
        else {
            this.dom.style.width = '';
        }
    }
}
export class TableWrapperView {
    constructor(node, view) {
        this.node = node;
        this.view = view;
        this.dom = document.createElement('div');
        this.setAttributes(this.dom, node.attrs);
        this.contentDOM = this.dom;
    }
    update(node) {
        if (node.type !== this.node.type) {
            return false;
        }
        this.node = node;
        this.setAttributes(this.dom, node.attrs);
        return true;
    }
    ignoreMutation(record) {
        return record.type === 'attributes' && record.attributeName === 'style';
    }
    destroy() {
        this.node = undefined;
        this.view = undefined;
    }
    setAttributes(dom, attrs) {
        for (let attrName in attrs) {
            if (attrName) {
                setAttribute(dom, attrName, attrs[attrName]);
            }
        }
        dom.setAttribute('table', '');
        if (/%/.test(dom.style.width)) {
            const table = this.node && this.node.lastChild;
            const tableStyles = table ? parseStyle(table.attrs.style) : {};
            const tableWidth = tableStyles.width || '';
            if (tableWidth && !/%/.test(tableWidth)) {
                dom.style.width = '';
            }
        }
    }
}
