import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { AuthGuard, DtrPredictionAnalysisComponent } from '@app/index'

const routes: Routes = [
  {
    path: '',
    component: DtrPredictionAnalysisComponent,
    canActivate: [AuthGuard],
  },
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DtrPredictionAnalysisRoutingModule {}
