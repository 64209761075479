import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable, of } from 'rxjs'
import { environment } from 'src/environments'

@Injectable()
export class AnalyticsService {
  current_A_CardData = {
    id: '766569',
    device_id: 'PTMS_181',
    kw_5_avg: '16.5103',
    kw_10_avg: '16.3711',
    kw_15_avg: '15.7356',
    kw_1_avg: '15.6491',
    kva_5_avg: '17.0224',
    kva_10_avg: '16.8848',
    kva_15_avg: '16.2288',
    kva_1_avg: '16.1772',
    kw_ph1: '9.2463',
    kw_ph2: '3.9149',
    kw_ph3: '2.4879',
    kva_ph1: '9.4286',
    kva_ph2: '4.1476',
    kva_ph3: '2.601',
    pf_total: '0.967',
    pf_5mins: '0.969',
    pf_10mins: '0.969',
    pf_15mins: '0.969',
    lag_kvah_ph1: '51444.7',
    lag_kvah_ph2: '20685.2',
    lag_kvah_ph3: '31493.2',
    lag_kvah_total: '103623.1',
    lead_kvah_ph1: '5778.6',
    lead_kvah_ph2: '20671',
    lead_kvah_ph3: '9810.3',
    lead_kvah_total: '36259.9',
    lag_kwh_ph1: '49859.5',
    lag_kwh_ph2: '20074.9',
    lag_kwh_ph3: '30600.6',
    lag_kwh_total: '100535',
    lead_kwh_ph1: '5657.8',
    lead_kwh_ph2: '20200.5',
    lead_kwh_ph3: '9560.1',
    lead_kwh_total: '35418.4',
    onboard_temp: '0',
    voltage_ph1: '238.58',
    voltage_ph2: '238.1',
    voltage_ph3: '239.77',
    current_ph1: '39.65',
    current_ph2: '17.48',
    current_ph3: '10.82',
    neutral_current: '25.99',
    energy_kwh_ph1: '55524.7',
    energy_kwh_ph2: '40283.7',
    energy_kwh_ph3: '40167.8',
    energy_kwh_total: '135976.2',
    energy_kvah_ph1: '57230',
    energy_kvah_ph2: '41364.6',
    energy_kvah_ph3: '41310.7',
    energy_kvah_total: '139905.3',
    lag_kvarh_ph1: '6811',
    lag_kvarh_ph2: '2405.6',
    lag_kvarh_ph3: '4379.5',
    lag_kvarh_total: '13596.1',
    lead_kvarh_ph1: '201.7',
    lead_kvarh_ph2: '1530.2',
    lead_kvarh_ph3: '573.9',
    lead_kvarh_total: '2305.8',
    frequency_ph1: '50',
    frequency_ph2: '50',
    frequency_ph3: '50',
    powerfactor_ph1: '0.98',
    powerfactor_ph2: '0.943',
    powerfactor_ph3: '0.956',
    load_factor: '8',
    load_factor_ph1: '14.1',
    load_factor_ph2: '6.2',
    load_factor_ph3: '3.9',
    temp_r: '38',
    temp_y: '38',
    temp_b: '37.5',
    temp_oil: '39',
    temp_ambient: '34',
    temp_body: '0',
    location: '3039.9658,07651.6948',
    signal_level: '30/4180',
    Date_Time: '16:53:05 09-05-2024',
  }

  voltage_A_CardData = {
    id: '766569',
    device_id: 'PTMS_181',
    kw_5_avg: '16.5103',
    kw_10_avg: '16.3711',
    kw_15_avg: '15.7356',
    kw_1_avg: '15.6491',
    kva_5_avg: '17.0224',
    kva_10_avg: '16.8848',
    kva_15_avg: '16.2288',
    kva_1_avg: '16.1772',
    kw_ph1: '9.2463',
    kw_ph2: '3.9149',
    kw_ph3: '2.4879',
    kva_ph1: '9.4286',
    kva_ph2: '4.1476',
    kva_ph3: '2.601',
    pf_total: '0.967',
    pf_5mins: '0.969',
    pf_10mins: '0.969',
    pf_15mins: '0.969',
    lag_kvah_ph1: '51444.7',
    lag_kvah_ph2: '20685.2',
    lag_kvah_ph3: '31493.2',
    lag_kvah_total: '103623.1',
    lead_kvah_ph1: '5778.6',
    lead_kvah_ph2: '20671',
    lead_kvah_ph3: '9810.3',
    lead_kvah_total: '36259.9',
    lag_kwh_ph1: '49859.5',
    lag_kwh_ph2: '20074.9',
    lag_kwh_ph3: '30600.6',
    lag_kwh_total: '100535',
    lead_kwh_ph1: '5657.8',
    lead_kwh_ph2: '20200.5',
    lead_kwh_ph3: '9560.1',
    lead_kwh_total: '35418.4',
    onboard_temp: '0',
    voltage_ph1: '238.58',
    voltage_ph2: '238.1',
    voltage_ph3: '239.77',
    current_ph1: '39.65',
    current_ph2: '17.48',
    current_ph3: '10.82',
    neutral_current: '25.99',
    energy_kwh_ph1: '55524.7',
    energy_kwh_ph2: '40283.7',
    energy_kwh_ph3: '40167.8',
    energy_kwh_total: '135976.2',
    energy_kvah_ph1: '57230',
    energy_kvah_ph2: '41364.6',
    energy_kvah_ph3: '41310.7',
    energy_kvah_total: '139905.3',
    lag_kvarh_ph1: '6811',
    lag_kvarh_ph2: '2405.6',
    lag_kvarh_ph3: '4379.5',
    lag_kvarh_total: '13596.1',
    lead_kvarh_ph1: '201.7',
    lead_kvarh_ph2: '1530.2',
    lead_kvarh_ph3: '573.9',
    lead_kvarh_total: '2305.8',
    frequency_ph1: '50',
    frequency_ph2: '50',
    frequency_ph3: '50',
    powerfactor_ph1: '0.98',
    powerfactor_ph2: '0.943',
    powerfactor_ph3: '0.956',
    load_factor: '8',
    load_factor_ph1: '14.1',
    load_factor_ph2: '6.2',
    load_factor_ph3: '3.9',
    temp_r: '38',
    temp_y: '38',
    temp_b: '37.5',
    temp_oil: '39',
    temp_ambient: '34',
    temp_body: '0',
    location: '3039.9658,07651.6948',
    signal_level: '30/4180',
    Date_Time: '16:53:05 09-05-2024',
  }

  currentGraphLineChart: any[] = [
    { date: '15:57 May 09', v_1: '38.4', v_2: '16.61', v_3: '6.77' },
    { date: '15:58 May 09', v_1: '38.39', v_2: '16.62', v_3: '6.76' },
    { date: '15:59 May 09', v_1: '38.69', v_2: '17.17', v_3: '7.48' },
    { date: '16:00 May 09', v_1: '36.67', v_2: '16.8', v_3: '6.54' },
    { date: '16:01 May 09', v_1: '29.26', v_2: '16.79', v_3: '6.75' },
    { date: '16:02 May 09', v_1: '26.13', v_2: '17', v_3: '7.33' },
    { date: '16:03 May 09', v_1: '22.29', v_2: '17.17', v_3: '7.68' },
    { date: '16:04 May 09', v_1: '26.85', v_2: '17.22', v_3: '7.61' },
    { date: '16:05 May 09', v_1: '27.92', v_2: '17.41', v_3: '7.59' },
    { date: '16:06 May 09', v_1: '27.28', v_2: '17.7', v_3: '8.26' },
    { date: '16:07 May 09', v_1: '27.34', v_2: '17.18', v_3: '8.89' },
    { date: '16:08 May 09', v_1: '26.42', v_2: '16.66', v_3: '8.91' },
    { date: '16:09 May 09', v_1: '25.45', v_2: '16.18', v_3: '7.98' },
    { date: '16:10 May 09', v_1: '26.8', v_2: '16.55', v_3: '8.58' },
    { date: '16:11 May 09', v_1: '34.5', v_2: '16.86', v_3: '9.29' },
    { date: '16:12 May 09', v_1: '29.25', v_2: '16.58', v_3: '8.79' },
    { date: '16:13 May 09', v_1: '30.2', v_2: '15.98', v_3: '8.37' },
    { date: '16:14 May 09', v_1: '32.22', v_2: '15.8', v_3: '7.97' },
    { date: '16:15 May 09', v_1: '38.49', v_2: '16.02', v_3: '8.37' },
    { date: '16:16 May 09', v_1: '38.89', v_2: '15.45', v_3: '7.46' },
    { date: '16:17 May 09', v_1: '42.23', v_2: '16.04', v_3: '8.22' },
    { date: '16:18 May 09', v_1: '46.96', v_2: '16.06', v_3: '8.09' },
    { date: '16:19 May 09', v_1: '40.64', v_2: '15.33', v_3: '7.29' },
    { date: '16:20 May 09', v_1: '40.91', v_2: '15.19', v_3: '8.81' },
    { date: '16:21 May 09', v_1: '40.11', v_2: '16.29', v_3: '8.86' },
    { date: '16:22 May 09', v_1: '40.1', v_2: '16.36', v_3: '10.58' },
    { date: '16:23 May 09', v_1: '40.04', v_2: '16.46', v_3: '13.03' },
    { date: '16:24 May 09', v_1: '42.2', v_2: '16.59', v_3: '14.75' },
    { date: '16:25 May 09', v_1: '42.28', v_2: '16.56', v_3: '14.63' },
    { date: '16:26 May 09', v_1: '40.22', v_2: '16.8', v_3: '14.84' },
    { date: '16:27 May 09', v_1: '40.07', v_2: '16.81', v_3: '15.15' },
    { date: '16:28 May 09', v_1: '38.98', v_2: '16.68', v_3: '14.97' },
    { date: '16:29 May 09', v_1: '38.86', v_2: '16.66', v_3: '13.71' },
    { date: '16:30 May 09', v_1: '37.45', v_2: '15.92', v_3: '10.74' },
    { date: '16:31 May 09', v_1: '35.7', v_2: '16.4', v_3: '10.07' },
    { date: '16:32 May 09', v_1: '35.52', v_2: '15.96', v_3: '8.67' },
    { date: '16:33 May 09', v_1: '36.18', v_2: '16.3', v_3: '9.29' },
    { date: '16:34 May 09', v_1: '29.4', v_2: '15.92', v_3: '8.79' },
    { date: '16:35 May 09', v_1: '36.38', v_2: '22.48', v_3: '16.01' },
    { date: '16:36 May 09', v_1: '42.91', v_2: '26.71', v_3: '20.18' },
    { date: '16:37 May 09', v_1: '47.87', v_2: '26.76', v_3: '19.34' },
    { date: '16:38 May 09', v_1: '44.06', v_2: '18.09', v_3: '9.33' },
    { date: '16:39 May 09', v_1: '43.63', v_2: '16.65', v_3: '8.09' },
    { date: '16:40 May 09', v_1: '35.72', v_2: '15.65', v_3: '8.19' },
    { date: '16:41 May 09', v_1: '35.84', v_2: '11.6', v_3: '10.07' },
    { date: '16:42 May 09', v_1: '35.71', v_2: '11.51', v_3: '9.6' },
    { date: '16:43 May 09', v_1: '35.92', v_2: '16.54', v_3: '10.62' },
    { date: '16:44 May 09', v_1: '35.97', v_2: '16.73', v_3: '13.15' },
    { date: '16:45 May 09', v_1: '37.63', v_2: '16.89', v_3: '14.66' },
    { date: '16:46 May 09', v_1: '43.83', v_2: '17.23', v_3: '14.94' },
    { date: '16:47 May 09', v_1: '44.98', v_2: '17.96', v_3: '15.68' },
    { date: '16:48 May 09', v_1: '40.07', v_2: '17.98', v_3: '14.71' },
    { date: '16:49 May 09', v_1: '39.93', v_2: '17.89', v_3: '14.72' },
    { date: '16:50 May 09', v_1: '39.26', v_2: '17.51', v_3: '14.66' },
    { date: '16:51 May 09', v_1: '40.24', v_2: '17.78', v_3: '15.4' },
    { date: '16:53 May 09', v_1: '39.65', v_2: '17.48', v_3: '10.82' },
    { date: '16:53 May 09', v_1: '39.67', v_2: '17.59', v_3: '7.55' },
    { date: '16:54 May 09', v_1: '40.67', v_2: '17.75', v_3: '7.61' },
    { date: '16:55 May 09', v_1: '40.58', v_2: '17.69', v_3: '7.72' },
    { date: '16:56 May 09', v_1: '39.36', v_2: '17.59', v_3: '9.41' },
  ]

  voltageGraphLineChart: any[] = [
    { date: '15:53 May 09', v_1: '237.55', v_2: '237.01', v_3: '238.73' },
    { date: '15:54 May 09', v_1: '237.48', v_2: '236.89', v_3: '238.69' },
    { date: '15:55 May 09', v_1: '237.38', v_2: '236.81', v_3: '238.65' },
    { date: '15:56 May 09', v_1: '237.32', v_2: '236.75', v_3: '238.51' },
    { date: '15:57 May 09', v_1: '237.37', v_2: '236.79', v_3: '238.48' },
    { date: '15:58 May 09', v_1: '237.45', v_2: '236.86', v_3: '238.61' },
    { date: '15:59 May 09', v_1: '237.48', v_2: '236.86', v_3: '238.6' },
    { date: '16:00 May 09', v_1: '237.64', v_2: '236.99', v_3: '238.8' },
    { date: '16:01 May 09', v_1: '238.09', v_2: '237.26', v_3: '239.13' },
    { date: '16:02 May 09', v_1: '238.14', v_2: '237.25', v_3: '239.18' },
    { date: '16:03 May 09', v_1: '238.35', v_2: '237.42', v_3: '239.28' },
    { date: '16:04 May 09', v_1: '238.31', v_2: '237.58', v_3: '239.29' },
    { date: '16:05 May 09', v_1: '238.14', v_2: '237.45', v_3: '239.24' },
    { date: '16:06 May 09', v_1: '238.04', v_2: '237.28', v_3: '239.07' },
    { date: '16:07 May 09', v_1: '238.24', v_2: '237.5', v_3: '239.21' },
    { date: '16:08 May 09', v_1: '238.36', v_2: '237.53', v_3: '239.2' },
    { date: '16:09 May 09', v_1: '238.4', v_2: '237.59', v_3: '239.36' },
    { date: '16:10 May 09', v_1: '238.09', v_2: '237.38', v_3: '239.18' },
    { date: '16:11 May 09', v_1: '237.99', v_2: '237.47', v_3: '239.1' },
    { date: '16:12 May 09', v_1: '238.14', v_2: '237.49', v_3: '239.2' },
    { date: '16:13 May 09', v_1: '237.92', v_2: '237.4', v_3: '239.04' },
    { date: '16:14 May 09', v_1: '238.02', v_2: '237.55', v_3: '239.11' },
    { date: '16:15 May 09', v_1: '238.25', v_2: '237.91', v_3: '239.43' },
    { date: '16:16 May 09', v_1: '238.32', v_2: '238.05', v_3: '239.53' },
    { date: '16:17 May 09', v_1: '238.05', v_2: '237.83', v_3: '239.48' },
    { date: '16:18 May 09', v_1: '237.8', v_2: '237.72', v_3: '239.49' },
    { date: '16:19 May 09', v_1: '238.09', v_2: '237.86', v_3: '239.43' },
    { date: '16:20 May 09', v_1: '238.08', v_2: '237.88', v_3: '239.37' },
    { date: '16:21 May 09', v_1: '238.22', v_2: '237.94', v_3: '239.45' },
    { date: '16:22 May 09', v_1: '238.17', v_2: '237.91', v_3: '239.43' },
    { date: '16:23 May 09', v_1: '238.17', v_2: '237.9', v_3: '239.39' },
    { date: '16:24 May 09', v_1: '238.15', v_2: '237.91', v_3: '239.34' },
    { date: '16:25 May 09', v_1: '238.26', v_2: '237.98', v_3: '239.45' },
    { date: '16:26 May 09', v_1: '238.1', v_2: '237.79', v_3: '239.28' },
    { date: '16:27 May 09', v_1: '238.07', v_2: '237.77', v_3: '239.19' },
    { date: '16:28 May 09', v_1: '238.17', v_2: '237.86', v_3: '239.28' },
    { date: '16:29 May 09', v_1: '238.25', v_2: '237.89', v_3: '239.34' },
    { date: '16:30 May 09', v_1: '238.47', v_2: '238.07', v_3: '239.55' },
    { date: '16:31 May 09', v_1: '238.6', v_2: '238.17', v_3: '239.77' },
    { date: '16:32 May 09', v_1: '238.54', v_2: '238.09', v_3: '239.76' },
    { date: '16:33 May 09', v_1: '238.47', v_2: '238.03', v_3: '239.76' },
    { date: '16:34 May 09', v_1: '238.5', v_2: '237.99', v_3: '239.62' },
    { date: '16:35 May 09', v_1: '238.15', v_2: '237.67', v_3: '239.24' },
    { date: '16:36 May 09', v_1: '238.12', v_2: '237.67', v_3: '239.17' },
    { date: '16:37 May 09', v_1: '238.21', v_2: '237.81', v_3: '239.35' },
    { date: '16:38 May 09', v_1: '238.48', v_2: '238.19', v_3: '239.76' },
    { date: '16:39 May 09', v_1: '238.26', v_2: '237.97', v_3: '239.57' },
    { date: '16:40 May 09', v_1: '238.18', v_2: '237.84', v_3: '239.42' },
    { date: '16:41 May 09', v_1: '238.09', v_2: '237.83', v_3: '239.23' },
    { date: '16:42 May 09', v_1: '238.14', v_2: '237.8', v_3: '239.27' },
    { date: '16:43 May 09', v_1: '238.22', v_2: '237.61', v_3: '239.21' },
    { date: '16:44 May 09', v_1: '238.28', v_2: '237.62', v_3: '239.19' },
    { date: '16:45 May 09', v_1: '238.31', v_2: '237.75', v_3: '239.22' },
    { date: '16:46 May 09', v_1: '238.28', v_2: '237.86', v_3: '239.35' },
    { date: '16:47 May 09', v_1: '238.26', v_2: '237.94', v_3: '239.42' },
    { date: '16:48 May 09', v_1: '238.35', v_2: '237.88', v_3: '239.46' },
    { date: '16:49 May 09', v_1: '238.26', v_2: '237.71', v_3: '239.39' },
    { date: '16:50 May 09', v_1: '238.35', v_2: '237.81', v_3: '239.4' },
    { date: '16:51 May 09', v_1: '238.49', v_2: '238', v_3: '239.52' },
    { date: '16:53 May 09', v_1: '238.58', v_2: '238.1', v_3: '239.77' },
  ]

  constructor(private http: HttpClient) {}

  getAnalyticsChart(analyticsData: any): Observable<any[]> {
    // Return an observable of device data
    // return of(this.analyticsChartData)
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    }

    return this.http.post<any>(
      `${environment.apiVersionUrl}Analytics/GetBarGraphDetails`,
      JSON.stringify(analyticsData),
      headers,
    )
  }

  getAnalyticsVoltageChart(analyticsVoltageData: any): Observable<any[]> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    }

    return this.http.post<any>(
      `${environment.apiVersionUrl}Analytics/GetVoltageGraphDetails`,
      JSON.stringify(analyticsVoltageData),
      headers,
    )
  }

  // getCurrentGraphChart(isCurrentGraph: boolean): Observable<any[]> {
  //   return of(
  //     isCurrentGraph ? this.currentGraphLineChart : this.voltageGraphLineChart,
  //   )
  // }

  getCurrentGraphChart(currentGraphData: boolean): Observable<any[]> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    }

    return this.http.post<any>(
      `${environment.apiVersionUrl}Analytics/GetCurrentGraphDetails`,
      JSON.stringify(currentGraphData),
      headers,
    )
  }

  getCurrentACardData(isCurrentGraph: boolean): Observable<any> {
    return of(
      isCurrentGraph ? this.current_A_CardData : this.voltage_A_CardData,
    )
  }

  getLoadTempGraphChart(analyticsLoadTempGraphData: any): Observable<any[]> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    }

    return this.http.post<any>(
      `${environment.apiVersionUrl}Analytics/GetPhaseLoadAndTemperatureGraph`,
      JSON.stringify(analyticsLoadTempGraphData),
      headers,
    )
  }
}
