<div class="q-content">
  <div class="d-flex justify-content-between gap-15 mb-20 flex-wrap">
    <app-common-header-text
      [triggerGetUpdatedTimeWithData]="triggerGetUpdatedTimeWithData"
    ></app-common-header-text>
    <app-common-header
      (triggerDataFromHeader)="getDataByHeaderData($event)"
      [showDateFilter]="false"
    ></app-common-header>
  </div>
  <div>
    <div class="d-flex-row">
      <div class="d-flex-col-lg-4 d-flex-col-xs-12 d-flex-col mb-20 d-flex">
        <div class="q-card flex-1">
          <div>
            <p class="font-size-16 fw-500 mb-20">
              Min Voltage (V)
              <i
                class="far fa-circle-info font-size-16 cursor-pointer ml-5 info-icon"
                title="Update the max-min voltage thresholds for each phase."
              ></i>
            </p>
          </div>
          <app-min-voltage
            [triggerMinVoltageData]="triggerMinVoltageData"
          ></app-min-voltage>
          <div
            class="d-flex justify-content-end update-btn"
            [ngClass]="{ 'curser-not-allowd': !(hasEditPermission$ | async) }"
          >
            <button
              kendoButton
              (click)="confirmPopupUp(ThresholdSettingsEnum.MinVoltage)"
              [disabled]="!(hasEditPermission$ | async)"
            >
              Update
            </button>
          </div>
        </div>
      </div>
      <div class="d-flex-col-lg-4 d-flex-col-xs-12 d-flex-col mb-20 d-flex">
        <div class="q-card flex-1">
          <div>
            <p class="font-size-16 fw-500 mb-20" ax-voltage>
              Max Voltage (V)
              <i
                class="far fa-circle-info font-size-16 cursor-pointer ml-5 info-icon"
                title="Update the max-min voltage thresholds for each phase."
              ></i>
            </p>
          </div>
          <app-max-voltage [triggerMaxVoltageData]="triggerMaxVoltageData">
          </app-max-voltage>
          <div
            class="d-flex justify-content-end update-btn"
            [ngClass]="{ 'curser-not-allowd': !(hasEditPermission$ | async) }"
          >
            <button
              kendoButton
              (click)="confirmPopupUp(ThresholdSettingsEnum.MaxVoltage)"
              [disabled]="!(hasEditPermission$ | async)"
            >
              Update
            </button>
          </div>
        </div>
      </div>
      <div class="d-flex-col-lg-4 d-flex-col-xs-12 d-flex-col mb-20 d-flex">
        <div class="q-card flex-1">
          <div>
            <p class="font-size-16 fw-500 mb-20" ax-voltage>
              Max Current (A)
              <i
                class="far fa-circle-info font-size-16 cursor-pointer ml-5 info-icon"
                title="Update the max current thresholds for each phase."
              ></i>
            </p>
          </div>
          <app-max-current
            [triggerMaxCurrentData]="triggerMaxCurrentData"
          ></app-max-current>
          <div
            class="d-flex justify-content-end update-btn"
            [ngClass]="{ 'curser-not-allowd': !(hasEditPermission$ | async) }"
          >
            <button
              kendoButton
              (click)="confirmPopupUp(ThresholdSettingsEnum.MaxCurrent)"
              [disabled]="!(hasEditPermission$ | async)"
            >
              Update
            </button>
          </div>
        </div>
      </div>
      <div class="d-flex-col-lg-4 d-flex-col-xs-12 d-flex-col mb-20 d-flex">
        <div class="q-card flex-1">
          <div>
            <p class="font-size-16 fw-500 mb-20" ax-voltage>
              KVA
              <i
                class="far fa-circle-info font-size-16 cursor-pointer ml-5 info-icon"
                title="Rated kVA of a tranformer for calculate the Load factor of the transformer."
              ></i>
            </p>
          </div>
          <app-kva [triggerKvaData]="triggerKvaData"></app-kva>
          <div
            class="d-flex justify-content-end update-btn"
            [ngClass]="{ 'curser-not-allowd': !(hasEditPermission$ | async) }"
          >
            <button
              kendoButton
              (click)="confirmPopupUp(ThresholdSettingsEnum.KVA)"
              [disabled]="!(hasEditPermission$ | async)"
            >
              Update
            </button>
          </div>
        </div>
      </div>
      <div class="d-flex-col-lg-4 d-flex-col-xs-12 d-flex-col mb-20 d-flex">
        <div class="q-card flex-1">
          <div>
            <p class="font-size-16 fw-500 mb-20" ax-voltage>
              CT Settings (A)
              <i
                class="far fa-circle-info font-size-16 cursor-pointer ml-5 info-icon"
                title="CT-Ratio for change CT Value in this Device."
              ></i>
            </p>
          </div>
          <app-ct-settings
            [triggerCtSettingData]="triggerCtSettingData"
          ></app-ct-settings>
          <div
            class="d-flex justify-content-end update-btn"
            [ngClass]="{ 'curser-not-allowd': !(hasEditPermission$ | async) }"
          >
            <button
              kendoButton
              (click)="confirmPopupUp(ThresholdSettingsEnum.CTSettings)"
              [disabled]="!(hasEditPermission$ | async)"
            >
              Update
            </button>
          </div>
        </div>
      </div>
      <div class="d-flex-col-lg-4 d-flex-col-xs-12 d-flex-col mb-20 d-flex">
        <div class="q-card flex-1">
          <div>
            <p class="font-size-16 fw-500 mb-20" ax-voltage>
              Temperature
              <i
                class="far fa-circle-info font-size-16 cursor-pointer ml-5 info-icon"
                title="To set the Temperature threhsold for all Sensors."
              ></i>
            </p>
          </div>
          <app-temperature
            [triggerTemperatureData]="triggerTemperatureData"
          ></app-temperature>
          <div
            class="d-flex justify-content-end update-btn"
            [ngClass]="{ 'curser-not-allowd': !(hasEditPermission$ | async) }"
          >
            <button
              kendoButton
              (click)="confirmPopupUp(ThresholdSettingsEnum.Temperature)"
              [disabled]="!(hasEditPermission$ | async)"
            >
              Update
            </button>
          </div>
        </div>
      </div>
      <div class="d-flex-col-lg-4 d-flex-col-xs-12 d-flex-col mb-20 d-flex">
        <div class="q-card flex-1">
          <div>
            <p class="font-size-16 fw-500 mb-20" ax-voltage>
              PF Settings
              <i
                class="far fa-circle-info font-size-16 cursor-pointer ml-5 info-icon"
                title="PF value for get the PF alerts."
              ></i>
            </p>
          </div>
          <app-pf-settings
            [triggerPfSettingsData]="triggerPfSettingsData"
          ></app-pf-settings>
          <div
            class="d-flex justify-content-end update-btn"
            [ngClass]="{ 'curser-not-allowd': !(hasEditPermission$ | async) }"
          >
            <button
              kendoButton
              (click)="confirmPopupUp(ThresholdSettingsEnum.PFSettings)"
              [disabled]="!(hasEditPermission$ | async)"
            >
              Update
            </button>
          </div>
        </div>
      </div>
      <div class="d-flex-col-lg-4 d-flex-col-xs-12 d-flex-col mb-20 d-flex">
        <div class="q-card flex-1">
          <div>
            <p class="font-size-16 fw-500 mb-20" ax-voltage>
              Unity PF
              <i
                class="far fa-circle-info font-size-16 cursor-pointer ml-5 info-icon"
                title="Unity PF is disabled and This is for Enable or Disabled the Unity PF."
              ></i>
            </p>
          </div>
          <app-unity-pf
            [triggerUnityPfData]="triggerUnityPfData"
          ></app-unity-pf>
          <div
            class="d-flex justify-content-end update-btn"
            [ngClass]="{ 'curser-not-allowd': !(hasEditPermission$ | async) }"
          >
            <button
              kendoButton
              (click)="confirmPopupUp(ThresholdSettingsEnum.UnityPF)"
              [disabled]="!(hasEditPermission$ | async)"
              class="mt-10"
            >
              Update
            </button>
          </div>
        </div>
      </div>
    </div>
    <div kendoDialogContainer></div>
  </div>
</div>
