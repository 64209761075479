import { Component, Input, OnInit } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { CommonService } from '@app/services'

@Component({
  selector: 'app-unbalanced-load',
  templateUrl: './unbalanced-load.component.html',
  styleUrl: './unbalanced-load.component.scss',
})
export class UnbalancedLoadComponent implements OnInit {
  unbalancedForm: FormGroup | any
  @Input()
  triggerUnbalancedLoadData: any
  constructor(public commonService: CommonService) {}

  ngOnInit(): void {
    this.setForm()
  }

  setForm(): void {
    this.unbalancedForm = new FormGroup({
      deviation: new FormControl('', [
        Validators.required,
        Validators.pattern('^[0-9]+(.[0-9]+)?$'),
      ]),
      kva: new FormControl('', [
        Validators.required,
        Validators.pattern('^[0-9]+(.[0-9]+)?$'),
      ]),
    })
  }

  setData(data: any): void {
    this.unbalancedForm.patchValue({
      deviation: data.deviation,
      kva: data.ratedKva,
    })
  }
}
