import { CreateUserLoginDetailModel } from '@app/models'

export class GetAccountList {
  static readonly type = '[ACCOUNT] Get Account List'
  constructor(public queryParam: any) {}
}

export class CreateUserAccount {
  static readonly type = '[ACCOUNT] Create userAccount'

  constructor(public userAccount: CreateUserLoginDetailModel) {}
}

export class UpdateUserAccount {
  static readonly type = '[ACCOUNT] Update user account'
  constructor(public userAccountData: CreateUserLoginDetailModel) {}
}

export class GetUserDetailsWithId {
  static readonly type = '[ACCOUNT]  Get Users Details'
  constructor(
    public emailId: string,
    public mobileNumber: any,
  ) {}
}

export class DeleteAccount {
  static readonly type = '[ACCOUNT] Delete Selected user'

  constructor(
    public emailId: string,
    public mobileNumber: any,
  ) {}
}
export class UpdateLoginUserPassword {
  static readonly type = '[Account] Update Login User Password'
  constructor(public password: string) {}
}

export class ForgotPassword {
  static readonly type = '[Account] Update Forgot Password'
  constructor(public emailMobileNo: string) {}
}

export class CheckAccountExistsByFirstName {
  static readonly type = '[Account] Check Account Exists By FirstName '

  constructor(
    public accountId: any,
    public firstName: string,
  ) {}
}

export class GetDeviceListByAccountId {
  static readonly type = '[DEVICE] Get Device List By Account Id'

  constructor(public queryParams: any) {}
}

export class SaveAccountDevice {
  static readonly type = '[DEVICE] Save Account Device'
  constructor(public requestBody: any) {}
}
