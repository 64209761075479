<form [formGroup]="kvaForm" class="k-form k-form-md">
  <div class="mb-20">
    <div class="d-flex gap-15">
      <kendo-label
        [for]="ratedKva"
        text="Rated KVA"
        labelCssClass="k-form-label"
        class="d-flex align-items-center w-100"
      >
        <span class="required">*</span>
      </kendo-label>
      <kendo-textbox
        formControlName="ratedKva"
        #ratedKva
        placeholder="Enter Rated KVA"
        (keypress)="commonService.numberOnly($event)"
      ></kendo-textbox>
    </div>
    <div
      *ngIf="
        kvaForm.get('ratedKva')?.invalid && kvaForm.get('ratedKva')?.touched
      "
      class="d-flex gap-15"
    >
      <div class="w-80"></div>
      <div>
        <p *ngIf="kvaForm.get('ratedKva')?.errors?.required" class="required">
          Rated KVA is required.
        </p>
        <p *ngIf="kvaForm.get('ratedKva')?.errors?.pattern" class="required">
          Please enter a valid number for Rated KVA.
        </p>
      </div>
    </div>
  </div>
</form>
