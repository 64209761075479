import { Component, OnInit } from '@angular/core'
import { DialogRef } from '@progress/kendo-angular-dialog'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { CommonService } from '@app/services'
import { Store } from '@ngxs/store'
import { NgxSpinnerService } from 'ngx-spinner'
import { CheckSubStationNameExist, CreateSubstation } from '@app/store'
import { ToastrService } from 'ngx-toastr'
import { NotificationMessage } from '@app/enums/notification'
import { noSpacesOrSpecialCharsValidator } from '@app/directive'
import swal from 'sweetalert'

@Component({
  selector: 'app-add-substation',
  templateUrl: './add-substation.component.html',
  styleUrl: './add-substation.component.scss',
})
export class AddSubstationComponent implements OnInit {
  substationForm: FormGroup | any
  substationId = 0
  substationData: any
  isAddMode = true
  constructor(
    public dialogRef: DialogRef,
    public commonService: CommonService,
    private store: Store,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
  ) {
    this.setForm()
  }

  setForm(): void {
    this.substationForm = new FormGroup({
      substation_AreaName: new FormControl('', [
        Validators.required,
        noSpacesOrSpecialCharsValidator(),
      ]),
    })
  }

  ngOnInit(): void {
    if (this.substationId > 0) {
      this.substationForm.controls.substation_AreaName.setValue(
        this.substationData.substationName,
      )
    }
  }

  onSubmit(): void {
    let textMessage = `You want to ${this.substationId === 0 ? 'add new' : 'update'} Substation - ${this.substationForm.get('substation_AreaName').value}.`

    swal({
      title: 'Are you sure ?',
      text: textMessage,
      icon: 'warning',
      buttons: {
        cancel: {
          text: 'Cancel',
          visible: true,
          closeModal: true,
        },
        confirm: {
          text: 'Yes',
        },
      },
      dangerMode: true,
    }).then((confirmed) => {
      if (confirmed) {
        this.spinner.show()
        this.store
          .dispatch(
            new CheckSubStationNameExist(
              this.isAddMode ? 0 : this.substationId,
              this.substationForm.get('substation_AreaName').value,
            ),
          )
          .subscribe((res) => {
            this.spinner.hide()
            if (!res.substation.isSubstationExists) {
              this.onSave()
            } else {
              this.toastr.error(NotificationMessage.sameSubstationErrorMsg)
            }
          })
      }
    })
  }

  onSave(): void {
    const param: any = {
      substationId: this.substationId,
      substationName: this.substationForm.get('substation_AreaName').value,
    }
    this.spinner.show()
    this.store.dispatch(new CreateSubstation(param)).subscribe(
      (res: any) => {
        this.spinner.hide()
        if (res.substation.isSubstationSuccess) {
          this.toastr.success(
            this.substationId === 0
              ? NotificationMessage.createSubstationSuccessMsg
              : NotificationMessage.updateSubstationSuccessMsg,
          )
          this.commonService.onDialogClose(this.dialogRef, true)
        }
      },
      (error) => {
        this.spinner.hide()
      },
    )
  }

  onCloseDialog(): void {
    this.commonService.onDialogClose(this.dialogRef, false)
  }
}
