import { Component, OnInit, Optional, ViewChild } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { TicketStatusEnum } from '@app/enums'
import { NotificationMessage } from '@app/enums/notification'
import { CommonService } from '@app/services'
import {
  AddComment,
  AddUpdateComplaint,
  GenerateTicketNumber,
  GetCommentsByTicketId,
  GetComplaintDataById,
  GetDeviceListWithPagination,
  GetTicketHistoryDataById,
  MultiImageFileUpload,
} from '@app/store'
import { Store } from '@ngxs/store'
import { DialogRef } from '@progress/kendo-angular-dialog'
import { EditorComponent } from '@progress/kendo-angular-editor'
import { Lightbox } from 'ngx-lightbox'
import { NgxPhotoEditorService } from 'ngx-photo-editor'
import { NgxSpinnerService } from 'ngx-spinner'
import { ToastrService } from 'ngx-toastr'
import { forkJoin, map } from 'rxjs'

@Component({
  selector: 'app-add-complaint',
  templateUrl: './add-complaint.component.html',
  styleUrl: './add-complaint.component.scss',
})
export class AddComplaintComponent implements OnInit {
  @ViewChild('CommentfileInput') commentfileInput: any
  @ViewChild('fileInput') fileInput: any

  complaintForm: FormGroup | any
  isAddMode = true
  commentFilesName: any[] = []
  complaintImageList: any[] = []
  tempComplaintImageList: any[] = []
  commentComplaintImageList: any[] = []
  filesName: any[] = []
  tempFilesName: any[] = []
  ticketCreatedId: any
  initiatedByList: any[] = [
    { id: 1, name: 'rohan' },
    { id: 2, name: 'darshan' },
    { id: 3, name: 'fleming' },
  ]

  deviceList: any[] = []

  statusList: { id: number; name: string }[] = []

  commentList: any = [
    {
      id: 1,
      name: 'Anamika Jain',
      date: 'Jun 24, 2024 06:52 AM (Just now)',
      description:
        'Transformer has been repaired successfully. Now it is working fine',
      img: 'tem.png',
    },
    {
      id: 2,
      name: 'Anamika Jain',
      date: 'Jun 24, 2024 06:52 AM (Just now)',
      description:
        'Transformer has been repaired successfully. Now it is working fine',
      img: 'tem.png',
    },
  ]

  activityList: any[] = []
  currentUserDetails: any
  ticketNumber: any
  seletedIndex = 0
  @ViewChild('editor') editor!: EditorComponent
  constructor(
    @Optional() private dialogRef: DialogRef,
    private toastr: ToastrService,
    private _Activatedroute: ActivatedRoute,
    private service: NgxPhotoEditorService,
    private _lightbox: Lightbox,
    private store: Store,
    private spinner: NgxSpinnerService,
    private commonService: CommonService,
    private router: Router,
  ) {
    this.currentUserDetails = this.commonService.getLoggedInUserDetails()

    this.statusList = this.convertEnumToArray(TicketStatusEnum)
    this._Activatedroute.paramMap.subscribe((params: any) => {
      if (params.keys.length > 0) {
        this.isAddMode = atob(params.get('isAddMode')) === 'true' ? true : false
        this.ticketCreatedId = +atob(params.get('id'))
      }
      this.setForm()
      this.getData()
    })
  }

  ngOnInit(): void {}

  getData(): void {
    const requests: any = {
      deviceList: this.store.dispatch(new GetDeviceListWithPagination()).pipe(
        map((res: any) =>
          res.device.deviceList.map((device: any) => ({
            id: device.deviceId,
            name: device.deviceName,
          })),
        ),
      ),
    }

    if (this.isAddMode) {
      requests['ticketNumber'] = this.store
        .dispatch(new GenerateTicketNumber())
        .pipe(map((res: any) => res.complaints.ticketNumber))
    }

    forkJoin(requests).subscribe({
      next: (response: any) => {
        this.deviceList = response.deviceList
        if (this.isAddMode) {
          this.ticketNumber = response.ticketNumber
          this.complaintForm.controls.ticketId.setValue(this.ticketNumber)
        } else {
          this.getComplaintDataById()
        }
      },
      error: (err) => {
        console.error('Error fetching data', err)
      },
    })
  }

  convertEnumToArray<T>(enumObj: any): { id: number; name: string }[] {
    return Object.keys(enumObj)
      .filter((key) => isNaN(Number(key)))
      .map((key) => ({
        id: enumObj[key as keyof T],
        name: key,
      }))
  }

  onSelectCommentFile(event: any): void {
    const file = event.target.files[0]
    if (file.type === 'image/jpeg' || file.type === 'image/png') {
      this.bindCommentImageToEditor(file)
    } else {
      this.toastr.error(NotificationMessage.imageInputNotValidMsg)
    }
  }

  onSelectComplaintFile(event: any): void {
    const file = event.target.files[0]
    if (file.type === 'image/jpeg' || file.type === 'image/png') {
      this.bindImageToEditor(file)
    } else {
      this.toastr.error(NotificationMessage.imageInputNotValidMsg)
    }
  }

  bindCommentImageToEditor(file: any): void {
    this.service
      .open(event, {
        aspectRatio: 4 / 3,
        autoCropArea: 1,
      })
      .subscribe((data) => {
        this.commentComplaintImageList.push({
          ...data,
          fileName: data.file?.name,
          src: data.base64,
        })
        this.commentfileInput.nativeElement.value = ''
      })
  }

  bindImageToEditor(file: any): void {
    this.service
      .open(event, {
        aspectRatio: 4 / 3,
        autoCropArea: 1,
      })
      .subscribe((data) => {
        this.complaintImageList.push({
          ...data,
          fileName: data.file?.name,
          src: data.base64,
        })
        this.fileInput.nativeElement.value = ''
      })
  }

  close(): void {
    this._lightbox.close()
  }

  onTabSelect(event: any): void {
    this.seletedIndex = event.index
  }

  onImageOpen(index: any): void {
    this._lightbox.open(this.complaintImageList, index, {
      disableScrolling: true,
      centerVertically: true,
      showDownloadButton: true,
    })
  }

  onCommentImageOpen(index: any): void {
    this._lightbox.open(this.commentComplaintImageList, index, {
      disableScrolling: true,
      centerVertically: true,
      showDownloadButton: true,
    })
  }

  onCommentListImageOpen(ticketCommentImageModels: any, index: any): void {
    this._lightbox.open(ticketCommentImageModels, index, {
      disableScrolling: true,
      centerVertically: true,
      showDownloadButton: true,
    })
  }

  getInitials(name: string): string {
    const nameArray = name.split(' ')
    const initials = nameArray.map((n) => n[0]).join('')
    return initials
  }

  setForm(): void {
    this.complaintForm = new FormGroup({
      ticketId: new FormControl(this.isAddMode ? this.ticketNumber : '', [
        Validators.required,
      ]),
      initiatedBy: new FormControl(this.currentUserDetails.FirstName ?? ''),
      device: new FormControl(null, [Validators.required]),
      status: new FormControl(this.isAddMode ? this.statusList[0] : null),
      description: new FormControl(''),
      commentDescription: new FormControl(''),
    })
  }

  getComplaintDataById(): void {
    this.store
      .dispatch(new GetComplaintDataById(this.ticketCreatedId))
      .subscribe((res) => {
        const response = res.complaints.complaintData
        if (response !== undefined && response !== null) {
          this.setCompliantData(response)
        }
      })
  }

  setCompliantData(data: any): void {
    this.complaintImageList = data.complaintImages ?? []
    if (this.complaintImageList.length > 0)
      this.complaintImageList = this.complaintImageList.map((x: any) => ({
        ...x,
        src: x.base64,
      }))
    this.filesName = []
    if (this.complaintImageList.length > 0) {
      this.complaintImageList.forEach((element) => {
        this.filesName.push(element.fileName)
      })
    }

    this.complaintForm.patchValue({
      ticketId: data.ticketId,
      description: data.description,
      device:
        this.deviceList.length > 0
          ? this.deviceList.filter((x) => x.id === data.deviceIds)[0]
          : null,
      status:
        this.statusList.length > 0
          ? this.statusList.filter((x) => x.id === +data.status)[0]
          : null,
    })

    this.getCommentList()
  }

  getTicketHistoryList(): void {
    this.spinner.show()
    this.store
      .dispatch(new GetTicketHistoryDataById(this.ticketCreatedId))
      .subscribe((res) => {
        this.spinner.hide()
        this.activityList = res.complaints.ticketHistoryData
      })
  }

  getCommentList(): void {
    this.store
      .dispatch(
        new GetCommentsByTicketId(this.complaintForm.controls.ticketId.value),
      )
      .subscribe((res) => {
        this.commentList = res.complaints.commentsList

        if (this.commentList.length > 0) {
          this.commentList.forEach((element: any) => {
            if (
              element.ticketCommentImageModels !== null &&
              element.ticketCommentImageModels !== undefined
            ) {
              element.ticketCommentImageModels =
                element.ticketCommentImageModels.map((x: any) => ({
                  ...x,
                  src: x.base64,
                }))
            }
          })
        }

        this.getTicketHistoryList()
      })
  }

  removeCommentComplaintImage(i: any): void {
    this.commentComplaintImageList.splice(i, 1)
  }

  onCancel(): void {
    this.redirectToCompliantList(false)
  }

  redirectToCompliantList(isSaved: boolean): void {
    if (this.isAddMode) {
      this.dialogRef.close({ isSave: isSaved })
    } else {
      this.router.navigate(['complaints'])
    }
  }

  onDeleteComplaintImage(index: any): void {
    const indexData = this.filesName.indexOf(
      this.complaintImageList[index].fileName,
    )
    if (indexData !== -1) {
      this.filesName.splice(indexData, 1)
    }

    this.complaintImageList.splice(index, 1)
  }

  onSubmit(): void {
    if (this.complaintImageList.length > 0) {
      let files: any[] = []

      this.complaintImageList.forEach((element) => {
        if (element.id === undefined || element.id === null) {
          files.push(element.file)
        }
      })
      if (files.length > 0) {
        this.spinner.show()
        this.store.dispatch(new MultiImageFileUpload(files)).subscribe(
          (res) => {
            this.tempFilesName = res.complaints.imageUploadedList
            this.saveComplaint()
          },
          (error) => {
            this.spinner.hide()
          },
        )
      } else {
        this.spinner.show()
        this.saveComplaint()
      }
    } else {
      this.spinner.show()
      this.saveComplaint()
    }
  }

  saveComplaint(): void {
    if (this.tempFilesName.length > 0) {
      this.tempFilesName.forEach((element) => {
        this.filesName.push(element)
      })
    }

    const param = {
      id: this.isAddMode ? 0 : this.ticketCreatedId,
      ticketId: this.complaintForm.get('ticketId').value,
      description: this.complaintForm.get('description').value,
      intiatedBy: this.complaintForm.get('initiatedBy').value,
      deviceIds: this.complaintForm.get('device').value?.id ?? null,
      status: this.complaintForm.get('status').value?.id ?? null,
      filesName: this.filesName,
    }

    this.store.dispatch(new AddUpdateComplaint(param)).subscribe(
      (res) => {
        this.spinner.hide()
        this.toastr.success(
          this.isAddMode
            ? NotificationMessage.ComplaintCreatedSuccessMsg
            : NotificationMessage.ComplaintUpdatedSuccessMsg,
        )
        this.redirectToCompliantList(true)
      },
      (error) => {
        this.spinner.hide()
      },
    )
  }

  onSubmitComment(): void {
    if (this.commentComplaintImageList.length > 0) {
      let files: any[] = []

      this.commentComplaintImageList.forEach((element) => {
        files.push(element.file)
      })
      this.spinner.show()
      this.store.dispatch(new MultiImageFileUpload(files)).subscribe(
        (res) => {
          this.commentFilesName = res.complaints.imageUploadedList
          this.saveComment()
        },
        (error) => {
          this.spinner.hide()
        },
      )
    } else {
      this.spinner.show()
      this.saveComment()
    }
  }

  saveComment(): void {
    const param = {
      ticketId: this.complaintForm.get('ticketId').value,
      comment: this.complaintForm.get('commentDescription').value,
      filesName: this.commentFilesName,
    }

    this.store.dispatch(new AddComment(param)).subscribe(
      (res) => {
        this.spinner.hide()

        this.commentComplaintImageList = []
        this.commentFilesName = []
        this.editor.value = ''
        this.complaintForm.get('commentDescription').setValue(' ')
        this.toastr.success(NotificationMessage.CommentCreatedSuccessMsg)
        this.getCommentList()
      },
      (error) => {
        this.spinner.hide()
      },
    )
  }

  removeParagraphTag(comment: string): string {
    return comment.replace(/<p[^>]*>|<\/p>/g, '')
  }

  redirectToComplaintGrid() {
    this.router.navigate(['/complaints'])
  }
}
