<div class="q-content">
  <div
    class="d-flex gap-15 flex-wrap justify-content-between align-items-center mb-20"
  >
    <app-common-header-text
      [triggerGetUpdatedTime]="triggerGetUpdatedTime"
    ></app-common-header-text>
    <div class="d-flex gap-15 flex-wrap">
      <button
        *ngIf="hasAddPermission$ | async"
        kendoButton
        id="create"
        type="button"
        (click)="onAddFeeder(true)"
      >
        <i class="far fa-plus mr-3"></i> Add Feeder Device
      </button>
    </div>
  </div>
  <div class="q-content-bg grid-wrapper">
    <ng-container *ngIf="moduleId$ | async as moduleId">
      <app-common-grid
        [gridView]="gridView"
        [columns]="gridView?.columns ?? []"
        [actions]="actions"
        [showActions]="true"
        [pageSize]="this.pageSize"
        [moduleId]="moduleId"
        (sortChange)="onSortChange($event)"
        (paginationChange)="onPaginationChange($event)"
        (triggerActionEvent)="onActionClick($event)"
        (deleteConfirmed)="onDeleteFeeder($event)"
        (filterChangeSQL)="onFilterChangeSQL($event)"
      ></app-common-grid>
    </ng-container>
  </div>
</div>
<div kendoDialogContainer></div>
