import { Component, ViewChild } from '@angular/core'
import { CommonGridComponent } from '@app/components/common'
import { CommonService } from '@app/services'
import { CommonState, GetSentNotificationData } from '@app/store'
import { Select, Store } from '@ngxs/store'
import { GridDataResult } from '@progress/kendo-angular-grid'
import { NgxSpinnerService } from 'ngx-spinner'
import { Observable, Subject } from 'rxjs'

@Component({
  selector: 'app-sent-notifcation-list',
  templateUrl: './sent-notifcation-list.component.html',
  styleUrl: './sent-notifcation-list.component.scss',
})
export class SentNotifcationListComponent {
  triggerGetUpdatedTime: Subject<any> = new Subject<any>()
  @ViewChild(CommonGridComponent) commonGridComponent:
    | CommonGridComponent
    | undefined

  gridView: GridDataResult | any
  feederAreaId: any
  deviceId: any
  startDate = null
  endDate = null
  pageNumber = 1
  pageSize = 10
  skip = 0
  sort: any
  sortOrder = false
  isDataLoaded = false
  previousSort: any = null
  filterQuery: string | any
  @Select(CommonState.moduleId)
  moduleId$!: Observable<number>
  infoText: string =
    'The table display the messages have been sent to the users along with sent time also.'

  constructor(
    private store: Store,
    private spinner: NgxSpinnerService,
    public commonService: CommonService,
  ) {}

  ngOnInit(): void {}

  getList(deviceId: any, feederAreaId: any, startDate: any, endDate: any) {
    const params = {
      deviceId: deviceId,
      feederAreaId: feederAreaId,
      pageNumber: this.pageNumber,
      pageSize: this.pageSize,
      sortBy: this.sort ?? null,
      sortOrder: this.sortOrder,
      filter: 0,
      search: null,
      startDate: startDate,
      endDate: endDate,
      moduleId: this.store.selectSnapshot(CommonState.moduleId),
      filterQuery: this.filterQuery,
    }
    this.spinner.show()

    this.store.dispatch(new GetSentNotificationData(params)).subscribe(
      (res: any) => {
        this.spinner.hide()
        const { data, totalCount } = res.sentNotification.sentNotificationData

        this.gridView = {
          data: data.rows,
          total: totalCount,
          columns: data.columns,
        }

        this.isDataLoaded = true
      },
      (error) => {
        this.spinner.hide()
      },
    )
  }

  onColumnSelector(): void {
    this.commonGridComponent?.onColumnSelector()
  }

  onSortChange(sortData: any) {
    const currentSortField = sortData.sort[0].field
    if (this.previousSort === currentSortField) {
      this.sortOrder = !this.sortOrder
    } else {
      this.sortOrder = true
    }
    this.sort = currentSortField
    this.previousSort = currentSortField
    this.getList(this.deviceId, this.feederAreaId, this.startDate, this.endDate)
  }

  onFilterChangeSQL(sqlQuery: any) {
    this.filterQuery = sqlQuery
    this.getList(this.deviceId, this.feederAreaId, this.startDate, this.endDate)
  }

  onPaginationChange({ skip, take }: any) {
    this.skip = skip
    this.pageSize = take
    this.pageNumber = this.skip / this.pageSize + 1

    this.getList(this.deviceId, this.feederAreaId, this.startDate, this.endDate)
  }

  getDataByHeaderData(event: any) {
    this.deviceId = event.deviceId
    this.feederAreaId = event.feederAreaId
    this.startDate = event.startDate
    this.endDate = event.endDate
    this.getList(this.deviceId, this.feederAreaId, this.startDate, this.endDate)
    this.triggerGetUpdatedTime.next(true)
  }

  getDataFromColumnSelector(event: any) {
    this.getList(this.deviceId, this.feederAreaId, this.startDate, this.endDate)
  }
}
