<kendo-dialog-titlebar (close)="onCloseDialog()">
  <div>{{ formName }}</div>
</kendo-dialog-titlebar>
<div>
  <form class="k-form k-form-md" [formGroup]="forgotPasswordChangeForm">
    <kendo-formfield class="mb-10 mt-5">
      <kendo-label
        [for]="emailMobileNo"
        text="Enter Registered Email/Mobile"
        labelCssClass="k-form-label"
        ><span class="required">*</span></kendo-label
      >
      <kendo-textbox
        formControlName="emailMobileNo"
        #emailMobileNo
        placeholder="Email/Mobile"
      ></kendo-textbox>
    </kendo-formfield>
  </form>
</div>
<kendo-dialog-actions>
  <button kendoButton fillMode="outline" (click)="onCloseDialog()">
    Cancel
  </button>
  <button kendoButton (click)="forgotPassword()">Submit</button>
</kendo-dialog-actions>
