import { Component, OnInit } from '@angular/core'
import { NotificationMessage } from '@app/enums/notification'
import { CommonState } from '@app/store'
import {
  GetAllAlertSettings,
  UpdateAlertSettings,
} from '@app/store/alert-settings'
import { Select, Store } from '@ngxs/store'
import { NgxSpinnerService } from 'ngx-spinner'
import { ToastrService } from 'ngx-toastr'
import { Observable, Subject } from 'rxjs'
import swal from 'sweetalert'

@Component({
  selector: 'app-alerts-settings',
  templateUrl: './alerts-settings.component.html',
  styleUrl: './alerts-settings.component.scss',
})
export class AlertsSettingsComponent implements OnInit {
  triggerGetUpdatedTime: Subject<any> = new Subject<any>()
  gridData: any = []
  gridHeight: number = 0
  intervalArray = Array.from({ length: 24 }, (_, index) => ({
    key: index + 1,
    value: index + 1,
  }))

  deviceId: any

  @Select(CommonState.hasAddPermission)
  hasAddPermission$!: Observable<boolean>

  @Select(CommonState.hasEditPermission)
  hasEditPermission$!: Observable<boolean>

  @Select(CommonState.moduleId)
  moduleId$!: Observable<number>
  isDataLoaded = false

  constructor(
    private store: Store,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
  ) {}

  ngOnInit(): void {
    this.calculateGridHeight()

    window.addEventListener('resize', () => {
      this.calculateGridHeight()
    })
  }

  calculateGridHeight(): void {
    const screenHeight = window.innerHeight
    const headerHeight = 81
    const gridHeaderHeight = 58
    const wrapperpadding = 20
    const footerHeight = 104

    this.gridHeight =
      screenHeight -
      (headerHeight + gridHeaderHeight + wrapperpadding + footerHeight)
  }

  onIntervalChange(value: any, item: any): void {
    item.alertIntervalTime = value.value
  }

  getIntervalObject(intervalValue: number): any {
    return this.intervalArray.find(
      (interval) => interval.value === intervalValue,
    )
  }

  GetAllAlertSettingsData(): void {
    this.spinner.show()
    let queryParams = {
      deviceId: this.deviceId,
      moduleId: this.store.selectSnapshot(CommonState.moduleId),
    }

    this.store.dispatch(new GetAllAlertSettings(queryParams)).subscribe(
      (res: any) => {
        this.spinner.hide()
        if (res.alertSettings.alertSettingsData) {
          this.gridData = res.alertSettings.alertSettingsData.data.rows
        } else {
          this.gridData = []
        }
      },
      (error) => {
        this.spinner.hide()
      },
    )
  }

  onEnableDisableChange(checked: boolean, dataItem: any): void {
    dataItem.isEmailAlertEnable = checked
    this.updateMobileAlertEnableState(dataItem)
  }

  onMobileNotifyChange(checked: boolean, dataItem: any): void {
    dataItem.isMobileAlertEnable = checked
  }

  updateMobileAlertEnableState(dataItem: any): void {
    if (!dataItem.isEmailAlertEnable) {
      dataItem.isMobileAlertEnable = false
    }
  }

  onSubmit(): void {
    let textMessage = `You want to change Alert Settings.`

    swal({
      title: 'Are you sure ?',
      text: textMessage,
      icon: 'warning',
      buttons: {
        cancel: {
          text: 'Cancel',
          visible: true,
          closeModal: true,
        },
        confirm: {
          text: 'Yes',
        },
      },
      dangerMode: true,
    }).then((confirmed) => {
      if (confirmed) {
        this.spinner.show()
        this.store
          .dispatch(new UpdateAlertSettings(this.gridData, this.deviceId))
          .subscribe(
            (res: any) => {
              this.spinner.hide()
              if (res.alertSettings.isAlertSettingsUpdated) {
                this.toastr.success(NotificationMessage.AlertSettingSuccessMsg)
                this.GetAllAlertSettingsData()
              }
            },
            (error) => {
              this.spinner.hide()
            },
          )
      }
    })
  }

  getDataByHeaderData(event: any): void {
    this.deviceId = event.deviceId
    this.GetAllAlertSettingsData()
    this.triggerGetUpdatedTime.next(true)
  }
}
