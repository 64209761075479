import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import {
  AddUpdateRoleComponent,
  CommonsModule,
  RoleComponent,
  RoleRoutingModule,
} from '@app/index'
import { SharedModule } from '@shared//shared.module'
import { ReactiveFormsModule } from '@angular/forms'

@NgModule({
  declarations: [RoleComponent, AddUpdateRoleComponent],
  imports: [
    CommonModule,
    RoleRoutingModule,
    SharedModule,
    CommonsModule,
    ReactiveFormsModule,
  ],
})
export class RoleModule {}
