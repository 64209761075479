import { Injectable } from '@angular/core'
import { Action, State, StateContext } from '@ngxs/store'
import { RoleService } from '@app/services'
import { tap } from 'rxjs'
import {
  AddRole,
  CheckRoleExistsByName,
  DeleteRole,
  GetRoleDetail,
  GetRoleList,
  UpdateRole,
} from '@app/index'
export class RoleStateInfo {
  roleList?: any
  roleDetail?: any
  isRoleExists?: boolean
}

@State<RoleStateInfo>({
  name: 'role',
  defaults: {},
})
@Injectable()
export class RoleState {
  constructor(private roleService: RoleService) {}

  @Action(GetRoleList)
  getRoleList(
    { getState, patchState }: StateContext<RoleStateInfo>,
    action: GetRoleList,
  ) {
    return this.roleService.getRoleList(action.queryParam).pipe(
      tap((res: any) => {
        patchState({
          roleList: res,
        })
      }),
    )
  }

  @Action(AddRole)
  addRole(
    { getState, patchState }: StateContext<RoleStateInfo>,
    action: AddRole,
  ) {
    return this.roleService.addRole(action.addRoleRequestBody)
  }

  @Action(GetRoleDetail)
  getRoleDetails(
    { getState, patchState }: StateContext<RoleStateInfo>,
    action: GetRoleDetail,
  ) {
    return this.roleService.getRoleDetails(action.roleId).pipe(
      tap((res: any) => {
        patchState({
          roleDetail: res,
        })
      }),
    )
  }

  @Action(UpdateRole)
  updateRole(
    { getState, patchState }: StateContext<RoleStateInfo>,
    action: UpdateRole,
  ) {
    return this.roleService.updateRole(action.updateRoleRequestBody)
  }

  @Action(DeleteRole)
  deleteRole(
    { getState, patchState }: StateContext<RoleStateInfo>,
    action: DeleteRole,
  ) {
    return this.roleService.deleteRole(action.roleId)
  }

  @Action(CheckRoleExistsByName)
  checkRoleExistsByName(
    { getState, patchState }: StateContext<RoleStateInfo>,
    action: CheckRoleExistsByName,
  ) {
    return this.roleService
      .checkRoleExistsByName(action.roleId, action.name)
      .pipe(
        tap((res: boolean) => {
          patchState({
            isRoleExists: res,
          })
        }),
      )
  }
}
