import { Injectable } from '@angular/core'
import {
  GetAllSubStation,
  SSSLDDashboardService,
  SSSLDDashboardtList,
} from '@app/index'
import { Action, Selector, State, StateContext } from '@ngxs/store'
import { tap } from 'rxjs'

export class SSSLDDashboardStateInfo {
  ssslddashboardListData?: any
  subStationList?: any
}

@State<SSSLDDashboardStateInfo>({
  name: 'ssslddashboard',
  defaults: {},
})
@Injectable()
export class SSSLDDashboardState {
  constructor(private sslddashboardService: SSSLDDashboardService) {}

  @Selector()
  static getSSSLDDashboardData(state: SSSLDDashboardStateInfo) {
    return state.ssslddashboardListData
  }

  @Action(SSSLDDashboardtList)
  getSSSLDDashboardList(
    { getState, patchState }: StateContext<SSSLDDashboardStateInfo>,
    action: any,
  ) {
    return this.sslddashboardService
      .getSSSLDDashboardList(action.queryParams, action.name)
      .pipe(
        tap((res: any) => {
          patchState({
            ssslddashboardListData: res,
          })
        }),
      )
  }

  @Action(GetAllSubStation)
  getAllSubStation(
    { getState, patchState }: StateContext<SSSLDDashboardStateInfo>,
    action: GetAllSubStation,
  ) {
    return this.sslddashboardService.getAllSubStation().pipe(
      tap((res: any) => {
        patchState({
          subStationList: res,
        })
      }),
    )
  }
}
