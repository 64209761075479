import { Component, Input, OnInit } from '@angular/core'
import { FormControl, FormGroup } from '@angular/forms'
import { Observable } from 'rxjs'

@Component({
  selector: 'app-unity-pf',
  templateUrl: './unity-pf.component.html',
  styleUrl: './unity-pf.component.scss',
})
export class UnityPfComponent implements OnInit {
  unityPfForm: FormGroup | any
  @Input() triggerUnityPfData: Observable<any> | undefined
  constructor() {}

  ngOnInit(): void {
    this.setForm()
    this.triggerUnityPfData?.subscribe((res: any) => {
      if (res) {
        this.setData(res)
      } else {
        this.setForm()
      }
    })
  }

  setData(data: any): void {
    this.unityPfForm.patchValue({
      unity: data.toString(),
    })
  }

  setForm(): void {
    this.unityPfForm = new FormGroup({
      unity: new FormControl(false),
    })
  }
}
