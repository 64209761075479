import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { environment } from 'src/environments/environment'

@Injectable()
export class DashboarddtrService {
  constructor(private http: HttpClient) {}

  dashboarddtrData(feederName: string, dashboardType: string): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    }

    return this.http.get<any>(
      `${environment.apiVersionUrl}Dashboard/GetDashboardDTRData/${feederName}/${dashboardType}`,
      headers,
    )
  }

  getDashboardDTRStatusDeviceList(
    status: number,
    queryParam: any,
    feederType: number,
  ): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    }

    return this.http.post<any>(
      `${environment.apiVersionUrl}Dashboard/GetDashboardDetails/FeederType/${feederType}/DetailType/${status}`,
      queryParam,
      headers,
    )
  }
}
