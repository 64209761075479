import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable, of } from 'rxjs'
import { environment } from 'src/environments'

@Injectable()
export class OfficeSettingsService {
  constructor(private http: HttpClient) {}

  getOfficeLoadSetting(deviceId: any): Observable<any> {
    return this.http.get<any>(
      `${environment.apiVersionUrl}OfficeSetting/GetOfficeLoadSettingSettings/${deviceId}`,
    )
  }

  getDeviceReportsData(
    officeSettingType: any,
    parameters: any,
  ): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    }

    return this.http.post<any>(
      `${environment.apiVersionUrl}OfficeSetting/GetAll/${officeSettingType}`,
      parameters,
      headers,
    )
  }

  createLoadedSetting(loadedSettingData: any): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    }

    return this.http.put<any>(
      `${environment.apiVersionUrl}OfficeSetting/UpdateLoadedSettingsSetting/${loadedSettingData.deviceId}`,
      JSON.stringify(loadedSettingData),
      headers,
    )
  }

  cancelDeviceSetting(reqBody: any): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    }
    const url = `${environment.apiVersionUrl}Setting/cancelDeviceSetting`
    return this.http.put<any>(url, JSON.stringify(reqBody), headers)
  }
}
