import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { SettingsComponent } from './settings.component'
import { ThresholdSettingsComponent } from './threshold-settings/threshold-settings.component'
import { AlertsSettingsComponent } from './alerts-settings/alerts-settings.component'
import { AuthGuard } from '@app/index'

const routes: Routes = [
  { path: '', component: SettingsComponent, canActivate: [AuthGuard] },
  {
    path: 'threshold-settings',
    component: ThresholdSettingsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'alerts-settings',
    component: AlertsSettingsComponent,
    canActivate: [AuthGuard],
  },
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SettingsRoutingModule {}
