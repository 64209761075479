<kendo-dialog-titlebar (close)="onCloseDialog({ text: 'close' })">
  <span>{{ headerText }}</span>
</kendo-dialog-titlebar>

<form class="k-form k-form-md" [formGroup]="addUpdateRoleForm">
  <fieldset class="k-form-fieldset">
    <kendo-formfield>
      <input
        #name
        type="text"
        kendoTextBox
        formControlName="name"
        appNoSpacesOrSpecialChars
      />
      <kendo-label labelCssClass="k-form-label" [for]="name" text="Name"
        ><span class="required">*</span></kendo-label
      >
    </kendo-formfield>

    <kendo-formfield class="mb-10">
      <kendo-label
        labelCssClass="k-form-label"
        [for]="description"
        text="Description"
      ></kendo-label>
      <kendo-textbox
        formControlName="description"
        #description
        appNoSpacesOrSpecialChars
      ></kendo-textbox>
    </kendo-formfield>
  </fieldset>
</form>

<kendo-dialog-actions>
  <button
    kendoButton
    (click)="onCloseDialog({ text: 'close' })"
    fillMode="outline"
  >
    Cancel
  </button>
  <div
    [ngClass]="{ 'curser-not-allowd': addUpdateRoleForm.invalid }"
    class="display-inline flex-0"
  >
    <button
      kendoButton
      (click)="onSubmit()"
      [disabled]="addUpdateRoleForm.invalid"
    >
      {{ isAddRole ? 'Add Role' : 'Update Role' }}
    </button>
  </div>
</kendo-dialog-actions>
