<div class="q-content">
  <div class="mb-20">
    <app-common-header-text
      [triggerGetUpdatedTime]="triggerGetUpdatedTime"
    ></app-common-header-text>
  </div>
  <div class="q-content-bg">
    <kendo-tabstrip (tabSelect)="onTabSelect($event)">
      <kendo-tabstrip-tab title="OTA Update" [selected]="true">
        <ng-template kendoTabContent>
          <div class="content">
            <app-ota-update></app-ota-update>
          </div>
        </ng-template>
      </kendo-tabstrip-tab>
      <kendo-tabstrip-tab title="OTA Status">
        <ng-template kendoTabContent>
          <div class="content">
            <div class="d-flex gap-15 flex-wrap mb-20 justify-content-end">
              <app-common-header
                (triggerDataFromHeader)="getDataByHeaderData($event)"
                [showDateFilter]="false"
              ></app-common-header>
              <div class="drag-icon-container" (click)="onColumnSelector()">
                <a class="drag-icon">
                  <img
                    src="assets/images/custom-columns.svg"
                    alt="column-select"
                  />
                </a>
              </div>
            </div>
            <app-ota-status
              [triggerSoftwareStatus]="triggerSoftwareStatus"
              [triggerColumnSelector]="triggerColumnSelector"
            ></app-ota-status>
          </div>
        </ng-template>
      </kendo-tabstrip-tab>
    </kendo-tabstrip>
  </div>
</div>
