import { AlertSettingsModel } from '@app/models/alert-settings'

export class GetAllAlertSettings {
  static readonly type = '[ALERT] Get All Alert Settings'

  constructor(public queryParams: any) {}
}

export class UpdateAlertSettings {
  static readonly type = '[ALERT] Update Alert Settings'

  constructor(
    public alertSettingsData: Array<AlertSettingsModel>,
    public deviceId: string,
  ) {}
}
