import { Component, Input, OnInit } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { CommonService } from '@app/services'
import { Observable } from 'rxjs'

@Component({
  selector: 'app-kva',
  templateUrl: './kva.component.html',
  styleUrl: './kva.component.scss',
})
export class KvaComponent implements OnInit {
  kvaForm: FormGroup | any
  @Input()
  triggerKvaData: Observable<any> | undefined
  constructor(public commonService: CommonService) {}

  ngOnInit(): void {
    this.setForm()
    this.triggerKvaData?.subscribe((res: any) => {
      if (res) {
        this.setData(res)
      } else {
        this.setForm()
      }
    })
  }

  setData(data: any): void {
    this.kvaForm.patchValue({
      ratedKva: data,
    })
  }

  setForm(): void {
    this.kvaForm = new FormGroup({
      ratedKva: new FormControl('', [
        Validators.required,
        Validators.pattern('^[0-9]+(.[0-9]+)?$'),
      ]),
    })
  }
}
