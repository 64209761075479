import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable, of } from 'rxjs'
import { environment } from 'src/environments'

@Injectable()
export class AssignHierarchicalStructureService {
  constructor(private http: HttpClient) {}
  stateData = [
    { stateName: 'Gujrat', stateId: 1, countryId: 1 },
    { stateName: 'Mumbai', stateId: 2, countryId: 1 },
    { stateName: 'Hydarabad', stateId: 3, countryId: 1 },
  ]

  regionData = [
    { regionName: 'North Gujrat', regionId: 1, stateId: 1 },
    { regionName: 'South Gujrat', regionId: 2, stateId: 3 },
    { regionName: 'Central Gujrat', regionId: 3, stateId: 2 },
  ]

  districtData = [
    { districtName: 'Aand', districtId: 1, regionId: 1 },
    { districtName: 'Vadodara', districtId: 2, regionId: 2 },
    { districtName: 'Charoter', districtId: 3, regionId: 3 },
  ]

  wardData = [
    { wardName: 'Ward 1', wardId: 1, districtId: 1 },
    { wardName: 'Ward 2', wardId: 2, districtId: 3 },
    { wardName: 'Ward 3', wardId: 3, districtId: 2 },
  ]

  permissionData = [
    // {StateData: {show: true , isMutliSelect:  false} ,
    // RegionData:{show: true , isMutliSelect:  false} ,
    // DistrictData: {show: true , isMutliSelect: false}  ,
    // CityData :{show: true , isMutliSelect:  false},
    // ZoneData :{show: true , isMutliSelect:  false} ,
    // WardData :{show: true , isMutliSelect:  true}}
    {
      id: 1,
      userId: null,
      roleId: 0,
      createdBy: 0,
      country: {
        isVisible: true,
        isMutipleSelect: false,
        value: ['India', 'India1', 'India2', 'India3'],
      },
      state: {
        isVisible: true,
        isMutipleSelect: false,
        value: ['Gujarat', 'Maharastra', 'Hydarabad'],
      },
      region: {
        isVisible: true,
        isMutipleSelect: true,
        value: [],
      },
      district: {
        isVisible: false,
        isMutipleSelect: false,
        value: ['Central District'],
      },
      city: {
        isVisible: false,
        isMutipleSelect: false,
        value: [],
      },
      zone: {
        isVisible: false,
        isMutipleSelect: false,
        value: [],
      },
    },
  ]

  getStateList(country: any): Observable<any> {
    return this.http.get<any>(
      `${environment.apiVersionUrl}HierarchicalUserRole/getStateList/${country}`,
    )
  }

  getDistrictList(region: any): Observable<any> {
    return this.http.get<any>(
      `${environment.apiVersionUrl}HierarchicalUserRole/getDistrictList/${region}`,
    )
  }

  getCityList(destrict: any): Observable<any> {
    return this.http.get<any>(
      `${environment.apiVersionUrl}HierarchicalUserRole/getCityList/${destrict}`,
    )
  }

  getZoneList(city: any): Observable<any> {
    return this.http.get<any>(
      `${environment.apiVersionUrl}HierarchicalUserRole/getZoneList/${city}`,
    )
  }

  getHierarchicalUserRole(userId: any): Observable<any> {
    return this.http.get<any>(
      `${environment.apiVersionUrl}HierarchicalUserRole/getHierarchicalUserRole/${userId}`,
    )
  }

  saveHierarchicalUserRole(queryParams: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      observe: 'response' as 'response',
    }

    return this.http.post<any>(
      `${environment.apiVersionUrl}HierarchicalUserRole/saveHierarchicalUserRole`,
      queryParams,
      httpOptions,
    )
  }
}
