import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { SystemBatteryComponent } from './system-battery.component'
import { StatusComponent } from './status'
import { AuthGuard } from '@app/index'

const routes: Routes = [
  { path: '', component: SystemBatteryComponent, canActivate: [AuthGuard] },
  { path: 'status', component: StatusComponent, canActivate: [AuthGuard] },
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SystemBatteryRoutingModule {}
