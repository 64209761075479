import { drawing as draw } from '@progress/kendo-drawing';

import { ChartElement, Box, Point } from '../../core';
import PointEventsMixin from '../mixins/point-events-mixin';

import { WHITE } from '../../common/constants';
import { deepExtend, setDefaultOptions, getTemplate } from '../../common';
import AccessibilityAttributesMixin from '../mixins/accessibility-attributes-mixin';
import { CHART_POINT_CLASSNAME, CHART_POINT_ROLE, CHART_POINT_ROLE_DESCRIPTION } from '../constants';

class FunnelSegment extends ChartElement {
    constructor(value, options, segmentOptions) {
        super(options);

        this.value = value;
        this.options.index = segmentOptions.index;
    }

    getLabelText(options) {
        let labelTemplate = getTemplate(options);

        if (labelTemplate) {
            return labelTemplate(this.pointData());
        } else if (options.format) {
            return this.formatValue(options.format);
        }

        return this.value;
    }

    reflow(chartBox) {
        const points = this.points;
        const label = this.children[0];
        const x1 = Math.min(points[0].x, points[3].x);
        const x2 = Math.max(points[1].x, points[2].x);

        this.box = new Box(x1, points[0].y, x2, points[2].y);

        if (label) {
            label.reflow(new Box(chartBox.x1, points[0].y, chartBox.x2, points[2].y));
        }
    }

    createVisual() {
        const options = this.options;
        let visual;

        super.createVisual();

        this.addAccessibilityAttributesToVisual();

        if (options.visual) {
            visual = options.visual({
                category: this.category,
                dataItem: this.dataItem,
                value: this.value,
                series: this.series,
                percentage: this.percentage,
                points: this.points,
                options: options,
                sender: this.getSender(),
                createVisual: () => this.createPath()
            });
        } else {
            visual = this.createPath();
        }

        if (visual) {
            this.visual.append(visual);
        }
    }

    createPath() {
        const options = this.options;
        const border = options.border;
        const path = draw.Path.fromPoints(this.points, {
            fill: {
                color: options.color,
                opacity: options.opacity
            },
            stroke: {
                color: border.color,
                opacity: border.opacity,
                width: border.width
            }
        }).close();

        return path;
    }

    createHighlight(style) {
        return draw.Path.fromPoints(this.points, style);
    }

    highlightVisual() {
        return this.visual.children[0];
    }

    highlightVisualArgs() {
        const path = draw.Path.fromPoints(this.points).close();

        return {
            options: this.options,
            path: path
        };
    }

    createFocusHighlight(style) {
        const borderWidth = this.options.accessibility.highlight.border.width;
        const result = draw.Path.fromPoints(this.points, deepExtend({}, style, {
            stroke: {
                width: borderWidth * 2
            }
        })).close();

        const clipPath = new draw.MultiPath();

        clipPath.paths.push(draw.Path.fromRect(this.box.clone().pad(borderWidth).toRect()));
        clipPath.paths.push(draw.Path.fromPoints(this.points));

        result.clip(clipPath);

        return result;
    }

    tooltipAnchor() {
        const box = this.box;
        return {
            point: new Point(box.center().x, box.y1),
            align: {
                horizontal: "center",
                vertical: "top"
            }
        };
    }

    formatValue(format) {
        const point = this;
        return point.owner.formatPointValue(point, format);
    }

    pointData() {
        return {
            dataItem: this.dataItem,
            category: this.category,
            value: this.value,
            series: this.series,
            percentage: this.percentage
        };
    }

    getIndex() {
        return this.index;
    }
}

setDefaultOptions(FunnelSegment, {
    color: WHITE,
    border: {
        width: 1
    },
    labels: {
        format: ""
    },
    accessibility: {
        role: CHART_POINT_ROLE,
        className: CHART_POINT_CLASSNAME,
        ariaRoleDescription: CHART_POINT_ROLE_DESCRIPTION
    }
});

deepExtend(FunnelSegment.prototype, PointEventsMixin);
deepExtend(FunnelSegment.prototype, AccessibilityAttributesMixin);

export default FunnelSegment;