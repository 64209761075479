import { Injectable } from '@angular/core'
import { Selector, Action, StateContext, State } from '@ngxs/store'
import { tap } from 'rxjs'
import { GetPowerFactorAlert } from '.'
import { PFService } from '@app/services/alert/pf-list'

export class PFStateInfo {
  pfData?: any
}

@State<PFStateInfo>({
  name: 'pf',
  defaults: {},
})
@Injectable()
export class PFState {
  constructor(private pfService: PFService) {}

  @Selector()
  static GetPowerFactorAlert(state: PFStateInfo) {
    return state.pfData
  }

  @Action(GetPowerFactorAlert)
  getPowerFactorAlert(
    { getState, patchState }: StateContext<PFStateInfo>,
    action: any,
  ) {
    return this.pfService.getPowerFactorAlert(action.queryParams).pipe(
      tap((res: any) => {
        patchState({
          pfData: res.body,
        })
      }),
    )
  }
}
