<div class="q-content">
  <div class="mb-20">
    <app-common-header-text
      [triggerGetUpdatedTime]="triggerGetUpdatedTime"
    ></app-common-header-text>
  </div>
  <div class="q-content-bg">
    <kendo-tabstrip (tabSelect)="onTabSelect($event)">
      <kendo-tabstrip-tab title="Activation Code" [selected]="true">
        <ng-template kendoTabContent>
          <div class="tabs-content">
            <form [formGroup]="activationCodeForm" class="k-form k-form-md">
              <div>
                <kendo-formfield class="mt-0 mb-20 wmax-280">
                  <kendo-label
                    [for]="deviceId"
                    text="Device Id"
                    labelCssClass="k-form-label"
                    ><span class="required">*</span></kendo-label
                  >
                  <kendo-textbox
                    formControlName="deviceId"
                    #deviceId
                    placeholder="Enter Device Id"
                    appNoSpacesOrSpecialChars
                  ></kendo-textbox>
                </kendo-formfield>
                <kendo-formfield class="mt-0 mb-20 wmax-280">
                  <kendo-label
                    [for]="activationCode"
                    text="Activation code"
                    labelCssClass="k-form-label"
                    ><span class="required">*</span></kendo-label
                  >
                  <kendo-textbox
                    formControlName="activationCode"
                    #activationCode
                    placeholder="Enter Activation Code"
                    appNoSpacesOrSpecialChars
                  ></kendo-textbox>
                </kendo-formfield>
              </div>
            </form>
          </div>
          <div
            class="footer-button-container"
            [ngClass]="{ 'curser-not-allowd': activationCodeForm.invalid }"
          >
            <button
              kendoButton
              (click)="addActivation()"
              [disabled]="activationCodeForm.invalid"
            >
              Submit
            </button>
          </div>
        </ng-template>
      </kendo-tabstrip-tab>
      <kendo-tabstrip-tab title="Database">
        <ng-template kendoTabContent>
          <div class="tabs-content">
            <form
              [formGroup]="createDatabaseForm"
              class="k-form k-form-md mb-20"
            >
              <kendo-formfield class="mb-0 mt-0 wmax-280">
                <kendo-label
                  [for]="device"
                  text="Device"
                  labelCssClass="k-form-label"
                  ><span class="required">*</span></kendo-label
                >
                <kendo-multiselect
                  [data]="deviceData"
                  formControlName="device"
                  [textField]="'text'"
                  [valueField]="'value'"
                  (valueChange)="onValueChange()"
                  [autoClose]="false"
                  [filterable]="true"
                  [tagMapper]="tagMapper"
                  (filterChange)="filterChange($event)"
                  #device
                >
                  <ng-template kendoMultiSelectHeaderTemplate>
                    <span class="ml-10 pl-10" *ngIf="deviceData?.length">
                      <input
                        type="checkbox"
                        id="chk"
                        kendoCheckBox
                        [checked]="isChecked"
                        [indeterminate]="isIndet"
                        (click)="onClick()"
                      />
                      <kendo-label
                        class="font-size-14 ml-5 cursor-pointer"
                        for="chk"
                        (click)="onClick()"
                        >{{ toggleAllText }}</kendo-label
                      >
                    </span>
                  </ng-template>

                  <ng-template kendoMultiSelectItemTemplate let-dataItem>
                    <span>
                      <kendo-label for="chk-{{ dataItem.value }}">
                        <input
                          type="checkbox"
                          id="chk-{{ dataItem.value }}"
                          kendoCheckBox
                          [checked]="isItemSelected(dataItem)"
                        />
                      </kendo-label>
                    </span>
                    <span>{{ dataItem.text }}</span>
                  </ng-template>
                </kendo-multiselect>
              </kendo-formfield>
            </form>
          </div>
          <div
            class="footer-button-container"
            [ngClass]="{ 'curser-not-allowd': createDatabaseForm.invalid }"
          >
            <button
              kendoButton
              (click)="createDatabase()"
              [disabled]="createDatabaseForm.invalid"
            >
              Create Database
            </button>
          </div>
        </ng-template>
      </kendo-tabstrip-tab>
    </kendo-tabstrip>
  </div>
</div>
