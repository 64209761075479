import { Component, OnInit, ViewChild } from '@angular/core'
import { Select, Store } from '@ngxs/store'
import { Observable, Subject, switchMap } from 'rxjs'
import {
  GetAllThresholdSettings,
  ThresholdSettingsState,
  UpdateCtThreshold,
  UpdateCurrentThreshold,
  UpdateKVAThreshold,
  UpdateMinMaxThresholdVoltage,
  UpdatePFThreshold,
  UpdateTemperatureThreshold,
  UpdateUnityPFThreshold,
} from '@app/store/threshold-settings'
import {
  CommonState,
  CtSettingsComponent,
  GetDeviceIdByFeeder,
  KvaComponent,
  MaxCurrentComponent,
  MaxVoltageComponent,
  MinVoltageComponent,
  PfSettingsComponent,
  TemperatureComponent,
  UnityPfComponent,
} from '@app/index'
import { ToastrService } from 'ngx-toastr'
import { NotificationMessage } from '@app/enums/notification'
import { NgxSpinnerService } from 'ngx-spinner'
import { ThresholdSettings } from '@app/enums/threshold-settings-enum'
import swal from 'sweetalert'

@Component({
  selector: 'app-threshold-settings',
  templateUrl: './threshold-settings.component.html',
  styleUrls: ['./threshold-settings.component.scss'],
})
export class ThresholdSettingsComponent implements OnInit {
  headerText = ''
  thresholdData: any = {}

  deviceId = ''
  deviceIds: any[] = []
  @Select(CommonState.hasEditPermission)
  hasEditPermission$!: Observable<boolean>

  triggerMinVoltageData: Subject<any> = new Subject<any>()
  triggerMaxVoltageData: Subject<any> = new Subject<any>()
  triggerMaxCurrentData: Subject<any> = new Subject<any>()
  triggerKvaData: Subject<any> = new Subject<any>()
  triggerCtSettingData: Subject<any> = new Subject<any>()
  triggerTemperatureData: Subject<any> = new Subject<any>()
  triggerPfSettingsData: Subject<any> = new Subject<any>()
  triggerUnityPfData: Subject<any> = new Subject<any>()
  triggerGetUpdatedTimeWithData: Subject<any> = new Subject<any>()

  @ViewChild(MinVoltageComponent, { static: false }) minVoltageDetails: any
  @ViewChild(MaxVoltageComponent, { static: false }) maxVoltageDetails: any
  @ViewChild(MaxCurrentComponent, { static: false }) maxCurrentDetails: any
  @ViewChild(KvaComponent, { static: false }) kvaDetails: any
  @ViewChild(CtSettingsComponent, { static: false }) ctSettingsDetails: any
  @ViewChild(TemperatureComponent, { static: false }) tempreatureDetails: any
  @ViewChild(PfSettingsComponent, { static: false }) pfSettingsDetails: any
  @ViewChild(UnityPfComponent, { static: false }) unityPfDetails: any

  @Select(ThresholdSettingsState.getAlertSettingData)
  thresholdSettingsData$!: Observable<any>
  ThresholdSettingsEnum = ThresholdSettings

  constructor(
    private store: Store,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
  ) {}

  ngOnInit(): void {}

  getAllThresholdSettingsData(): void {
    if (this.deviceId) {
      this.spinner.show()
      this.store.dispatch(new GetAllThresholdSettings(this.deviceId)).subscribe(
        (data: any) => {
          this.spinner.hide()
          if (data.thresholdSettings.thresholdSettingsData) {
            const responseData = data.thresholdSettings.thresholdSettingsData
            this.triggerMinVoltageData.next(responseData.voltage ?? null)
            this.triggerMaxVoltageData.next(responseData.voltage ?? null)
            this.triggerMaxCurrentData.next(responseData.maxCurrent ?? null)
            this.triggerKvaData.next(responseData.ratedKVA ?? null)
            this.triggerCtSettingData.next(responseData.ctRatio ?? null)
            this.triggerTemperatureData.next(responseData.temperature ?? null)
            this.triggerPfSettingsData.next(responseData.powerFactor ?? null)
            this.triggerUnityPfData.next(responseData.unityPF)
          }
        },
        (error) => {
          this.spinner.hide()
        },
      )
    } else {
      this.triggerMinVoltageData.next(null)
      this.triggerMaxVoltageData.next(null)
      this.triggerMaxCurrentData.next(null)
      this.triggerKvaData.next(null)
      this.triggerCtSettingData.next(null)
      this.triggerTemperatureData.next(null)
      this.triggerPfSettingsData.next(null)
      this.triggerUnityPfData.next(null)
    }
  }

  onSubmitMinMaxVoltage(isMinUpdated: boolean): void {
    if (this.deviceIds.length) {
      const voltageData = {
        minRPhase: +this.minVoltageDetails.minVoltageForm.controls.rPhase.value,
        minYPhase: +this.minVoltageDetails.minVoltageForm.controls.yPhase.value,
        minBPhase: +this.minVoltageDetails.minVoltageForm.controls.bPhase.value,
        maxRPhase: +this.maxVoltageDetails.maxVoltageForm.controls.rPhase.value,
        maxYPhase: +this.maxVoltageDetails.maxVoltageForm.controls.yPhase.value,
        maxBPhase: +this.maxVoltageDetails.maxVoltageForm.controls.bPhase.value,
        deviceIds: this.deviceIds.join(','),
      }

      this.spinner.show()
      this.store
        .dispatch(new UpdateMinMaxThresholdVoltage(voltageData))
        .subscribe(
          (res: any) => {
            this.spinner.hide()
            if (res.thresholdSettings.isMinMaxVolatgeUpdated) {
              if (isMinUpdated) {
                this.toastr.success(NotificationMessage.MinVoltageSuccessMsg)
              } else {
                this.toastr.success(NotificationMessage.MaxVoltageSuccessMsg)
              }
              this.getAllThresholdSettingsData()
            }
          },
          (error) => {
            this.spinner.hide()
          },
        )
    } else {
      this.toastr.error(NotificationMessage.noDeviceSelected)
    }
  }

  onMaxCurrentSubmit(): void {
    if (this.deviceIds.length) {
      const currentData = {
        rPhase: this.maxCurrentDetails.maxCurrentForm.controls.rPhase.value,
        yPhase: this.maxCurrentDetails.maxCurrentForm.controls.yPhase.value,
        bPhase: this.maxCurrentDetails.maxCurrentForm.controls.bPhase.value,
        deviceIds: this.deviceIds.join(','),
      }
      this.spinner.show()
      this.store.dispatch(new UpdateCurrentThreshold(currentData)).subscribe(
        (res: any) => {
          this.spinner.hide()
          if (res.thresholdSettings.isMaxCurrentUpdated) {
            this.toastr.success(NotificationMessage.MaxCurrentSuccessMsg)
            this.getAllThresholdSettingsData()
          }
        },
        (error) => {
          this.spinner.hide()
        },
      )
    } else {
      this.toastr.error(NotificationMessage.noDeviceSelected)
    }
  }

  onKVASubmit(): void {
    if (this.deviceIds.length) {
      this.spinner.show()
      this.store
        .dispatch(
          new UpdateKVAThreshold(
            this.kvaDetails.kvaForm.controls.ratedKva.value,
            this.deviceIds.join(','),
          ),
        )
        .pipe(
          switchMap((res: any) =>
            this.store.dispatch(new GetDeviceIdByFeeder()),
          ),
        )
        .subscribe(
          (res: any) => {
            this.spinner.hide()
            if (res.thresholdSettings.isKVAUpdated) {
              this.toastr.success(NotificationMessage.kVASuccessMsg)
              this.getAllThresholdSettingsData()
            }
          },
          (error) => {
            this.spinner.hide()
          },
        )
    } else {
      this.toastr.error(NotificationMessage.noDeviceSelected)
    }
  }

  onCTSubmit(): void {
    if (this.deviceIds.length) {
      this.spinner.show()
      this.store
        .dispatch(
          new UpdateCtThreshold(
            this.ctSettingsDetails.ctSettingForm.controls.ctRatio.value,
            this.deviceIds.join(','),
          ),
        )
        .subscribe(
          (res: any) => {
            this.spinner.hide()
            if (res.thresholdSettings.isCtUpdated) {
              this.toastr.success(NotificationMessage.CTSettingSuccessMsg)
              this.getAllThresholdSettingsData()
            }
          },
          (error) => {
            this.spinner.hide()
          },
        )
    } else {
      this.toastr.error(NotificationMessage.noDeviceSelected)
    }
  }

  onTemperatureSubmit(): void {
    if (this.deviceIds.length) {
      this.spinner.show()
      this.store
        .dispatch(
          new UpdateTemperatureThreshold(
            this.tempreatureDetails.temperatureForm.controls.temp.value,
            this.deviceIds.join(','),
          ),
        )
        .subscribe(
          (res: any) => {
            this.spinner.hide()
            if (res.thresholdSettings.isTemperatureUpdated) {
              this.toastr.success(NotificationMessage.TemperatureSuccessMsg)
              this.getAllThresholdSettingsData()
            }
          },
          (error) => {
            this.spinner.hide()
          },
        )
    } else {
      this.toastr.error(NotificationMessage.noDeviceSelected)
    }
  }

  onPFSubmit(): void {
    if (this.deviceIds.length) {
      this.spinner.show()
      this.store
        .dispatch(
          new UpdatePFThreshold(
            this.pfSettingsDetails.pfSettingForm.controls.pf.value,
            this.deviceIds.join(','),
          ),
        )
        .subscribe(
          (res: any) => {
            this.spinner.hide()
            if (res.thresholdSettings.isPfUpdated) {
              this.toastr.success(NotificationMessage.PfSettingSuccessMsg)
              this.getAllThresholdSettingsData()
            }
          },
          (error) => {
            this.spinner.hide()
          },
        )
    } else {
      this.toastr.error(NotificationMessage.noDeviceSelected)
    }
  }

  onUnityPfSubmit(): void {
    if (this.deviceIds.length) {
      this.spinner.show()
      this.store
        .dispatch(
          new UpdateUnityPFThreshold(
            +this.unityPfDetails.unityPfForm.controls.unity.value === 1
              ? true
              : false,
            this.deviceIds.join(','),
          ),
        )
        .subscribe(
          (res: any) => {
            this.spinner.hide()
            if (res.thresholdSettings.isUnityPfUpdated) {
              this.toastr.success(NotificationMessage.UnityPfSuccessMsg)
              this.getAllThresholdSettingsData()
            }
          },
          (error) => {
            this.spinner.hide()
          },
        )
    } else {
      this.toastr.error(NotificationMessage.noDeviceSelected)
    }
  }

  confirmPopupUp(thresholdSettingsEnum: any) {
    let textMessage = ''

    if (thresholdSettingsEnum === ThresholdSettings.MinVoltage) {
      textMessage = `You want to update Min Voltage.`
      if (this.minVoltageDetails.minVoltageForm.invalid) {
        this.minVoltageDetails.minVoltageForm.controls.rPhase.markAsTouched()
        this.minVoltageDetails.minVoltageForm.controls.yPhase.markAsTouched()
        this.minVoltageDetails.minVoltageForm.controls.bPhase.markAsTouched()
        return
      }
    } else if (thresholdSettingsEnum === ThresholdSettings.MaxVoltage) {
      if (this.maxVoltageDetails.maxVoltageForm.invalid) {
        this.maxVoltageDetails.maxVoltageForm.controls.rPhase.markAsTouched()
        this.maxVoltageDetails.maxVoltageForm.controls.yPhase.markAsTouched()
        this.maxVoltageDetails.maxVoltageForm.controls.bPhase.markAsTouched()
        return
      }
      textMessage = `You want to update Max Voltage.`
    } else if (thresholdSettingsEnum === ThresholdSettings.MaxCurrent) {
      if (this.maxCurrentDetails.maxCurrentForm.invalid) {
        this.maxCurrentDetails.maxCurrentForm.controls.rPhase.markAsTouched()
        this.maxCurrentDetails.maxCurrentForm.controls.yPhase.markAsTouched()
        this.maxCurrentDetails.maxCurrentForm.controls.bPhase.markAsTouched()
        return
      }
      textMessage = `You want to update Max Current.`
    } else if (thresholdSettingsEnum === ThresholdSettings.KVA) {
      if (this.kvaDetails.kvaForm.invalid) {
        this.kvaDetails.kvaForm.controls.ratedKva.markAsTouched()
        return
      }
      textMessage = `You want to update KVA.`
    } else if (thresholdSettingsEnum === ThresholdSettings.CTSettings) {
      if (this.ctSettingsDetails.ctSettingForm.invalid) {
        this.ctSettingsDetails.ctSettingForm.controls.ctRatio.markAsTouched()
        return
      }
      textMessage = `You want to update CT Setting.`
    } else if (thresholdSettingsEnum === ThresholdSettings.Temperature) {
      if (this.tempreatureDetails.temperatureForm.invalid) {
        this.tempreatureDetails.temperatureForm.controls.temp.markAsTouched()
        return
      }
      textMessage = `You want to update Temperature.`
    } else if (thresholdSettingsEnum === ThresholdSettings.PFSettings) {
      textMessage = `You want to update PF Setting.`
    } else if (thresholdSettingsEnum === ThresholdSettings.UnityPF) {
      textMessage = `You want to Unity PF.`
    }

    swal({
      title: 'Are you sure ?',
      text: textMessage,
      icon: 'warning',
      buttons: {
        cancel: {
          text: 'Cancel',
          visible: true,
          closeModal: true,
        },
        confirm: {
          text: 'Yes',
        },
      },
      dangerMode: true,
    }).then((confirmed) => {
      if (confirmed) {
        if (thresholdSettingsEnum === ThresholdSettings.MinVoltage) {
          this.onSubmitMinMaxVoltage(true)
        } else if (thresholdSettingsEnum === ThresholdSettings.MaxVoltage) {
          this.onSubmitMinMaxVoltage(false)
        } else if (thresholdSettingsEnum === ThresholdSettings.MaxCurrent) {
          this.onMaxCurrentSubmit()
        } else if (thresholdSettingsEnum === ThresholdSettings.KVA) {
          this.onKVASubmit()
        } else if (thresholdSettingsEnum === ThresholdSettings.CTSettings) {
          this.onCTSubmit()
        } else if (thresholdSettingsEnum === ThresholdSettings.Temperature) {
          this.onTemperatureSubmit()
        } else if (thresholdSettingsEnum === ThresholdSettings.PFSettings) {
          if (this.pfSettingsDetails.pfSettingForm.invalid) {
            this.pfSettingsDetails.pfSettingForm.controls.pf.markAsTouched()
            return
          }
          this.onPFSubmit()
        } else if (thresholdSettingsEnum === ThresholdSettings.UnityPF) {
          this.onUnityPfSubmit()
        }
      }
    })
  }

  getDataByHeaderData(event: any) {
    this.deviceId = event.deviceIds[0]
    this.deviceIds = event.deviceIds
    this.getAllThresholdSettingsData()
    let data = {
      deviceId: this.deviceId,
      isGetUpdateTime: true,
    }
    this.triggerGetUpdatedTimeWithData.next(data)
  }
}
