import { Component, OnInit } from '@angular/core'
import { FormGroup, FormControl, Validators } from '@angular/forms'
import { Store } from '@ngxs/store'
import { ToastrService } from 'ngx-toastr'
import { ForgotPassword } from '@app/store'
import { CommonService } from '@app/services'
import { DialogRef } from '@progress/kendo-angular-dialog'
import { NotificationMessage } from '@app/enums/notification'
import { NgxSpinnerService } from 'ngx-spinner'

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {
  forgotPasswordChangeForm!: FormGroup
  formName = 'Forgot Password'
  constructor(
    private toastr: ToastrService,
    private store: Store,
    public commonService: CommonService,
    public dialogRef: DialogRef,
    private spinner: NgxSpinnerService,
  ) {}

  ngOnInit(): void {
    this.setForm()
  }

  setForm(): void {
    this.forgotPasswordChangeForm = new FormGroup({
      emailMobileNo: new FormControl('', [Validators.required]),
    })
  }

  forgotPassword(): void {
    if (this.forgotPasswordChangeForm.valid) {
      const emailMobileNo =
        this.forgotPasswordChangeForm.get('emailMobileNo')?.value
      this.spinner.show()
      this.store.dispatch(new ForgotPassword(emailMobileNo)).subscribe(
        (res) => {
          this.spinner.hide()
          if (res.account.isUpdatedForgotPassword) {
            this.forgotPasswordChangeForm.reset()
            this.toastr.success(NotificationMessage.forgotPasswordSuccessMsg)
          } else {
            this.forgotPasswordChangeForm.reset()
            this.toastr.error(NotificationMessage.forgotPasswordErrorMsg)
          }
        },
        (error) => {
          this.spinner.hide()
        },
      )
    }
  }
  onCancel(): void {}

  onCloseDialog(): void {
    this.commonService.onDialogClose(this.dialogRef, false)
  }
}
