import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import {
  CommonsModule,
  PTRInterruptionComponent,
  PTRLoadsComponent,
  ssRoutingModule,
} from '@app/index'

@NgModule({
  declarations: [PTRLoadsComponent, PTRInterruptionComponent],
  imports: [CommonModule, CommonsModule, ssRoutingModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SsptrrModule {}
