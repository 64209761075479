import { Component, OnInit } from '@angular/core'
import { FeederType } from '@app/enums'
import { NotificationMessage } from '@app/enums/notification'
import { CommonService } from '@app/services'
import {
  CommonState,
  ConfirmationDeviceForFeeder,
  GetDeviceIdByFeeder,
  GetFeederAreaList,
  GetFeederListWithDevice,
  SaveFeederDevice,
} from '@app/store'
import { Store } from '@ngxs/store'
import { DialogRef } from '@progress/kendo-angular-dialog'
import { PageChangeEvent } from '@progress/kendo-angular-grid'
import { CompositeFilterDescriptor } from '@progress/kendo-data-query'
import { NgxSpinnerService } from 'ngx-spinner'
import { ToastrService } from 'ngx-toastr'
import { switchMap } from 'rxjs'
import swal from 'sweetalert'

@Component({
  selector: 'app-link-device-with-feeder',
  templateUrl: './link-device-with-feeder.component.html',
  styleUrl: './link-device-with-feeder.component.scss',
})
export class LinkDeviceWithFeederComponent implements OnInit {
  feederId: any
  feederName: any
  feederType: any
  deviceList: any[] = []
  isSortingAllowed: boolean = true
  sort: any
  isAllSelected = false
  filterQuery: string | any
  public filter: CompositeFilterDescriptor = {
    logic: 'and',
    filters: [],
  }
  previousSort: any = null
  sortOrder = false
  isDataLoaded = false
  skip = 0
  pageNumber = 1
  pageSize = 10
  constructor(
    public dialogRef: DialogRef,
    public commonService: CommonService,
    private store: Store,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
  ) {}

  ngOnInit(): void {
    this.getDeviceList(this.feederId)
  }

  onCloseDialog(): void {
    this.commonService.onDialogClose(this.dialogRef, false)
  }

  getDeviceList(feederId: any): void {
    this.spinner.show()
    let queryParams = {
      feederName: this.feederName,
      moduleId: this.store.selectSnapshot(CommonState.moduleId),
      feederAreaId: feederId.toString(),
      pageNumber: this.pageNumber,
      pageSize: 2147483647,
      sortBy: this.sort ?? null,
      sortOrder: this.sortOrder,
      filter: 0,
      search: null,
      startDate: null,
      endDate: null,
      filterQuery: this.filterQuery,
    }

    this.store.dispatch(new GetFeederListWithDevice(queryParams)).subscribe(
      (res) => {
        this.spinner.hide()
        if (res.feeder.deviceList) {
          const { data, totalCount } = res.feeder.deviceList
          this.deviceList = data.rows

          this.isDataLoaded = true

          this.isAllSelected = this.deviceList.every(
            (device) => device.isSelected === true,
          )
        }
      },
      (error) => {
        this.spinner.hide()
      },
    )
  }

  toggleSelectAll(event: any) {
    this.isAllSelected = event.target.checked
    this.deviceList.forEach(
      (device) => (device.isSelected = this.isAllSelected),
    )
  }

  onCheckboxChange(dataItem: any, event: any) {
    dataItem.isSelected = event.target.checked

    if (this.feederType === FeederType.PTR) {
      this.deviceList.forEach((x: any) => {
        if (x.deviceId !== dataItem.deviceId) {
          x.isSelected = false
        }
      })
    }

    this.isAllSelected = this.deviceList.every((device) => device.isSelected)
  }

  onSortChange(sortData: any): void {
    const currentSortField = sortData[0].field
    if (this.previousSort === currentSortField) {
      this.sortOrder = !this.sortOrder
    } else {
      this.sortOrder = true
    }
    this.sort = currentSortField
    this.previousSort = currentSortField
    this.getDeviceList(this.feederId)
  }

  onPageChange({ skip, take }: PageChangeEvent): void {
    this.skip = skip
    this.pageSize = take
    this.pageNumber = this.skip / this.pageSize + 1

    this.getDeviceList(this.feederId)
  }

  filterChange(filter: CompositeFilterDescriptor): void {
    this.filter = filter
    this.filterQuery = this.toSQLExpression(this.filter)
    this.getDeviceList(this.feederId)
  }

  checkConfirmation(): void {
    let selectedDeviceIds = this.deviceList
      .filter((device) => device.isSelected)
      .map((device) => device.deviceId)

    // if (selectedDeviceIds.length === 0) {
    //   this.toastr.error(NotificationMessage.deviceSelectErrorMsg)
    //   return
    // }

    const param = {
      deviceIds: selectedDeviceIds,
      feederName: this.feederName,
      feederId: this.feederId,
    }

    this.store.dispatch(new ConfirmationDeviceForFeeder(param)).subscribe(
      (res) => {
        this.spinner.hide()

        const data = res.feeder.confirmationMessageList
        const errorMessagelist: any[] = []

        if (data.length > 0) {
          data.forEach((element: any, i: any) => {
            errorMessagelist.push(element.message)
          })
          this.confirmDeviceRemove(errorMessagelist)
        } else {
          this.onSubmit()
        }
      },
      (error) => {
        this.spinner.hide()
      },
    )
  }

  confirmDeviceRemove(errorMessagelist: string[]) {
    let confirmationMessage = ''

    if (errorMessagelist.length) {
      errorMessagelist.forEach((error) => {
        confirmationMessage += ` ${error}\n`
      })
      confirmationMessage += `\n You want to change it.`
    }

    swal({
      title: 'Are you sure ?',
      text: confirmationMessage,
      icon: 'warning',
      buttons: {
        cancel: {
          text: 'Cancel',
          visible: true,
          closeModal: true,
        },
        confirm: {
          text: 'Yes',
        },
      },
      dangerMode: true,
    }).then((confirmed: any) => {
      if (confirmed) {
        this.onSubmit()
      }
    })
  }

  onSubmit() {
    let selectedDeviceIds = this.deviceList
      .filter((device) => device.isSelected)
      .map((device) => device.deviceId)

    // if (selectedDeviceIds.length === 0) {
    //   this.toastr.error(NotificationMessage.deviceSelectErrorMsg)
    //   return
    // }

    if (this.feederType === FeederType.PTR && selectedDeviceIds.length > 1) {
      this.toastr.error(NotificationMessage.prtDeviceSelectionErrorMsg)
      return
    }

    const param = {
      deviceIds: selectedDeviceIds,
      feederName: this.feederName,
      feederId: this.feederId,
    }

    this.store.dispatch(new SaveFeederDevice(param)).subscribe(
      (res) => {
        this.spinner.hide()
        this.store
          .dispatch(new GetFeederAreaList())
          .pipe(
            switchMap((res: any) =>
              this.store.dispatch(new GetDeviceIdByFeeder()),
            ),
          )
          .subscribe()
        this.toastr.success(NotificationMessage.feederSuccessMsg)
        this.commonService.onDialogClose(this.dialogRef, true)
      },
      (error) => {
        this.spinner.hide()
      },
    )
  }

  toSQLExpression(filter: any) {
    if (!filter || !filter.filters || filter.filters.length === 0) {
      return null
    }

    const operators: any = {
      eq: '=',
      neq: '!=',
      lt: '<',
      lte: '<=',
      gt: '>',
      gte: '>=',
      startswith: 'LIKE',
      contains: 'LIKE',
      doesnotcontain: 'NOT LIKE',
      endswith: 'LIKE',
    }

    const isDate = (value: any): boolean => {
      const dateRegex =
        /^[A-Za-z]{3} [A-Za-z]{3} \d{2} \d{4} \d{2}:\d{2}:\d{2} GMT[+-]\d{4} \(.*\)$/
      return dateRegex.test(value)
    }

    const buildCondition = (condition: any) => {
      const { field, operator, value } = condition
      let sqlOperator = operators[operator]
      let formattedValue = value

      if (operator === 'startswith') {
        return `${field} ${sqlOperator} '${value}%'`
      } else if (operator === 'contains') {
        return `${field} ${sqlOperator} '%${value}%'`
      } else if (operator === 'doesnotcontain') {
        return `${field} ${sqlOperator} '%${value}%'`
      } else if (operator === 'endswith') {
        return `${field} ${sqlOperator} '%${value}'`
      } else {
        return `${field} ${sqlOperator} '${value}'`
      }
    }

    const buildSQL = (filter: any) => {
      if (filter.filters) {
        const expressions = filter.filters.map((f: any) => buildSQL(f))
        return `(${expressions.join(` ${filter.logic.toUpperCase()} `)})`
      } else {
        return buildCondition(filter)
      }
    }

    return buildSQL(filter)
  }
}
