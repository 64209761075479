import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable, of } from 'rxjs'
import { environment } from 'src/environments'

@Injectable()
export class CAIDIService {
  caidiData = [
    { peroid: 'Today', VAL: '0', TIME: 0, STS: '' },
    { peroid: 'This Week', VAL: '0', TIME: 0, STS: '' },
    { peroid: 'This Month', VAL: '2', TIME: 224.95, STS: '' },
    { peroid: 'Last 3 Months', VAL: '24', TIME: 6717.53, STS: '' },
    { peroid: 'Last 6 Months', VAL: '38', TIME: 7101.95, STS: '' },
    { peroid: 'Last 1 Year', VAL: '133', TIME: 20149.02, STS: '' },
  ]

  lossAmountData = [
    { peroid: 'Today', LOAD: '0' },
    { peroid: 'This Week', LOAD: '0' },
    { peroid: 'This Month', LOAD: '0' },
    { peroid: 'Last 3 Months', LOAD: '0' },
    { peroid: 'Last 6 Months', LOAD: '0' },
    { peroid: 'Last 1 Year', LOAD: '190' },
  ]
  constructor(private http: HttpClient) {}

  getCAIDIData(deviceId: any): Observable<any[]> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    }

    return this.http.post<any>(
      `${environment.apiVersionUrl}Alert/GetPowerSupplyTimeCount/${deviceId}`,
      null,
      headers,
    )
  }

  getLossAmountData(param: any): Observable<any[]> {
    // Return an observable of alert setting data
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    }
    return this.http.post<any>(
      `${environment.apiVersionUrl}Alert/GetLossPriceCalculationAsync/${param.deviceId}/${param.pricePerUnit}/${param.unitPerHour}`,
      null,
      headers,
    )
  }
}
