<div class="q-content">
  <div class="mb-20">
    <app-common-header-text
      [infoText]="infoText"
      [isInfoVisible]="true"
      [triggerGetUpdatedTime]="triggerGetUpdatedTime"
    ></app-common-header-text>
  </div>
  <div class="q-content-bg section-height">
    <app-common-analytics-card
      [isCurrentGraph]="isCurrentGraph"
      [triggerCurrentCardApi]="triggerCurrentCardApi"
    ></app-common-analytics-card>
    <div class="d-flex align-items-center gap-15 flex-wrap">
      <app-common-header
        (triggerDataFromHeader)="getDataByHeaderData($event)"
        [showDateFilter]="false"
      ></app-common-header>
      <app-common-separate-date-picker-header
        (onAnalyticsFilterChange)="onAnalyticsFilterChange($event)"
        (onAnalyticsSelectYearFilterChange)="getAllYearData()"
      ></app-common-separate-date-picker-header>
      <button kendoButton (click)="getAllYearData()">All Years</button>
      <div class="d-flex align-items-end">
        <button kendoButton (click)="onAllButtonClick()">Live</button>
      </div>
    </div>
    <hr />
    <app-common-analytics-graph
      [isCurrentGraph]="isCurrentGraph"
      [roundedMaxValue]="roundedMaxValue"
      [chartData]="chartData"
      (chartDataChange)="(chartData)"
      [showLoader]="showLoader"
      [isNoRecordFound]="isNoRecordFound"
    ></app-common-analytics-graph>
  </div>
</div>
