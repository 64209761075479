import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'

import { SharedModule } from 'src/shared/shared.module'
import {
  CommonGridComponent,
  CommonHeaderComponent,
  CommonRoutingModule,
  PipeModule,
} from '@app/index'
import { CommonSettingGridComponent } from './common-setting-grid/common-setting-grid.component'
import { CommonColorInfoComponent } from './common-color-info/common-color-info.component'
import { CommonSeparateDatePickerHeaderComponent } from './common-separate-date-picker-header/common-separate-date-picker-header.component'
import { CommonMapMarkerDetailsComponent } from './common-map-marker-details/common-map-marker-details.component'
import { CommonColumnPermissionComponent } from './common-column-permission/common-column-permission.component'
import { CommonColumnSelectorComponent } from './common-column-selector/common-column-selector.component'
import { CommonHeaderTextComponent } from './common-header-text/common-header-text.component'

@NgModule({
  declarations: [
    CommonHeaderComponent,
    CommonGridComponent,
    CommonSettingGridComponent,
    CommonColorInfoComponent,
    CommonSeparateDatePickerHeaderComponent,
    CommonMapMarkerDetailsComponent,
    CommonColumnPermissionComponent,
    CommonColumnSelectorComponent,
    CommonHeaderTextComponent,
  ],
  imports: [CommonModule, CommonRoutingModule, SharedModule, PipeModule],
  exports: [
    CommonHeaderComponent,
    CommonGridComponent,
    CommonSettingGridComponent,
    CommonSeparateDatePickerHeaderComponent,
    CommonColumnPermissionComponent,
    CommonColumnSelectorComponent,
    CommonHeaderTextComponent,
  ],
})
export class CommonsModule {}
