import { Component, OnInit, ViewChild } from '@angular/core'
import { GoogleMap } from '@angular/google-maps'
import { CommonService } from '@app/services'
import { CommonState, GetGpsLocation, GisInfoGetDeviceList } from '@app/store'
import { Select, Store } from '@ngxs/store'
import { GridDataResult } from '@progress/kendo-angular-grid'
import { NgxSpinnerService } from 'ngx-spinner'
import { Observable } from 'rxjs'
import { CommonGridComponent } from '../common'
import { ActionTypeEnum } from '@app/enums'
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog'
import { AddInstalledDateComponent } from '../device'
interface Marker {
  position: google.maps.LatLngLiteral
  title: string
  description: string
  iconUrl: string
}
@Component({
  selector: 'app-gis-info',
  templateUrl: './gis-info.component.html',
  styleUrl: './gis-info.component.scss',
})
export class GisInfoComponent implements OnInit {
  @ViewChild(CommonGridComponent) commonGridComponent:
    | CommonGridComponent
    | undefined
  gridView: GridDataResult | any
  sort: any
  sortOrder = false
  previousSort: any = null
  pageNumber = 1
  pageSize = 10
  skip = 0
  feederAreaId: any
  deviceId: any
  startDate = null
  endDate = null
  locationData: any
  infoText: string = "Red Color shows the stopped DTR's"
  center: google.maps.LatLngLiteral = { lat: 20.5937, lng: 78.9629 }
  zoom = 12
  markers: Marker[] = []
  currentMarkers: google.maps.marker.AdvancedMarkerElement[] = []
  @ViewChild('map') map!: GoogleMap
  infoWindow: google.maps.InfoWindow
  mapObject: google.maps.Map | undefined
  mapId = 'istlMaps'
  isDataLoaded = false

  slideConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: false,
    arrows: true,
    speed: 300,
    infinite: false,
  }

  @Select(CommonState.moduleId)
  moduleId$!: Observable<number>

  filterQueryJson: string | any
  actions: any[] = []

  constructor(
    private store: Store,
    private spinner: NgxSpinnerService,
    private commonService: CommonService,
    private dialogService: DialogService,
  ) {
    this.infoWindow = new google.maps.InfoWindow()
    this.commonService.addInstallDateAction(this.actions)
  }

  ngOnInit(): void {}

  onActionClick(event: any): void {
    if (event.actionTypeId === ActionTypeEnum.AddDate) {
      this.onInstalledDate(event.data)
    }
  }

  onInstalledDate(data: any) {
    const dialogRef: DialogRef = this.dialogService.open({
      title: 'Add Installation Date For- ' + data.deviceName,
      content: AddInstalledDateComponent,
      width: 450,
    })
    const dialogInfo = dialogRef.content.instance as AddInstalledDateComponent
    dialogInfo.deviceData = data

    dialogRef.result.subscribe((res: any) => {
      if (res && res.confirmed) {
        this.getDeviceList(
          this.deviceId,
          this.feederAreaId,
          this.startDate,
          this.endDate,
        )
      }
    })
  }

  onFilterChangeSQL(sqlQuery: any) {
    this.filterQueryJson = sqlQuery
    this.getDeviceList(
      this.deviceId,
      this.feederAreaId,
      this.startDate,
      this.endDate,
    )
  }

  getDataFromColumnSelector(event: any) {
    this.getDeviceList(
      this.deviceId,
      this.feederAreaId,
      this.startDate,
      this.endDate,
    )
  }

  ngAfterViewInit(): void {
    this.mapObject = this.map.googleMap
  }

  onColumnSelector(): void {
    this.commonGridComponent?.onColumnSelector()
  }

  resetMarkers() {
    this.currentMarkers.forEach((marker: any) => marker.setMap(null))
    this.currentMarkers = []
  }

  addMarkers() {
    this.resetMarkers()
    let currentInfoWindow: google.maps.InfoWindow | null = null
    if (!this.mapObject) {
      console.error('Map object is not available.')
      return
    }

    this.markers.forEach((markerData) => {
      const markerElement = document.createElement('img')
      markerElement.src = markerData.iconUrl
      markerElement.style.width = '30px'
      markerElement.style.height = '30px'
      const marker = new google.maps.marker.AdvancedMarkerElement({
        position: markerData.position,
        title: markerData.title,
        map: this.mapObject,
        content: markerElement,
      })

      marker.addListener('click', () => {
        // Close the currently open InfoWindow, if any
        if (currentInfoWindow) {
          currentInfoWindow.close()
        }

        const infoWindow = new google.maps.InfoWindow({
          content: markerData.description,
        })

        // Update the reference to the currently open InfoWindow
        currentInfoWindow = infoWindow

        infoWindow.addListener('domready', () => {
          const iwContent = document.querySelector('.gm-style-iw-ch')
          if (iwContent) {
            iwContent.innerHTML +=
              '<h3 class="fw-500 font-size-18 primary">DTR Info</h3>'
          }
        })

        infoWindow.open({
          anchor: marker,
          map: this.mapObject,
          shouldFocus: false,
        })
      })
      this.currentMarkers.push(marker)
    })
  }

  getDataByHeaderData(event: any): void {
    this.deviceId = event.deviceId
    this.feederAreaId = event.feederAreaId
    this.startDate = event.startDate
    this.endDate = event.endDate
    this.getDeviceList(
      event.deviceId,
      event.feederAreaId,
      event.startDate,
      event.endDate,
    )
    this.getGpsLocation()
  }

  getDeviceList(
    deviceId: any,
    feederAreaId: any,
    startDate: any,
    endDate: any,
  ): void {
    this.spinner.show()
    const param = {
      deviceId: deviceId,
      feederAreaId: feederAreaId,
      pageNumber: this.pageNumber,
      pageSize: this.pageSize,
      filter: 0,
      sortBy: this.sort ?? null,
      sortOrder: this.sortOrder,
      search: null,
      startDate: startDate,
      endDate: endDate,
      moduleId: this.store.selectSnapshot(CommonState.moduleId),
      filterQueryJson: this.filterQueryJson,
    }

    this.store.dispatch(new GisInfoGetDeviceList(param)).subscribe(
      (res) => {
        this.spinner.hide()

        const { data, totalCount } = res.gisInfo.deviceListData

        this.gridView = {
          data: data.rows,
          total: totalCount,
          columns: data.columns,
        }

        this.isDataLoaded = true
      },
      (error) => {
        this.spinner.hide()
      },
    )
  }

  getGpsLocation(): void {
    this.store
      .dispatch(new GetGpsLocation(this.feederAreaId.toUpperCase()))
      .subscribe(
        (res) => {
          this.locationData = res.device.locationData
          let markers: any = []
          this.locationData.forEach((element: any) => {
            let image: string
            if (element.icon === 1) {
              image = 'https://maps.google.com/mapfiles/ms/icons/green-dot.png'
            } else if (element.icon === 2) {
              image = 'https://maps.google.com/mapfiles/ms/icons/red-dot.png'
            } else if (element.icon === 3) {
              image = 'https://maps.google.com/mapfiles/ms/icons/orange-dot.png'
            } else {
              image = 'https://maps.google.com/mapfiles/ms/icons/purple-dot.png'
            }
            markers.push({
              position: { lat: element.l1, lng: element.l2 },
              title: 'DTR Info',
              description: this.buildContent(element),
              iconUrl: image,
            })
          })

          this.markers = markers

          setTimeout(() => {
            if (markers.length) {
              this.center = this.markers[0].position
            } else {
              this.center = { lat: 20.5937, lng: 78.9629 }
            }
          }, 1000)

          this.addMarkers()
          this.spinner.hide()
        },
        (error) => {
          this.spinner.hide()
        },
      )
  }

  onPaginationChange({ skip, take }: any) {
    this.skip = skip
    this.pageSize = take
    this.pageNumber = this.skip / this.pageSize + 1

    this.getDeviceList(
      this.deviceId,
      this.feederAreaId,
      this.startDate,
      this.endDate,
    )
  }

  onSortChange(sortData: any) {
    const currentSortField = sortData.sort[0].field
    if (this.previousSort === currentSortField) {
      this.sortOrder = !this.sortOrder
    } else {
      this.sortOrder = true
    }
    this.sort = currentSortField
    this.previousSort = currentSortField
    this.getDeviceList(
      this.deviceId,
      this.feederAreaId,
      this.startDate,
      this.endDate,
    )
  }

  buildContent(data: any): HTMLElement {
    const content = document.createElement('div')

    const deviceImage = data.locationDeviceInformation.deviceImages

    content.innerHTML = `
      <div class="d-flex flex-wrap gap-15">
        <div>
          <p><span class="fw-500">Device ID:</span> ${data.locationDeviceInformation.device_Id}</p>
          <p><span class="fw-500">Updated On:</span> ${new Date(data.locationDeviceInformation.date).toLocaleString()}</p>
          <p><span class="fw-500">Voltage(V):</span> R =${data.locationDeviceInformation.voltage_Ph1}, Y =${data.locationDeviceInformation.voltage_Ph2}, B =${data.locationDeviceInformation.voltage_Ph3}</p>
          <p><span class="fw-500">Current(A):</span> R =${data.locationDeviceInformation.current_Ph1}, Y =${data.locationDeviceInformation.current_Ph2}, B =${data.locationDeviceInformation.current_Ph3}</p>
          <p><span class="fw-500">Rated KVA :</span> ${data.locationDeviceInformation.kva_val}</p>
          <p><span class="fw-500">Load Factor:</span> ${data.locationDeviceInformation.load_Factor}%</p>
          <p><span class="fw-500">Energy(Units):</span> KWH =${data.locationDeviceInformation.energy_Kwh_Total}, KVAH =${data.locationDeviceInformation.energy_Kwh_Total}</p>
          <p class="mb-0"><span class="fw-500">Power Factor:</span> ${data.locationDeviceInformation.pf_Total}</p>
        </div>
        ${
          deviceImage.length > 0
            ? ` <div id="carousel" style="position: relative; width: 150px; height: 150px; overflow: hidden;">
          <div id="slides" style="display: flex; transition: transform 0.5s ease-in-out; height: 100%;">
            ${deviceImage
              .map(
                (x: { base64Value: string }) => `
              <div style="flex: 0 0 100%; display: flex; justify-content: center; align-items: center; background-color: rgba(0,0,0,0.8)">
                <img src="${x.base64Value}" alt="Image" style="max-width: 100%; max-height: 100%; object-fit: contain;">
              </div>
            `,
              )
              .join('')}
          </div>
          ${
            deviceImage.length > 1
              ? `
           <a id="prevBtn" style="position: absolute; top: 50%; left: 10px; transform: translateY(-50%); color: white; z-index: 1;"><i class="far fa-angle-left"></i></a>
          <a id="nextBtn" style="position: absolute; top: 50%; right: 10px; transform: translateY(-50%); color: white; z-index: 1;"><i class="far fa-angle-right"></i></a>`
              : ``
          }
        </div>`
            : ``
        }
       
      </div>
    `

    let currentIndex = 0

    const showSlide = (index: number) => {
      const slides = content.querySelector('#slides') as HTMLElement
      const totalSlides = slides.children.length
      if (index >= totalSlides) {
        currentIndex = 0
      } else if (index < 0) {
        currentIndex = totalSlides - 1
      } else {
        currentIndex = index
      }
      const offset = -currentIndex * 100
      slides.style.transform = `translateX(${offset}%)`
    }

    if (deviceImage.length > 1) {
      const nextSlide = () => {
        showSlide(currentIndex + 1)
      }

      const prevSlide = () => {
        showSlide(currentIndex - 1)
      }

      const nextBtn: any = content.querySelector('#nextBtn')
      const prevBtn: any = content.querySelector('#prevBtn')

      nextBtn.addEventListener('click', nextSlide)
      prevBtn.addEventListener('click', prevSlide)
    }

    return content
  }
}
