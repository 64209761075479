import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable, of } from 'rxjs'
import { environment } from 'src/environments'

@Injectable()
export class SentNotificationService {
  constructor(private http: HttpClient) {}

  getSentNotificationData(sendNotificationData: any): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    }

    return this.http.post<any>(
      `${environment.apiVersionUrl}Alert/GetAlert`,
      JSON.stringify(sendNotificationData),
      headers,
    )
  }
}
