<kendo-treelist
  [kendoTreeListFlatBinding]="data"
  kendoTreeListExpandable="true"
  class="mb-20 module-tree"
>
  <kendo-treelist-column
    *ngFor="let column of columns; let i = index"
    [field]="column.field"
    [title]="column.title"
    [expandable]="column.expandable"
    [width]="column.width"
  >
    <ng-template
      kendoTreeListHeaderTemplate
      let-aggregates="aggregates"
      let-dataItem="dataItem"
    >
      <div class="d-flex gap-5 align-items-center">
        <ng-container *ngIf="i > 0">
          <input
            kendoCheckBox
            type="checkbox"
            [(ngModel)]="headerCheckboxState[i]"
            (change)="onActionHeaderSelected($event, column.actionType, i)"
          />
        </ng-container>
        <span>{{ column.title }}</span>
      </div>
    </ng-template>
    <ng-template
      kendoTreeListCellTemplate
      let-dataItem="dataItem"
      let-column="column"
      let-formGroup="formGroup"
      let-isNew="isNew"
    >
      <div
        class="display-inline"
        [ngClass]="{ 'cursor-not-allowed': dataItem.isViewOnly }"
      >
        <ng-container *ngIf="i > 0">
          <input
            kendoCheckBox
            type="checkbox"
            [(ngModel)]="dataItem[column.field]"
            [disabled]="dataItem.isViewOnly"
            (ngModelChange)="setViewValue(dataItem, column, i)"
          />
        </ng-container>
        <ng-container *ngIf="i === 0">
          {{ dataItem[column.field] }}
        </ng-container>
      </div>
    </ng-template>
  </kendo-treelist-column>
</kendo-treelist>
