export class GetStateList {
  static readonly type = '[ASSIGNHIERARCHICALSTRUCTURE] Get State List'

  constructor(public country: any) {}
}

export class GetDistrictList {
  static readonly type = '[ASSIGNHIERARCHICALSTRUCTURE] Get District List'
  constructor(public region: any) {}
}

export class GetCityList {
  static readonly type = '[ASSIGNHIERARCHICALSTRUCTURE] Get City List'
  constructor(public destrict: any) {}
}

export class GetZoneList {
  static readonly type = '[ASSIGNHIERARCHICALSTRUCTURE] Get Zone List'
  constructor(public city: any) {}
}

export class GetHierarchicalUserRole {
  static readonly type =
    '[ASSIGNHIERARCHICALSTRUCTURE] Get Hierarchical User Role'

  constructor(public userId: any) {}
}

export class SaveHierarchicalUserRole {
  static readonly type =
    '[ASSIGNHIERARCHICALSTRUCTURE] Save Hierarchical User Role'

  constructor(public data: any) {}
}
