import { Component, Input, OnChanges, SimpleChanges } from '@angular/core'

@Component({
  selector: 'app-common-analytics-graph',
  templateUrl: './common-analytics-graph.component.html',
  styleUrl: './common-analytics-graph.component.scss',
})
export class CommonAnalyticsGraphComponent implements OnChanges {
  @Input() isCurrentGraph: boolean = true
  @Input() roundedMaxValue: number | any
  @Input() chartData: any[] | any
  @Input() showLoader: boolean = true
  @Input() isNoRecordFound: boolean = false
  title = ''
  currentGraphData = []
  isCurrentGraphData = false
  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      this.currentGraphData = this.chartData
      this.title = this.isCurrentGraph ? 'Current (A)' : 'Voltage (V)'
    }
  }
}
