import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { CommonGridComponent } from '@app/components/common'
import { CommonState, GetSoftwareStatus } from '@app/store'
import { Select, Store } from '@ngxs/store'
import { NgxSpinnerService } from 'ngx-spinner'
import { Observable, Subscription } from 'rxjs'

@Component({
  selector: 'app-ota-status',
  templateUrl: './ota-status.component.html',
  styleUrl: './ota-status.component.scss',
})
export class OtaStatusComponent implements OnInit, OnDestroy {
  @ViewChild(CommonGridComponent) commonGridComponent:
    | CommonGridComponent
    | undefined

  subscriptions: Subscription[] = []
  gridView: any = []

  deviceId: any
  feederAreaId: any
  date: any
  @Input() triggerSoftwareStatus: Observable<any> | undefined
  @Select(CommonState.moduleId)
  moduleId$!: Observable<number>
  isDataLoaded = false
  filterQuery: string | any

  @Input()
  triggerColumnSelector: any
  sort: any
  sortOrder = false
  pageNumber = 1
  pageSize = 10
  skip = 0
  startDate = null
  endDate = null
  previousSort: any = null
  constructor(
    private store: Store,
    private spinner: NgxSpinnerService,
  ) {}

  ngOnInit(): void {
    this.triggerSoftwareStatus?.subscribe((res: any) => {
      this.deviceId = res.deviceId
      this.feederAreaId = res.feederAreaId
      this.getList(res)
    })

    if (this.triggerColumnSelector) {
      this.subscriptions.push(
        this.triggerColumnSelector.subscribe((res: any) => {
          if (res) this.commonGridComponent?.onColumnSelector()
        }),
      )
    }
  }

  getList(data: any) {
    let queryParams = {
      deviceId: data.deviceId,
      feederAreaId: data.feederAreaId,
      filter: 0,
      sortBy: this.sort ?? null,
      sortOrder: this.sortOrder,
      search: '',
      startDate: null,
      endDate: null,
      moduleId: this.store.selectSnapshot(CommonState.moduleId),
      filterQuery: this.filterQuery,
      pageNumber: this.pageNumber,
      pageSize: this.pageSize,
    }
    this.spinner.show()
    this.store.dispatch(new GetSoftwareStatus(queryParams)).subscribe(
      (res: any) => {
        this.spinner.hide()
        if (res.software.softwareStatusData) {
          const { data, totalCount } = res.software.softwareStatusData

          this.gridView = {
            data: data.rows,
            total: totalCount,
            columns: data.columns,
          }

          this.isDataLoaded = true
        }
      },
      (error) => {
        this.spinner.hide()
      },
    )
  }

  getDataFromColumnSelector(event: any) {
    const data = {
      deviceId: this.deviceId,
      feederAreaId: this.feederAreaId,
    }
    this.getList(data)
  }

  onSortChange(sortData: any) {
    const currentSortField = sortData.sort[0].field
    if (this.previousSort === currentSortField) {
      this.sortOrder = !this.sortOrder
    } else {
      this.sortOrder = true
    }
    this.sort = currentSortField
    this.previousSort = currentSortField
    const data = {
      deviceId: this.deviceId,
      feederAreaId: this.feederAreaId,
    }
    this.getList(data)
  }

  onPaginationChange({ skip, take }: any) {
    this.skip = skip
    this.pageSize = take
    this.pageNumber = this.skip / this.pageSize + 1
    const data = {
      deviceId: this.deviceId,
      feederAreaId: this.feederAreaId,
    }
    this.getList(data)
  }

  onFilterChangeSQL(sqlQuery: any) {
    this.filterQuery = sqlQuery
    this.getList(this.deviceId)
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe())
  }
}
