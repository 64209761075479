<div>
  <div class="tabs-content">
    <form class="k-form k-form-md mb-24">
      <div class="d-flex flex-wrap gap-20">
        <kendo-formfield class="wmax-280 mt-0">
          <kendo-label
            text="Select Area"
            labelCssClass="k-form-label"
          ></kendo-label>
          <kendo-dropdownlist
            [data]="feederAreaList"
            [(ngModel)]="selectedFeederAreaId"
            [ngModelOptions]="{ standalone: true }"
            [textField]="'key'"
            [valueField]="'value'"
            (selectionChange)="onFeederAreaChange($event)"
          ></kendo-dropdownlist>
        </kendo-formfield>

        <kendo-formfield class="wmax-280 mt-0">
          <kendo-label
            text="Select Device"
            labelCssClass="k-form-label"
          ></kendo-label>
          <kendo-dropdownlist
            [data]="deviceList"
            [(ngModel)]="selectedDeviceId"
            [ngModelOptions]="{ standalone: true }"
            [textField]="'key'"
            [valueField]="'value'"
            (selectionChange)="onUploadValidation()"
          ></kendo-dropdownlist>
        </kendo-formfield>
      </div>
      <div class="mb-20 mt-10">
        <kendo-label
          text="Upload File"
          labelCssClass="k-form-label"
        ></kendo-label>
        <div class="file-upload-area">
          <label for="fileInput" class="file-upload">
            <span class="upload-icon-container"
              ><i class="far fa-arrow-up-from-bracket"></i
            ></span>
            <p class="font-size-14 mb-0">
              <span class="primary-text fw-500">Choose file</span> to upload
            </p>
          </label>
          <input
            type="file"
            id="fileInput"
            #fileInput
            (change)="onFileSelect($event)"
          />
        </div>
        <div class="q-card pt-0 pl-0 pb-0 image-url-conatiner">
          <div *ngIf="softwareUploadList.length">
            <div
              *ngFor="let data of softwareUploadList; let i = index"
              class="image-upload-area"
            >
              <div class="font-size-14">{{ data.fileName }}</div>
              <div>
                <a title="delete" (click)="onDeleteUploadFileFromListFile(i)">
                  <i
                    class="far fa-circle-xmark text-secondary font-size-16 mt-5"
                  ></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
    <div class="info-text-container">
      <p class="font-size-14 text-secondary mb-0">
        <span class="ml-5">*</span>Update the OTA to device and Office use only.
      </p>
      <p class="ml-5 mt-5 red font-size-14"><span>*</span>Office Use Only.</p>
    </div>
  </div>
  <div
    class="footer-button-container"
    [ngClass]="{
      'curser-not-allowd':
        !(hasAddPermission$ | async) || isUploadButtonDisabled
    }"
  >
    <button
      kendoButton
      (click)="onSubmit()"
      [disabled]="!(hasAddPermission$ | async) || isUploadButtonDisabled"
    >
      Upload
    </button>
  </div>

  <div kendoDialogContainer></div>
</div>
