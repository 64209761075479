import { Injectable } from '@angular/core'
import { Action, State, StateContext } from '@ngxs/store'
import { ColumnPermissionService } from '@app/services'
import { tap } from 'rxjs'
import {
  GetColumnPermissionList,
  GetRoleModulePermissionList,
  SaveColumnModulePermissionList,
} from '@app/index'
export class ColumnPermissionStateInfo {
  columnPermissionRoleList?: any
  columnModulePermissionList?: any
}

@State<ColumnPermissionStateInfo>({
  name: 'columnPermission',
  defaults: {},
})
@Injectable()
export class ColumnPermissionState {
  constructor(private columnPermissionService: ColumnPermissionService) {}

  @Action(SaveColumnModulePermissionList)
  saveColumnModulePermissionList(
    { getState, patchState }: StateContext<ColumnPermissionStateInfo>,
    action: SaveColumnModulePermissionList,
  ) {
    return this.columnPermissionService.saveColumnModulePermissionList(
      action.moduleId,
      action.roleId,
      action.saveRoleModulePermissionRequestBody,
    )
  }

  @Action(GetColumnPermissionList)
  getColumnPermissionList(
    { getState, patchState }: StateContext<ColumnPermissionStateInfo>,
    action: GetColumnPermissionList,
  ) {
    return this.columnPermissionService
      .getColumnPermissionList(action.columnsPermissionData)
      .pipe(
        tap((res: any) => {
          patchState({
            columnModulePermissionList: res,
          })
        }),
      )
  }
}
