import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { DataTypeEnum } from '@app/enums'
import { Observable } from 'rxjs'
import { environment } from 'src/environments/environment'

@Injectable()
export class MeterDataService {
  constructor(private http: HttpClient) {}

  getMeterDataList(queryParam: any): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    }
    return this.http.post<any>(
      `${environment.apiVersionUrl}MeterData/GetDataReport`,
      queryParam,
      headers,
    )
  }
}
