import { Component, Input, OnInit } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { CommonService } from '@app/services'

@Component({
  selector: 'app-min-voltage',
  templateUrl: './min-voltage.component.html',
  styleUrl: './min-voltage.component.scss',
})
export class MinVoltageComponent implements OnInit {
  minVoltageForm: FormGroup | any
  @Input()
  triggerMinVoltageData: any
  constructor(public commonService: CommonService) {}

  ngOnInit(): void {
    this.setForm()
    this.triggerMinVoltageData.subscribe((res: any) => {
      if (res) {
        this.setData(res)
      } else {
        this.setForm()
      }
    })
  }

  setData(data: any): void {
    this.minVoltageForm.patchValue({
      rPhase: data.minRPhase ?? '',
      yPhase: data.minYPhase ?? '',
      bPhase: data.minBPhase ?? '',
    })
  }

  setForm(): void {
    this.minVoltageForm = new FormGroup({
      rPhase: new FormControl('', [
        Validators.required,
        Validators.pattern('^[0-9]+(.[0-9]+)?$'),
      ]),
      yPhase: new FormControl('', [
        Validators.required,
        Validators.pattern('^[0-9]+(.[0-9]+)?$'),
      ]),
      bPhase: new FormControl('', [
        Validators.required,
        Validators.pattern('^[0-9]+(.[0-9]+)?$'),
      ]),
    })
  }
}
