import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { MenuList } from '@app/enums'
import { CommonService } from '@app/services'
import { CommonState, GetUpdatedTime } from '@app/store'
import { Store } from '@ngxs/store'

@Component({
  selector: 'app-common-header-text',
  templateUrl: './common-header-text.component.html',
  styleUrl: './common-header-text.component.scss',
})
export class CommonHeaderTextComponent implements OnInit {
  @Input() infoText: string = ''
  @Input() isInfoVisible: boolean = false
  @Input() isUpdateDataVisible: boolean = true
  @Output() infoClick = new EventEmitter<void>()
  @Input() triggerGetUpdatedTime: any
  @Input() triggerGetUpdatedTimeWithData: any
  deviceId: any
  updatedTime = ''
  constructor(
    public commonService: CommonService,
    private store: Store,
  ) {}

  ngOnInit(): void {
    this.triggerGetUpdatedTime?.subscribe((res: boolean) => {
      if (res) {
        this.getUpdateTime(res)
      } else {
        this.updatedTime = ''
      }
    })

    this.triggerGetUpdatedTimeWithData?.subscribe((res: any) => {
      this.getUpdateTime(res)
    })
  }

  getUpdateTime(res?: any): void {
    const moduleId = this.store.selectSnapshot(CommonState.moduleId)

    if (
      moduleId !== MenuList['Meter Data'] &&
      moduleId !== MenuList['Data Report'] &&
      moduleId !== MenuList['Threshold Settings']
    ) {
      this.deviceId = this.store.selectSnapshot(CommonState.getDeviceId)
    } else {
      this.deviceId = { value: res.deviceId }
    }

    if (this.deviceId?.value !== undefined && this.deviceId?.value !== null) {
      this.store
        .dispatch(new GetUpdatedTime(this.deviceId?.value))
        .subscribe((res) => {
          this.updatedTime = res.dashboard.updatedTime
        })
    }
  }

  onInfoClick() {
    if (this.infoClick.observers.length > 0) {
      this.infoClick.emit()
    }
  }
}
