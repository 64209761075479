import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import {
  DownloadRoutingModule,
  CommonsModule,
  DtrDataComponent,
} from '@app/index'
import { SharedModule } from 'src/shared/shared.module'

@NgModule({
  declarations: [DtrDataComponent],
  imports: [CommonModule, DownloadRoutingModule, CommonsModule, SharedModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class DownloadModule {}
