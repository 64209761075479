import { Injectable } from '@angular/core'
import { CanActivate, Router } from '@angular/router'
import { CommonService } from '@app/services'

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private commonService: CommonService,
    private router: Router,
  ) {}

  async canActivate(): Promise<boolean> {
    if (this.commonService.isLoggedIn()) {
      return true
    } else if (await this.commonService.refreshToken()) {
      return true
    } else {
      localStorage.removeItem('loginToken')
      localStorage.removeItem('refreshLoginToken')
      this.router.navigate(['/login'])
      return false
    }
  }
}
