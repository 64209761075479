import { geometry, drawing } from '@progress/kendo-drawing';
import { SankeyElement } from './element';
import { deepExtend } from '../common';
import { ARIA_ACTIVE_DESCENDANT } from '../common/constants';

export class Node extends SankeyElement {
    getElement() {
        return drawing.Path.fromRect(this.getRect(), this.visualOptions());
    }

    getRect() {
        const node = this.options.node;
        return new geometry.Rect([node.x0, node.y0], [node.x1 - node.x0, node.y1 - node.y0]);
    }

    getLabelText(options) {
        let labelTemplate = options.labels.ariaTemplate;

        if (labelTemplate) {
            return labelTemplate({ node: options.node });
        }
    }

    visualOptions() {
        const options = deepExtend({}, this.options, this.options.node);
        const ariaLabel = this.getLabelText(options);

        return {
            fill: {
                color: options.color,
                opacity: options.opacity
            },
            stroke: { width: 0 },
            className: 'k-sankey-node',
            role: 'graphics-symbol',
            ariaRoleDescription: 'Node',
            ariaLabel: ariaLabel
        };
    }

    createFocusHighlight() {
        if (!this.options.navigatable) {
            return;
        }

        this._highlight = drawing.Path.fromRect(this.getRect(), {
            stroke: this.options.focusHighlight,
            visible: false
        });

        return this._highlight;
    }

    focus(options) {
        if (this._highlight) {
            const { highlight = true } = options || {};
            if (highlight) {
                this._highlight.options.set('visible', true);
            }
            const id = this.options.node.id;
            this.visual.options.set('id', id);

            if (this.options.root()) {
                this.options.root().setAttribute(ARIA_ACTIVE_DESCENDANT, id);
            }
        }
    }

    blur() {
        if (this._highlight) {
            this._highlight.options.set('visible', false);
            this.visual.options.set('id', '');

            if (this.options.root()) {
                this.options.root().removeAttribute(ARIA_ACTIVE_DESCENDANT);
            }
        }
    }
}

const nodeColor = (node, nodeColors, index) => node.color || nodeColors[index % nodeColors.length];

export const resolveNodeOptions = (node, options, nodeColors, index) => {
    const nodeOptions = deepExtend({}, options, options.node);
    return deepExtend({},
        { color: nodeColor(node, nodeColors, index) },
        nodeOptions,
        { node },
        {
            visual: node.visual,
            opacity: node.opacity,
            offset: node.offset,
            color: node.color
        }
    );
};
