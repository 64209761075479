<form [formGroup]="deviceIdForm" class="k-form k-form-md">
  <kendo-formfield class="mb-20">
    <kendo-label [for]="deviceId" text="New Id" labelCssClass="k-form-label"
      ><span class="required">*</span></kendo-label
    >
    <kendo-textbox
      formControlName="newDeviceId"
      placeholder="New ID"
      #deviceId
    ></kendo-textbox>
  </kendo-formfield>
</form>
