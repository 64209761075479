<p class="font-size-18 mb-0 fw-500 header-text">
  <span>{{ this.commonService.headerText }}</span>
  <i
    class="far fa-circle-info font-size-16 ml-10 cursor-pointer fw-500 info-icon"
    *ngIf="isInfoVisible"
    [title]="infoText"
    (click)="onInfoClick()"
  ></i>
</p>
<p class="update-date" *ngIf="isUpdateDataVisible">
  <i class="far fa-arrows-rotate"></i> Updated On : {{ updatedTime }}
</p>
