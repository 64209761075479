import { DatePipe } from '@angular/common'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import {
  ActionTypeEnum,
  DashboardDTRStatus,
  DataTypeEnum,
  MenuList,
  PeriodEnum,
} from '@app/enums'
import { Roles } from '@app/enums/roles'
import {
  CommonState,
  GetRefreshLoginToken,
  SetModulePermission,
} from '@app/store'
import { JwtHelperService } from '@auth0/angular-jwt'
import { Select, Store } from '@ngxs/store'
import { NgxSpinnerService } from 'ngx-spinner'
import { Observable } from 'rxjs'
import { environment } from 'src/environments'

@Injectable()
export class CommonService {
  private jwtHelper = new JwtHelperService()
  @Select(CommonState.menu)
  menuList$: Observable<Array<any>> | undefined

  headerText: any = 'Welcome to the Dashboard'
  private regex: RegExp = new RegExp(/^\d*\.?\d{0,2}$/g)
  private specialKeys: Array<string> = [
    'Backspace',
    'Tab',
    'End',
    'Home',
    'ArrowLeft',
    'ArrowRight',
    'Del',
    'Delete',
  ]

  constructor(
    private http: HttpClient,
    private router: Router,
    private store: Store,
    private spinner: NgxSpinnerService,
    private datePipe: DatePipe,
  ) {}

  checkPermission(url: any): boolean {
    let isPermission = true
    this.menuList$?.subscribe((data) => {
      let isPathAvailable = this.isPathAvailable(data, url)
      if (isPathAvailable) {
        isPermission = true
      } else {
        isPermission = false
      }
    })
    return isPermission
  }

  changeOfRoutes(): void {
    this.menuList$?.subscribe((data) => {
      let urlIndex = ''
      if (this.router.url.indexOf(';') > 0) {
        urlIndex = ';'
      }
      if (this.router.url.indexOf('?') > 0) {
        urlIndex = '?'
      }
      const currentUrl =
        this.router.url.indexOf(urlIndex) > 0
          ? this.router.url.split(urlIndex)[0]
          : this.router.url
      let isPathAvailable = this.isPathAvailable(data, currentUrl)
      if (isPathAvailable) {
        this.setData(currentUrl, data)
      } else {
        if (
          currentUrl !== '/edit-ticket' &&
          currentUrl !== '/manage-device' &&
          currentUrl !== '/login' &&
          currentUrl !== '/change-password' &&
          currentUrl !== '/account-list' &&
          currentUrl !== '/create-account' &&
          currentUrl !== '/add-Device' &&
          currentUrl !== '/assign-hierarchical-structure' &&
          currentUrl !== '/complaints' &&
          currentUrl !== '/module-history'
        ) {
          // direct url hit of page which is not allowed for user
          this.router.navigate(['access-denied'])
        } else {
          this.setData(currentUrl, data)
        }
      }
    })
  }

  isPathAvailable(menu: any, targetPath: any): boolean {
    for (const item of menu) {
      if (item.path === targetPath) {
        return true
      }
    }
    return false
  }

  setData(currentUrl: any, data: any): void {
    data.forEach((x: any) => {
      if (x.path === currentUrl) {
        this.store.dispatch(new SetModulePermission(x)).subscribe()
        //set module permission
      }
    })
  }

  getLoggedInUserDetails() {
    return JSON.parse(localStorage.getItem('userDetails') || '{}')
  }

  onDialogClose(dialogRef: any, isSuccess: any): void {
    const dialogResult = { confirmed: isSuccess }
    dialogRef.close(dialogResult)
  }

  getRefreshLoginToken(refreshToken: string): any {
    const url = `${environment.apiVersionUrl}Token/referesh-token`
    const body = refreshToken
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' })
    return this.http.post(url, JSON.stringify(body), { headers })
  }

  getLoginToken(userName: string, password: string): any {
    const url = `${environment.apiVersionUrl}Token/generate-token`
    const body = { userName, password }
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' })
    return this.http.post(url, body, { headers })
  }

  refreshToken(): Promise<boolean> {
    if (this.checkRefreshTokenExpired()) {
      const token = localStorage.getItem('refreshLoginToken')
      if (!token) {
        return Promise.resolve(false)
      }
      this.spinner.show()
      return this.store
        .dispatch(new GetRefreshLoginToken(token))
        .toPromise()
        .then((res: any) => {
          this.spinner.hide()
          localStorage.setItem('loginToken', res.common.loginToken.accessToken)
          localStorage.setItem(
            'refreshLoginToken',
            res.common.loginToken.refreshToken,
          )
          return true
        })
        .catch((error) => {
          this.spinner.hide()
          return false
        })
    }
    return Promise.resolve(false)
  }

  checkRefreshTokenExpired(): boolean {
    const token = localStorage.getItem('refreshLoginToken')
    if (!token) {
      return false
    }

    if (this.jwtHelper.isTokenExpired(token)) {
      return false
    }

    return true
  }

  isLoggedIn(): boolean {
    const token = localStorage.getItem('loginToken')
    if (!token) {
      return false
    }

    if (this.jwtHelper.isTokenExpired(token)) {
      return false
    }
    return true
  }

  getMenu(): Observable<Array<any>> {
    return this.http.get<Array<any>>(
      `${environment.apiVersionUrl}ModuleRolePermission/user`,
    )
  }

  getMenuWithColumns(): Observable<Array<any>> {
    return this.http.get<Array<any>>(
      `${environment.apiVersionUrl}ModuleRolePermission/user/with-columns`,
    )
  }

  onlyAlphabateInputValidation(event: any): void {
    const key = event.key
    const regex = /^[a-zA-Z\s]+$/

    if (!regex.test(key)) {
      event.preventDefault()
    }
  }

  onlyNumericInputValidation(event: KeyboardEvent): void {
    const allowedKeys = [
      'Backspace',
      'Tab',
      'Escape',
      'Delete',
      'ArrowLeft',
      'ArrowRight',
      'ArrowUp',
      'ArrowDown',
      'Home',
      'End',
      'Control',
      'v',
      '.',
    ]

    const input = event.target as HTMLInputElement
    const value = input.value
    const decimalIndex = value.indexOf('.')

    if (
      (!event.key.match(/^\d+$/) && !allowedKeys.includes(event.key)) ||
      (event.key === '.' && (decimalIndex !== -1 || value.length === 0))
    ) {
      event.preventDefault()
    }

    const [integerPart, decimalPart] = value.split('.')
    if (
      (decimalIndex === -1 &&
        integerPart.length >= 6 &&
        event.key.match(/^\d+$/)) ||
      (decimalIndex !== -1 &&
        decimalPart &&
        decimalPart.length >= 2 &&
        event.key.match(/^\d+$/))
    ) {
      event.preventDefault()
    }
  }

  getFileExtension(fileName: string | undefined): string {
    if (!fileName) {
      return ''
    }

    const parts = fileName.split('.')
    return parts.pop()?.toLowerCase() || ''
  }

  getFeederAreaList(): Observable<any> {
    const url = `${environment.apiVersionUrl}FeederDevice/GetFeederIdByUser`
    return this.http.get<any>(url)
  }

  getFeederAreaListBasedOnFeederType(isDTR: boolean): Observable<any> {
    const url = `${environment.apiVersionUrl}FeederDevice/GetFeederIdByUserBasedOnFeederType/${isDTR}`
    return this.http.get<any>(url)
  }

  getIconClass(period: string): string {
    const formattedPeriod = this.toUpperCase(period)

    const iconMap: { [key: string]: string } = {
      [PeriodEnum['Today'].toString()]: 'far fa-calendar-day',
      [PeriodEnum['This Week'].toString()]: 'far fa-calendar-week',
      [PeriodEnum['This Month'].toString()]: 'far fa-calendar-alt',
      [PeriodEnum['Last 3 Months'].toString()]: 'far fa-calendar-alt',
      [PeriodEnum['Last 6 Months'].toString()]: 'far fa-calendar-alt',
      [PeriodEnum['Last 1 Year'].toString()]: 'far fa-calendar',
      default: 'far fa-calendar',
    }

    return iconMap[formattedPeriod] || iconMap['default']
  }

  getPeroidName(period: string): string {
    const formattedPeriod = this.toUpperCase(period)
    const periodNameMap: { [key: string]: string } = {
      Today: 'day',
      'This Week': 'week',
      'This Month': 'month',
      'Last 3 Months': 'threeMonths',
      'Last 6 Months': 'sixMonths',
      'Last 1 Year': 'year',
      default: 'year',
    }

    return periodNameMap[formattedPeriod] || periodNameMap['default']
  }

  toUpperCase(str: string): string {
    return str.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase())
  }

  getColumnNameByModule(moduleId: any, staus?: any): any {
    let columns
    switch (moduleId) {
      case MenuList['Data Report']:
        columns = [
          {
            field: 'deviceId',
            title: 'Device Id',
            hasColumn: [],
            isSelected: true,
            isLocked: false,
          },
          {
            field: 'capacity',
            title: 'Capacity(kVA)',
            hasColumn: [],
            isSelected: true,
            isLocked: false,
          },
          {
            field: 'updatedOn',
            title: 'Updated On',
            hasColumn: [],
            dataTypeId: DataTypeEnum.DateTime,
            isSelected: true,
            isLocked: false,
          },
          {
            field: 'phaseVoltage',
            title: 'Phase Voltage (Volts)',
            hasColumn: [
              {
                field: 'phaseVoltageR',
                title: 'R',
                dataTypeId: DataTypeEnum.Float,
                hasColumn: [],
                isSelected: true,
                isLocked: false,
              },
              {
                field: 'phaseVoltageY',
                title: 'Y',
                dataTypeId: DataTypeEnum.Float,
                hasColumn: [],
                isSelected: true,
                isLocked: false,
              },
              {
                field: 'phaseVoltageB',
                title: 'B',
                dataTypeId: DataTypeEnum.Float,
                hasColumn: [],
                isSelected: true,
                isLocked: false,
              },
            ],
            isSelected: true,
            isLocked: false,
          },
          {
            field: 'phaseCurrent',
            title: 'Phase Currents (Amps)',
            hasColumn: [
              {
                field: 'phaseCurrentR',
                title: 'R',
                dataTypeId: DataTypeEnum.Float,
                hasColumn: [],
                isSelected: true,
                isLocked: false,
              },
              {
                field: 'phaseCurrentY',
                title: 'Y',
                dataTypeId: DataTypeEnum.Float,
                hasColumn: [],
                isSelected: true,
                isLocked: false,
              },
              {
                field: 'phaseCurrentB',
                title: 'B',
                dataTypeId: DataTypeEnum.Float,
                hasColumn: [],
                isSelected: true,
                isLocked: false,
              },
              {
                field: 'phaseCurrentNC',
                title: 'NC',
                dataTypeId: DataTypeEnum.Float,
                hasColumn: [],
                isSelected: true,
                isLocked: false,
              },
            ],
            isSelected: true,
            isLocked: false,
          },
          {
            field: 'loadFactor',
            title: 'Load Factor (%)',
            hasColumn: [
              {
                field: 'loadFactorValue',
                title: 'Total',
                dataTypeId: DataTypeEnum.Float,
                hasColumn: [],
                isSelected: true,
                isLocked: false,
              },
            ],
            isSelected: true,
            isLocked: false,
          },
          {
            field: 'phaseLoadFactor',
            title: 'Phases Load Factor(%)',
            hasColumn: [
              {
                field: 'phaseLoadFactorR',
                title: 'R',
                dataTypeId: DataTypeEnum.Float,
                hasColumn: [],
                isSelected: true,
                isLocked: false,
              },
              {
                field: 'phaseLoadFactorR',
                title: 'Y',
                dataTypeId: DataTypeEnum.Float,
                hasColumn: [],
                isSelected: true,
                isLocked: false,
              },
              {
                field: 'phaseLoadFactorB',
                title: 'B',
                dataTypeId: DataTypeEnum.Float,
                hasColumn: [],
                isSelected: true,
                isLocked: false,
              },
            ],
            isSelected: true,
            isLocked: false,
          },
          {
            field: 'powerFactor',
            title:
              'Power Factor ("-" Indicates the lead PF(Load is capacitive))',
            hasColumn: [
              {
                field: 'powerFactorR',
                title: 'R',
                dataTypeId: DataTypeEnum.Float,
                hasColumn: [],
                isSelected: true,
                isLocked: false,
              },
              {
                field: 'powerFactorY',
                title: 'Y',
                dataTypeId: DataTypeEnum.Float,
                hasColumn: [],
                isSelected: true,
                isLocked: false,
              },
              {
                field: 'powerFactorB',
                title: 'B',
                dataTypeId: DataTypeEnum.Float,
                hasColumn: [],
                isSelected: true,
                isLocked: false,
              },
              {
                field: 'powerFactorTotal',
                title: 'Total',
                dataTypeId: DataTypeEnum.Float,
                hasColumn: [],
                isSelected: true,
                isLocked: false,
              },
            ],
            isSelected: true,
            isLocked: false,
          },
          {
            field: 'temperature',
            title: 'Temperature(C)',
            hasColumn: [
              {
                field: 'temperatureR',
                title: 'R',
                dataTypeId: DataTypeEnum.Float,
                hasColumn: [],
                isSelected: true,
                isLocked: false,
              },
              {
                field: 'temperatureY',
                title: 'Y',
                dataTypeId: DataTypeEnum.Float,
                hasColumn: [],
                isSelected: true,
                isLocked: false,
              },
              {
                field: 'temperatureB',
                title: 'B',
                dataTypeId: DataTypeEnum.Float,
                hasColumn: [],
                isSelected: true,
                isLocked: false,
              },
              {
                field: 'temperatureOil',
                title: 'Oil',
                dataTypeId: DataTypeEnum.Float,
                hasColumn: [],
                isSelected: true,
                isLocked: false,
              },
            ],
            isSelected: true,
            isLocked: false,
          },
          {
            field: 'frequency',
            title: 'Frequency(Hz)',
            hasColumn: [
              {
                field: 'frequencyR',
                title: 'R',
                dataTypeId: DataTypeEnum.Float,
                hasColumn: [],
                isSelected: true,
                isLocked: false,
              },
              {
                field: 'frequencyY',
                title: 'Y',
                dataTypeId: DataTypeEnum.Float,
                hasColumn: [],
                isSelected: true,
                isLocked: false,
              },
              {
                field: 'frequencyB',
                title: 'B',
                dataTypeId: DataTypeEnum.Float,
                hasColumn: [],
                isSelected: true,
                isLocked: false,
              },
            ],
            isSelected: true,
            isLocked: false,
          },
          {
            field: 'kw',
            title: 'KW',
            hasColumn: [
              {
                field: 'kwr',
                title: 'R',
                dataTypeId: DataTypeEnum.Float,
                hasColumn: [],
                isSelected: true,
                isLocked: false,
              },
              {
                field: 'kwy',
                title: 'Y',
                dataTypeId: DataTypeEnum.Float,
                hasColumn: [],
                isSelected: true,
                isLocked: false,
              },
              {
                field: 'kwb',
                title: 'B',
                dataTypeId: DataTypeEnum.Float,
                hasColumn: [],
                isSelected: true,
                isLocked: false,
              },
              {
                field: 'kwTotal',
                title: 'Total',
                dataTypeId: DataTypeEnum.Float,
                hasColumn: [],
                isSelected: true,
                isLocked: false,
              },
            ],
            isSelected: true,
            isLocked: false,
          },
          {
            field: 'kva',
            title: 'KVA',
            hasColumn: [
              {
                field: 'kvar',
                title: 'R',
                dataTypeId: DataTypeEnum.Float,
                hasColumn: [],
                isSelected: true,
                isLocked: false,
              },
              {
                field: 'kvay',
                title: 'Y',
                dataTypeId: DataTypeEnum.Float,
                hasColumn: [],
                isSelected: true,
                isLocked: false,
              },
              {
                field: 'kvab',
                title: 'B',
                dataTypeId: DataTypeEnum.Float,
                hasColumn: [],
                isSelected: true,
                isLocked: false,
              },
              {
                field: 'kvaTotal',
                title: 'Total',
                dataTypeId: DataTypeEnum.Float,
                hasColumn: [],
                isSelected: true,
                isLocked: false,
              },
            ],
            isSelected: true,
            isLocked: false,
          },
          {
            field: 'lagKWh',
            title: 'Lag KWh(Units)',
            hasColumn: [
              {
                field: 'lagKWhR',
                title: 'R',
                dataTypeId: DataTypeEnum.Float,
                hasColumn: [],
                isSelected: true,
                isLocked: false,
              },
              {
                field: 'lagKWhY',
                title: 'Y',
                dataTypeId: DataTypeEnum.Float,
                hasColumn: [],
                isSelected: true,
                isLocked: false,
              },
              {
                field: 'lagKWhB',
                title: 'B',
                dataTypeId: DataTypeEnum.Float,
                hasColumn: [],
                isSelected: true,
                isLocked: false,
              },
              {
                field: 'lagKWhTotal',
                title: 'Total',
                dataTypeId: DataTypeEnum.Float,
                hasColumn: [],
                isSelected: true,
                isLocked: false,
              },
            ],
            isSelected: true,
            isLocked: false,
          },
          {
            field: 'leadKWh',
            title: 'Lead KWh(Units)',
            hasColumn: [
              {
                field: 'leadKWhR',
                title: 'R',
                dataTypeId: DataTypeEnum.Float,
                hasColumn: [],
                isSelected: true,
                isLocked: false,
              },
              {
                field: 'leadKWhY',
                title: 'Y',
                dataTypeId: DataTypeEnum.Float,
                hasColumn: [],
                isSelected: true,
                isLocked: false,
              },
              {
                field: 'leadKWhB',
                title: 'B',
                dataTypeId: DataTypeEnum.Float,
                hasColumn: [],
                isSelected: true,
                isLocked: false,
              },
              {
                field: 'leadKWhTotal',
                title: 'Total',
                dataTypeId: DataTypeEnum.Float,
                hasColumn: [],
                isSelected: true,
                isLocked: false,
              },
            ],
            isSelected: true,
            isLocked: false,
          },
          {
            field: 'lagKVAh',
            title: 'Lag KVAh(Units)',
            hasColumn: [
              {
                field: 'lagKVAhR',
                title: 'R',
                dataTypeId: DataTypeEnum.Float,
                hasColumn: [],
                isSelected: true,
                isLocked: false,
              },
              {
                field: 'lagKVAhY',
                title: 'Y',
                dataTypeId: DataTypeEnum.Float,
                hasColumn: [],
                isSelected: true,
                isLocked: false,
              },
              {
                field: 'lagKVAhB',
                title: 'B',
                dataTypeId: DataTypeEnum.Float,
                hasColumn: [],
                isSelected: true,
                isLocked: false,
              },
              {
                field: 'lagKVAhTotal',
                title: 'Total',
                dataTypeId: DataTypeEnum.Float,
                hasColumn: [],
                isSelected: true,
                isLocked: false,
              },
            ],
            isSelected: true,
            isLocked: false,
          },
          {
            field: 'leadKVAh',
            title: 'Lead KVAh(Units)',
            hasColumn: [
              {
                field: 'leadKVAhR',
                title: 'R',
                dataTypeId: DataTypeEnum.Float,
                hasColumn: [],
                isSelected: true,
                isLocked: false,
              },
              {
                field: 'leadKVAhY',
                title: 'Y',
                dataTypeId: DataTypeEnum.Float,
                hasColumn: [],
                isSelected: true,
                isLocked: false,
              },
              {
                field: 'leadKVAhB',
                title: 'B',
                dataTypeId: DataTypeEnum.Float,
                hasColumn: [],
                isSelected: true,
                isLocked: false,
              },
              {
                field: 'leadKVAhTotal',
                title: 'Total',
                dataTypeId: DataTypeEnum.Float,
                hasColumn: [],
                isSelected: true,
                isLocked: false,
              },
            ],
            isSelected: true,
            isLocked: false,
          },
          {
            field: 'lagKVArh',
            title: 'Lag KVArh(Units)',
            hasColumn: [
              {
                field: 'lagKVArhR',
                title: 'R',
                dataTypeId: DataTypeEnum.Float,
                hasColumn: [],
                isSelected: true,
                isLocked: false,
              },
              {
                field: 'lagKVArhY',
                title: 'Y',
                dataTypeId: DataTypeEnum.Float,
                hasColumn: [],
                isSelected: true,
                isLocked: false,
              },
              {
                field: 'lagKVArhB',
                title: 'B',
                dataTypeId: DataTypeEnum.Float,
                hasColumn: [],
                isSelected: true,
                isLocked: false,
              },
              {
                field: 'lagKVArhTotal',
                title: 'Total',
                dataTypeId: DataTypeEnum.Float,
                hasColumn: [],
                isSelected: true,
                isLocked: false,
              },
            ],
            isSelected: true,
            isLocked: false,
          },
          {
            field: 'leadKVArh',
            title: 'Lead KVArh(Units)',
            hasColumn: [
              {
                field: 'leadKVArhR',
                title: 'R',
                dataTypeId: DataTypeEnum.Float,
                hasColumn: [],
                isSelected: true,
                isLocked: false,
              },
              {
                field: 'leadKVArhY',
                title: 'Y',
                dataTypeId: DataTypeEnum.Float,
                hasColumn: [],
                isSelected: true,
                isLocked: false,
              },
              {
                field: 'leadKVArhB',
                title: 'B',
                dataTypeId: DataTypeEnum.Float,
                hasColumn: [],
                isSelected: true,
                isLocked: false,
              },
              {
                field: 'leadKVArhTotal',
                title: 'Total',
                dataTypeId: DataTypeEnum.Float,
                hasColumn: [],
                isSelected: true,
                isLocked: false,
              },
            ],
            isSelected: true,
            isLocked: false,
          },
          {
            field: 'onBoardTemp',
            title: 'On-Board Temp',
            hasColumn: [],
            isSelected: true,
            isLocked: false,
          },
          {
            field: 'systemBattery',
            title: 'System Battery(mV)',
            hasColumn: [],
            isSelected: true,
            isLocked: false,
          },
          {
            field: 'location',
            title: 'Location',
            hasColumn: [],
            isSelected: true,
            isLocked: false,
            dataTypeId: DataTypeEnum.Link,
          },
        ]
        break

      case MenuList['Meter Data']:
        columns = [
          {
            field: 'deviceId',
            title: 'Device Id',
            hasColumn: [],
            isSelected: true,
            isLocked: false,
          },
          {
            field: 'updatedOn',
            title: 'Updated On',
            hasColumn: [],
            dataTypeId: DataTypeEnum.DateTime,
            isSelected: true,
            isLocked: false,
          },
          {
            field: 'capacity',
            title: 'Capicity(kVA)',
            hasColumn: [],
            isSelected: true,
            isLocked: false,
          },
          {
            field: 'kWhData',
            title: 'kWh(Units)',
            isSelected: true,
            isLocked: false,
            hasColumn: [
              { field: 'kWhR', title: 'R', isSelected: true, isLocked: false },
              { field: 'kWhY', title: 'Y', isSelected: true, isLocked: false },
              { field: 'kWhB', title: 'B', isSelected: true, isLocked: false },
              {
                field: 'kWhTotal',
                title: 'Total',
                isSelected: true,
                isLocked: false,
              },
            ],
          },
          {
            field: 'kvAhData',
            title: 'kVAh(Units)',
            isSelected: true,
            isLocked: false,
            hasColumn: [
              { field: 'kvAhR', title: 'R', isSelected: true, isLocked: false },
              { field: 'kvAhY', title: 'Y', isSelected: true, isLocked: false },
              { field: 'kvAhB', title: 'B', isSelected: true, isLocked: false },
              {
                field: 'kvAhTotal',
                title: 'Total',
                isSelected: true,
                isLocked: false,
              },
            ],
          },
        ]
        break
      case MenuList.Overload:
        columns = [
          {
            field: 'message',
            title: 'Alert',
            hasColumn: [],
            isSelected: true,
            isLocked: false,
          },
          {
            field: 'load',
            title: 'Load',
            hasColumn: [],
            isSelected: true,
            isLocked: false,
          },
          {
            field: 'dateTime',
            title: 'Date & Time',
            hasColumn: [],
            isSelected: true,
            isLocked: false,
            dataTypeId: DataTypeEnum.DateTime,
          },
        ]
        break

      case MenuList.PF:
        columns = [
          {
            field: 'alert',
            title: 'Alert',
            hasColumn: [],
            isSelected: true,
            isLocked: false,
          },
          {
            field: 'pfValue',
            title: 'PF',
            hasColumn: [],
            isSelected: true,
            isLocked: false,
          },
          {
            field: 'dateTime',
            title: 'Date & time',
            hasColumn: [],
            isSelected: true,
            isLocked: false,
            dataTypeId: DataTypeEnum.DateTime,
          },
        ]
        break

      case MenuList['Sent-Notification']:
        columns = [
          {
            id: 1,
            userId: 14,
            roleId: null,
            moduleGridColumnId: 3,
            isSelected: false,
            isLocked: false,
            name: 'Sent Message',
            field: 'message',
            dataType: 1,
            displayOrder: 1,
            allowSortBy: true,
            alignment: null,
            hasColumn: [],
          },
          {
            id: 2,
            userId: 14,
            roleId: null,
            moduleGridColumnId: 4,
            isSelected: true,
            isLocked: false,
            name: 'Sent Time',
            field: 'dateTime',
            dataType: 12,
            displayOrder: 2,
            allowSortBy: true,
            alignment: null,
            hasColumn: [],
          },
        ]
        break

      case MenuList.Status:
        columns = [
          {
            field: 'batteryVoltage',
            title: 'Voltage(mV)',
            hasColumn: [],
            isSelected: true,
            isLocked: false,
          },
          {
            field: 'dateTime',
            title: 'Date & Time',
            hasColumn: [],
            isSelected: true,
            isLocked: false,
            dataTypeId: DataTypeEnum.DateTime,
          },
        ]
        break

      case MenuList.Software:
        columns = [
          {
            field: 'status',
            title: 'Status',
            hasColumn: [],
            isSelected: true,
            isLocked: false,
          },
          {
            field: 'code',
            title: 'Code',
            hasColumn: [],
            isSelected: true,
            isLocked: false,
          },
          {
            field: 'dateTime',
            title: 'Date & Time',
            hasColumn: [],
            isSelected: true,
            isLocked: false,
            dataTypeId: DataTypeEnum.DateTime,
          },
        ]
        break

      case MenuList['SS SLD Dashboard']:
        columns = [
          {
            field: 'deviceId',
            title: 'Feeder/LV',
            hasColumn: [],
            isSelected: true,
            isLocked: false,
          },
          {
            field: 'dateTime',
            title: 'Date&Time',
            dataTypeId: DataTypeEnum.DateTime,
            hasColumn: [],
            isSelected: true,
            isLocked: false,
          },
          {
            field: 'acvtiveDevices',
            title: 'Status',
            dataTypeId: DataTypeEnum.StatusBox,
            hasColumn: [],
            isSelected: true,
            isLocked: false,
          },
          {
            field: 'currentPh1',
            title: 'l-R',
            hasColumn: [],
            isSelected: true,
            isLocked: false,
          },
          {
            field: 'currentPh2',
            title: 'l-Y',
            hasColumn: [],
            isSelected: true,
            isLocked: false,
          },
          {
            field: 'currentPh3',
            title: 'l-B',
            hasColumn: [],
            isSelected: true,
            isLocked: false,
          },
        ]
        break

      case MenuList.Roles:
        columns = [
          {
            field: 'name',
            title: 'Role Name',
            hasColumn: [],
            isSelected: true,
            isLocked: false,
          },
          {
            field: 'description',
            title: 'Description',
            hasColumn: [],
            isSelected: true,
            isLocked: false,
          },
          {
            field: 'createdOn',
            title: 'Created On',
            hasColumn: [],
            dataTypeId: DataTypeEnum.DateTime,
            isSelected: true,
            isLocked: false,
          },
          {
            field: 'updatedOn',
            title: 'Updated On',
            hasColumn: [],
            dataTypeId: DataTypeEnum.DateTime,
            isSelected: true,
            isLocked: false,
          },
          {
            field: 'status',
            title: 'Status',
            hasColumn: [],
            isSelected: true,
            isLocked: false,
          },
        ]
        break

      case MenuList['Page Permission']:
        columns = [
          {
            field: 'moduleName',
            title: 'Module Name',
            hasColumn: [],
            isSelected: true,
            isLocked: false,
          },
        ]
        break
      case MenuList.Devices:
      case MenuList['GISInfo']:
        columns = [
          {
            field: 'deviceId',
            title: 'Device Id',
            dataTypeId: DataTypeEnum.Link,
            hasColumn: [],
            isSelected: true,
            isLocked: false,
          },
          {
            field: 'deviceName',
            title: 'Device Name',
            hasColumn: [],
            isSelected: true,
            isLocked: false,
          },
          {
            field: 'kva',
            title: 'Capacity(kVA)',
            hasColumn: [],
            isSelected: true,
            isLocked: false,
          },
          {
            field: 'installationDate',
            title: 'Installed Date',
            hasColumn: [],
            dataTypeId: DataTypeEnum.DateTime,
            isSelected: true,
            isLocked: false,
          },
          {
            field: 'lastUpdatedOn',
            title: 'Last Update',
            hasColumn: [],
            dataTypeId: DataTypeEnum.DateTime,
            isSelected: true,
            isLocked: false,
          },
          {
            field: 'address',
            title: 'Address',
            hasColumn: [],
            isSelected: true,
            isLocked: false,
          },
          {
            field: 'area',
            title: 'Area',
            hasColumn: [],
            isSelected: true,
            isLocked: false,
          },
        ]
        break
      case MenuList.Feeder:
        columns = [
          {
            field: 'key',
            title: 'Feeder Area Id',
            hasColumn: [],
            isSelected: true,
            isLocked: false,
          },
          {
            field: 'value',
            title: 'Feeder Area Name',
            hasColumn: [],
            isSelected: true,
            isLocked: false,
          },
        ]
        break
      case MenuList['Dashboard Device Status']:
        switch (staus) {
          case DashboardDTRStatus.HighVolatageR:
          case DashboardDTRStatus.HighVolatageY:
          case DashboardDTRStatus.HighVolatageB:
          case DashboardDTRStatus.LowVolatageR:
          case DashboardDTRStatus.LowVolatageY:
          case DashboardDTRStatus.LowVolatageB:
            columns = [
              {
                field: 'deviceID',
                title: 'Device Id',
                hasColumn: [],
              },
              {
                field: 'name',
                title: 'Name',
                hasColumn: [],
              },
              {
                field: 'phR',
                title: 'Ph-R',
                hasColumn: [],
              },
              {
                field: 'phY',
                title: 'Ph-Y',
                hasColumn: [],
              },
              {
                field: 'phB',
                title: 'Ph-B',
                hasColumn: [],
              },
              {
                field: 'updatedRecord',
                title: 'Updated Record',
                hasColumn: [],
              },
              {
                field: 'location',
                title: 'Location',
                hasColumn: [],
              },
            ]
            break
          default:
            columns = [
              {
                field: 'deviceID',
                title: 'Device Id',
                hasColumn: [],
              },
              {
                field: 'name',
                title: 'Name',
                hasColumn: [],
              },
              {
                field: 'updatedRecord',
                title: 'Updated Record',
                hasColumn: [],
              },
              {
                field: 'location',
                title: 'Location',
                hasColumn: [],
              },
            ]
            break
        }
        break
      case MenuList['Module-History']:
        columns = [
          {
            field: 'description',
            title: 'History Description',
            hasColumn: [],
            isSelected: true,
            isLocked: false,
          },
          {
            field: 'createdDate',
            title: 'Created Date',
            hasColumn: [],
            isSelected: true,
            isLocked: false,
          },
          {
            field: 'createdByName',
            title: 'Created By',
            hasColumn: [],
            isSelected: true,
            isLocked: false,
          },
        ]
        break
      case MenuList['Module-History']:
      case MenuList['Device-History']:
        columns = [
          {
            field: 'description',
            title: 'History Description',
            hasColumn: [],
            isSelected: true,
            isLocked: false,
          },
          {
            field: 'createdDate',
            title: 'Created Date',
            hasColumn: [],
            isSelected: true,
            isLocked: false,
          },
          {
            field: 'createdByName',
            title: 'Created By',
            hasColumn: [],
            isSelected: true,
            isLocked: false,
          },
        ]
        break
    }
    return columns
  }

  saveUserModuleGridColumn(saveUserModuleGridColumnList: any): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    }
    const url = `${environment.apiVersionUrl}ModuleGridColumn/SaveUserModuleGridColumnAsync`
    return this.http.put<any>(
      url,
      JSON.stringify(saveUserModuleGridColumnList),
      headers,
    )
  }

  numberOnly(event: any): boolean {
    let value = event.target.value
    if (this.specialKeys.indexOf(event.key) !== -1) {
      return true
    }
    let current: string = value
    const position = event.target.selectionStart
    const next: string = [
      current.slice(0, position),
      event.key == 'Decimal' ? '.' : event.key,
      current.slice(position),
    ].join('')
    if (next && !String(next).match(this.regex)) {
      event.preventDefault()
      return false
    }
    return true
  }

  convertFilterToDateFormat(sqlQuery: any): any {
    const clonedQuery = JSON.parse(JSON.stringify(sqlQuery))

    clonedQuery.filters.forEach((filter: any) => {
      filter.filters.forEach((subFilter: any) => {
        if (subFilter.operator === 'gte') {
          subFilter.value =
            this.datePipe.transform(subFilter.value, 'yyyy-MM-dd') ||
            subFilter.value
        }
      })
    })

    return clonedQuery
  }

  toSQLExpression(filter: any) {
    if (!filter || !filter.filters || filter.filters.length === 0) {
      return null
    }

    const operators: any = {
      eq: '=',
      neq: '!=',
      lt: '<',
      lte: '<=',
      gt: '>',
      gte: '>=',
      startswith: 'LIKE',
      contains: 'LIKE',
      doesnotcontain: 'NOT LIKE',
      endswith: 'LIKE',
    }

    const isDate = (value: any): boolean => {
      const dateRegex =
        /^[A-Za-z]{3} [A-Za-z]{3} \d{2} \d{4} \d{2}:\d{2}:\d{2} GMT[+-]\d{4} \(.*\)$/
      return dateRegex.test(value)
    }

    const formatDateString = (dateString: string): string => {
      const date = new Date(dateString)
      const year = date.getFullYear()
      const month = ('0' + (date.getMonth() + 1)).slice(-2)
      const day = ('0' + date.getDate()).slice(-2)
      return `${year}-${month}-${day}`
    }

    const buildCondition = (condition: any) => {
      const { field, operator, value } = condition
      let sqlOperator = operators[operator]
      let formattedValue = value

      if (isDate(value)) {
        formattedValue = formatDateString(value)
        if (operator === 'startswith') {
          return `${field} ${sqlOperator} DATE('${formattedValue}')%`
        } else if (operator === 'contains') {
          return `${field} ${sqlOperator} %DATE('${formattedValue}')%`
        } else if (operator === 'doesnotcontain') {
          return `${field} ${sqlOperator} %DATE('${formattedValue}')%`
        } else if (operator === 'endswith') {
          return `${field} ${sqlOperator} %DATE('${formattedValue}')`
        } else {
          return `${field} ${sqlOperator} DATE('${formattedValue}')`
        }
      } else {
        if (operator === 'startswith') {
          return `${field} ${sqlOperator} '${value}%'`
        } else if (operator === 'contains') {
          return `${field} ${sqlOperator} '%${value}%'`
        } else if (operator === 'doesnotcontain') {
          return `${field} ${sqlOperator} '%${value}%'`
        } else if (operator === 'endswith') {
          return `${field} ${sqlOperator} '%${value}'`
        } else {
          return `${field} ${sqlOperator} '${value}'`
        }
      }
    }

    const buildSQL = (filter: any) => {
      if (filter.filters) {
        const expressions = filter.filters.map((f: any) => buildSQL(f))
        return `(${expressions.join(` ${filter.logic.toUpperCase()} `)})`
      } else {
        return buildCondition(filter)
      }
    }

    return buildSQL(filter)
  }

  addInstallDateAction(actions: any) {
    const currentUserDetails = this.getLoggedInUserDetails()
    const shouldAddDateAction =
      currentUserDetails.RoleId == Roles.SuperAdmin ? true : false
    if (shouldAddDateAction) {
      actions.push({
        title: 'Add Device Installation Date',
        icon: 'far fa-calendar-day',
        actionTypeId: ActionTypeEnum.AddDate,
      })
    }
  }

  isDDMFormat(lat: number, lon: number): boolean {
    // Simple heuristic: DDM values are generally large since they include both degrees and minutes
    return lat > 90 || lon > 180
  }

  convertDDMtoDD(ddm: string): { latitude: number; longitude: number } {
    const ddmString = ddm.toString()

    const [latDDM, lonDDM] = ddmString
      .split(',')
      .map((coord) => parseFloat(coord.trim()))

    if (!this.isDDMFormat(latDDM, lonDDM)) {
      // Already in Decimal Degrees
      return { latitude: latDDM, longitude: lonDDM }
    }
    // Extract degrees and minutes from latitude
    const latDegrees = Math.floor(latDDM / 100)
    const latMinutes = latDDM % 100
    const latitude = latDegrees + latMinutes / 60

    // Extract degrees and minutes from longitude
    const lonDegrees = Math.floor(lonDDM / 100)
    const lonMinutes = lonDDM % 100
    const longitude = lonDegrees + lonMinutes / 60

    return { latitude, longitude }
  }
}
