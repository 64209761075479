import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { DataTypeEnum } from '@app/enums'
import { Observable, of } from 'rxjs'
import { environment } from 'src/environments'

@Injectable()
export class UserActivationService {
  constructor(private http: HttpClient) {}

  addUserActivation(activationCode: string, deviceId: any): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    }

    const url = `${environment.apiVersionUrl}UserActivation/${deviceId}`
    return this.http.post<any>(url, JSON.stringify(activationCode), headers)
  }

  getDeviceListWithoutDb(): Observable<any> {
    const url = `${environment.apiVersionUrl}UserActivation/GetDeviceListWithoutDb`
    return this.http.get<any>(url)
  }
}
