import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'

import {
  CommonsModule,
  DashboardDeviceStatusPopupComponent,
  DashboardDtrComponent,
  DashboardDtrRoutingModule,
} from '@app/index'
import { SharedModule } from '@shared//shared.module'

@NgModule({
  declarations: [DashboardDtrComponent, DashboardDeviceStatusPopupComponent],
  imports: [
    CommonModule,
    DashboardDtrRoutingModule,
    CommonsModule,
    SharedModule,
  ],
})
export class DashboardDtrModule {}
