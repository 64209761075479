import { Component } from '@angular/core'
import { GetPTRLoadsReporttList } from '@app/store'
import { Store } from '@ngxs/store'
import { NgxSpinnerService } from 'ngx-spinner'

@Component({
  selector: 'app-ptr-loads',
  templateUrl: './ptr-loads.component.html',
  styleUrl: './ptr-loads.component.scss',
})
export class PTRLoadsComponent {
  gridView: any[] = []
  columns: any[] = []

  constructor(
    private store: Store,
    private spinner: NgxSpinnerService,
  ) {}

  ngOnInit(): void {
    this.getSSReportList()
  }

  getSSReportList(): void {
    this.spinner.show()
    this.store.dispatch(new GetPTRLoadsReporttList()).subscribe(
      (res) => {
        this.spinner.hide()
        this.gridView = res.ss.ptrLoadsListData
        this.columns = res.ss.columns
      },
      (error) => {
        this.spinner.hide()
      },
    )
  }

  onSortChange(sortData: any) {}

  onPaginationChange({ skip, take }: any) {}

  getDataByHeaderData(event: any): void {}
}
