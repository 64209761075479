import { Component, Input } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'

@Component({
  selector: 'app-wifi',
  templateUrl: './wifi.component.html',
  styleUrl: './wifi.component.scss',
})
export class WifiComponent {
  wifiForm: FormGroup | any
  @Input()
  triggerWifiData: any
  constructor() {}

  ngOnInit(): void {
    this.setForm()
  }

  setForm(): void {
    this.wifiForm = new FormGroup({
      accessPoint: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required]),
    })
  }
}
