import { Class } from '../../common';

export class EncodingResult extends Class {
    constructor(dataString, version) {
        super();

        this.dataString = dataString;
        this.version = version;
    }
}
