export class GetThresholdSettingsData {
  static readonly type = '[THRESHOLD] Get Threshold Settings Data'
}

export class GetAllThresholdSettings {
  static readonly type = '[THRESHOLD] Get Threshold Settings Data'

  constructor(public deviceId: string) {}
}

export class UpdateMinMaxThresholdVoltage {
  static readonly type = '[THRESHOLD] Update Min Max Threshold Voltage'

  constructor(public voltageData: any) {}
}

export class UpdateCurrentThreshold {
  static readonly type = '[THRESHOLD] Update Current Threshold Voltage'

  constructor(public currentData: any) {}
}

export class UpdateKVAThreshold {
  static readonly type = '[THRESHOLD] Update KVA Threshold Voltage'

  constructor(
    public ratedKVA: any,
    public deviceIds: string,
  ) {}
}

export class UpdateCtThreshold {
  static readonly type = '[THRESHOLD] Update Ct Threshold Voltage'

  constructor(
    public cTRatio: any,
    public deviceIds: string,
  ) {}
}

export class UpdateTemperatureThreshold {
  static readonly type = '[THRESHOLD] Update Temperature Threshold Voltage'

  constructor(
    public temperature: any,
    public deviceIds: string,
  ) {}
}

export class UpdatePFThreshold {
  static readonly type = '[THRESHOLD] Update PF Threshold Voltage'

  constructor(
    public powerFactor: any,
    public deviceIds: string,
  ) {}
}

export class UpdateUnityPFThreshold {
  static readonly type = '[THRESHOLD] Update Unity PF Threshold'

  constructor(
    public isUnityPF: boolean,
    public deviceIds: string,
  ) {}
}
