<div class="q-content">
  <div class="d-flex gap-15 flex-wrap justify-content-between mb-20">
    <app-common-header-text
      [infoText]="infoText"
      [isInfoVisible]="true"
      [isUpdateDataVisible]="false"
    ></app-common-header-text>
    <div class="d-flex gap-15 flex-wrap">
      <app-common-header
        (triggerDataFromHeader)="getDataByHeaderData($event)"
        [showAreaFilter]="true"
      ></app-common-header>
      <div class="drag-icon-container" (click)="onColumnSelector()">
        <a class="drag-icon">
          <img src="assets/images/custom-columns.svg" alt="column-select" />
        </a>
      </div>
      <button
        *ngIf="hasAddPermission$ | async"
        kendoButton
        id="create"
        type="button"
        (click)="onDeviceAdd()"
      >
        <i class="far fa-plus mr-5"></i> Add Device
      </button>
    </div>
  </div>
  <div class="q-content-bg grid-wrapper" *ngIf="isDataLoaded">
    <ng-container *ngIf="moduleId$ | async as moduleId">
      <app-common-grid
        [gridView]="gridView"
        [columns]="gridView?.columns ?? []"
        [actions]="actions"
        [pageSize]="this.pageSize"
        [showActions]="true"
        [moduleId]="moduleId"
        (triggerActionEvent)="onActionClick($event)"
        (sortChange)="onSortChange($event)"
        (paginationChange)="onPaginationChange($event)"
        (deleteConfirmed)="onVerifyDeleteOtp($event)"
        (triggerDataFromColumnSelector)="getDataFromColumnSelector($event)"
        (filterChangeSQL)="onFilterChangeSQL($event)"
      ></app-common-grid>
    </ng-container>
  </div>
</div>

<div kendoDialogContainer></div>
