import { Component, OnInit } from '@angular/core'
import { ActionTypeEnum, MenuList } from '@app/enums'
import { NotificationMessage } from '@app/enums/notification'
import { Roles } from '@app/enums/roles'
import { CommonService } from '@app/services'
import {
  CommonState,
  GetRoleList,
  GetRoleModulePermissionList,
  SaveRoleModulePermissionList,
} from '@app/store'
import { Select, Store } from '@ngxs/store'
import { NgxSpinnerService } from 'ngx-spinner'
import { ToastrService } from 'ngx-toastr'
import { Observable, Subject } from 'rxjs'
import swal from 'sweetalert'

@Component({
  selector: 'app-page-permission',
  templateUrl: './page-permission.component.html',
  styleUrl: './page-permission.component.scss',
})
export class PagePermissionComponent implements OnInit {
  triggerGetUpdatedTime: Subject<any> = new Subject<any>()
  headerActionType = ActionTypeEnum
  isViewHeaderSelected?: boolean = false

  rolesList: any = []
  moduleList: any[] = []
  columns: any[] = []
  selectedRole: any
  isAddCheckAll = false
  isDeleteCheckAll = false
  isUpdateCheckAll = false
  isViewCheckAll = false

  @Select(CommonState.hasEditPermission)
  hasEditPermission$!: Observable<boolean>
  currentUserDetails: any
  constructor(
    private store: Store,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private commonService: CommonService,
  ) {
    this.columns = this.commonService.getColumnNameByModule(
      MenuList['Page Permission'],
    )
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.triggerGetUpdatedTime.next(true)
    }, 0)
    this.getPagePermissionRoleList()
    this.currentUserDetails = this.commonService.getLoggedInUserDetails()
  }

  getPagePermissionRoleList() {
    this.spinner.show()
    this.store.dispatch(new GetRoleList(null)).subscribe(
      (res) => {
        this.spinner.hide()

        const roleList = res.role.roleList.data.rows.map((x: any) => ({
          id: x.id,
          name: x.name,
        }))

        this.rolesList = roleList.filter(
          (role: { id: number }) => role.id !== Roles.SuperAdmin,
        )

        this.selectedRole = this.rolesList[0].id
        this.getRoleModulePermissionList()
      },
      (error) => {
        this.spinner.hide()
      },
    )
  }

  getRoleModulePermissionList() {
    this.spinner.show()
    this.store
      .dispatch(new GetRoleModulePermissionList(this.selectedRole))
      .subscribe(
        (res) => {
          this.spinner.hide()
          this.moduleList = res.pagePermission.roleModulePermissionList
          this.isAddCheckAll = this.moduleList.every(
            (res) => res.isAddCheck === true,
          )
          this.isDeleteCheckAll = this.moduleList.every(
            (res) => res.isDeleteCheck === true,
          )
          this.isUpdateCheckAll = this.moduleList.every(
            (res) => res.isUpdateCheck === true,
          )
          this.isViewCheckAll = this.moduleList.every(
            (res) => res.isViewCheck === true,
          )
        },
        (error) => {
          this.spinner.hide()
        },
      )
  }

  onSubmit() {
    let textMessage = `You want to update menu permission.`

    swal({
      title: 'Are you sure ?',
      text: textMessage,
      icon: 'warning',
      buttons: {
        cancel: {
          text: 'Cancel',
          visible: true,
          closeModal: true,
        },
        confirm: {
          text: 'Yes',
        },
      },
      dangerMode: true,
    }).then((confirmed) => {
      if (confirmed) {
        this.spinner.show()
        this.store
          .dispatch(
            new SaveRoleModulePermissionList(
              this.selectedRole,
              this.moduleList,
            ),
          )
          .subscribe(
            (res) => {
              this.spinner.hide()
              this.toastr.success(
                NotificationMessage.PagePErmissionSaveSuccessMsg,
              )
              this.getRoleModulePermissionList()
            },
            (error) => {
              this.spinner.hide()
            },
          )
      }
    })
  }

  fetchChildren = (item: any) => {
    return this.getsubMenu(item.moduleId)
  }

  getsubMenu(id: any) {
    return this.moduleList.filter((x: any) => x.moduleId === id)[0]?.subMenu
  }

  public hasChildren = (item: any): boolean => {
    return item.hasChildren
  }

  updateParentDataBySubMenu(data: any, permission: string) {
    const parentMenu = this.moduleList.filter(
      (x) => x.moduleId === data.parentModuleId,
    )
    if (parentMenu.length > 0) {
      const isChildSelected = parentMenu[0].subMenu.filter(
        (y: any) => y[permission] === true,
      )
      parentMenu[0][permission] = isChildSelected.length > 0 ? true : false
    }
  }

  setViewValue(dataItem: any, permission: any, isSelected?: boolean) {
    if (
      (dataItem.isAddCheck ||
        dataItem.isUpdateCheck ||
        dataItem.isDeleteCheck) &&
      !dataItem.isViewCheck &&
      isSelected === undefined
    ) {
      dataItem.isViewCheck = true
    }

    if (dataItem.hasChildren) {
      switch (permission) {
        case this.headerActionType.Add:
          this.updatesubMenuData(
            dataItem.subMenu,
            'isAddCheck',
            dataItem.isAddCheck,
            dataItem.isViewCheck,
          )
          break
        case this.headerActionType.Edit:
          this.updatesubMenuData(
            dataItem.subMenu,
            'isUpdateCheck',
            dataItem.isUpdateCheck,
            dataItem.isViewCheck,
          )
          break
        case this.headerActionType.Delete:
          this.updatesubMenuData(
            dataItem.subMenu,
            'isDeleteCheck',
            dataItem.isDeleteCheck,
            dataItem.isViewCheck,
          )
          break
        case this.headerActionType.View:
          this.updatesubMenuData(
            dataItem.subMenu,
            'isViewCheck',
            dataItem.isViewCheck,
            dataItem.isViewCheck,
          )
          break
      }
    } else {
      switch (permission) {
        case this.headerActionType.Add:
          this.updateParentDataBySubMenu(dataItem, 'isAddCheck')
          break
        case this.headerActionType.Edit:
          this.updateParentDataBySubMenu(dataItem, 'isUpdateCheck')
          break
        case this.headerActionType.Delete:
          this.updateParentDataBySubMenu(dataItem, 'isDeleteCheck')
          break
        case this.headerActionType.View:
          this.updateParentDataBySubMenu(dataItem, 'isViewCheck')
          break
      }
    }

    this.isAddCheckAll = this.moduleList.every((res) => res.isAddCheck === true)
    this.isDeleteCheckAll = this.moduleList.every(
      (res) => res.isDeleteCheck === true,
    )
    this.isUpdateCheckAll = this.moduleList.every(
      (res) => res.isUpdateCheck === true,
    )
    this.isViewCheckAll = this.moduleList.every(
      (res) => res.isViewCheck === true,
    )
  }

  onActionHeaderSelected(evnet: any, selectedHeader: any) {
    const checkedValue = evnet.target.checked
    switch (selectedHeader) {
      case this.headerActionType.Add:
        this.updateModuleListData(checkedValue, 'isAddCheck')
        break
      case this.headerActionType.Edit:
        this.updateModuleListData(checkedValue, 'isUpdateCheck')
        break
      case this.headerActionType.Delete:
        this.updateModuleListData(checkedValue, 'isDeleteCheck')
        break
      case this.headerActionType.View:
        this.updateModuleListData(checkedValue, 'isViewCheck')
        break
    }
  }

  updateModuleListData(
    checkedValue: boolean,
    permission: string,
    subMenu?: any[],
  ) {
    if (!subMenu) {
      subMenu = this.moduleList
    }

    for (const item of subMenu) {
      if (this.checkIsSuperAdmin(item.moduleId)) {
        continue
      }

      if (permission === 'isViewCheck' || !item.isViewOnly) {
        if (item[permission] != 'isViewCheck' && checkedValue) {
          item[permission] = checkedValue
          item['isViewCheck'] = checkedValue
          this.isViewHeaderSelected = true
        } else {
          item[permission] = checkedValue
        }

        this.updatesubMenuData(
          item.subMenu,
          permission,
          checkedValue,
          item['isViewCheck'],
        )
      }
    }
  }

  updatesubMenuData(
    data: any,
    permission: string,
    checkedValue: boolean,
    isViewSelected: boolean,
  ) {
    for (const item of data) {
      if (this.checkIsSuperAdmin(item.moduleId)) {
        continue
      }
      if (permission === 'isViewCheck' || !item.isViewOnly) {
        if (item[permission] != 'isViewCheck') {
          item[permission] = checkedValue
          item['isViewCheck'] = checkedValue
        } else {
          item[permission] = checkedValue
        }
      }

      if (isViewSelected) {
        item['isViewCheck'] = true
      }
    }
  }

  checkIsSuperAdmin(moduleId: any): boolean {
    const isFeederOrSubstation =
      moduleId === MenuList['Feeder'] || moduleId === MenuList['Substation']
    const isNotSuperAdmin = +this.currentUserDetails.RoleId !== Roles.SuperAdmin

    return isFeederOrSubstation && isNotSuperAdmin
  }
}
