<kendo-dialog-titlebar (click)="closePopup()">
  <div>DTR Info</div>
</kendo-dialog-titlebar>

<div>
  <p>Device ID: {{ data.device_Id }}</p>
  <p>Updated On: {{ data.date | date: 'dd/MM/yyyy HH:mm:ss' }}</p>
  <p>
    Voltage (V): R ={{ data.voltage_Ph1 }} , Y ={{ data.voltage_Ph2 }} , B ={{
      data.voltage_Ph3
    }}
  </p>
  <p>
    Current (A): R ={{ data.current_Ph1 }} , Y ={{ data.current_Ph2 }}, B ={{
      data.current_Ph3
    }}
  </p>
  <p>Rated KVA: {{ data.kva_val }}</p>
  <p>Load Factor: {{ data.load_Factor }}%</p>
  <p>
    Energy (Units): KWH ={{ data.energy_Kwh_Total }}, KVAH ={{
      data.energy_Kwh_Total
    }}
  </p>
  <p>Power Factor: {{ data.pf_Total }}</p>
</div>
