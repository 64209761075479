<div class="q-content">
  <div
    class="d-flex gap-15 flex-wrap justify-content-between align-items-center mb-20"
  >
    <app-common-header-text
      [triggerGetUpdatedTime]="triggerGetUpdatedTime"
    ></app-common-header-text>
    <app-common-header
      [showDateFilter]="false"
      (triggerDataFromHeader)="getDataByHeaderData($event)"
    ></app-common-header>
  </div>
  <div class="d-flex-row">
    <div class="d-flex-col-lg-4 d-flex-col-xs-12 d-flex-col mb-20 d-flex">
      <div class="q-card flex-1 border">
        <div>
          <p class="font-size-16 fw-500 mb-20">
            Device Id Change
            <i
              class="far fa-circle-info font-size-16 cursor-pointer ml-5 info-icon"
              title="To rename the IoT id."
            ></i>
          </p>
        </div>
        <app-device-id-change
          [triggerDeviceIdData]="triggerDeviceIdData"
        ></app-device-id-change>
        <div [ngClass]="{ 'curser-not-allowd': !(hasEditPermission$ | async) }">
          <button
            kendoButton
            (click)="onDeviceIdDataSubmit()"
            [disabled]="!(hasEditPermission$ | async)"
          >
            Update
          </button>
        </div>
      </div>
    </div>
    <div class="d-flex-col-lg-4 d-flex-col-xs-12 d-flex-col mb-20 d-flex">
      <div class="q-card flex-1 border">
        <div>
          <p class="font-size-16 fw-500 mb-20">
            Energy
            <i
              class="far fa-circle-info font-size-16 cursor-pointer ml-5 info-icon"
              title="Reset or change the energy."
            ></i>
          </p>
        </div>
        <app-energy [triggerEnergyData]="triggerEnergyData"></app-energy>
        <div
          class="d-flex justify-content-end"
          [ngClass]="{ 'curser-not-allowd': !(hasEditPermission$ | async) }"
        >
          <button
            kendoButton
            (click)="confirmPopupUp(OfficeChangeEnum.Energy)"
            [disabled]="!(hasEditPermission$ | async)"
          >
            Update
          </button>
        </div>
      </div>
    </div>
    <div class="d-flex-col-lg-4 d-flex-col-xs-12 d-flex-col mb-20 d-flex">
      <div class="q-card flex-1 border">
        <div>
          <p class="font-size-16 fw-500 mb-20">
            Unbalanced Load(%)
            <i
              class="far fa-circle-info font-size-16 cursor-pointer ml-5 info-icon"
              title="To change deviation %."
            ></i>
          </p>
        </div>
        <app-unbalanced-load
          [triggerUnbalancedLoadData]="triggerUnbalancedLoadData"
        ></app-unbalanced-load>
        <div
          class="d-flex justify-content-end"
          [ngClass]="{ 'curser-not-allowd': !(hasEditPermission$ | async) }"
        >
          <button
            kendoButton
            (click)="confirmPopupUp(OfficeChangeEnum.UnbalancedLoad)"
            [disabled]="!(hasEditPermission$ | async)"
          >
            Update
          </button>
        </div>
      </div>
    </div>
    <div class="d-flex-col-lg-4 d-flex-col-xs-12 d-flex-col mb-20 d-flex">
      <div class="q-card flex-1 border update-data-container">
        <div class="d-flex justify-content-between">
          <p class="font-size-16 fw-500 mb-20">
            WIFI
            <i
              class="far fa-circle-info font-size-16 cursor-pointer ml-5 info-icon"
              title="To change wifi details."
            ></i>
          </p>
        </div>
        <app-wifi [triggerWifiData]="triggerWifiData"></app-wifi>
        <div
          class="update-btn"
          [ngClass]="{ 'curser-not-allowd': !(hasEditPermission$ | async) }"
        >
          <button
            kendoButton
            (click)="confirmPopupUp(OfficeChangeEnum.WIFI)"
            [disabled]="!(hasEditPermission$ | async)"
          >
            Update
          </button>
        </div>
      </div>
    </div>

    <div class="d-flex-col-lg-8 d-flex-col-xs-12 d-flex-col mb-20 d-flex">
      <div class="q-card border">
        <div class="d-flex-row">
          <div
            class="d-flex-col-lg-6 d-flex-col-sm-12 d-flex-col-xs-12 d-flex-col update-data-container"
          >
            <div class="d-flex justify-content-between">
              <p class="font-size-16 fw-500 mb-20">
                Angle (Below)
                <i
                  class="far fa-circle-info font-size-16 cursor-pointer ml-5 info-icon"
                  title="Angle for calibration factor."
                ></i>
              </p>
            </div>
            <app-angle-below
              [triggerAngleBelowData]="triggerAngleBelowData"
            ></app-angle-below>
          </div>
          <div
            class="d-flex-col-lg-6 d-flex-col-sm-12 d-flex-col-xs-12 d-flex-col update-data-container"
          >
            <div>
              <p class="font-size-16 fw-500 mb-20">
                Angle (Above)
                <i
                  class="far fa-circle-info font-size-16 cursor-pointer ml-5 info-icon"
                  title="Angle for calibration factor."
                ></i>
              </p>
            </div>
            <app-angle-above
              [triggerAngleAboveData]="triggerAngleAboveData"
            ></app-angle-above>
            <div
              class="d-flex justify-content-end"
              [ngClass]="{ 'curser-not-allowd': !(hasEditPermission$ | async) }"
            >
              <button
                kendoButton
                (click)="confirmPopupUp(OfficeChangeEnum.Angle)"
                [disabled]="!(hasEditPermission$ | async)"
              >
                Update
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="d-flex-col-lg-4 d-flex-col-xs-12 d-flex-col mb-20 d-flex">
      <div class="q-card flex-1 border update-data-container">
        <div>
          <p class="font-size-16 fw-500 mb-20">
            Device Serial Id
            <i
              class="far fa-circle-info font-size-16 cursor-pointer ml-5 info-icon"
              title="To Change the IoT serial ID."
            ></i>
          </p>
        </div>
        <app-device-serial-id
          [triggerDeviceSerialIdData]="triggerDeviceSerialIdData"
        ></app-device-serial-id>
        <div
          class="update-btn"
          [ngClass]="{ 'curser-not-allowd': !(hasEditPermission$ | async) }"
        >
          <button
            kendoButton
            (click)="confirmPopupUp(OfficeChangeEnum.DeviceSerialId)"
            [disabled]="!(hasEditPermission$ | async)"
          >
            Update
          </button>
        </div>
      </div>
    </div>
    <div class="d-flex-col-lg-4 d-flex-col-xs-12 d-flex-col mb-20 d-flex">
      <div class="q-card flex-1 border update-data-container">
        <div>
          <p class="font-size-16 fw-500 mb-20">Read IoT Settings</p>
        </div>
        <div
          class="update-btn"
          [ngClass]="{ 'curser-not-allowd': !(hasEditPermission$ | async) }"
        >
          <button
            kendoButton
            (click)="confirmPopupUp(OfficeChangeEnum.ReadIoTSettings)"
            [disabled]="!(hasEditPermission$ | async)"
          >
            Read
          </button>
        </div>
      </div>
    </div>
  </div>
  <div kendoDialogContainer></div>
</div>
