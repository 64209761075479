import { DatePipe } from '@angular/common'
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable, of } from 'rxjs'
import { environment } from 'src/environments'

@Injectable()
export class DeviceService {
  constructor(
    private http: HttpClient,
    private datePipe: DatePipe,
  ) {}

  getGpsLocation(location: any): Observable<any> {
    return this.http.get<any>(
      `${environment.apiVersionUrl}Device/getGpsLocation/${location}`,
    )
  }

  getDeviceList(queryParam: any): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    }
    return this.http.post<any>(
      `${environment.apiVersionUrl}Device/deviceList`,
      queryParam,
      headers,
    )
  }

  createDatabase(deviceList: any): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    }
    return this.http.post<any>(
      `${environment.apiVersionUrl}Device/createDatabase`,
      deviceList,
      headers,
    )
  }

  checkActivationDeviceExistsByDeviceId(deviceId: any): Observable<any> {
    return this.http.get<any>(
      `${environment.apiVersionUrl}Device/checkActivationDeviceExistsByDeviceId/${deviceId}`,
    )
  }

  checkIfDbExistsForDevice(deviceId: any): Observable<any> {
    return this.http.get<any>(
      `${environment.apiVersionUrl}Device/CheckIfDbExistsForDevice/${deviceId}`,
    )
  }

  getDeviceDetailByDeviceId(deviceId: any): Observable<any> {
    return this.http.get<any>(`${environment.apiVersionUrl}Device/${deviceId}`)
  }

  getDeviceIdByFeeder(feederName: string): Observable<HttpResponse<any>> {
    if (feederName !== null && feederName !== undefined) {
      return this.http.get<any>(
        `${environment.apiVersionUrl}Device/GetDeviceIdByFeeder/${feederName}`,
      )
    } else {
      return of(new HttpResponse({ body: [] }))
    }
  }

  deleteDeviceById(deleteDeviceByIdRequestBody: any): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    }
    return this.http.delete(`${environment.apiVersionUrl}Device/delete`, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      body: JSON.stringify(deleteDeviceByIdRequestBody),
    })
  }

  updateDeviceByDeviceId(
    deviceId: any,
    updateDeviceRequestBody: any,
  ): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    }
    return this.http.put<any>(
      `${environment.apiVersionUrl}Device/update/${deviceId}`,
      JSON.stringify(updateDeviceRequestBody),
      headers,
    )
  }

  addDevice(addDeviceRequestBody: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      observe: 'response' as 'response',
    }

    Object.assign(httpOptions, { responseType: 'text' })

    return this.http.post<any>(
      `${environment.apiVersionUrl}Device/device/add`,
      JSON.stringify(addDeviceRequestBody),
      httpOptions,
    )
  }

  getDeviceListForAccount(queryParam: any): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    }
    return this.http.post<any>(
      `${environment.apiVersionUrl}Device/getDeviceListForAccount`,
      queryParam,
      headers,
    )
  }

  GetAllByPagination(
    queryParam: any,
    mobileNumber: any,
    email: any,
  ): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    }
    return this.http.post<any>(
      `${environment.apiVersionUrl}Account/GetAllByPagination/${mobileNumber}/${email}`,
      queryParam,
      headers,
    )
  }

  deleteUserDeviceLogs(queryParam: any): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(queryParam),
    }

    return this.http.delete<any>(
      `${environment.apiVersionUrl}Device/deleteUserDeviceLogs`,
      options,
    )
  }

  getDeviceListWithPagination(): Observable<any> {
    return this.http.get<any>(
      `${environment.apiVersionUrl}Device/GetAllDevices`,
    )
  }

  sendOtpVerification(): Observable<any> {
    return this.http.get<any>(`${environment.apiVersionUrl}UserOtp/sendOtp`)
  }

  uploadImage(deviceId: any, file: any): Observable<any> {
    const formData: FormData = new FormData()

    formData.append('file', file, file.name)
    const headers = {
      headers: new HttpHeaders({
        enctype: 'multipart/form-data',
        Accept: 'multipart/form-data',
      }),
    }

    return this.http.post<any>(
      `${environment.apiVersionUrl}Device/uploadDeviceImage/${deviceId}`,
      formData,
      headers,
    )
  }

  downloadFileUpload(dowloadFileRequestBody: any): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        enctype: 'multipart/form-data',
        Accept: 'multipart/form-data',
      }),
    }
    Object.assign(headers, { responseType: 'blob' })

    return this.http.post<any>(
      `${environment.apiVersionUrl}FileUpload/download`,
      JSON.stringify(dowloadFileRequestBody),
      headers,
    )
  }

  updateInstallDateByDeviceId(
    updateInstallDateRequestBody: any,
  ): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    }

    return this.http.put<any>(
      `${environment.apiVersionUrl}Device/updateDeviceInstallationDate`,
      JSON.stringify(updateInstallDateRequestBody),
      headers,
    )
  }
}
