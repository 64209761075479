<kendo-dialog-titlebar (click)="closePopup()">
  <div>Color Info</div>
</kendo-dialog-titlebar>
<div>
  <p class="font-size-18 fw-500">Voltage</p>
  <p>
    <span class="red fw-500">Red color</span> - Indicates the voltage is crossed
    the Maximum threshold or minimum threshold value.
  </p>
  <p>
    <span class="orange fw-500">Orange color</span> - Indicates the voltage is
    reaching the maximum threshold value (greater than 95% of maximum
    threshold).
  </p>
  <p>
    <span class="black fw-500">No color</span> - Indicates the Voltages are
    within the thresholds.
  </p>

  <p>
    *The threshold values are getting from the already updated in the voltage
    threshold page.
  </p>
</div>
<hr />
<p class="font-size-18 fw-500">Current</p>
<div>
  <p>
    <span class="red w-500">Red color</span> - Indicates the Current is crossed
    the maximum threshold value.
  </p>
  <p>
    <span class="black fw-500">No color</span>>No color - Indicates the Currents
    are within the thresholds.
  </p>

  <p>
    *The threshold values are getting from the already updated in the Current
    threshold page.
  </p>
</div>
<hr />
<p class="font-size-18 fw-500">Load Factor</p>
<div>
  <p>
    <span class="red w-500">Red color</span> - Indicates the Load is crossed the
    Maximum capacity of the Transformer i.e. crossed the total capacity.
  </p>
  <p>
    <span class="orange fw-500">Orange color</span> - Indicate the load is
    crossed 80% of the total capacity.
  </p>
  <p>
    <span class="black fw-500">No color</span> - Indicates the working in a safe
    condition.
  </p>
</div>
<hr />
<p class="font-size-18 fw-500">PF</p>
<div>
  <p>
    <span class="red fw-500">Red color</span> - Indicates the PF is Dropped to
    below the threshold value.
  </p>
  <p>
    <span class="black fw-500">No color</span> - Indicates the above the
    threshold value.
  </p>

  <p>
    *The Pf threshold value is getting from the updated value in the pf setting
    page.
  </p>

  <p>
    *Leading PF means that the current leads the voltage, the load is capacitive
    then the PF is Leading and its sign is Negative(-). If the load is inductive
    then the PF is lagging and its sign is Positive(+) or No sign.
  </p>
</div>
<hr />
<p class="font-size-18 fw-500">Temperature</p>
<div>
  <p>
    <span class="red fw-500">Red color</span> - Indicates the temperature
    crossed the threshold value.
  </p>
  <p>
    <span class="black fw-500">No color</span> - Indicates the temperature are
    within the thresholds.
  </p>

  <p>
    *The temperature value is getting from the updated value in the temperature
    threshold settings page.
  </p>
</div>

<kendo-dialog-actions></kendo-dialog-actions>
