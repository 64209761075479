import { NodeSelection, Plugin, PluginKey } from "prosemirror-state";
import { Decoration, DecorationSet } from "prosemirror-view";
import { dataResizeDirImage, resizeHandle } from "../config/constants";
import { changeStylesString } from "../utils";
import { directions, handles } from './resize-utils';
export const imageResizeKey = new PluginKey('image-resize');
const setSize = (domNode, sizeType, value) => {
    domNode.style[sizeType] = value + 'px';
};
const reSize = /[^\-]width:|[^\-]height:/;
const reAnyValue = /^.+$/;
class ResizeState {
    constructor(activeHandle, dragging, rect, nodePosition) {
        this.activeHandle = activeHandle;
        this.dragging = dragging;
        this.rect = rect;
        this.nodePosition = nodePosition;
    }
    apply(tr) {
        let state = this, next = tr.getMeta(imageResizeKey);
        if (next) {
            return new ResizeState(next.activeHandle, next.setDragging, next.rect, next.nodePosition);
        }
        return state;
    }
}
const handleMouseMove = (view, event, options) => {
    const state = imageResizeKey.getState(view.state);
    const { rect, dragging, nodePosition: nodePosition, activeHandle } = state;
    if (!dragging || !rect) {
        return;
    }
    const img = view.nodeDOM(nodePosition);
    const dir = directions[activeHandle];
    const diffX = (event.clientX - dragging.startX) * dir.x;
    const diffY = (event.clientY - dragging.startY) * dir.y;
    let width = dir.x ? diffX + img.width : rect.width;
    let height = dir.y ? diffY + img.height : rect.height;
    if (options.lockRatio && dir.x && dir.y) {
        let ratio = Math.min(width / img.width, height / img.height);
        let lockWidth = img.width * ratio;
        let lockHeight = img.height * ratio;
        dragging.startX = event.clientX - (width - lockWidth) * dir.x;
        dragging.startY = event.clientY - (height - lockHeight) * dir.y;
        width = lockWidth;
        height = lockHeight;
    }
    else {
        dragging.startX = dir.x ? event.clientX : dragging.startX;
        dragging.startY = dir.y ? event.clientY : dragging.startY;
    }
    setSize(img, 'width', width);
    setSize(img, 'height', height);
    rect.top = img.offsetTop;
    rect.left = img.offsetLeft;
    rect.width = img.offsetWidth;
    rect.height = img.offsetHeight;
    const handlesWrapper = img.nextElementSibling;
    handlesWrapper.style.width = rect.width + 'px';
    handlesWrapper.style.height = rect.height + 'px';
    handlesWrapper.style.top = rect.top + 'px';
    handlesWrapper.style.left = rect.left + 'px';
};
const handleMouseUp = (view) => {
    const { rect, dragging, nodePosition } = imageResizeKey.getState(view.state);
    if (dragging && rect) {
        const selection = view.state.selection;
        if (selection instanceof NodeSelection) {
            const currAttrs = selection.node.attrs;
            const width = rect.width;
            const height = rect.height;
            let attrs;
            if (reSize.test(currAttrs.style || '')) {
                const changedWidth = changeStylesString(currAttrs.style, { style: 'width', value: reAnyValue, newValue: width + 'px' });
                const { style } = changeStylesString(changedWidth.style || '', { style: 'height', value: reAnyValue, newValue: height + 'px' });
                attrs = Object.assign(Object.assign({}, currAttrs), { style });
            }
            else {
                attrs = Object.assign(Object.assign({}, currAttrs), { width, height });
            }
            const newImage = selection.node.type.createAndFill(attrs);
            if (newImage) {
                const tr = view.state.tr;
                tr.replaceWith(nodePosition, nodePosition + 1, newImage);
                tr.setSelection(NodeSelection.create(tr.doc, nodePosition));
                tr.setMeta('commandName', 'image-resize');
                tr.setMeta('args', attrs);
                tr.setMeta(imageResizeKey, {
                    setDragging: null,
                    activeHandle: null,
                    rect,
                    nodePosition
                });
                view.dispatch(tr);
            }
        }
    }
};
const handleMouseDown = (view, event, options) => {
    const target = event.target;
    const activeHandle = target.getAttribute(dataResizeDirImage);
    if (!activeHandle) {
        return false;
    }
    const resizeState = imageResizeKey.getState(view.state);
    event.preventDefault();
    const transaction = view.state.tr;
    transaction.setMeta(imageResizeKey, {
        setDragging: { startX: event.clientX, startY: event.clientY },
        activeHandle,
        rect: resizeState.rect,
        nodePosition: resizeState.nodePosition
    });
    transaction.setMeta('addToHistory', false);
    view.dispatch(transaction);
    function move(e) {
        handleMouseMove(view, e, options);
    }
    function finish(e) {
        e.view.removeEventListener('mouseup', finish);
        e.view.removeEventListener('mousemove', move);
        handleMouseUp(view);
    }
    event.view.addEventListener('mouseup', finish);
    event.view.addEventListener('mousemove', move);
    return true;
};
export const imageResizing = (options = { node: 'image', lockRatio: true }) => {
    return new Plugin({
        key: imageResizeKey,
        view: (viewObj) => ({
            resize() {
                if (imageResizeKey.getState(viewObj.state).rect) {
                    viewObj.dispatch(viewObj.state.tr.setMeta('resize', true));
                }
            },
            get window() {
                return viewObj.dom.ownerDocument && viewObj.dom.ownerDocument.defaultView;
            },
            attachResize() {
                const win = this.window;
                if (win) {
                    win.removeEventListener('resize', this.resize);
                    win.addEventListener('resize', this.resize);
                }
            },
            removeResize() {
                const win = this.window;
                if (win) {
                    win.removeEventListener('resize', this.resize);
                }
            },
            update(view, prevState) {
                const state = view.state;
                const selection = state.selection;
                const nodeType = state.schema.nodes[options.node];
                const pluginState = imageResizeKey.getState(state);
                const prevRect = pluginState.rect;
                if (selection instanceof NodeSelection && nodeType === selection.node.type) {
                    const img = view.nodeDOM(selection.from);
                    const rect = {
                        top: img.offsetTop,
                        left: img.offsetLeft,
                        width: img.offsetWidth,
                        height: img.offsetHeight
                    };
                    if (!prevState.selection.eq(selection) ||
                        (prevRect && (prevRect.width !== rect.width || prevRect.height !== rect.height ||
                            prevRect.top !== rect.top || prevRect.left !== rect.left))) {
                        const tr = state.tr;
                        tr.setMeta(imageResizeKey, { rect, nodePosition: selection.from });
                        view.dispatch(tr);
                        this.attachResize();
                    }
                }
                else if (prevRect) {
                    pluginState.rect = null;
                    pluginState.nodePosition = -1;
                }
            },
            destroy() {
                this.removeResize();
            }
        }),
        state: {
            init() {
                return new ResizeState('', null, null, -1);
            },
            apply(tr, prev) {
                return prev.apply(tr);
            }
        },
        props: {
            handleDOMEvents: {
                mousedown(view, event) {
                    return handleMouseDown(view, event, options);
                }
            },
            decorations(state) {
                const selection = state.selection;
                const nodeType = state.schema.nodes[options.node];
                const rect = imageResizeKey.getState(state).rect;
                if (rect && selection instanceof NodeSelection && nodeType === selection.node.type) {
                    const wrapper = document.createElement('div');
                    wrapper.className = 'k-editor-resize-handles-wrapper';
                    wrapper.style.width = rect.width + 'px';
                    wrapper.style.height = rect.height + 'px';
                    wrapper.style.top = rect.top + 'px';
                    wrapper.style.left = rect.left + 'px';
                    for (let i = 0; i < handles.length; i++) {
                        let dom = document.createElement('div');
                        dom.className = resizeHandle + ' ' + handles[i];
                        dom.setAttribute(dataResizeDirImage, handles[i]);
                        wrapper.appendChild(dom);
                    }
                    return DecorationSet.create(state.doc, [Decoration.widget(state.selection.from + 1, wrapper)]);
                }
                return DecorationSet.empty;
            }
        }
    });
};
