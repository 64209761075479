export const VersionsCodewordsInformation = [{
    L: {
        groups: [
            [1, 19]
        ],
        totalDataCodewords: 19,
        errorCodewordsPerBlock: 7
    },
    M: {
        groups: [
            [1, 16]
        ],
        totalDataCodewords: 16,
        errorCodewordsPerBlock: 10
    },
    Q: {
        groups: [
            [1, 13]
        ],
        totalDataCodewords: 13,
        errorCodewordsPerBlock: 13
    },
    H: {
        groups: [
            [1, 9]
        ],
        totalDataCodewords: 9,
        errorCodewordsPerBlock: 17
    }
}, {
    L: {
        groups: [
            [1, 34]
        ],
        totalDataCodewords: 34,
        errorCodewordsPerBlock: 10
    },
    M: {
        groups: [
            [1, 28]
        ],
        totalDataCodewords: 28,
        errorCodewordsPerBlock: 16
    },
    Q: {
        groups: [
            [1, 22]
        ],
        totalDataCodewords: 22,
        errorCodewordsPerBlock: 22
    },
    H: {
        groups: [
            [1, 16]
        ],
        totalDataCodewords: 16,
        errorCodewordsPerBlock: 28
    }
}, {
    L: {
        groups: [
            [1, 55]
        ],
        totalDataCodewords: 55,
        errorCodewordsPerBlock: 15
    },
    M: {
        groups: [
            [1, 44]
        ],
        totalDataCodewords: 44,
        errorCodewordsPerBlock: 26
    },
    Q: {
        groups: [
            [2, 17]
        ],
        totalDataCodewords: 34,
        errorCodewordsPerBlock: 18
    },
    H: {
        groups: [
            [2, 13]
        ],
        totalDataCodewords: 26,
        errorCodewordsPerBlock: 22
    }
}, {
    L: {
        groups: [
            [1, 80]
        ],
        totalDataCodewords: 80,
        errorCodewordsPerBlock: 20
    },
    M: {
        groups: [
            [2, 32]
        ],
        totalDataCodewords: 64,
        errorCodewordsPerBlock: 18
    },
    Q: {
        groups: [
            [2, 24]
        ],
        totalDataCodewords: 48,
        errorCodewordsPerBlock: 26
    },
    H: {
        groups: [
            [4, 9]
        ],
        totalDataCodewords: 36,
        errorCodewordsPerBlock: 16
    }
}, {
    L: {
        groups: [
            [1, 108]
        ],
        totalDataCodewords: 108,
        errorCodewordsPerBlock: 26
    },
    M: {
        groups: [
            [2, 43]
        ],
        totalDataCodewords: 86,
        errorCodewordsPerBlock: 24
    },
    Q: {
        groups: [
            [2, 15],
            [2, 16]
        ],
        totalDataCodewords: 62,
        errorCodewordsPerBlock: 18
    },
    H: {
        groups: [
            [2, 11],
            [2, 12]
        ],
        totalDataCodewords: 46,
        errorCodewordsPerBlock: 22
    }
}, {
    L: {
        groups: [
            [2, 68]
        ],
        totalDataCodewords: 136,
        errorCodewordsPerBlock: 18
    },
    M: {
        groups: [
            [4, 27]
        ],
        totalDataCodewords: 108,
        errorCodewordsPerBlock: 16
    },
    Q: {
        groups: [
            [4, 19]
        ],
        totalDataCodewords: 76,
        errorCodewordsPerBlock: 24
    },
    H: {
        groups: [
            [4, 15]
        ],
        totalDataCodewords: 60,
        errorCodewordsPerBlock: 28
    }
}, {
    L: {
        groups: [
            [2, 78]
        ],
        totalDataCodewords: 156,
        errorCodewordsPerBlock: 20
    },
    M: {
        groups: [
            [4, 31]
        ],
        totalDataCodewords: 124,
        errorCodewordsPerBlock: 18
    },
    Q: {
        groups: [
            [2, 14],
            [4, 15]
        ],
        totalDataCodewords: 88,
        errorCodewordsPerBlock: 18
    },
    H: {
        groups: [
            [4, 13],
            [1, 14]
        ],
        totalDataCodewords: 66,
        errorCodewordsPerBlock: 26
    }
}, {
    L: {
        groups: [
            [2, 97]
        ],
        totalDataCodewords: 194,
        errorCodewordsPerBlock: 24
    },
    M: {
        groups: [
            [2, 38],
            [2, 39]
        ],
        totalDataCodewords: 154,
        errorCodewordsPerBlock: 22
    },
    Q: {
        groups: [
            [4, 18],
            [2, 19]
        ],
        totalDataCodewords: 110,
        errorCodewordsPerBlock: 22
    },
    H: {
        groups: [
            [4, 14],
            [2, 15]
        ],
        totalDataCodewords: 86,
        errorCodewordsPerBlock: 26
    }
}, {
    L: {
        groups: [
            [2, 116]
        ],
        totalDataCodewords: 232,
        errorCodewordsPerBlock: 30
    },
    M: {
        groups: [
            [3, 36],
            [2, 37]
        ],
        totalDataCodewords: 182,
        errorCodewordsPerBlock: 22
    },
    Q: {
        groups: [
            [4, 16],
            [4, 17]
        ],
        totalDataCodewords: 132,
        errorCodewordsPerBlock: 20
    },
    H: {
        groups: [
            [4, 12],
            [4, 13]
        ],
        totalDataCodewords: 100,
        errorCodewordsPerBlock: 24
    }
}, {
    L: {
        groups: [
            [2, 68],
            [2, 69]
        ],
        totalDataCodewords: 274,
        errorCodewordsPerBlock: 18
    },
    M: {
        groups: [
            [4, 43],
            [1, 44]
        ],
        totalDataCodewords: 216,
        errorCodewordsPerBlock: 26
    },
    Q: {
        groups: [
            [6, 19],
            [2, 20]
        ],
        totalDataCodewords: 154,
        errorCodewordsPerBlock: 24
    },
    H: {
        groups: [
            [6, 15],
            [2, 16]
        ],
        totalDataCodewords: 122,
        errorCodewordsPerBlock: 28
    }
}, {
    L: {
        groups: [
            [4, 81]
        ],
        totalDataCodewords: 324,
        errorCodewordsPerBlock: 20
    },
    M: {
        groups: [
            [1, 50],
            [4, 51]
        ],
        totalDataCodewords: 254,
        errorCodewordsPerBlock: 30
    },
    Q: {
        groups: [
            [4, 22],
            [4, 23]
        ],
        totalDataCodewords: 180,
        errorCodewordsPerBlock: 28
    },
    H: {
        groups: [
            [3, 12],
            [8, 13]
        ],
        totalDataCodewords: 140,
        errorCodewordsPerBlock: 24
    }
}, {
    L: {
        groups: [
            [2, 92],
            [2, 93]
        ],
        totalDataCodewords: 370,
        errorCodewordsPerBlock: 24
    },
    M: {
        groups: [
            [6, 36],
            [2, 37]
        ],
        totalDataCodewords: 290,
        errorCodewordsPerBlock: 22
    },
    Q: {
        groups: [
            [4, 20],
            [6, 21]
        ],
        totalDataCodewords: 206,
        errorCodewordsPerBlock: 26
    },
    H: {
        groups: [
            [7, 14],
            [4, 15]
        ],
        totalDataCodewords: 158,
        errorCodewordsPerBlock: 28
    }
}, {
    L: {
        groups: [
            [4, 107]
        ],
        totalDataCodewords: 428,
        errorCodewordsPerBlock: 26
    },
    M: {
        groups: [
            [8, 37],
            [1, 38]
        ],
        totalDataCodewords: 334,
        errorCodewordsPerBlock: 22
    },
    Q: {
        groups: [
            [8, 20],
            [4, 21]
        ],
        totalDataCodewords: 244,
        errorCodewordsPerBlock: 24
    },
    H: {
        groups: [
            [12, 11],
            [4, 12]
        ],
        totalDataCodewords: 180,
        errorCodewordsPerBlock: 22
    }
}, {
    L: {
        groups: [
            [3, 115],
            [1, 116]
        ],
        totalDataCodewords: 461,
        errorCodewordsPerBlock: 30
    },
    M: {
        groups: [
            [4, 40],
            [5, 41]
        ],
        totalDataCodewords: 365,
        errorCodewordsPerBlock: 24
    },
    Q: {
        groups: [
            [11, 16],
            [5, 17]
        ],
        totalDataCodewords: 261,
        errorCodewordsPerBlock: 20
    },
    H: {
        groups: [
            [11, 12],
            [5, 13]
        ],
        totalDataCodewords: 197,
        errorCodewordsPerBlock: 24
    }
}, {
    L: {
        groups: [
            [5, 87],
            [1, 88]
        ],
        totalDataCodewords: 523,
        errorCodewordsPerBlock: 22
    },
    M: {
        groups: [
            [5, 41],
            [5, 42]
        ],
        totalDataCodewords: 415,
        errorCodewordsPerBlock: 24
    },
    Q: {
        groups: [
            [5, 24],
            [7, 25]
        ],
        totalDataCodewords: 295,
        errorCodewordsPerBlock: 30
    },
    H: {
        groups: [
            [11, 12],
            [7, 13]
        ],
        totalDataCodewords: 223,
        errorCodewordsPerBlock: 24
    }
}, {
    L: {
        groups: [
            [5, 98],
            [1, 99]
        ],
        totalDataCodewords: 589,
        errorCodewordsPerBlock: 24
    },
    M: {
        groups: [
            [7, 45],
            [3, 46]
        ],
        totalDataCodewords: 453,
        errorCodewordsPerBlock: 28
    },
    Q: {
        groups: [
            [15, 19],
            [2, 20]
        ],
        totalDataCodewords: 325,
        errorCodewordsPerBlock: 24
    },
    H: {
        groups: [
            [3, 15],
            [13, 16]
        ],
        totalDataCodewords: 253,
        errorCodewordsPerBlock: 30
    }
}, {
    L: {
        groups: [
            [1, 107],
            [5, 108]
        ],
        totalDataCodewords: 647,
        errorCodewordsPerBlock: 28
    },
    M: {
        groups: [
            [10, 46],
            [1, 47]
        ],
        totalDataCodewords: 507,
        errorCodewordsPerBlock: 28
    },
    Q: {
        groups: [
            [1, 22],
            [15, 23]
        ],
        totalDataCodewords: 367,
        errorCodewordsPerBlock: 28
    },
    H: {
        groups: [
            [2, 14],
            [17, 15]
        ],
        totalDataCodewords: 283,
        errorCodewordsPerBlock: 28
    }
}, {
    L: {
        groups: [
            [5, 120],
            [1, 121]
        ],
        totalDataCodewords: 721,
        errorCodewordsPerBlock: 30
    },
    M: {
        groups: [
            [9, 43],
            [4, 44]
        ],
        totalDataCodewords: 563,
        errorCodewordsPerBlock: 26
    },
    Q: {
        groups: [
            [17, 22],
            [1, 23]
        ],
        totalDataCodewords: 397,
        errorCodewordsPerBlock: 28
    },
    H: {
        groups: [
            [2, 14],
            [19, 15]
        ],
        totalDataCodewords: 313,
        errorCodewordsPerBlock: 28
    }
}, {
    L: {
        groups: [
            [3, 113],
            [4, 114]
        ],
        totalDataCodewords: 795,
        errorCodewordsPerBlock: 28
    },
    M: {
        groups: [
            [3, 44],
            [11, 45]
        ],
        totalDataCodewords: 627,
        errorCodewordsPerBlock: 26
    },
    Q: {
        groups: [
            [17, 21],
            [4, 22]
        ],
        totalDataCodewords: 445,
        errorCodewordsPerBlock: 26
    },
    H: {
        groups: [
            [9, 13],
            [16, 14]
        ],
        totalDataCodewords: 341,
        errorCodewordsPerBlock: 26
    }
}, {
    L: {
        groups: [
            [3, 107],
            [5, 108]
        ],
        totalDataCodewords: 861,
        errorCodewordsPerBlock: 28
    },
    M: {
        groups: [
            [3, 41],
            [13, 42]
        ],
        totalDataCodewords: 669,
        errorCodewordsPerBlock: 26
    },
    Q: {
        groups: [
            [15, 24],
            [5, 25]
        ],
        totalDataCodewords: 485,
        errorCodewordsPerBlock: 30
    },
    H: {
        groups: [
            [15, 15],
            [10, 16]
        ],
        totalDataCodewords: 385,
        errorCodewordsPerBlock: 28
    }
}, {
    L: {
        groups: [
            [4, 116],
            [4, 117]
        ],
        totalDataCodewords: 932,
        errorCodewordsPerBlock: 28
    },
    M: {
        groups: [
            [17, 42]
        ],
        totalDataCodewords: 714,
        errorCodewordsPerBlock: 26
    },
    Q: {
        groups: [
            [17, 22],
            [6, 23]
        ],
        totalDataCodewords: 512,
        errorCodewordsPerBlock: 28
    },
    H: {
        groups: [
            [19, 16],
            [6, 17]
        ],
        totalDataCodewords: 406,
        errorCodewordsPerBlock: 30
    }
}, {
    L: {
        groups: [
            [2, 111],
            [7, 112]
        ],
        totalDataCodewords: 1006,
        errorCodewordsPerBlock: 28
    },
    M: {
        groups: [
            [17, 46]
        ],
        totalDataCodewords: 782,
        errorCodewordsPerBlock: 28
    },
    Q: {
        groups: [
            [7, 24],
            [16, 25]
        ],
        totalDataCodewords: 568,
        errorCodewordsPerBlock: 30
    },
    H: {
        groups: [
            [34, 13]
        ],
        totalDataCodewords: 442,
        errorCodewordsPerBlock: 24
    }
}, {
    L: {
        groups: [
            [4, 121],
            [5, 122]
        ],
        totalDataCodewords: 1094,
        errorCodewordsPerBlock: 30
    },
    M: {
        groups: [
            [4, 47],
            [14, 48]
        ],
        totalDataCodewords: 860,
        errorCodewordsPerBlock: 28
    },
    Q: {
        groups: [
            [11, 24],
            [14, 25]
        ],
        totalDataCodewords: 614,
        errorCodewordsPerBlock: 30
    },
    H: {
        groups: [
            [16, 15],
            [14, 16]
        ],
        totalDataCodewords: 464,
        errorCodewordsPerBlock: 30
    }
}, {
    L: {
        groups: [
            [6, 117],
            [4, 118]
        ],
        totalDataCodewords: 1174,
        errorCodewordsPerBlock: 30
    },
    M: {
        groups: [
            [6, 45],
            [14, 46]
        ],
        totalDataCodewords: 914,
        errorCodewordsPerBlock: 28
    },
    Q: {
        groups: [
            [11, 24],
            [16, 25]
        ],
        totalDataCodewords: 664,
        errorCodewordsPerBlock: 30
    },
    H: {
        groups: [
            [30, 16],
            [2, 17]
        ],
        totalDataCodewords: 514,
        errorCodewordsPerBlock: 30
    }
}, {
    L: {
        groups: [
            [8, 106],
            [4, 107]
        ],
        totalDataCodewords: 1276,
        errorCodewordsPerBlock: 26
    },
    M: {
        groups: [
            [8, 47],
            [13, 48]
        ],
        totalDataCodewords: 1000,
        errorCodewordsPerBlock: 28
    },
    Q: {
        groups: [
            [7, 24],
            [22, 25]
        ],
        totalDataCodewords: 718,
        errorCodewordsPerBlock: 30
    },
    H: {
        groups: [
            [22, 15],
            [13, 16]
        ],
        totalDataCodewords: 538,
        errorCodewordsPerBlock: 30
    }
}, {
    L: {
        groups: [
            [10, 114],
            [2, 115]
        ],
        totalDataCodewords: 1370,
        errorCodewordsPerBlock: 28
    },
    M: {
        groups: [
            [19, 46],
            [4, 47]
        ],
        totalDataCodewords: 1062,
        errorCodewordsPerBlock: 28
    },
    Q: {
        groups: [
            [28, 22],
            [6, 23]
        ],
        totalDataCodewords: 754,
        errorCodewordsPerBlock: 28
    },
    H: {
        groups: [
            [33, 16],
            [4, 17]
        ],
        totalDataCodewords: 596,
        errorCodewordsPerBlock: 30
    }
}, {
    L: {
        groups: [
            [8, 122],
            [4, 123]
        ],
        totalDataCodewords: 1468,
        errorCodewordsPerBlock: 30
    },
    M: {
        groups: [
            [22, 45],
            [3, 46]
        ],
        totalDataCodewords: 1128,
        errorCodewordsPerBlock: 28
    },
    Q: {
        groups: [
            [8, 23],
            [26, 24]
        ],
        totalDataCodewords: 808,
        errorCodewordsPerBlock: 30
    },
    H: {
        groups: [
            [12, 15],
            [28, 16]
        ],
        totalDataCodewords: 628,
        errorCodewordsPerBlock: 30
    }
}, {
    L: {
        groups: [
            [3, 117],
            [10, 118]
        ],
        totalDataCodewords: 1531,
        errorCodewordsPerBlock: 30
    },
    M: {
        groups: [
            [3, 45],
            [23, 46]
        ],
        totalDataCodewords: 1193,
        errorCodewordsPerBlock: 28
    },
    Q: {
        groups: [
            [4, 24],
            [31, 25]
        ],
        totalDataCodewords: 871,
        errorCodewordsPerBlock: 30
    },
    H: {
        groups: [
            [11, 15],
            [31, 16]
        ],
        totalDataCodewords: 661,
        errorCodewordsPerBlock: 30
    }
}, {
    L: {
        groups: [
            [7, 116],
            [7, 117]
        ],
        totalDataCodewords: 1631,
        errorCodewordsPerBlock: 30
    },
    M: {
        groups: [
            [21, 45],
            [7, 46]
        ],
        totalDataCodewords: 1267,
        errorCodewordsPerBlock: 28
    },
    Q: {
        groups: [
            [1, 23],
            [37, 24]
        ],
        totalDataCodewords: 911,
        errorCodewordsPerBlock: 30
    },
    H: {
        groups: [
            [19, 15],
            [26, 16]
        ],
        totalDataCodewords: 701,
        errorCodewordsPerBlock: 30
    }
}, {
    L: {
        groups: [
            [5, 115],
            [10, 116]
        ],
        totalDataCodewords: 1735,
        errorCodewordsPerBlock: 30
    },
    M: {
        groups: [
            [19, 47],
            [10, 48]
        ],
        totalDataCodewords: 1373,
        errorCodewordsPerBlock: 28
    },
    Q: {
        groups: [
            [15, 24],
            [25, 25]
        ],
        totalDataCodewords: 985,
        errorCodewordsPerBlock: 30
    },
    H: {
        groups: [
            [23, 15],
            [25, 16]
        ],
        totalDataCodewords: 745,
        errorCodewordsPerBlock: 30
    }
}, {
    L: {
        groups: [
            [13, 115],
            [3, 116]
        ],
        totalDataCodewords: 1843,
        errorCodewordsPerBlock: 30
    },
    M: {
        groups: [
            [2, 46],
            [29, 47]
        ],
        totalDataCodewords: 1455,
        errorCodewordsPerBlock: 28
    },
    Q: {
        groups: [
            [42, 24],
            [1, 25]
        ],
        totalDataCodewords: 1033,
        errorCodewordsPerBlock: 30
    },
    H: {
        groups: [
            [23, 15],
            [28, 16]
        ],
        totalDataCodewords: 793,
        errorCodewordsPerBlock: 30
    }
}, {
    L: {
        groups: [
            [17, 115]
        ],
        totalDataCodewords: 1955,
        errorCodewordsPerBlock: 30
    },
    M: {
        groups: [
            [10, 46],
            [23, 47]
        ],
        totalDataCodewords: 1541,
        errorCodewordsPerBlock: 28
    },
    Q: {
        groups: [
            [10, 24],
            [35, 25]
        ],
        totalDataCodewords: 1115,
        errorCodewordsPerBlock: 30
    },
    H: {
        groups: [
            [19, 15],
            [35, 16]
        ],
        totalDataCodewords: 845,
        errorCodewordsPerBlock: 30
    }
}, {
    L: {
        groups: [
            [17, 115],
            [1, 116]
        ],
        totalDataCodewords: 2071,
        errorCodewordsPerBlock: 30
    },
    M: {
        groups: [
            [14, 46],
            [21, 47]
        ],
        totalDataCodewords: 1631,
        errorCodewordsPerBlock: 28
    },
    Q: {
        groups: [
            [29, 24],
            [19, 25]
        ],
        totalDataCodewords: 1171,
        errorCodewordsPerBlock: 30
    },
    H: {
        groups: [
            [11, 15],
            [46, 16]
        ],
        totalDataCodewords: 901,
        errorCodewordsPerBlock: 30
    }
}, {
    L: {
        groups: [
            [13, 115],
            [6, 116]
        ],
        totalDataCodewords: 2191,
        errorCodewordsPerBlock: 30
    },
    M: {
        groups: [
            [14, 46],
            [23, 47]
        ],
        totalDataCodewords: 1725,
        errorCodewordsPerBlock: 28
    },
    Q: {
        groups: [
            [44, 24],
            [7, 25]
        ],
        totalDataCodewords: 1231,
        errorCodewordsPerBlock: 30
    },
    H: {
        groups: [
            [59, 16],
            [1, 17]
        ],
        totalDataCodewords: 961,
        errorCodewordsPerBlock: 30
    }
}, {
    L: {
        groups: [
            [12, 121],
            [7, 122]
        ],
        totalDataCodewords: 2306,
        errorCodewordsPerBlock: 30
    },
    M: {
        groups: [
            [12, 47],
            [26, 48]
        ],
        totalDataCodewords: 1812,
        errorCodewordsPerBlock: 28
    },
    Q: {
        groups: [
            [39, 24],
            [14, 25]
        ],
        totalDataCodewords: 1286,
        errorCodewordsPerBlock: 30
    },
    H: {
        groups: [
            [22, 15],
            [41, 16]
        ],
        totalDataCodewords: 986,
        errorCodewordsPerBlock: 30
    }
}, {
    L: {
        groups: [
            [6, 121],
            [14, 122]
        ],
        totalDataCodewords: 2434,
        errorCodewordsPerBlock: 30
    },
    M: {
        groups: [
            [6, 47],
            [34, 48]
        ],
        totalDataCodewords: 1914,
        errorCodewordsPerBlock: 28
    },
    Q: {
        groups: [
            [46, 24],
            [10, 25]
        ],
        totalDataCodewords: 1354,
        errorCodewordsPerBlock: 30
    },
    H: {
        groups: [
            [2, 15],
            [64, 16]
        ],
        totalDataCodewords: 1054,
        errorCodewordsPerBlock: 30
    }
}, {
    L: {
        groups: [
            [17, 122],
            [4, 123]
        ],
        totalDataCodewords: 2566,
        errorCodewordsPerBlock: 30
    },
    M: {
        groups: [
            [29, 46],
            [14, 47]
        ],
        totalDataCodewords: 1992,
        errorCodewordsPerBlock: 28
    },
    Q: {
        groups: [
            [49, 24],
            [10, 25]
        ],
        totalDataCodewords: 1426,
        errorCodewordsPerBlock: 30
    },
    H: {
        groups: [
            [24, 15],
            [46, 16]
        ],
        totalDataCodewords: 1096,
        errorCodewordsPerBlock: 30
    }
}, {
    L: {
        groups: [
            [4, 122],
            [18, 123]
        ],
        totalDataCodewords: 2702,
        errorCodewordsPerBlock: 30
    },
    M: {
        groups: [
            [13, 46],
            [32, 47]
        ],
        totalDataCodewords: 2102,
        errorCodewordsPerBlock: 28
    },
    Q: {
        groups: [
            [48, 24],
            [14, 25]
        ],
        totalDataCodewords: 1502,
        errorCodewordsPerBlock: 30
    },
    H: {
        groups: [
            [42, 15],
            [32, 16]
        ],
        totalDataCodewords: 1142,
        errorCodewordsPerBlock: 30
    }
}, {
    L: {
        groups: [
            [20, 117],
            [4, 118]
        ],
        totalDataCodewords: 2812,
        errorCodewordsPerBlock: 30
    },
    M: {
        groups: [
            [40, 47],
            [7, 48]
        ],
        totalDataCodewords: 2216,
        errorCodewordsPerBlock: 28
    },
    Q: {
        groups: [
            [43, 24],
            [22, 25]
        ],
        totalDataCodewords: 1582,
        errorCodewordsPerBlock: 30
    },
    H: {
        groups: [
            [10, 15],
            [67, 16]
        ],
        totalDataCodewords: 1222,
        errorCodewordsPerBlock: 30
    }
}, {
    L: {
        groups: [
            [19, 118],
            [6, 119]
        ],
        totalDataCodewords: 2956,
        errorCodewordsPerBlock: 30
    },
    M: {
        groups: [
            [18, 47],
            [31, 48]
        ],
        totalDataCodewords: 2334,
        errorCodewordsPerBlock: 28
    },
    Q: {
        groups: [
            [34, 24],
            [34, 25]
        ],
        totalDataCodewords: 1666,
        errorCodewordsPerBlock: 30
    },
    H: {
        groups: [
            [20, 15],
            [61, 16]
        ],
        totalDataCodewords: 1276,
        errorCodewordsPerBlock: 30
    }
}];
