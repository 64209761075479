<div>
  <div *ngIf="isDataLoaded" class="mb-10">
    <ng-container *ngIf="moduleId$ | async as moduleId">
      <app-common-grid
        [gridView]="gridView"
        [columns]="gridView?.columns ?? []"
        [moduleId]="moduleId"
        (sortChange)="onSortChange($event)"
        (paginationChange)="onPaginationChange($event)"
        (filterChangeSQL)="onFilterChangeSQL($event)"
        (triggerDataFromColumnSelector)="getDataFromColumnSelector($event)"
      ></app-common-grid>
    </ng-container>
  </div>
  <div class="info-text-container">
    <p class="font-size-14 text-secondary mb-0">
      <span class="ml-5">*</span>Display the status of the OTA update.
    </p>
  </div>
  <div kendoDialogContainer></div>
</div>
