import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { DataTypeEnum } from '@app/enums'
import { Observable, of } from 'rxjs'
import { environment } from 'src/environments'

@Injectable()
export class SoftwareService {
  gridData = [
    {
      status: 'Done',
      code: 123,
      dateTime: '13:49:59 06-05-2024',
    },
    {
      status: 'Done',
      code: 432,
      dateTime: '13:49:59 06-05-2024',
    },
    {
      status: 'Done',
      code: 434,
      dateTime: '13:49:59 06-05-2024',
    },
  ]

  columns: any[] = [
    {
      field: 'status',
      title: 'Status',
      dataTypeId: DataTypeEnum.UpperCase,
      hasColumn: [],
      isSelected: true,
      isLocked: false,
    },
    {
      field: 'code',
      title: 'Code',
      hasColumn: [],
      isSelected: true,
      isLocked: false,
    },
    {
      field: 'dateTime',
      title: 'Date & Time',
      hasColumn: [],
      isSelected: true,
      isLocked: false,
      dataTypeId: DataTypeEnum.DateTime,
    },
  ]

  constructor(private http: HttpClient) {}

  getSoftwareStatus(queryParams: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    }
    return this.http.post<any>(
      `${environment.apiVersionUrl}Software/GetOTAStatus`,
      queryParams,
      httpOptions,
    )
  }

  saveSoftware(data: any): Observable<any> {
    return of(data) // temprory
  }

  softwareUpload(deviceId: any, file: any): Observable<any> {
    const headers = new HttpHeaders({
      enctype: 'multipart/form-data',
      Accept: 'multipart/form-data',
    })

    return this.http.post<any>(
      `${environment.apiVersionUrl}Software/SoftwareUpload/${deviceId}`,
      file,
      {
        headers: headers,
        responseType: 'text' as 'json', // Specify the response type here
      },
    )
  }
}
