import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import {
  AlertComponent,
  AlertRoutingModule,
  CaidiDetailsComponent,
  CommonsModule,
  OverloadListComponent,
  PfListComponent,
  SentNotifcationListComponent,
} from '@app/index'
import { SharedModule } from 'src/shared/shared.module'
@NgModule({
  declarations: [
    AlertComponent,
    SentNotifcationListComponent,
    PfListComponent,
    OverloadListComponent,
    CaidiDetailsComponent,
  ],
  imports: [CommonModule, AlertRoutingModule, CommonsModule, SharedModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AlertModule {}
