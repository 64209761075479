export class GetAnalyticsChart {
  static readonly type = '[ANALYTICS] Get Analytics Chart'
  constructor(public analyticsData: any) {}
}

export class GetAnalyticsVoltageChart {
  static readonly type = '[ANALYTICS] Get Analytics valtage Chart'
  constructor(public analyticsVoltageData: any) {}
}

export class GetCurrentACardData {
  static readonly type = '[ANALYTICS] Get Current (A) Card Data'
  constructor(public isCurrentGraph: boolean) {}
}

export class GetCurrentGraphChart {
  static readonly type = '[ANALYTICS] Get Current Graph Chart'
  constructor(public currentGraphData: any) {}
}

export class GetLoadTempGraphChart {
  static readonly type = '[ANALYTICS] Get Load temp Graph Chart'
  constructor(public analyticsLoadTempGraphData: any) {}
}
