<form [formGroup]="minVoltageForm" class="k-form k-form-md">
  <div class="mb-20">
    <div class="d-flex gap-15">
      <kendo-label
        [for]="rPhase"
        text="R Phase"
        labelCssClass="k-form-label"
        class="w-100 d-flex align-items-center"
      >
        <span class="required mb-0">*</span>
      </kendo-label>
      <kendo-textbox
        formControlName="rPhase"
        #rPhase
        placeholder="Enter Min Voltage R Phase"
        (keypress)="commonService.numberOnly($event)"
      ></kendo-textbox>
    </div>
    <div
      *ngIf="
        minVoltageForm.get('rPhase').invalid &&
        minVoltageForm.get('rPhase').touched
      "
      class="d-flex gap-15"
    >
      <div class="w-80"></div>
      <div>
        <p
          *ngIf="minVoltageForm.get('rPhase').errors?.required"
          class="required mb-0"
        >
          R Phase is required.
        </p>
        <p
          *ngIf="minVoltageForm.get('rPhase').errors?.pattern"
          class="required mb-0"
        >
          Please enter a valid number for Min Voltage R Phase.
        </p>
      </div>
    </div>
  </div>

  <div class="mb-20">
    <div class="d-flex gap-15">
      <kendo-label
        [for]="yPhase"
        text="Y Phase"
        labelCssClass="k-form-label"
        class="w-100 d-flex align-items-center"
      >
        <span class="required mb-0">*</span>
      </kendo-label>
      <kendo-textbox
        formControlName="yPhase"
        #yPhase
        placeholder="Enter Min Voltage Y Phase"
        (keydown)="commonService.numberOnly($event)"
      ></kendo-textbox>
    </div>
    <div
      *ngIf="
        minVoltageForm.get('yPhase').invalid &&
        minVoltageForm.get('yPhase').touched
      "
      class="d-flex gap-15"
    >
      <div class="w-80"></div>
      <p
        *ngIf="minVoltageForm.get('yPhase').errors?.required"
        class="required mb-0"
      >
        Y Phase is required.
      </p>
      <p
        *ngIf="minVoltageForm.get('yPhase').errors?.pattern"
        class="required mb-0"
      >
        Please enter a valid number for Min Voltage Y Phase.
      </p>
    </div>
  </div>

  <div class="mb-20">
    <div class="d-flex gap-15">
      <kendo-label
        [for]="bPhase"
        text="B Phase"
        labelCssClass="k-form-label"
        class="w-100 d-flex align-items-center"
      >
        <span class="required mb-0">*</span>
      </kendo-label>
      <kendo-textbox
        formControlName="bPhase"
        #bPhase
        placeholder="Enter Min Voltage B Phase"
        (keydown)="commonService.numberOnly($event)"
      ></kendo-textbox>
    </div>
    <div
      *ngIf="
        minVoltageForm.get('bPhase').invalid &&
        minVoltageForm.get('bPhase').touched
      "
      class="d-flex gap-15"
    >
      <div class="w-80"></div>
      <div>
        <p
          *ngIf="minVoltageForm.get('bPhase').errors?.required"
          class="required mb-0"
        >
          B Phase is required.
        </p>
        <p
          *ngIf="minVoltageForm.get('bPhase').errors?.pattern"
          class="required mb-0"
        >
          Please enter a valid number for Min Voltage B Phase.
        </p>
      </div>
    </div>
  </div>
</form>
