import { Injectable } from '@angular/core'
import { Observable, of } from 'rxjs'

@Injectable()
export class SSReportService {
  ptrLoadsreportLGridData: any[] = [
    {
      deviceId: 1,
      xyz: 2,
      B: 3,
      updatedOn: {
        updatedOn: new Date().toDateString(),
      },
      lineVoltages: {
        RY: 1,
        YB: 2,
        BR: 3,
      },
      lineCurrents: {
        R: 1,
        Y: 2,
        B: 3,
      },
      powerFactor: 1,
    },
    {
      deviceId: 1,
      xyz: 2,
      B: 3,
      updatedOn: {
        updatedOn: new Date().toDateString(),
      },
      lineVoltages: {
        RY: 1,
        YB: 2,
        BR: 3,
      },
      lineCurrents: {
        R: 1,
        Y: 2,
        B: 3,
      },
      powerFactor: 1,
    },
    {
      deviceId: 1,
      xyz: 2,
      B: 3,
      updatedOn: {
        updatedOn: new Date().toDateString(),
      },
      lineVoltages: {
        RY: 1,
        YB: 2,
        BR: 3,
      },
      lineCurrents: {
        R: 1,
        Y: 2,
        B: 3,
      },
      powerFactor: 1,
    },
  ]

  ptrInterruptionreportLGridData: any[] = [
    {
      deviceId: 1,
      deviceName: 'adadadad',
      noOfInterruptions: 3,
      duration: 3,
    },
    {
      deviceId: 1,
      deviceName: 'adadadad',
      noOfInterruptions: 3,
      duration: 3,
    },
    {
      deviceId: 1,
      deviceName: 'adadadad',
      noOfInterruptions: 3,
      duration: 3,
    },
  ]

  columnsPTRInterruption = [
    {
      field: 'deviceId',
      title: 'Device Id',
      hasColumn: [],
      isSelected: true,
      isLocked: false,
    },
    {
      field: 'deviceName',
      title: 'Device Name',
      hasColumn: [],
      isSelected: true,
      isLocked: false,
    },
    {
      field: 'noOfInterruptions',
      title: 'No Of Interruptions',
      hasColumn: [],
      isSelected: true,
      isLocked: false,
    },
    {
      field: 'duration',
      title: 'Duration',
      hasColumn: [],
      isSelected: true,
      isLocked: false,
    },
  ]

  columns = [
    {
      field: 'deviceId',
      title: 'Device Id',
      hasColumn: [],
      isSelected: true,
      isLocked: false,
    },
    {
      field: 'updatedOn',
      title: 'Updated On',
      isSelected: true,
      isLocked: false,
      hasColumn: [
        { field: 'updatedOn', title: '', isSelected: true, isLocked: false },
      ],
    },
    {
      field: 'lineVoltages',
      title: 'LineVoltages(Volts)',
      isSelected: true,
      isLocked: false,
      hasColumn: [
        { field: 'RY', title: 'RY', isSelected: true, isLocked: false },
        { field: 'YB', title: 'YB', isSelected: true, isLocked: false },
        { field: 'BR', title: 'BR', isSelected: true, isLocked: false },
      ],
    },
    {
      field: 'lineCurrents',
      title: 'LineCurrents(Amps)',
      isSelected: true,
      isLocked: false,
      hasColumn: [
        { field: 'R', title: 'R', isSelected: true, isLocked: false },
        { field: 'Y', title: 'Y', isSelected: true, isLocked: false },
        { field: 'B', title: 'B', isSelected: true, isLocked: false },
      ],
    },
    {
      field: 'powerFactor',
      title: 'Total',
      hasColumn: [],
      isSelected: true,
      isLocked: false,
    },
  ]

  getPTRLoadsReporttList(): Observable<any> {
    // Return an observable of device data
    const data = {
      ptrLoadsListData: this.ptrLoadsreportLGridData,
      columns: this.columns,
    }
    return of(data)
  }

  getPTRInterruptionReportList(): Observable<any> {
    // Return an observable of device data
    const data = {
      ptrInterruptionreportLGridData: this.ptrInterruptionreportLGridData,
      columnsPTRInterruption: this.columnsPTRInterruption,
    }
    return of(data)
  }
}
