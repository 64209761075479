<div>
  <div class="d-flex-row">
    <div
      class="d-flex-col-sm-6 d-flex-col-lg-4 d-flex-col-xs-12 d-flex-col d-flex"
    >
      <div class="q-card mb-20 flex-1 border q-card-bg-phaseR">
        <div class="d-flex justify-content-between align-items-center">
          <div>
            <p class="q-card-title mb-12 text-secondary">Phase R</p>
            <p class="q-card-value m-0 font-size-24">{{ current_ph1 }}</p>
          </div>
          <div class="q-card-icon q-card-icon-phaseR">
            <i class="far fa-gauge-circle-bolt ml-5"></i>
          </div>
        </div>
      </div>
    </div>

    <div
      class="d-flex-col-sm-6 d-flex-col-lg-4 d-flex-col-xs-12 d-flex-col d-flex"
    >
      <div class="q-card mb-20 flex-1 border q-card-bg-phaseY">
        <div class="d-flex justify-content-between align-items-center">
          <div>
            <p class="q-card-title mb-12 text-secondary">Phase Y</p>
            <p class="q-card-value m-0 font-size-24">{{ current_ph2 }}</p>
          </div>
          <div class="q-card-icon q-card-icon-phaseY">
            <i class="far fa-gauge-circle-bolt ml-5"></i>
          </div>
        </div>
      </div>
    </div>

    <div
      class="d-flex-col-sm-6 d-flex-col-lg-4 d-flex-col-xs-12 d-flex-col d-flex"
    >
      <div class="q-card mb-20 flex-1 border q-card-bg-phaseB">
        <div class="d-flex justify-content-between align-items-center">
          <div>
            <p class="q-card-title mb-12 text-secondary">Phase B</p>
            <p class="q-card-value m-0 font-size-24">{{ current_ph3 }}</p>
          </div>
          <div class="q-card-icon q-card-icon-phaseB">
            <i class="far fa-gauge-circle-bolt ml-5"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
