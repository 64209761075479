import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { SharedModule } from 'src/shared/shared.module'
import {
  CommonsModule,
  SSLDDashboardRoutingModule,
  SslddashboardComponent,
} from '@app/index'

@NgModule({
  declarations: [SslddashboardComponent],
  imports: [
    CommonModule,
    SSLDDashboardRoutingModule,
    SharedModule,
    CommonsModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SSLDDashboardModule {}
