import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { NgxSpinnerService } from 'ngx-spinner'
import { ToastrService } from 'ngx-toastr'
import { throwError } from 'rxjs'
import { Observable } from 'rxjs/internal/Observable'
import { catchError, tap } from 'rxjs/operators'

@Injectable()
export class CustomHttpInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    let apiReq = request.clone({ url: request.url })
    const token: any = localStorage.getItem('loginToken')
    const authToken = 'Bearer ' + token
    if (token) {
      apiReq = apiReq.clone({
        setHeaders: {
          Authorization: authToken ? authToken : '',
        },
      })
    }

    return next.handle(apiReq).pipe(
      tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          // handle the response if needed
        }
      }),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          // Unauthorized request, redirect to login
          localStorage.removeItem('loginToken')
          localStorage.removeItem('refreshLoginToken')
          this.router.navigate(['/login'])
        } else {
          this.toastr.error(
            error.error.Message
              ? error.error.Message
              : JSON.parse(error.error).Message,
          )
        }

        this.spinner.hide()
        return throwError(error)
      }),
    )
  }
}
