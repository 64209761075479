import { Component, Input, OnInit } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { CommonService } from '@app/services'
import { Observable } from 'rxjs'

@Component({
  selector: 'app-ct-settings',
  templateUrl: './ct-settings.component.html',
  styleUrl: './ct-settings.component.scss',
})
export class CtSettingsComponent implements OnInit {
  ctSettingForm: FormGroup | any
  @Input()
  triggerCtSettingData: Observable<any> | undefined
  constructor(public commonService: CommonService) {}

  ngOnInit(): void {
    this.setForm()
    this.triggerCtSettingData?.subscribe((res: any) => {
      if (res) {
        this.setData(res)
      } else {
        this.setForm()
      }
    })
  }

  setData(data: any): void {
    if (
      typeof data === 'object' &&
      data !== null &&
      Object.keys(data).length === 0
    ) {
      return
    }
    this.ctSettingForm.patchValue({
      ctRatio: data ?? '',
    })
  }

  setForm(): void {
    this.ctSettingForm = new FormGroup({
      ctRatio: new FormControl('', [
        Validators.required,
        Validators.pattern('^[0-9]+(.[0-9]+)?$'),
      ]),
    })
  }
}
