import { Component } from '@angular/core'
import { NotificationMessage } from '@app/enums/notification'
import { OfficeUseChangesService } from '@app/services'
import { CommonState } from '@app/store'
import { Select } from '@ngxs/store'
import { NgxSpinnerService } from 'ngx-spinner'
import { ToastrService } from 'ngx-toastr'
import { Observable, Subject } from 'rxjs'
import swal from 'sweetalert'

@Component({
  selector: 'app-reset-device',
  templateUrl: './reset-device.component.html',
  styleUrl: './reset-device.component.scss',
})
export class ResetDeviceComponent {
  triggerGetUpdatedTime: Subject<any> = new Subject<any>()

  @Select(CommonState.hasEditPermission)
  hasEditPermission$!: Observable<boolean>

  deviceId = ''

  constructor(
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private officeUseChangesService: OfficeUseChangesService,
  ) {}

  ngOnInit(): void {}
  getDataByHeaderData(data: any): void {
    this.deviceId = data.deviceId
    this.triggerGetUpdatedTime.next(true)
  }

  confirmPopupUp() {
    let textMessage = `You want to Reset Device.`

    swal({
      title: 'Are you sure ?',
      text: textMessage,
      icon: 'warning',
      buttons: {
        cancel: {
          text: 'Cancel',
          visible: true,
          closeModal: true,
        },
        confirm: {
          text: 'Yes',
        },
      },
      dangerMode: true,
    }).then((confirmed) => {
      if (confirmed) {
        {
          this.resetDevice()
        }
      }
    })
  }

  resetDevice(): void {
    this.spinner.show()
    this.officeUseChangesService.resetDevice(this.deviceId).subscribe(
      (res: any) => {
        this.spinner.hide()
        if (res) {
          this.toastr.success(NotificationMessage.ResetDeviceSuccessMsg)
        } else {
          this.toastr.error(NotificationMessage.errorMsg)
        }
      },
      (error) => {
        this.spinner.hide()
      },
    )
  }
}
