<div class="q-content">
  <div
    class="d-flex gap-15 flex-wrap justify-content-between align-items-center mb-20"
  >
    <app-common-header-text
      [triggerGetUpdatedTime]="triggerGetUpdatedTime"
    ></app-common-header-text>
    <app-common-header
      [showDateFilter]="false"
      (triggerDataFromHeader)="getDataByHeaderData($event)"
    ></app-common-header>
  </div>
  <div class="d-flex-row">
    <div class="d-flex-col-lg-4 d-flex-col-xs-12 d-flex-col mb-20 d-flex">
      <div class="d-flex-col-lg-4 d-flex-col-xs-12 d-flex-col mb-20 d-flex">
        <div class="q-card flex-1 border update-data-container">
          <div>
            <p class="font-size-16 fw-500 mb-20">Reset Device</p>
          </div>
          <div
            class="update-btn"
            [ngClass]="{ 'curser-not-allowd': !(hasEditPermission$ | async) }"
          >
            <button
              kendoButton
              (click)="confirmPopupUp()"
              [disabled]="!(hasEditPermission$ | async)"
            >
              Reset
            </button>
          </div>
        </div>
      </div>
    </div>
    <div kendoDialogContainer></div>
  </div>
</div>
