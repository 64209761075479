export const KeyCode = {
    BACKSPACE: 8,
    DELETE: 46,
    TAB: 9,
    ENTER: 13,
    ESCAPE: 27,
    ARROW_LEFT: 37,
    ARROW_UP: 38,
    ARROW_RIGHT: 39,
    ARROW_DOWN: 40,
    SPACE: 32,
    END: 35,
    HOME: 36,
    PAGE_UP: 33,
    PAGE_DOWN: 34
};
