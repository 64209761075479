import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { SharedModule } from 'src/shared/shared.module'
import {
  AnalyticsRoutingModule,
  CommonAnalyticsCardComponent,
  CommonAnalyticsGraphComponent,
  CommonsModule,
  CurrentGraphComponent,
  LoadComponent,
  LoadTempGraphComponent,
  VoltageGraphComponent,
} from '@app/index'

@NgModule({
  declarations: [
    LoadComponent,
    CurrentGraphComponent,
    CommonAnalyticsCardComponent,
    CommonAnalyticsGraphComponent,
    VoltageGraphComponent,
    LoadTempGraphComponent,
  ],
  imports: [CommonModule, AnalyticsRoutingModule, CommonsModule, SharedModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AnalyticsModule {}
