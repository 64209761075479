<div class="q-content">
  <div
    class="d-flex gap-15 flex-wrap justify-content-between align-items-center mb-20"
  >
    <app-common-header-text
      [infoText]="infoText"
      [isInfoVisible]="true"
      [triggerGetUpdatedTime]="triggerGetUpdatedTime"
    ></app-common-header-text>
    <div class="d-flex gap-15 flex-wrap justify-content-end flex-wrap">
      <app-common-header
        (triggerDataFromHeader)="getDataByHeaderData($event)"
        [showDateFilter]="false"
      ></app-common-header>
    </div>
  </div>
  <div class="d-flex justify-content-between gap-20 mb-20 flex-wrap">
    <div class="q-card flex-1 volt-boxes border">
      <div class="d-flex justify-content-between align-items-center">
        <div>
          <p class="mb-12 text-secondary">Instantaneous KW</p>
          <p class="q-card-value m-0 font-size-24">
            {{ dashboardCardData?.instantaneousKW }}
          </p>
        </div>
        <div class="q-card-icon q-card-icon-Primary">
          <i class="far fa-bolt"></i>
        </div>
      </div>
    </div>
    <div class="q-card flex-1 volt-boxes border">
      <div class="d-flex justify-content-between align-items-center">
        <div>
          <p class="mb-12 text-secondary">Instantaneous KVA</p>
          <p class="q-card-value m-0 font-size-24">
            {{ dashboardCardData?.instantaneousKVA }}
          </p>
        </div>
        <div class="q-card-icon q-card-icon-Primary">
          <i class="far fa-bolt"></i>
        </div>
      </div>
    </div>
    <div class="q-card flex-1 volt-boxes border">
      <div class="d-flex justify-content-between align-items-center">
        <div>
          <p class="mb-12 text-secondary">Load(%)</p>
          <p class="q-card-value m-0 font-size-24">
            {{ dashboardCardData?.load }}
          </p>
        </div>
        <div class="q-card-icon q-card-icon-Primary">
          <i class="far fa-bolt"></i>
        </div>
      </div>
    </div>
    <div class="q-card flex-1 volt-boxes border">
      <div class="d-flex justify-content-between align-items-center">
        <div>
          <p class="mb-12 text-secondary">PF</p>
          <p class="q-card-value m-0 font-size-24">
            {{ dashboardCardData?.pf }}
          </p>
        </div>
        <div class="q-card-icon q-card-icon-Primary">
          <i class="far fa-bolt"></i>
        </div>
      </div>
    </div>
    <div class="q-card flex-1 volt-boxes border">
      <div class="d-flex justify-content-between align-items-center">
        <div>
          <p class="mb-12 text-secondary">Max KVA</p>
          <p class="q-card-value m-0 font-size-24">
            {{ dashboardCardData?.maxKVA }}
          </p>
        </div>
        <div class="q-card-icon q-card-icon-Primary">
          <i class="far fa-bolt"></i>
        </div>
      </div>
    </div>
  </div>
  <div class="d-flex-row">
    <div
      class="d-flex-col-xl-7 d-flex-col-md-12 d-flex-col-xs-12 d-flex-col d-flex"
    >
      <div class="q-card border pb-0 mb-20 flex-1">
        <div class="d-flex-row mb-20">
          <div class="d-flex-col-xl-3 d-flex-col-lg-12 d-flex-col">
            <p class="font-size-16 mb-20 fw-500 mt-10">Voltage (V)</p>
          </div>
          <div
            class="d-flex-col-xl-9 d-flex-col-lg-12 d-flex flex-wrap gap-10 box-1"
          >
            <div class="q-card q-card-bg-phaseR q-card-box">
              <div
                class="d-flex justify-content-center align-items-center flex-column"
              >
                <i
                  class="far fa-gauge-circle-bolt phaseR mb-10 font-size-20"
                ></i>
                <p class="q-card-value font-size-20">
                  {{ dashboardCardData?.voltageR }}
                </p>
                <p class="m-0 text-secondary">Phase R</p>
              </div>
            </div>
            <div class="q-card q-card-bg-phaseY q-card-box">
              <div
                class="d-flex justify-content-center align-items-center flex-column"
              >
                <i
                  class="far fa-gauge-circle-bolt phaseY mb-10 font-size-20"
                ></i>
                <p class="q-card-value">
                  {{ dashboardCardData?.voltageY }}
                </p>
                <p class="m-0 text-secondary">Phase Y</p>
              </div>
            </div>
            <div class="q-card q-card-bg-phaseB q-card-box">
              <div
                class="d-flex justify-content-center align-items-center flex-column"
              >
                <i
                  class="far fa-gauge-circle-bolt phaseB mb-10 font-size-20"
                ></i>
                <p class="q-card-value">
                  {{ dashboardCardData?.voltageB }}
                </p>
                <p class="m-0 text-secondary">Phase B</p>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div class="d-flex-row mb-20">
          <div class="d-flex-col-xl-3 d-flex-col-lg-12 d-flex-col">
            <p class="font-size-16 mb-20 fw-500 mt-10">Current (A)</p>
          </div>
          <div
            class="d-flex-col-xl-9 d-flex-col-lg-12 d-flex flex-wrap gap-10 box-1"
          >
            <div class="q-card q-card-bg-phaseR q-card-box q-card-box">
              <div
                class="d-flex justify-content-center align-items-center flex-column"
              >
                <i class="far fa-bolt mb-10 font-size-20 phaseR"></i>
                <p class="q-card-value font-size-20">
                  {{ dashboardCardData?.currentR }}
                </p>
                <p class="m-0 text-secondary">Phase R</p>
              </div>
            </div>
            <div class="q-card q-card-bg-phaseY q-card-box">
              <div
                class="d-flex justify-content-center align-items-center flex-column"
              >
                <i class="far fa-bolt mb-10 font-size-20 phaseY"></i>
                <p class="q-card-value">
                  {{ dashboardCardData?.currentY }}
                </p>
                <p class="m-0 text-secondary">Phase Y</p>
              </div>
            </div>
            <div class="q-card q-card-bg-phaseB q-card-box">
              <div
                class="d-flex justify-content-center align-items-center flex-column"
              >
                <i class="far fa-bolt mb-10 font-size-20 phaseB"></i>
                <p class="q-card-value">
                  {{ dashboardCardData?.currentB }}
                </p>
                <p class="m-0 text-secondary">Phase B</p>
              </div>
            </div>
            <div class="q-card q-card-bg-Gray q-card-box">
              <div
                class="d-flex justify-content-center align-items-center flex-column"
              >
                <i class="far fa-bolt mb-10 font-size-20"></i>
                <p class="q-card-value">
                  {{ dashboardCardData?.currentN }}
                </p>
                <p class="m-0">Phase Neutral</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="d-flex-col-xl-5 d-flex-col-lg-12 d-flex-col-md-12 d-flex-col-xs-12 d-flex-col d-flex"
    >
      <div class="q-card border pb-0 mb-20">
        <p class="font-size-16 mb-20 fw-500">Total Energy (Units)</p>
        <div class="d-flex-row energy-box">
          <div
            class="d-flex-col-sm-6 d-flex-col-lg-3 d-flex-col-xs-12 d-flex-col d-flex"
          >
            <div
              class="q-card border flex-1 mb-20 d-flex align-items-center text-align-center justify-content-center"
            >
              <div class="d-flex justify-content-between align-items-center">
                <div>
                  <i class="far fa-transformer-bolt font-size-24 mb-10"></i>
                  <p class="q-card-value">
                    {{ dashboardCardData?.kWh }}
                  </p>
                  <p class="m-0 text-uppercase text-secondary">KWh</p>
                </div>
              </div>
            </div>
          </div>

          <div
            class="d-flex-col-sm-6 d-flex-col-lg-3 d-flex-col-xs-12 d-flex-col d-flex"
          >
            <div
              class="q-card border flex-1 mb-20 d-flex align-items-center text-align-center justify-content-center"
            >
              <div class="d-flex justify-content-between align-items-center">
                <div>
                  <i class="far fa-transformer-bolt font-size-24 mb-10"></i>
                  <p class="q-card-value">
                    {{ dashboardCardData?.kvAh }}
                  </p>
                  <p class="m-0 text-uppercase text-secondary">KVAh</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="d-flex-row">
    <div
      class="d-flex-col-lg-7 d-flex-col-sm-12 d-flex-col-xs-12 d-flex-col d-flex"
    >
      <div class="q-card border flex-1 power-section">
        <p class="font-size-16 mb-20 fw-500">Phases Power & PF</p>
        <div class="d-flex-row gap-10">
          <div class="d-flex-col-xl-3 d-flex-col-lg-12 pl-10">
            <div class="d-flex align-items-center gap-10 h-100">
              <div class="q-card-bg-phaseR power-icon">
                <i class="far fa-power-off phaseR"></i>
              </div>
              <p class="mb-0">Phases R</p>
            </div>
          </div>
          <div
            class="d-flex-col-lg-8 d-flex-col-sm-12 d-flex-col d-flex flex-wrap gap-15"
          >
            <div class="d-flex gap-20 flex-wrap align-items-center">
              <div class="q-card-box">
                <p class="q-card-value m-0">
                  {{ dashboardCardData?.phasesPowerR1 }}
                  <span class="font-size-12 fw-500 text-secondary">KW</span>
                </p>
              </div>
              <div class="q-card-box">
                <p class="q-card-value m-0">
                  {{ dashboardCardData?.phasesPowerR2 }}
                  <span class="font-size-12 fw-500 text-secondary">KVA</span>
                </p>
              </div>
              <div class="q-card-box">
                <p class="q-card-value m-0">
                  {{ dashboardCardData?.phasesPowerR3 }}
                  <span class="font-size-12 fw-500 text-secondary">PF</span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <hr class="mt-10 mb-10" />
        <div class="d-flex-row gap-10">
          <div class="d-flex-col-xl-3 d-flex-col-lg-12 pl-10">
            <div class="d-flex align-items-center gap-10 h-100">
              <div class="q-card-bg-phaseY power-icon">
                <i class="far fa-power-off phaseY"></i>
              </div>
              <p class="mb-0">Phases Y</p>
            </div>
          </div>
          <div
            class="d-flex-col-lg-8 d-flex-col-sm-12 d-flex-col d-flex flex-wrap gap-15"
          >
            <div class="d-flex gap-20 flex-wrap align-items-center">
              <div class="q-card-box">
                <p class="q-card-value m-0">
                  {{ dashboardCardData?.phasesPowerY1 }}
                  <span class="font-size-12 fw-500 text-secondary">KW</span>
                </p>
              </div>
              <div class="q-card-box">
                <p class="q-card-value m-0">
                  {{ dashboardCardData?.phasesPowerY2 }}
                  <span class="font-size-12 fw-500 text-secondary">KVA</span>
                </p>
              </div>
              <div class="q-card-box">
                <p class="q-card-value m-0">
                  {{ dashboardCardData?.phasesPowerY3 }}
                  <span class="font-size-12 fw-500 text-secondary">PF</span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <hr class="mt-10 mb-10" />
        <div class="d-flex-row gap-10">
          <div class="d-flex-col-xl-3 d-flex-col-lg-12 pl-10">
            <div class="d-flex align-items-center gap-10 h-100">
              <div class="q-card-bg-phaseB power-icon">
                <i class="far fa-power-off phaseB"></i>
              </div>
              <p class="mb-0">Phases B</p>
            </div>
          </div>
          <div
            class="d-flex-col-lg-8 d-flex-col-sm-12 d-flex-col d-flex flex-wrap gap-15"
          >
            <div class="d-flex gap-20 flex-wrap align-items-center">
              <div class="q-card-box">
                <p class="q-card-value m-0">
                  {{ dashboardCardData?.phasesPowerB1 }}
                  <span class="font-size-12 fw-500 text-secondary">KW</span>
                </p>
              </div>
              <div class="q-card-box">
                <p class="q-card-value m-0">
                  {{ dashboardCardData?.phasesPowerB2 }}
                  <span class="font-size-12 fw-500 text-secondary">KVA</span>
                </p>
              </div>
              <div class="q-card-box">
                <p class="q-card-value m-0">
                  {{ dashboardCardData?.phasesPowerB3 }}
                  <span class="font-size-12 fw-500 text-secondary">PF</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="d-flex-col-lg-5 d-flex-col-md-12 d-flex-col-xs-12 d-flex-col d-flex"
    >
      <div class="q-card border flex-1">
        <p class="font-size-16 mb-20 fw-500">Temperatures (C)</p>
        <div class="q-card border p-0 table-container">
          <table class="dashboard-table">
            <tr>
              <td>
                <div class="d-flex gap-15 align-items-center">
                  <div class="q-card-icon q-card-bg-phaseR power-icon">
                    <i class="far fa-temperature-three-quarters phaseR"></i>
                  </div>
                  <div>
                    <div
                      class="q-card-value d-flex gap-20 justify-content-between q-card-box"
                    >
                      <span>R</span>
                      <p class="q-card-value mb-0">
                        {{ dashboardCardData?.temperaturesR
                        }}<span class="font-size-12 fw-500"> ℃</span>
                      </p>
                    </div>
                  </div>
                </div>
              </td>
              <td>
                <div class="d-flex gap-15 align-items-center">
                  <div class="q-card-icon q-card-bg-phaseY power-icon">
                    <i class="far fa-temperature-three-quarters phaseY"></i>
                  </div>
                  <div>
                    <div
                      class="q-card-value d-flex gap-20 justify-content-between q-card-box"
                    >
                      <span>Y</span>
                      <p class="q-card-value mb-0">
                        {{ dashboardCardData?.temperaturesY
                        }}<span class="font-size-12 fw-500"> ℃</span>
                      </p>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div class="d-flex gap-15 align-items-center">
                  <div class="q-card-icon q-card-bg-phaseB power-icon">
                    <i class="far fa-temperature-three-quarters phaseB"></i>
                  </div>
                  <div>
                    <div
                      class="q-card-value d-flex gap-20 justify-content-between q-card-box"
                    >
                      <span>B</span>
                      <p class="q-card-value mb-0">
                        {{ dashboardCardData?.temperaturesB
                        }}<span class="font-size-12 fw-500"> ℃</span>
                      </p>
                    </div>
                  </div>
                </div>
              </td>
              <td>
                <div class="d-flex gap-15 align-items-center">
                  <div class="q-card-icon q-card-bg-Brown power-icon">
                    <i class="far fa-temperature-three-quarters brown"></i>
                  </div>
                  <div>
                    <div
                      class="q-card-value d-flex gap-20 justify-content-between q-card-box"
                    >
                      <span>Oil</span>
                      <p class="q-card-value mb-0">
                        {{ dashboardCardData?.oil
                        }}<span class="font-size-12 fw-500"> ℃</span>
                      </p>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
