import { Component } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { NotificationMessage } from '@app/enums/notification'
import { CommonService, OfficeUseChangesService } from '@app/services'
import { CreateLoadedSetting } from '@app/store'
import { Store } from '@ngxs/store'
import { DialogRef } from '@progress/kendo-angular-dialog'
import { NgxSpinnerService } from 'ngx-spinner'
import { ToastrService } from 'ngx-toastr'

@Component({
  selector: 'app-loaded-setting-dialog',
  templateUrl: './loaded-setting-dialog.component.html',
  styleUrl: './loaded-setting-dialog.component.scss',
})
export class LoadedSettingDialogComponent {
  loadedSettingForm: FormGroup | any
  loadedSettingArrayData: any[] = []
  currentIndex = 0
  deviceId: any
  constructor(
    public dialogRef: DialogRef,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private store: Store,
    private officeUseChangesService: OfficeUseChangesService,
    public commonService: CommonService,
  ) {}

  ngOnInit(): void {
    this.setForm()
    if (this.loadedSettingArrayData) {
      this.loadedSettingFormBind(0)
    }
  }

  loadedSettingFormBind(index: number) {
    let frameData = this.loadedSettingArrayData[index].frame.split(';')
    this.loadedSettingForm.patchValue({
      voltagesR: frameData[4],
      voltagesY: frameData[12],
      voltagesB: frameData[20],
      currentR: frameData[5],
      currentY: frameData[13],
      currentB: frameData[21],
      gainR: frameData[6],
      gainY: frameData[14],
      gainB: frameData[22],
      angel1R: frameData[7],
      angel1Y: frameData[15],
      angel1B: frameData[23],
      angel2R: frameData[8],
      angel2Y: frameData[16],
      angel2B: frameData[24],
      awgR: frameData[9],
      awgY: frameData[17],
      awgB: frameData[25],
      avagR: frameData[10],
      avagY: frameData[18],
      avagB: frameData[26],
      avargR: frameData[11],
      avargY: frameData[19],
      avaegB: frameData[27],
    })
  }

  setForm(): void {
    this.loadedSettingForm = new FormGroup({
      voltagesR: new FormControl('', Validators.pattern('^[0-9]+(.[0-9]+)?$')),
      voltagesY: new FormControl('', Validators.pattern('^[0-9]+(.[0-9]+)?$')),
      voltagesB: new FormControl('', Validators.pattern('^[0-9]+(.[0-9]+)?$')),
      currentR: new FormControl('', Validators.pattern('^[0-9]+(.[0-9]+)?$')),
      currentY: new FormControl('', Validators.pattern('^[0-9]+(.[0-9]+)?$')),
      currentB: new FormControl('', Validators.pattern('^[0-9]+(.[0-9]+)?$')),
      gainR: new FormControl('', Validators.pattern('^[0-9]+(.[0-9]+)?$')),
      gainY: new FormControl('', Validators.pattern('^[0-9]+(.[0-9]+)?$')),
      gainB: new FormControl('', Validators.pattern('^[0-9]+(.[0-9]+)?$')),
      angel1R: new FormControl('', Validators.pattern('^[0-9]+(.[0-9]+)?$')),
      angel1Y: new FormControl('', Validators.pattern('^[0-9]+(.[0-9]+)?$')),
      angel1B: new FormControl('', Validators.pattern('^[0-9]+(.[0-9]+)?$')),
      angel2R: new FormControl('', Validators.pattern('^[0-9]+(.[0-9]+)?$')),
      angel2Y: new FormControl('', Validators.pattern('^[0-9]+(.[0-9]+)?$')),
      angel2B: new FormControl('', Validators.pattern('^[0-9]+(.[0-9]+)?$')),
      awgR: new FormControl('', Validators.pattern('^[0-9]+(.[0-9]+)?$')),
      awgY: new FormControl('', Validators.pattern('^[0-9]+(.[0-9]+)?$')),
      awgB: new FormControl('', Validators.pattern('^[0-9]+(.[0-9]+)?$')),
      avagR: new FormControl('', Validators.pattern('^[0-9]+(.[0-9]+)?$')),
      avagY: new FormControl('', Validators.pattern('^[0-9]+(.[0-9]+)?$')),
      avagB: new FormControl('', Validators.pattern('^[0-9]+(.[0-9]+)?$')),
      avargR: new FormControl('', Validators.pattern('^[0-9]+(.[0-9]+)?$')),
      avargY: new FormControl('', Validators.pattern('^[0-9]+(.[0-9]+)?$')),
      avaegB: new FormControl('', Validators.pattern('^[0-9]+(.[0-9]+)?$')),
    })
  }

  next(): void {
    if (this.currentIndex <= this.loadedSettingArrayData.length - 1) {
      this.currentIndex--
      this.loadedSettingFormBind(this.currentIndex)
    }
  }

  previous(): void {
    if (this.currentIndex < this.loadedSettingArrayData.length - 1) {
      this.currentIndex++
      this.loadedSettingFormBind(this.currentIndex)
    }
  }

  saveChanges(): void {
    if (this.loadedSettingForm.invalid) {
      Object.keys(this.loadedSettingForm.controls).forEach((key) => {
        this.loadedSettingForm.controls[key].markAsTouched()
      })
      return
    }
    const formValues = this.loadedSettingForm.value
    const formattedString = `${this.loadedSettingArrayData[this.currentIndex].values.value1};${this.loadedSettingArrayData[this.currentIndex].values.value2};${this.loadedSettingArrayData[this.currentIndex].values.value3};${this.loadedSettingArrayData[this.currentIndex].values.value4};${formValues.voltagesR};${formValues.currentR};${formValues.gainR};${formValues.angel1R};${formValues.angel2R};${formValues.awgR};${formValues.avagR};${formValues.avargR};${formValues.voltagesY};${formValues.currentY};${formValues.gainY};${formValues.angel1Y};${formValues.angel2Y};${formValues.awgY};${formValues.avagY};${formValues.avargY};${formValues.voltagesB};${formValues.currentB};${formValues.gainB};${formValues.angel1B};${formValues.angel2B};${formValues.awgB};${formValues.avagB};${formValues.avaegB};${this.loadedSettingArrayData[this.currentIndex].values.value27};
    ${this.loadedSettingArrayData[this.currentIndex].values.value28};${this.loadedSettingArrayData[this.currentIndex].values.value29};${this.loadedSettingArrayData[this.currentIndex].values.value30};${this.loadedSettingArrayData[this.currentIndex].values.value31};${this.loadedSettingArrayData[this.currentIndex].values.value32};${this.loadedSettingArrayData[this.currentIndex].values.value33};${this.loadedSettingArrayData[this.currentIndex].values.value34};${this.loadedSettingArrayData[this.currentIndex].values.value35};${this.loadedSettingArrayData[this.currentIndex].values.value36};${this.loadedSettingArrayData[this.currentIndex].values.value37};${this.loadedSettingArrayData[this.currentIndex].values.value38};${this.loadedSettingArrayData[this.currentIndex].values.value39};${this.loadedSettingArrayData[this.currentIndex].values.value40};${this.loadedSettingArrayData[this.currentIndex].values.value41};${this.loadedSettingArrayData[this.currentIndex].values.value42};${this.loadedSettingArrayData[this.currentIndex].values.value43};${this.loadedSettingArrayData[this.currentIndex].values.value44};
    ${this.loadedSettingArrayData[this.currentIndex].values.value45};${this.loadedSettingArrayData[this.currentIndex].values.value46};${this.loadedSettingArrayData[this.currentIndex].values.value47};${this.loadedSettingArrayData[this.currentIndex].values.value48};${this.loadedSettingArrayData[this.currentIndex].values.value49};${this.loadedSettingArrayData[this.currentIndex].values.value50};${this.loadedSettingArrayData[this.currentIndex].values.value51};${this.loadedSettingArrayData[this.currentIndex].values.value52};${this.loadedSettingArrayData[this.currentIndex].values.value53};${this.loadedSettingArrayData[this.currentIndex].values.value54};`

    const param: any = {
      frameData: formattedString,
      deviceId: this.deviceId,
    }

    this.spinner.show()
    this.store.dispatch(new CreateLoadedSetting(param)).subscribe(
      (res) => {
        this.spinner.hide()
        if (res.officeSettings.isSaved) {
          this.toastr.success(NotificationMessage.loadedSettingMsg)
          this.commonService.onDialogClose(this.dialogRef, true)
        } else {
          this.toastr.error('something wrong!')
        }
      },
      (error) => {
        this.spinner.hide()
      },
    )
  }

  readFromDevice() {
    this.spinner.show()
    this.officeUseChangesService.readDataFromDevice(this.deviceId).subscribe(
      (res: any) => {
        this.spinner.hide()
        if (res) {
          this.toastr.success(NotificationMessage.readDataSuccessMsg)
        } else {
          this.toastr.error(NotificationMessage.readDataErrorMsg)
        }
      },
      (error) => {
        this.spinner.hide()
      },
    )
  }

  closePopup(): void {
    this.commonService.onDialogClose(this.dialogRef, false)
  }
}
