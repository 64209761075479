import { DatePipe } from '@angular/common'
import { Component, OnInit, ViewChild } from '@angular/core'
import {
  CommonGridComponent,
  DataTypeEnum,
  ElectricType,
  ElectricTypeInfo,
  GetSettingHistoryTypeList,
} from '@app/index'
import { Store } from '@ngxs/store'
import { GridDataResult } from '@progress/kendo-angular-grid'
import { NgxSpinnerService } from 'ngx-spinner'
import { Subject } from 'rxjs'

@Component({
  selector: 'app-setting-history',
  templateUrl: './setting-history.component.html',
  styleUrl: './setting-history.component.scss',
})
export class SettingHistoryComponent implements OnInit {
  triggerGetUpdatedTime: Subject<any> = new Subject<any>()
  triggerToClearAllFilter: Subject<any> = new Subject<any>()
  @ViewChild(CommonGridComponent) commonGridComponent:
    | CommonGridComponent
    | undefined

  electricType = ElectricType
  infoTitle = ElectricTypeInfo.Voltage
  selectedElectricType: any = ElectricType['Voltage']

  gridView: GridDataResult | any
  columns: any[] = []
  currentView: any[] = []

  //#region GridData region

  voltageData = [
    {
      minVoltage: {
        minVoltagesR: 165,
        minVoltagesY: 165,
        minVoltagesB: 165,
      },
      maxVoltage: {
        maxVoltagesR: 270,
        maxVoltagesY: 270,
        maxVoltagesB: 270,
      },
      timestamp: '10:49:53 04-11-2022',
    },
    {
      minVoltage: {
        minVoltagesR: 165,
        minVoltagesY: 165,
        minVoltagesB: 165,
      },
      maxVoltage: {
        maxVoltagesR: 270,
        maxVoltagesY: 270,
        maxVoltagesB: 270,
      },
      timestamp: '10:49:53 04-11-2022',
    },
  ]

  voltageColumn = [
    {
      field: 'minVoltage',
      title: 'Min Voltages(Volts)',
      isSelected: true,
      isLocked: false,
      hasColumn: [
        {
          field: 'minVoltagesR',
          title: 'R',
          isSelected: true,
          isLocked: false,
        },
        {
          field: 'minVoltagesY',
          title: 'Y',
          isSelected: true,
          isLocked: false,
        },
        {
          field: 'minVoltagesB',
          title: 'B',
          isSelected: true,
          isLocked: false,
        },
      ],
    },
    {
      field: 'maxVoltage',
      title: 'Max Voltages(Volts)',
      isSelected: true,
      isLocked: false,
      hasColumn: [
        {
          field: 'maxVoltagesR',
          title: 'R',
          isSelected: true,
          isLocked: false,
        },
        {
          field: 'maxVoltagesY',
          title: 'Y',
          isSelected: true,
          isLocked: false,
        },
        {
          field: 'maxVoltagesB',
          title: 'B',
          isSelected: true,
          isLocked: false,
        },
      ],
    },
    {
      field: 'dateTime',
      title: 'Date & Time',
      hasColumn: [],
      isSelected: true,
      isLocked: false,
      dataTypeId: DataTypeEnum.DateTime,
    },
  ]

  currentData = [
    {
      current: {
        currentR: 165,
        currentY: 165,
        currentB: 165,
      },
      timestamp: '10:49:53 04-11-2022',
    },
  ]

  currentColumn = [
    {
      field: 'maxCurrent',
      title: 'Current (A) Settings',
      isSelected: true,
      isLocked: false,
      hasColumn: [
        { field: 'maxCurrentR', title: 'R', isSelected: true, isLocked: false },
        { field: 'maxCurrentY', title: 'Y', isSelected: true, isLocked: false },
        { field: 'maxCurrentB', title: 'B', isSelected: true, isLocked: false },
      ],
    },
    {
      field: 'dateTime',
      title: 'Date & Time',
      hasColumn: [],
      isSelected: true,
      isLocked: false,
      dataTypeId: DataTypeEnum.DateTime,
    },
  ]

  ctData = [
    {
      ctRatio: 165,
      timestamp: '10:49:53 04-11-2022',
    },
    {
      ctRatio: 165,
      timestamp: '10:49:53 04-11-2022',
    },
  ]

  ctColumn = [
    {
      field: 'ct',
      title: 'CT Ratio',
      hasColumn: [],
      isSelected: true,
      isLocked: false,
    },
    {
      field: 'dateTime',
      title: 'Date & Time',
      hasColumn: [],
      isSelected: true,
      isLocked: false,
      dataTypeId: DataTypeEnum.DateTime,
    },
  ]

  kvaData = [
    {
      kva: 165,
      timestamp: '10:49:53 04-11-2022',
    },
    {
      kva: 165,
      timestamp: '10:49:53 04-11-2022',
    },
  ]

  kvaColumn = [
    {
      field: 'kva',
      title: 'KVA',
      hasColumn: [],
      isSelected: true,
      isLocked: false,
    },
    {
      field: 'dateTime',
      title: 'Date & Time',
      hasColumn: [],
      isSelected: true,
      isLocked: false,
      dataTypeId: DataTypeEnum.DateTime,
    },
  ]

  pfData = [
    {
      pf: 165,
      timestamp: '10:49:53 04-11-2022',
    },
    {
      pf: 165,
      timestamp: '10:49:53 04-11-2022',
    },
  ]

  pfColumn = [
    {
      field: 'pf',
      title: 'PF',
      hasColumn: [],
      isSelected: true,
      isLocked: false,
    },
    {
      field: 'dateTime',
      title: 'Date & Time',
      hasColumn: [],
      isSelected: true,
      isLocked: false,
      dataTypeId: DataTypeEnum.DateTime,
    },
  ]

  unityPFData = [
    {
      unityPF: 'Disabled',
      timestamp: '10:49:53 04-11-2022',
    },
    {
      unityPF: 'Disabled',
      timestamp: '10:49:53 04-11-2022',
    },
  ]

  unityPFColumn = [
    {
      field: 'unityPF',
      title: 'UnitY PF',
      hasColumn: [],
      isSelected: true,
      isLocked: false,
    },
    {
      field: 'dateTime',
      title: 'Date & Time',
      hasColumn: [],
      isSelected: true,
      isLocked: false,
      dataTypeId: DataTypeEnum.DateTime,
    },
  ]

  tempSettingLogsData = [
    {
      temp: 50,
      timestamp: '10:49:53 04-11-2022',
    },
    {
      temp: 50,
      timestamp: '10:49:53 04-11-2022',
    },
  ]

  tempSettingLogsColumns = [
    {
      field: 'temp',
      title: 'Temp(℃)',
      hasColumn: [],
      isSelected: true,
      isLocked: false,
    },
    {
      field: 'dateTime',
      title: 'Date & Time',
      hasColumn: [],
      isSelected: true,
      isLocked: false,
      dataTypeId: DataTypeEnum.DateTime,
    },
  ]

  //#endregion

  //#region  SettingHistoryTypeParams
  feederAreaId: any
  deviceId: any
  date: any
  pageNumber: any = 1
  pageSize: any = 10
  sort: any
  sortOrder = false
  skip = 0
  previousSort: any = null
  filterQuery: string | any
  //#endregion

  constructor(
    private store: Store,
    private spinner: NgxSpinnerService,
    private datePipe: DatePipe,
  ) {}

  ngOnInit(): void {
    this.columns = this.voltageColumn
  }

  setInfoMessage(): void {
    switch (this.selectedElectricType) {
      case ElectricType['Voltage']:
        this.infoTitle = ElectricTypeInfo.Voltage
        break
      case ElectricType['Current']:
        this.infoTitle = ElectricTypeInfo.Current
        break
      case ElectricType['CT']:
        this.infoTitle = ElectricTypeInfo.CT
        break
      case ElectricType['KVA']:
        this.infoTitle = ElectricTypeInfo.KVA
        break
      case ElectricType['PF']:
        ElectricTypeInfo.PF

        break
      case ElectricType['Unity PF']:
        ElectricTypeInfo.UnityPF

        break
      case ElectricType['Temp Settings Logs']:
        this.infoTitle = ElectricTypeInfo.TempSettingsLogs

        break
    }
  }

  getSettingHistoryTypeList(isFilterClear: boolean = false) {
    if (isFilterClear) {
      this.filterQuery = null
      this.triggerToClearAllFilter.next(true)
    }
    this.setInfoMessage()
    const selectedDate = this.date
      ? this.datePipe.transform(
          new Date(this.date),
          "yyyy-MM-ddTHH:mm:ss.SSS'Z'",
        )
      : null
    const params = {
      deviceId: this.deviceId === undefined ? null : this.deviceId,
      feederAreaId: this.feederAreaId === undefined ? null : this.feederAreaId,
      pageNumber: this.pageNumber === undefined ? null : this.pageNumber,
      pageSize: this.pageSize === undefined ? null : this.pageSize,
      filter: 0,
      sortBy: this.sort === undefined ? null : this.sort,
      sortOrder: this.sortOrder,
      search: null,
      startDate: selectedDate,
      endDate: selectedDate,
      filterQuery: this.filterQuery,
    }

    this.spinner.show()
    this.store
      .dispatch(
        new GetSettingHistoryTypeList(this.selectedElectricType, params),
      )
      .subscribe(
        (res) => {
          this.spinner.hide()
          const response = res.historySetting.settingHistoryTypeList

          this.columns = response.data.columns
          switch (this.selectedElectricType) {
            case ElectricType['Voltage']:
              this.gridView = {
                data: response.data.rows[0].voltage,
                total: response.totalCount,
                columns: this.columns,
              }

              break
            case ElectricType['Current']:
              this.gridView = {
                data: response.data.rows[0].current,
                total: response.totalCount,
                columns: this.columns,
              }

              break
            case ElectricType['CT']:
              this.gridView = {
                data: response.data.rows[0].ct,
                total: response.totalCount,
                columns: this.columns,
              }

              break
            case ElectricType['KVA']:
              this.gridView = {
                data: response.data.rows[0].kva,
                total: response.totalCount,
                columns: this.columns,
              }

              break
            case ElectricType['PF']:
              this.gridView = {
                data: response.data.rows[0].pf,
                total: response.totalCount,
                columns: this.columns,
              }
              break
            case ElectricType['Unity PF']:
              this.gridView = {
                data: response.data.rows[0].unityPF,
                total: response.totalCount,
                columns: this.columns,
              }
              break
            case ElectricType['Temp Settings Logs']:
              this.gridView = {
                data: response.data.rows[0].tempLogs,
                total: response.totalCount,
                columns: this.columns,
              }
              break
          }
        },
        (error) => {
          this.spinner.hide()
        },
      )
  }

  getDataByHeaderData(event: any) {
    this.feederAreaId = event.feederAreaId
    this.deviceId = event.deviceId
    this.date = event.date

    this.getSettingHistoryTypeList()
    this.triggerGetUpdatedTime.next(true)
  }

  onColumnSelector(): void {
    this.commonGridComponent?.onColumnSelector()
  }

  onSortChange(sortData: any) {
    const currentSortField = sortData.sort[0].field
    if (this.previousSort === currentSortField) {
      this.sortOrder = !this.sortOrder
    } else {
      this.sortOrder = true
    }
    this.sort = currentSortField
    this.previousSort = currentSortField
    this.getSettingHistoryTypeList()
  }

  onPaginationChange({ skip, take }: any) {
    this.skip = skip
    this.pageSize = take
    this.pageNumber = this.skip / this.pageSize + 1
    this.getSettingHistoryTypeList()
  }

  onFilterChangeSQL(sqlQuery: any) {
    this.filterQuery = sqlQuery
    this.getSettingHistoryTypeList()
  }
}
