<kendo-dialog-titlebar (close)="onCloseDialog()">
  <span>Customize Columns</span>
</kendo-dialog-titlebar>

<div class="mt-10">
  <kendo-textbox
    placeholder="Search"
    type="text"
    class="mb-20"
    [(ngModel)]="searchText"
    (ngModelChange)="onSearchChange($event)"
  ></kendo-textbox>

  <div
    cdkDropList
    class="example-list mt-20 mb-20"
    [cdkDropListData]="filterColumnsData"
    (cdkDropListDropped)="drop($event)"
  >
    <ng-container *ngFor="let col of filterColumnsData; index as i">
      <div class="example-box d-flex align-items-center gap-10" cdkDrag>
        <div class="drag-icon">
          <i
            *ngIf="col.isLocked"
            class="fas fa-grip-dots-vertical font-size-16"
          ></i>
          <i
            *ngIf="!col.isLocked"
            class="fas fa-grip-dots-vertical font-size-16"
            cdkDragHandle
          ></i>
        </div>
        <div class="drag-content d-flex align-items-center gap-5">
          <input
            type="checkbox"
            [checked]="col.isSelected"
            (change)="onCheckboxChange(i)"
            kendoCheckBox
          />
          <span class="font-size-14 ml-5 column-title">{{ col.name }}</span>
        </div>
      </div>

      <!-- Nested columns -->
      <div
        *ngIf="col.hasColumn.length"
        cdkDropList
        class="nested-list ml-20"
        [cdkDropListData]="col.hasColumn"
        (cdkDropListDropped)="drop($event)"
      >
        <div
          class="nested-box d-flex align-items-center gap-10"
          *ngFor="let nestedCol of col.hasColumn; index as nestedIndex"
          cdkDrag
        >
          <div class="drag-icon">
            <i
              *ngIf="nestedCol.isLocked"
              class="fas fa-grip-dots-vertical font-size-16"
            ></i>
            <i
              *ngIf="!nestedCol.isLocked"
              class="fas fa-grip-dots-vertical font-size-16"
              cdkDragHandle
            ></i>
          </div>
          <div
            class="drag-content d-flex align-items-center gap-5 nested-column"
          >
            <input
              type="checkbox"
              [checked]="nestedCol.isSelected"
              (change)="onCheckboxChange(i, nestedIndex)"
              kendoCheckBox
            />
            <span class="font-size-14 ml-5 column-title">{{
              nestedCol.name
            }}</span>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>

<kendo-dialog-actions>
  <button kendoButton (click)="onCloseDialog()" fillMode="outline">
    Cancel
  </button>
  <div class="display-inline flex-0">
    <button
      kendoButton
      [disabled]="this.filterColumnsData.length === 0"
      (click)="onSave()"
    >
      Save
    </button>
  </div>
</kendo-dialog-actions>
