import { Component, Input, OnInit } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { CommonService } from '@app/services'

@Component({
  selector: 'app-max-voltage',
  templateUrl: './max-voltage.component.html',
  styleUrl: './max-voltage.component.scss',
})
export class MaxVoltageComponent implements OnInit {
  maxVoltageForm: FormGroup | any
  @Input()
  triggerMaxVoltageData: any

  constructor(public commonService: CommonService) {}

  ngOnInit(): void {
    this.setForm()
    this.triggerMaxVoltageData.subscribe((res: any) => {
      if (res) {
        this.setData(res)
      } else {
        this.setForm()
      }
    })
  }

  setData(data: any): void {
    this.maxVoltageForm.patchValue({
      rPhase: data.maxRPhase ?? '',
      yPhase: data.maxYPhase ?? '',
      bPhase: data.maxBPhase ?? '',
    })
  }

  setForm(): void {
    this.maxVoltageForm = new FormGroup({
      rPhase: new FormControl('', [
        Validators.required,
        Validators.pattern('^[0-9]+(.[0-9]+)?$'),
      ]),
      yPhase: new FormControl('', [
        Validators.required,
        Validators.pattern('^[0-9]+(.[0-9]+)?$'),
      ]),
      bPhase: new FormControl('', [
        Validators.required,
        Validators.pattern('^[0-9]+(.[0-9]+)?$'),
      ]),
    })
  }
}
