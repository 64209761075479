import { Component, Input, OnInit } from '@angular/core'
import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
} from '@angular/cdk/drag-drop'
import { DialogRef } from '@progress/kendo-angular-dialog'
import { CommonService } from '@app/services'
import { Store } from '@ngxs/store'
import { SaveUserModuleGridColumn } from '@app/store'
import { NgxSpinnerService } from 'ngx-spinner'
import { NotificationMessage } from '@app/enums/notification'
import { ToastrService } from 'ngx-toastr'

@Component({
  selector: 'app-common-column-selector',
  templateUrl: './common-column-selector.component.html',
  styleUrls: ['./common-column-selector.component.scss'],
})
export class CommonColumnSelectorComponent implements OnInit {
  @Input() columnsData: any
  searchText: string = ''
  filterColumnsData: any[] = []
  tempColumnsData: any[] = []
  constructor(
    public dialogRef: DialogRef,
    public commonService: CommonService,
    private store: Store,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
  ) {}

  ngOnInit(): void {
    this.filterColumnsData = JSON.parse(JSON.stringify(this.columnsData))
    this.tempColumnsData = this.filterColumnsData
  }

  drop(event: CdkDragDrop<any[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      )
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      )
    }
    this.updateDisplayOrder()
  }

  updateDisplayOrder() {
    this.filterColumnsData.forEach((column: any, parentIndex: number) => {
      column.displayOrder = parentIndex + 1
      if (column.hasColumn && column.hasColumn.length) {
        column.hasColumn.forEach((childColumn: any, childIndex: number) => {
          childColumn.displayOrder = childIndex + 1
        })
      }
    })
  }

  onCloseDialog(): void {
    this.commonService.onDialogClose(this.dialogRef, false)
  }

  onCheckboxChange(parentIndex: number, nestedIndex: any = null) {
    if (nestedIndex === null) {
      // Handle parent checkbox change
      if (!this.filterColumnsData[parentIndex].isLocked) {
        this.filterColumnsData[parentIndex].isSelected =
          !this.filterColumnsData[parentIndex].isSelected

        // If parent is selected, select all child columns
        this.filterColumnsData[parentIndex].hasColumn.forEach(
          (childCol: any) => {
            if (!childCol.isLocked) {
              childCol.isSelected =
                this.filterColumnsData[parentIndex].isSelected
            }
          },
        )
      }
    } else {
      // Handle nested column checkbox change
      const parentColumn = this.filterColumnsData[parentIndex]
      const nestedColumn = parentColumn.hasColumn[nestedIndex]

      if (!nestedColumn.isLocked) {
        nestedColumn.isSelected = !nestedColumn.isSelected

        // Check if any child column is selected
        const anyChildSelected = parentColumn.hasColumn.some(
          (childCol: any) => childCol.isSelected,
        )

        // Update parent selection based on child selection
        parentColumn.isSelected = anyChildSelected
      }
    }
  }

  onSearchChange(event: any) {
    this.searchText = event.toLowerCase()
    if (this.searchText === '') {
      // Reset to original data if search text is empty
      this.filterColumnsData = JSON.parse(JSON.stringify(this.tempColumnsData))
    } else {
      // Filter based on search text
      this.filterColumnsData = this.tempColumnsData
        .map((col) => {
          const isParentMatch = col.name.toLowerCase().includes(this.searchText)
          const filteredChildColumns = col.hasColumn.filter(
            (childCol: { name: string }) =>
              childCol.name.toLowerCase().includes(this.searchText),
          )
          return {
            ...col,
            hasColumn: filteredChildColumns,
            displayOrder: col.displayOrder,
            isSelected: col.isSelected,
            isLocked: col.isLocked,
          }
        })
        .filter(
          (col) =>
            col.hasColumn.length > 0 ||
            col.name.toLowerCase().includes(this.searchText),
        )
    }
  }

  onSave(): void {
    this.spinner.show()
    this.store
      .dispatch(new SaveUserModuleGridColumn(this.filterColumnsData))
      .subscribe(
        (res: any) => {
          this.toastr.success(NotificationMessage.columnUpdateSuccessMsg)
          this.spinner.hide()
          this.commonService.onDialogClose(this.dialogRef, true)
        },
        (error) => {
          this.spinner.hide()
        },
      )
  }
}
