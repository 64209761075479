export class Dashboard {
  static readonly type = '[Dashboard] Fetch Dashboard Data'

  constructor(public deviceId?: string) {}
}

export class GetUpdatedTime {
  static readonly type = '[Dashboard] Get Updated Time'

  constructor(public deviceId?: string) {}
}
