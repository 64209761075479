import { Component, OnInit } from '@angular/core'
import { NotificationMessage } from '@app/enums/notification'
import {
  ActionTypeEnum,
  AddSubstationComponent,
  LinkFeederWithSubstationComponent,
} from '@app/index'
import {
  CommonState,
  DeleteSubstation,
  GetDeviceIdBySubstation,
  GetSubstationAreaList,
  GetSubstationList,
} from '@app/store'
import { Select, Store } from '@ngxs/store'
import { DialogService } from '@progress/kendo-angular-dialog'
import {
  GridDataResult,
  MultipleSortSettings,
  PagerPosition,
  PagerType,
} from '@progress/kendo-angular-grid'
import { CompositeFilterDescriptor } from '@progress/kendo-data-query'
import { NgxSpinnerService } from 'ngx-spinner'
import { ToastrService } from 'ngx-toastr'
import { Observable, Subject, switchMap } from 'rxjs'
@Component({
  selector: 'app-substation',
  templateUrl: './substation.component.html',
  styleUrl: './substation.component.scss',
})
export class SubstationComponent implements OnInit {
  triggerGetUpdatedTime: Subject<any> = new Subject<any>()
  substationAreaList: any[] = []
  substationAreaId?: any
  deviceList: any[] = []

  gridHeight: number = 0
  pagerposition: PagerPosition = 'bottom'
  skip = 0
  sorting = null
  type: PagerType = 'numeric'
  sort: any
  previousSort: any = null
  pageNumber = 1
  pageSize = 10

  isAllSelected = false
  sortOrder = false
  isDataLoaded = false
  isPaginationAllowed: boolean = true
  buttonCount = 5
  info = true
  pageSizes = true
  previousNext = true
  multiple = false
  allowUnsort = true
  isSortingAllowed: boolean = true
  columns = [
    {
      field: 'substationId',
      name: 'Substation Id',
      hasColumn: [],
      isSelected: true,
      isLocked: false,
    },
    {
      field: 'substationName',
      name: 'Substation name',
      hasColumn: [],
      isSelected: true,
      isLocked: false,
    },
  ]
  filterQuery: string | any
  sortSettings: MultipleSortSettings = {
    mode: 'multiple',
    initialDirection: 'desc',
    allowUnsort: true,
    showIndexes: true,
  }

  public filter: CompositeFilterDescriptor = {
    logic: 'and',
    filters: [],
  }

  @Select(CommonState.moduleId)
  moduleId$!: Observable<number>

  gridView: GridDataResult | any
  actions: any[] = [
    {
      title: 'Edit',
      icon: 'far fa-pen',
      actionTypeId: ActionTypeEnum.Edit,
    },
    {
      title: 'Delete',
      icon: 'far fa-trash-alt',
      actionTypeId: ActionTypeEnum.Delete,
    },
    {
      title: 'Assign Feeders',
      icon: 'far fa-eye',
      actionTypeId: ActionTypeEnum.View,
    },
  ]

  @Select(CommonState.hasAddPermission)
  hasAddPermission$!: Observable<boolean>

  constructor(
    private store: Store,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private dialogService: DialogService,
  ) {}

  ngOnInit(): void {
    setTimeout(() => {
      this.triggerGetUpdatedTime.next(true)
    }, 0)
    this.getSubstationList()
    this.calculateGridHeight()

    window.addEventListener('resize', () => {
      this.calculateGridHeight()
    })
  }

  onDeleteSubstation(event: any): void {
    this.spinner.show()
    this.store.dispatch(new DeleteSubstation(event.substationId)).subscribe(
      (res: any) => {
        this.spinner.hide()
        if (res.substation.isSubstationDeleted) {
          this.toastr.success(NotificationMessage.deleteSubstationSuccessMsg)
          this.getSubstationList()
        }
      },
      (error) => {
        this.spinner.hide()
      },
    )
  }

  onFilterChangeSQL(sqlQuery: any) {
    this.filterQuery = sqlQuery
    this.getSubstationList()
  }

  onActionClick(event: any): void {
    if (event.actionTypeId === ActionTypeEnum.Edit) {
      this.onAddSubstation(false, event)
    } else if (event.actionTypeId === ActionTypeEnum.Delete) {
      this.onDeleteSubstation(event.data)
    } else if (event.actionTypeId === ActionTypeEnum.View) {
      this.onViewDeviceList(event.data)
    }
  }

  onPaginationChange({ skip, take }: any) {
    this.skip = skip
    this.pageSize = take
    this.pageNumber = this.skip / this.pageSize + 1

    this.getSubstationList()
  }

  onSortChange(sortData: any): void {
    const currentSortField = sortData.sort[0].field
    if (this.previousSort === currentSortField) {
      this.sortOrder = !this.sortOrder
    } else {
      this.sortOrder = true
    }
    this.sort = currentSortField
    this.previousSort = currentSortField
    this.getSubstationList()
  }

  calculateGridHeight(): void {
    const screenHeight = window.innerHeight
    const headerHeight = 81
    const gridHeaderHeight = 160
    const wrapperpadding = 20

    this.gridHeight =
      screenHeight - (headerHeight + gridHeaderHeight + wrapperpadding)
  }

  getSubstationList() {
    this.spinner.show()
    const param = {
      pageNumber: this.pageNumber,
      pageSize: this.pageSize,
      sortBy: this.sort,
      sortOrder: this.sortOrder,
      filterQuery: this.filterQuery,
      moduleId: this.store.selectSnapshot(CommonState.moduleId),
    }
    this.spinner.show()
    this.store.dispatch(new GetSubstationList(param)).subscribe((res) => {
      this.spinner.hide()
      const { data, totalCount } = res.substation.substationList
      this.gridView = {
        data: data.rows,
        total: totalCount,
        columns: data.columns,
      }
    })
  }

  onViewDeviceList(data: any): void {
    const dialogRef = this.dialogService.open({
      content: LinkFeederWithSubstationComponent,
      width: 800,
    })
    const component: any = dialogRef.content
      .instance as LinkFeederWithSubstationComponent

    component.substationId = data.substationId
    component.substationName = data.substationName

    dialogRef.result.subscribe((res: any) => {
      if (res && res.confirmed) {
      }
    })
  }

  onAddSubstation(isAddMode: boolean, event?: any): void {
    const dialogRef = this.dialogService.open({
      content: AddSubstationComponent,
      width: 450,
    })

    const component: any = dialogRef.content.instance as AddSubstationComponent
    component.substationId = isAddMode ? 0 : event.data.substationId
    component.substationData =
      event !== null && event !== undefined ? event.data : null
    component.isAddMode = isAddMode
    dialogRef.result.subscribe((res: any) => {
      if (res && res.confirmed) {
        this.store
          .dispatch(new GetSubstationAreaList())
          .pipe(
            switchMap((res: any) =>
              this.store.dispatch(new GetDeviceIdBySubstation()),
            ),
          )
          .subscribe()
        this.getSubstationList()
      }
    })
  }
}
