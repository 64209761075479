export enum DashboardDTRStatus {
  ActiveDevice = 1,
  PowerFail = 2,
  SignalLossDevice = 3,
  Overload100Device = 4,
  Overload80_100Device = 5,
  Load_100_R = 6,
  Load_80_100_R = 7,
  Load_100_Y = 8,
  Load_80_100_Y = 9,
  Load_100_B = 10,
  Load_80_100_B = 11,
  LowVolatageR = 12,
  LowVolatageY = 13,
  LowVolatageB = 14,
  HighVolatageR = 15,
  HighVolatageY = 16,
  HighVolatageB = 17,
}
