export class GetDTRPredictionDetail {
  static readonly type = '[Dtrprediction] Fetch ITMS Data'

  constructor(public deviceId: string) {}
}

export class GetGraphDetails {
  static readonly type = '[Dtrprediction] Get Graph Data'

  constructor(public deviceId: any) {}
}
