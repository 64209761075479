<div class="q-content">
  <div class="d-flex gap-20 flex-wrap justify-content-end">
    <app-common-header
      (triggerDataFromHeader)="getDataByHeaderData($event)"
    ></app-common-header>
    <div class="q-content-bg grid-wrapper">
      <app-common-grid
        [gridView]="gridView"
        [columns]="columns"
        (sortChange)="onSortChange($event)"
        (paginationChange)="onPaginationChange($event)"
        id="data-report"
      ></app-common-grid>
    </div>
  </div>
</div>
