import { Component, ViewChild } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { Router } from '@angular/router'
import { CommonService } from '@app/services'
import {
  CommonState,
  GetDeviceIdByFeeder,
  GetFeederAreaList,
  GetLoginToken,
  GetMenu,
} from '@app/store'
import { Store } from '@ngxs/store'
import { NgxSpinnerService } from 'ngx-spinner'
import { ForgotPasswordComponent } from '../forgot-password/forgot-password.component'
import { DialogService } from '@progress/kendo-angular-dialog'
import { ToastrService } from 'ngx-toastr'
import { NotificationMessage } from '@app/enums/notification'

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss',
})
export class LoginComponent {
  passwordVisible = false
  @ViewChild(ForgotPasswordComponent) forgotPasswordComponent:
    | ForgotPasswordComponent
    | undefined
  public loginForm: FormGroup = new FormGroup({
    username: new FormControl('', Validators.required),
    password: new FormControl('', Validators.required),
  })
  menuList: any[] = []
  constructor(
    private store: Store,
    public commonService: CommonService,
    public router: Router,
    private spinner: NgxSpinnerService,
    private dialogService: DialogService,
    private toastr: ToastrService,
  ) {}

  togglePasswordVisibility(): void {
    this.passwordVisible = !this.passwordVisible
  }

  getIsLogged(): boolean {
    return localStorage.getItem('loginToken') ? true : false
  }

  loginUser(): void {
    if (this.loginForm.invalid) {
      this.loginForm.markAllAsTouched()

      return
    }

    const param = {
      userName: this.loginForm.controls['username'].value,
      password: this.loginForm.controls['password'].value,
    }
    this.spinner.show()
    this.store
      .dispatch(new GetLoginToken(param.userName, param.password))
      .subscribe(
        (res: any) => {
          localStorage.setItem('loginToken', res.common.loginToken.accessToken)
          localStorage.setItem(
            'refreshLoginToken',
            res.common.loginToken.refreshToken,
          )

          const jwtBase64 = res.common.loginToken.accessToken!.split('.')[1]
          const jwtToken = JSON.parse(atob(jwtBase64))
          localStorage.setItem('userDetails', JSON.stringify(jwtToken))
          this.redirection()
        },
        (error) => {
          this.spinner.hide()
        },
      )
  }

  redirection(): void {
    this.store.dispatch(new GetMenu()).subscribe(
      (res: any) => {
        this.spinner.hide()
        if (res.common.Menu.length > 0) {
          localStorage.setItem('menuList', JSON.stringify(res.common.Menu))
          this.menuList = res.common.Menu
          let routingPath = this.navigateToRouteOrSubMenu(this.menuList[0])
          window.location.href = routingPath
        }
      },
      (error) => {
        this.spinner.hide()
      },
    )
  }

  navigateToRouteOrSubMenu(menuItem: any): any {
    let routingPath = ''
    if (menuItem.path) {
      routingPath = menuItem.path
      return routingPath
    } else if (menuItem.hasChildren) {
      const subMenuItems = this.menuList.filter(
        (item) => item.parentId === menuItem.id,
      )
      return this.navigateToRouteOrSubMenu(subMenuItems[0])
    }
  }

  openForgotPasswordPopup() {
    const dialogRef = this.dialogService.open({
      content: ForgotPasswordComponent,
      width: 450,
    })
  }

  showSignupMessage(): void {
    this.toastr.warning(NotificationMessage.signupMessage)
  }
}
