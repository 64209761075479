import { Component, OnInit, ViewChild } from '@angular/core'
import { Router } from '@angular/router'
import { Select, Store } from '@ngxs/store'
import {
  CommonState,
  DeleteDeviceByDeviceId,
  GetDeviceIdByFeeder,
  GetDeviceList,
  GetFeederAreaList,
  SendOtp,
} from '@app/store'
import { ActionTypeEnum } from '@app/enums/action-type-enum'
import { NgxSpinnerService } from 'ngx-spinner'
import { CommonService } from '@app/services'
import { EMPTY, Observable, catchError, finalize, switchMap } from 'rxjs'
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog'
import {
  AddDeviceListComponent,
  AddInstalledDateComponent,
  CommonGridComponent,
  OtpDialogComponent,
} from '@app/index'
import { GridDataResult } from '@progress/kendo-angular-grid'
import { ToastrService } from 'ngx-toastr'
import { NotificationMessage } from '@app/enums/notification'
import { Roles } from '@app/enums/roles'
import { AnyCatcher } from 'rxjs/internal/AnyCatcher'

@Component({
  selector: 'app-device-list',
  templateUrl: './device-list.component.html',
  styleUrl: './device-list.component.scss',
})
export class DeviceListComponent implements OnInit {
  @ViewChild(CommonGridComponent) commonGridComponent:
    | CommonGridComponent
    | undefined

  gridView: GridDataResult | any
  feederAreaId: any
  deviceId: any
  sort: any
  sortOrder = false
  pageNumber = 1
  pageSize = 10
  skip = 0
  startDate = null
  endDate = null
  previousSort: any = null
  isDataLoaded = false
  @Select(CommonState.hasAddPermission)
  hasAddPermission$!: Observable<boolean>

  @Select(CommonState.moduleId)
  moduleId$!: Observable<number>

  actions: any[] = [
    {
      title: 'Edit',
      icon: 'far fa-pen',
      actionTypeId: ActionTypeEnum.Edit,
    },
    {
      title: 'Delete',
      icon: 'far fa-trash-alt',
      actionTypeId: ActionTypeEnum.Delete,
    },
  ]

  // Now actions array will include the "add installed date" action if the condition is true

  filterQueryJson: string | any
  infoText: string = "Red Color shows the stopped DTR's"
  constructor(
    private router: Router,
    private store: Store,
    private spinner: NgxSpinnerService,
    private commonService: CommonService,
    private dialogService: DialogService,
    private toastr: ToastrService,
  ) {
    this.commonService.addInstallDateAction(this.actions)
  }

  ngOnInit(): void {}

  onFilterChangeSQL(sqlQuery: any) {
    this.filterQueryJson =
      this.commonService.convertFilterToDateFormat(sqlQuery)

    this.getDeviceList(
      this.deviceId,
      this.feederAreaId,
      this.startDate,
      this.endDate,
    )
  }

  getDeviceList(
    deviceId: any,
    feederAreaId: any,
    startDate: any,
    endDate: any,
  ): void {
    this.spinner.show()
    const param = {
      deviceId: deviceId,
      feederAreaId: feederAreaId,
      pageNumber: this.pageNumber,
      pageSize: this.pageSize,
      filter: 0,
      sortBy: this.sort ?? null,
      sortOrder: this.sortOrder,
      search: null,
      startDate: startDate,
      endDate: endDate,
      moduleId: this.store.selectSnapshot(CommonState.moduleId),
      filterQueryJson: this.filterQueryJson,
    }

    this.store.dispatch(new GetDeviceList(param)).subscribe(
      (res) => {
        this.spinner.hide()
        const { data, totalCount } = res.device.deviceListData

        this.gridView = {
          data: data.rows,
          total: totalCount,
          columns: data.columns,
        }

        this.isDataLoaded = true
      },
      (error) => {
        this.spinner.hide()
      },
    )
  }

  getDataFromColumnSelector(event: any) {
    this.getDeviceList(
      this.deviceId,
      this.feederAreaId,
      this.startDate,
      this.endDate,
    )
  }

  onColumnSelector(): void {
    this.commonGridComponent?.onColumnSelector()
  }

  onActionClick(event: any): void {
    if (event.actionTypeId === ActionTypeEnum.Edit) {
      this.onDeviceAdd(event.data.deviceId, false)
    } else if (event.actionTypeId === ActionTypeEnum.AddDate) {
      this.onInstalledDate(event.data)
    }
  }

  onInstalledDate(data: any) {
    const dialogRef: DialogRef = this.dialogService.open({
      title: 'Add Installation Date For-' + data.deviceName,
      content: AddInstalledDateComponent,
      width: 450,
    })
    const dialogInfo = dialogRef.content.instance as AddInstalledDateComponent
    dialogInfo.deviceData = data

    dialogRef.result.subscribe((res: any) => {
      if (res && res.confirmed) {
        this.getDeviceList(
          this.deviceId,
          this.feederAreaId,
          this.startDate,
          this.endDate,
        )
      }
    })
  }

  onDeviceAdd(id?: any, isAdd: boolean = true): void {
    if (isAdd) {
      const dialogRef: DialogRef = this.dialogService.open({
        title: 'Create a new Device',
        content: AddDeviceListComponent,
        width: 450,
      })
      const dialogInfo = dialogRef.content.instance as AddDeviceListComponent
      dialogInfo.isAddMode = isAdd
      dialogInfo.deviceId = id
      dialogInfo.setOnLoadData(isAdd)
      dialogRef.result.subscribe((result: any) => {
        if (result.isSave) {
          this.getDeviceList(
            this.deviceId,
            this.feederAreaId,
            this.startDate,
            this.endDate,
          )
        }
      })
    } else {
      const params = {
        isAddMode: btoa(isAdd.toString()),
        deviceId: btoa(id),
      }
      this.router.navigate(['add-Device', params])
    }
  }

  getDataByHeaderData(event: any) {
    this.deviceId = event.deviceId
    this.feederAreaId = event.feederAreaId
    this.startDate = event.startDate
    this.endDate = event.endDate
    this.getDeviceList(
      event.deviceId,
      event.feederAreaId,
      event.startDate,
      event.endDate,
    )
  }

  onVerifyDeleteOtp(data: any) {
    this.deviceId = data.deviceId
    const currentUserDetails = this.commonService.getLoggedInUserDetails()
    if (+currentUserDetails.RoleId !== Roles.SuperAdmin) {
      const param = {
        deviceId: this.deviceId,
      }
      this.store.dispatch(new SendOtp()).subscribe(
        (res) => {
          this.toastr.success(NotificationMessage.otpSendSuccessMsg)
          this.onOpenOtpVerificationDialog(ActionTypeEnum.Delete, param)
        },
        (error) => {
          this.spinner.hide()
        },
      )
    } else {
      this.onDeviceDelete(this.deviceId)
    }
  }

  onOpenOtpVerificationDialog(actionType: any, requestBodyParams: any): void {
    const dialogRef = this.dialogService.open({
      content: OtpDialogComponent,
      width: 450,
    })

    this.spinner.hide()
    const otpDialogInfo = dialogRef.content.instance as OtpDialogComponent
    otpDialogInfo.actionType = actionType
    otpDialogInfo.requestBodyParams = requestBodyParams

    dialogRef.result.subscribe((result: any) => {
      if (result.confirmed === true) {
        this.getDeviceList(
          this.deviceId,
          this.feederAreaId,
          this.startDate,
          this.endDate,
        )
      }
    })
  }

  onDeviceDelete(deviceId: any) {
    const params = {
      deviceId,
    }
    this.spinner.show()
    this.store.dispatch(new DeleteDeviceByDeviceId(params)).subscribe(
      (res) => {
        this.store
          .dispatch(new GetFeederAreaList())
          .pipe(
            switchMap(() => {
              this.spinner.show()
              return this.store.dispatch(new GetDeviceIdByFeeder())
            }),
            finalize(() => this.spinner.hide()),
            catchError((error) => {
              this.spinner.hide()
              console.error('Error during onDeviceDelete:', error)
              return EMPTY
            }),
          )
          .subscribe(() => {
            this.toastr.success(
              'Device ' + params.deviceId + ' Successfully Deleted',
            )
            this.getDeviceList(
              this.deviceId,
              this.feederAreaId,
              this.startDate,
              this.endDate,
            )
          })
      },
      (error) => {
        this.spinner.hide()
        if (error.error.Message) {
          console.error('Error during device deletion:', error)
        }
      },
    )
  }

  onSortChange(sortData: any) {
    const currentSortField = sortData.sort[0].field
    if (this.previousSort === currentSortField) {
      this.sortOrder = !this.sortOrder
    } else {
      this.sortOrder = true
    }
    this.sort = currentSortField
    this.previousSort = currentSortField
    this.getDeviceList(
      this.deviceId,
      this.feederAreaId,
      this.startDate,
      this.endDate,
    )
  }

  onPaginationChange({ skip, take }: any) {
    this.skip = skip
    this.pageSize = take
    this.pageNumber = this.skip / this.pageSize + 1

    this.getDeviceList(
      this.deviceId,
      this.feederAreaId,
      this.startDate,
      this.endDate,
    )
  }
}
