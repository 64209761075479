import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable, of } from 'rxjs'
import { environment } from 'src/environments'
import { SettingHistoryTypeModel } from '@app/models'

@Injectable()
export class SettingHistoryService {
  constructor(private http: HttpClient) {}

  getSettingHistoryTypeList(
    settingHistoryType: SettingHistoryTypeModel,
    settingHistoryRequestBody: any,
  ): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    }

    return this.http.post<any>(
      `${environment.apiVersionUrl}SettingHistory/GetAll/${settingHistoryType}`,
      JSON.stringify(settingHistoryRequestBody),
      headers,
    )
  }
}
