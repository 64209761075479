import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { SslddashboardComponent } from '@app/index'

const routes: Routes = [{ path: '', component: SslddashboardComponent }]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SSLDDashboardRoutingModule {}
