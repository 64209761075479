export class GetSubstationList {
  static readonly type = '[substation] Get Substation List'
  constructor(public queryParam: any) {}
}

export class CreateSubstation {
  static readonly type = '[substation] Create Substation'
  constructor(public param: any) {}
}

export class DeleteSubstation {
  static readonly type = '[substation] Delete Substation'
  constructor(public substationId: any) {}
}

export class CheckSubStationNameExist {
  static readonly type = '[Substation] Check Substation Name Exist'
  constructor(
    public subStationId: any,
    public substationName: string,
  ) {}
}

export class GetSubStationListWithFeeder {
  static readonly type = '[substation] Get SubStation List With Feeder'

  constructor(
    public queryParams: any,
    public substationId: any,
  ) {}
}

export class SaveSubStationFeeder {
  static readonly type = '[substation] Save SubStation Feeder'
  constructor(
    public feederIds: any,
    public subStationId: any,
  ) {}
}

export class ConfirmationFeederForSubstation {
  static readonly type = '[substation] Confirmation feeder For substation'
  constructor(public requestBody: any) {}
}
