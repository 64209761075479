<div class="q-content">
  <div
    class="d-flex gap-15 flex-wrap justify-content-between align-items-center mb-20"
  >
    <app-common-header-text
      [triggerGetUpdatedTime]="triggerGetUpdatedTime"
    ></app-common-header-text>
    <div class="d-flex gap-15 flex-wrap">
      <button
        kendoButton
        id="create"
        type="button"
        (click)="onCreateAccount()"
        *ngIf="hasAddPermission$ | async"
      >
        <i class="far fa-plus mr-3"></i> Create Account
      </button>
    </div>
  </div>
  <div class="q-content-bg grid-wrapper">
    <app-common-grid
      [gridView]="gridView"
      [columns]="gridView?.columns ?? []"
      [actions]="actions"
      [showActions]="true"
      [pageSize]="this.pageSize"
      [moduleId]="moduleId"
      (sortChange)="onSortChange($event)"
      (paginationChange)="onPaginationChange($event)"
      (triggerActionEvent)="onActionClick($event)"
      (deleteConfirmed)="onDeleteAccount($event)"
      (filterChangeSQL)="onFilterChangeSQL($event)"
    ></app-common-grid>
  </div>
  <div kendoDialogContainer></div>
</div>
