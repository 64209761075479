<div *ngIf="hasSelectedColumns()">
  <kendo-grid
    #grid
    [data]="gridView"
    [pageSize]="pageSize"
    [skip]="skip"
    [pageable]="
      gridView === undefined || !isPaginationAllowed
        ? false
        : {
            buttonCount: buttonCount,
            info: info,
            type: type,
            pageSizes: pageSizes,
            previousNext: previousNext,
            position: pagerposition
          }
    "
    [sortable]="isSortingAllowed"
    [sort]="sort"
    (sortChange)="onSortChange($event)"
    (pageChange)="onPageChange($event)"
    [height]="gridHeight"
    [resizable]="true"
    [reorderable]="true"
    filterable="menu"
    [filter]="filter"
    (filterChange)="filterChange($event)"
  >
    <ng-container *ngFor="let column of columns">
      <kendo-grid-column
        *ngIf="column.isSelected && !column.hasColumn.length"
        [field]="column.field"
        [title]="column.name"
        [width]="200"
        [maxResizableWidth]="300"
      >
        <ng-template
          kendoGridFilterMenuTemplate
          let-filter
          let-filterService="filterService"
        >
          <ng-container [ngSwitch]="column.dataType">
            <ng-container *ngSwitchCase="dataType.UpperCase">
              <kendo-grid-string-filter-menu
                [column]="column"
                [filter]="filter"
                [filterService]="filterService"
              >
                <kendo-filter-startswith-operator></kendo-filter-startswith-operator>
                <kendo-filter-eq-operator></kendo-filter-eq-operator>
              </kendo-grid-string-filter-menu>
            </ng-container>
            <ng-container *ngSwitchCase="dataType.DateTime">
              <kendo-grid-date-filter-menu
                [column]="column"
                [filter]="filter"
                [filterService]="filterService"
                format="dd/MM/yyyy"
              >
                <kendo-filter-eq-operator></kendo-filter-eq-operator>
                <kendo-filter-neq-operator></kendo-filter-neq-operator>
                <kendo-filter-gt-operator></kendo-filter-gt-operator>
                <kendo-filter-gte-operator></kendo-filter-gte-operator>
                <kendo-filter-lt-operator></kendo-filter-lt-operator>
                <kendo-filter-lte-operator></kendo-filter-lte-operator>
              </kendo-grid-date-filter-menu>
            </ng-container>
            <ng-container *ngSwitchCase="dataType.Float">
              <kendo-grid-numeric-filter-menu
                [column]="column"
                [filter]="filter"
                [filterService]="filterService"
              >
                <kendo-filter-eq-operator></kendo-filter-eq-operator>
                <kendo-filter-gt-operator></kendo-filter-gt-operator>
                <kendo-filter-lt-operator></kendo-filter-lt-operator>
              </kendo-grid-numeric-filter-menu>
            </ng-container>
            <ng-container *ngSwitchDefault>
              <kendo-grid-string-filter-menu
                [column]="column"
                [filter]="filter"
                [filterService]="filterService"
              >
                <kendo-filter-startswith-operator></kendo-filter-startswith-operator>
                <kendo-filter-eq-operator></kendo-filter-eq-operator>
              </kendo-grid-string-filter-menu>
            </ng-container>
          </ng-container>
        </ng-template>

        <ng-template kendoGridCellTemplate let-dataItem>
          <ng-container [ngSwitch]="column.dataType">
            <ng-container *ngSwitchCase="dataType.Link">
              <a
                class="wmx-100 clamp text-underline blue-text"
                [title]="dataItem[column.field]"
              >
                <ng-container
                  *ngIf="
                    column.field === 'location' || column.field === 'ticketId';
                    else otherLink
                  "
                >
                  <div
                    (click)="
                      column.field === 'location'
                        ? onTrackLocation(dataItem)
                        : onComplaintClick(dataItem)
                    "
                  >
                    {{
                      column.field === 'location'
                        ? 'Track Location'
                        : dataItem[column.field]
                    }}
                  </div>
                </ng-container>

                <ng-template #otherLink>
                  <div (click)="onDeviceClick(dataItem)">
                    {{ dataItem[column.field] }}
                  </div>
                </ng-template>
              </a>
            </ng-container>

            <ng-container *ngSwitchCase="dataType.UpperCase">
              <div [ngClass]="getClass(dataItem.r_STATUS)" class="break-word">
                {{ dataItem[column.field] | uppercase }}
              </div>
            </ng-container>

            <ng-container *ngSwitchCase="dataType.ReviewSettingStatus">
              <div
                [ngClass]="getCssClass(dataItem[column.field])"
                class="break-word"
              >
                {{ dataItem[column.field] }}
              </div>
            </ng-container>

            <ng-container *ngSwitchCase="dataType.DateTime">
              <div [ngClass]="getClass(dataItem.r_STATUS)" class="break-word">
                {{ dataItem[column.field] | date: 'dd/MM/yyyy HH:mm:ss' }}
              </div>
            </ng-container>

            <ng-container *ngSwitchCase="dataType.Date">
              <div [ngClass]="getClass(dataItem.r_STATUS)" class="break-word">
                {{ dataItem[column.field] | date: 'dd/MM/yyyy' }}

                <span
                  *ngIf="
                    moduleId === module.GISInfo &&
                    this.getLoggedInUserDetails().RoleId ===
                      roles.SuperAdmin.toString()
                  "
                  class="align-end"
                >
                  <a
                    title="Add Device Installation Date"
                    (click)="onActionClick(dataItem, 5)"
                  >
                    <i class="far fa-calendar-day"></i>
                  </a>
                </span>
              </div>
            </ng-container>

            <ng-container *ngSwitchCase="dataType.Float">
              <div [ngClass]="getClass(dataItem.r_STATUS)" class="break-word">
                {{ dataItem[column.field] | number: '1.2-2' }}
              </div>
            </ng-container>

            <ng-container *ngSwitchCase="dataType.TicketStatus">
              <div
                class="status"
                [ngClass]="getStatusText(dataItem[column.field])"
              >
                <span>{{ getStatusText(dataItem[column.field]) }}</span>
              </div>
            </ng-container>

            <ng-container *ngSwitchCase="dataType.FeederType">
              <div class="status">
                <span>{{ getFeederType(dataItem[column.field]) }}</span>
              </div>
            </ng-container>

            <ng-container *ngSwitchCase="dataType.StatusBox">
              <div
                class="centered-div"
                [ngClass]="dataItem.acvtiveDevices === 1 ? 'green' : 'red'"
              ></div>
            </ng-container>

            <ng-container *ngSwitchDefault>
              <div [ngClass]="getClass(dataItem.r_STATUS)" class="break-word">
                {{ dataItem[column.field] }}
              </div>
            </ng-container>
          </ng-container>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column-group
        *ngIf="column.isSelected && column.hasColumn.length"
        [title]="column.name"
        [headerClass]="'header-group-class'"
      >
        <ng-container *ngFor="let data of column.hasColumn">
          <kendo-grid-column
            *ngIf="data.isSelected"
            [field]="data.field"
            [title]="data.name"
            [width]="200"
            [maxResizableWidth]="300"
          >
            <ng-template
              kendoGridHeaderTemplate
              let-column
              let-columnIndex="columnIndex"
              let-dataItem
            >
              <div [ngClass]="getConditionalClass(dataItem.title)">
                {{ data.name }}
              </div>
            </ng-template>
            <ng-template
              kendoGridFilterMenuTemplate
              let-filter
              let-filterService="filterService"
            >
              <ng-container [ngSwitch]="data.dataType">
                <ng-container *ngSwitchCase="dataType.UpperCase">
                  <kendo-grid-string-filter-menu
                    [column]="data"
                    [filter]="filter"
                    [filterService]="filterService"
                  >
                    <kendo-filter-startswith-operator></kendo-filter-startswith-operator>
                    <kendo-filter-eq-operator></kendo-filter-eq-operator>
                  </kendo-grid-string-filter-menu>
                </ng-container>
                <ng-container *ngSwitchCase="dataType.DateTime">
                  <kendo-grid-date-filter-menu
                    [column]="data"
                    [filter]="filter"
                    [filterService]="filterService"
                    format="dd/MM/yyyy"
                  >
                    <kendo-filter-eq-operator></kendo-filter-eq-operator>
                    <kendo-filter-neq-operator></kendo-filter-neq-operator>
                    <kendo-filter-gt-operator></kendo-filter-gt-operator>
                    <kendo-filter-gte-operator></kendo-filter-gte-operator>
                    <kendo-filter-lt-operator></kendo-filter-lt-operator>
                    <kendo-filter-lte-operator></kendo-filter-lte-operator>
                  </kendo-grid-date-filter-menu>
                </ng-container>
                <ng-container *ngSwitchCase="dataType.Float">
                  <kendo-grid-numeric-filter-menu
                    [column]="data"
                    [filter]="filter"
                    [filterService]="filterService"
                  >
                    <kendo-filter-eq-operator></kendo-filter-eq-operator>
                    <kendo-filter-gt-operator></kendo-filter-gt-operator>
                    <kendo-filter-lt-operator></kendo-filter-lt-operator>
                  </kendo-grid-numeric-filter-menu>
                </ng-container>
                <ng-container *ngSwitchDefault>
                  <kendo-grid-string-filter-menu
                    [column]="data"
                    [filter]="filter"
                    [filterService]="filterService"
                  >
                    <kendo-filter-startswith-operator></kendo-filter-startswith-operator>
                    <kendo-filter-eq-operator></kendo-filter-eq-operator>
                  </kendo-grid-string-filter-menu>
                </ng-container>
              </ng-container>
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem>
              <ng-container [ngSwitch]="data.dataType">
                <ng-container *ngSwitchCase="dataType.Link">
                  <a
                    class="wmx-100 clamp text-underline blue-text"
                    [title]="dataItem[column.field][data.field]"
                  >
                    {{ dataItem[column.field][data.field] }}
                  </a>
                </ng-container>
                <ng-container *ngSwitchCase="dataType.DateTime">
                  <div
                    [ngClass]="getClass(dataItem.r_STATUS)"
                    class="break-word"
                  >
                    {{
                      dataItem[column.field][data.field]
                        | date: 'dd/MM/yyyy HH:mm:ss'
                    }}
                  </div>
                </ng-container>
                <ng-container *ngSwitchCase="dataType.Date">
                  <div
                    [ngClass]="getClass(dataItem.r_STATUS)"
                    class="break-word"
                  >
                    {{
                      dataItem[column.field][data.field] | date: 'dd/MM/yyyy'
                    }}
                  </div>
                </ng-container>
                <ng-container *ngSwitchCase="dataType.Float">
                  <div [ngClass]="getClass(dataItem.r_STATUS)">
                    <div
                      class="boxes"
                      [ngClass]="
                        getColourBoxClass(
                          dataItem[column.field][getColorField(data.field)]
                        )
                      "
                    >
                      {{ dataItem[column.field][data.field] | number: '1.2-2' }}
                    </div>
                  </div>
                </ng-container>
                <ng-container *ngSwitchDefault>
                  <div
                    [ngClass]="getClass(dataItem.r_STATUS)"
                    class="break-word"
                  >
                    {{ dataItem[column.field][data.field] }}
                  </div>
                </ng-container>
              </ng-container>
            </ng-template>
          </kendo-grid-column>
        </ng-container>
      </kendo-grid-column-group>
    </ng-container>

    <kendo-grid-command-column
      *ngIf="showActions"
      [width]="150"
      [maxResizableWidth]="80"
      [columnMenu]="false"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <ul class="grid-action-list">
          <div
            class="d-flex justify-content-end gap-10"
            *ngIf="
              moduleId > 0 && moduleId === module.Roles
                ? dataItem.id !== roles.SuperAdmin &&
                  dataItem.id !== roles.Admin &&
                  dataItem.id !== roles.CountryLevelRole &&
                  dataItem.id !== roles.StateLevelRole &&
                  dataItem.id !== roles.RegionLevelRole &&
                  dataItem.id !== roles.DistrictLevelRole &&
                  dataItem.id !== roles.CityLevelRole &&
                  dataItem.id !== roles.ZoneLevelRole &&
                  dataItem.id !== roles.TechnicianLevelRole
                : true
            "
          >
            <li
              *ngFor="let ac of actions"
              [ngClass]="{
                'curser-not-allowd':
                  (!(hasEditPermission$ | async) &&
                    ac.actionTypeId === actionTypeEnum.Edit) ||
                  (!(hasDeletePermission$ | async) &&
                    ac.actionTypeId === actionTypeEnum.Delete)
              }"
            >
              <ng-container
                *ngIf="
                  ac.actionTypeId === actionTypeEnum.AddDate &&
                    (dataItem.installationDate === null ||
                      dataItem.installationDate === undefined);
                  else cancelAction
                "
              >
                <a
                  title="{{ ac.title }}"
                  (click)="onActionClick(dataItem, ac.actionTypeId)"
                  [class.disabled]="
                    (!(hasEditPermission$ | async) &&
                      ac.actionTypeId === actionTypeEnum.Edit) ||
                    (!(hasDeletePermission$ | async) &&
                      ac.actionTypeId === actionTypeEnum.Delete)
                  "
                >
                  <i class="{{ ac.icon }}"></i>
                </a>
              </ng-container>
              <ng-template #cancelAction>
                <ng-container
                  *ngIf="
                    ac.actionTypeId === actionTypeEnum.Cancel &&
                      (dataItem.settingFlag === 1 ||
                        dataItem.settingFlag === 2);
                    else otherActions
                  "
                >
                  <a
                    title="{{ ac.title }}"
                    (click)="onActionClick(dataItem, ac.actionTypeId)"
                    [class.disabled]="
                      (!(hasEditPermission$ | async) &&
                        ac.actionTypeId === actionTypeEnum.Edit) ||
                      (!(hasDeletePermission$ | async) &&
                        ac.actionTypeId === actionTypeEnum.Delete)
                    "
                  >
                    <i class="{{ ac.icon }}"></i>
                  </a>
                </ng-container>
              </ng-template>
              <ng-template #otherActions>
                <a
                  *ngIf="
                    ac.actionTypeId !== actionTypeEnum.AddDate &&
                    ac.actionTypeId !== actionTypeEnum.Cancel &&
                    !(
                      ac.actionTypeId === actionTypeEnum.View &&
                      moduleId === module.Accounts &&
                      dataItem.id.toString() ===
                        this.getLoggedInUserDetails().UserId
                    )
                  "
                  title="{{ ac.title }}"
                  (click)="onActionClick(dataItem, ac.actionTypeId)"
                  [class.disabled]="
                    (!(hasEditPermission$ | async) &&
                      ac.actionTypeId === actionTypeEnum.Edit) ||
                    (!(hasDeletePermission$ | async) &&
                      ac.actionTypeId === actionTypeEnum.Delete)
                  "
                >
                  <i class="{{ ac.icon }}"></i>
                </a>
              </ng-template>
            </li>
          </div>
        </ul>
      </ng-template>
    </kendo-grid-command-column>
  </kendo-grid>
</div>

<div *ngIf="!hasSelectedColumns()">
  <div
    class="d-flex justify-content-center align-items-center flex-column"
    [ngClass]="{ 'no-column-section': !hasSelectedColumns() }"
  >
    <img src="assets/images/no-column.png" alt="no-column" class="mb-10" />
    <div class="no-column-text">
      <p class="font-size-18 mb-10">{{ noColumnMessage }}</p>
      <p class="text-desc mb-20">
        {{ noColumnDetailMessage }}
      </p>
      <a
        *ngIf="showCustomizeColumn"
        (click)="onColumnSelector()"
        class="d-flex align-items-center justify-content-center gap-5 column-popup-link"
        ><img src="assets/images/custom-columns-blue.svg" alt="column" /><span
          >Customize Columns</span
        ></a
      >
    </div>
  </div>
</div>
