import { Component, OnInit, ViewChild } from '@angular/core'
import { Router } from '@angular/router'
import { NotificationMessage } from '@app/enums/notification'
import {
  ActionTypeEnum,
  AddComplaintComponent,
  CommonGridComponent,
  CommonService,
} from '@app/index'
import { CommonState, DeleteTicket, GetcomplaintstList } from '@app/store'
import { Select, Store } from '@ngxs/store'
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog'
import { GridDataResult } from '@progress/kendo-angular-grid'
import { NgxSpinnerService } from 'ngx-spinner'
import { ToastrService } from 'ngx-toastr'
import { Observable, Subject } from 'rxjs'

@Component({
  selector: 'app-complaints',
  templateUrl: './complaints.component.html',
  styleUrl: './complaints.component.scss',
})
export class ComplaintsComponent implements OnInit {
  @ViewChild(CommonGridComponent) commonGridComponent!: CommonGridComponent
  gridView: GridDataResult | any
  public pageSize = 10
  public skip = 1
  searchText = ''
  public sorting = null
  sort = null
  sortOrder = false
  pageNumber = 1
  filterQuery: string | any
  actions: any[] = [
    {
      title: 'Edit',
      icon: 'far fa-pen',
      actionTypeId: ActionTypeEnum.Edit,
    },
    {
      title: 'Delete',
      icon: 'far fa-trash-alt',
      actionTypeId: ActionTypeEnum.Delete,
    },
  ]
  triggerGetUpdatedTime: Subject<any> = new Subject<any>()

  @Select(CommonState.moduleId)
  moduleId$!: Observable<number>

  isDataLoaded = false

  constructor(
    private router: Router,
    private store: Store,
    private dialogService: DialogService,
    private spinner: NgxSpinnerService,
    public commonService: CommonService,
    private toastr: ToastrService,
  ) {}

  ngOnInit(): void {
    this.getComplaintstList()
    setTimeout(() => {
      this.triggerGetUpdatedTime.next(true)
    }, 0)
  }

  onFilterChangeSQL(sqlQuery: any) {
    this.filterQuery = sqlQuery

    this.getComplaintstList()
  }

  getComplaintstList(): void {
    this.spinner.show()
    let queryParams = {
      deviceId: '',
      feederAreaId: '',
      filter: 0,
      pageNumber: this.pageNumber,
      pageSize: this.pageSize,
      sortBy: this.sort ?? null,
      sortOrder: this.sortOrder,
      search: '',
      startDate: null,
      endDate: null,
      moduleId: this.store.selectSnapshot(CommonState.moduleId),
      filterQuery: this.filterQuery,
    }
    this.store.dispatch(new GetcomplaintstList(queryParams)).subscribe(
      (res) => {
        this.spinner.hide()
        const { data, totalCount } = res.complaints.complaintsList

        this.gridView = {
          data: data.rows,
          total: totalCount,
          columns: data.columns,
        }
        this.isDataLoaded = true
      },
      (error) => {
        this.spinner.hide()
      },
    )
  }

  onSortChange(sortData: any) {
    this.sort = sortData.sort[0].field
    this.sortOrder = sortData.sort[0].dir === 'asc' ? true : false
    this.getComplaintstList()
  }

  onPaginationChange({ skip, take }: any) {
    this.skip = skip
    this.pageSize = take
    this.pageNumber = this.skip / this.pageSize + 1
    this.searchText = ''
    this.getComplaintstList()
  }

  onAddComplaint(id?: any, isAdd: boolean = true): void {
    if (isAdd) {
      const dialogRef: DialogRef = this.dialogService.open({
        title: 'Add New Ticket',
        content: AddComplaintComponent,
        width: 600,
      })
      const dialogInfo = dialogRef.content.instance as AddComplaintComponent

      dialogRef.result.subscribe((result: any) => {
        if (result.isSave) {
          this.getComplaintstList()
        }
      })
    } else {
      const params = {
        isAddMode: btoa(isAdd.toString()),
        id: btoa(id),
      }
      this.router.navigate(['edit-ticket', params])
    }
  }

  onActionClick(event: any): void {
    if (event.actionTypeId === ActionTypeEnum.Edit) {
      this.onAddComplaint(event.data.id, false)
    } else if (event.actionTypeId === ActionTypeEnum.Delete) {
      this.onDeleteTicket(event.data)
    }
  }

  onEditComplaint(dataItem: any): void {
    this.onAddComplaint(dataItem.id, false)
  }

  onDeleteTicket(event: any): void {
    this.spinner.show()
    this.store.dispatch(new DeleteTicket(event.id)).subscribe(
      (res: any) => {
        this.spinner.hide()

        if (res.complaints.isTicketDeleted) {
          this.toastr.success(NotificationMessage.deleteTicketSuccessMsg)
          this.gridView.data = this.gridView.data.filter(
            (item: any) => item.id !== event.id,
          )

          if (this.gridView.data.length === 0 && this.pageNumber > 1) {
            this.pageNumber--
            this.skip = Math.max(0, this.skip - this.pageSize)
            this.commonGridComponent.skip = this.skip
            this.getComplaintstList()
          } else {
            this.getComplaintstList()
          }
        }
      },
      (error) => {
        this.spinner.hide()
      },
    )
  }

  onColumnSelector(): void {
    this.commonGridComponent?.onColumnSelector()
  }
}
