import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { SharedModule } from 'src/shared/shared.module'
import {
  AccountComponent,
  AccountDevicePopupComponent,
  AccountListComponent,
  AccountRoutingModule,
  CommonsModule,
  ForgotPasswordComponent,
  LoginComponent,
  PasswordChangeComponent,
  RegistrationComponent,
} from '@app/index'
@NgModule({
  declarations: [
    RegistrationComponent,
    AccountComponent,
    AccountListComponent,
    LoginComponent,
    PasswordChangeComponent,
    ForgotPasswordComponent,
    AccountDevicePopupComponent,
  ],
  imports: [CommonModule, SharedModule, CommonsModule, AccountRoutingModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AccountModule {}
