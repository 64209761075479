import { Component, Input } from '@angular/core'
import { Router } from '@angular/router'
import { ActionTypeEnum } from '@app/enums'
import { NotificationMessage } from '@app/enums/notification'
import { CommonService } from '@app/services'
import {
  AddDevice,
  DeleteDeviceByDeviceId,
  GetDeviceIdByFeeder,
  GetFeederAreaList,
} from '@app/store'
import { Store } from '@ngxs/store'
import { DialogRef } from '@progress/kendo-angular-dialog'
import { NgxSpinnerService } from 'ngx-spinner'
import { ToastrService } from 'ngx-toastr'
import { switchMap } from 'rxjs'

@Component({
  selector: 'app-otp-dialog',
  templateUrl: './otp-dialog.component.html',
  styleUrl: './otp-dialog.component.scss',
})
export class OtpDialogComponent {
  @Input() actionType: any
  @Input() requestBodyParams: any

  otpValue?: any = ''

  constructor(
    private dialogRef: DialogRef,
    public commonService: CommonService,
    private store: Store,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private router: Router,
  ) {}

  onCloseDialog(): void {
    this.commonService.onDialogClose(this.dialogRef, false)
  }

  onVerifyOtp() {
    switch (this.actionType) {
      case ActionTypeEnum.Add:
        this.onDeviceAdd()
        break
      case ActionTypeEnum.Delete:
        this.onDeviceDelete()
        break
    }
  }

  onDeviceAdd() {
    const params = {
      ...this.requestBodyParams,
      otp: this.otpValue,
    }

    this.store.dispatch(new AddDevice(params)).subscribe(
      (res) => {
        if (res) {
          this.spinner.hide()
          this.toastr.success(NotificationMessage.DeviceCreatedSuccessMsg)
          this.commonService.onDialogClose(this.dialogRef, true)
          this.router.navigate(['device'])
        }
      },
      (error) => {
        this.spinner.hide()
      },
    )
  }

  onDeviceDelete() {
    const params = {
      ...this.requestBodyParams,
      otp: this.otpValue,
    }

    this.store.dispatch(new DeleteDeviceByDeviceId(params)).subscribe(
      (res) => {
        this.spinner.hide()
        this.store
          .dispatch(new GetFeederAreaList())
          .pipe(
            switchMap((res: any) =>
              this.store.dispatch(new GetDeviceIdByFeeder()),
            ),
          )
          .subscribe()
        this.toastr.success(
          'Device ' + params.deviceId + ' Successfully Deleted',
        )
        this.commonService.onDialogClose(this.dialogRef, true)
        this.router.navigate(['device'])
      },
      (error) => {
        if (error.error.Message) {
          this.spinner.hide()
        }
      },
    )
  }
}
