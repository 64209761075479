<kendo-dialog-titlebar (close)="onCloseDialog()">
  <span>{{ feederId === 0 ? 'Create' : 'Update' }} Feeder</span>
</kendo-dialog-titlebar>

<div>
  <form [formGroup]="feederForm" class="k-form k-form-md">
    <kendo-formfield class="mb-20">
      <kendo-label
        [for]="feeder_AreaName"
        text="Feeder/Area Name"
        labelCssClass="k-form-label"
        ><span class="required">*</span></kendo-label
      >
      <kendo-textbox
        formControlName="feeder_AreaName"
        #feeder_AreaName
        appNoSpacesOrSpecialChars
      ></kendo-textbox>
    </kendo-formfield>

    <kendo-label
      [for]="feeder_type"
      text="Feeder Type"
      labelCssClass="k-form-label"
    ></kendo-label>
    <kendo-dropdownlist
      #feeder_type
      [data]="feederType | useEnumValues"
      textField="value"
      valueField="key"
      [(ngModel)]="selectedFeederType"
      [ngModelOptions]="{ standalone: true }"
      [value]="selectedFeederType"
      [valuePrimitive]="true"
      class="wp-260"
    ></kendo-dropdownlist>
  </form>
</div>
<kendo-dialog-actions>
  <button kendoButton (click)="onCloseDialog()" fillMode="outline">
    Cancel
  </button>
  <div
    [ngClass]="{ 'curser-not-allowd': feederForm.invalid }"
    class="display-inline flex-0"
  >
    <button kendoButton (click)="onSave()" [disabled]="feederForm.invalid">
      Save
    </button>
  </div>
</kendo-dialog-actions>
