import { Injectable } from '@angular/core'
import {
  DtrpredictionService,
  GetGraphDetails,
  GetDTRPredictionDetail,
} from '@app/index'
import { Action, State, StateContext } from '@ngxs/store'
import { tap } from 'rxjs/operators'

export class DtrpredictionStateInfo {
  itmsDetailsData?: any
  graphDetails?: any
}

@State<DtrpredictionStateInfo>({
  name: 'dtrprediction',
  defaults: {},
})
@Injectable()
export class DtrpredictionState {
  constructor(private dtrpredictionService: DtrpredictionService) {}

  @Action(GetDTRPredictionDetail)
  GetItmsDetails(
    { getState, patchState }: StateContext<DtrpredictionStateInfo>,
    action: GetDTRPredictionDetail,
  ) {
    const deviceId = action.deviceId
    return this.dtrpredictionService.GetItmsDetails(deviceId).pipe(
      tap((res) => {
        patchState({
          itmsDetailsData: res,
        })
      }),
    )
  }

  @Action(GetGraphDetails, { cancelUncompleted: true })
  getGraphDetails(
    { getState, patchState }: StateContext<DtrpredictionStateInfo>,
    action: GetGraphDetails,
  ) {
    const deviceId = action.deviceId
    return this.dtrpredictionService.getGraphDetails(deviceId).pipe(
      tap((res) => {
        patchState({
          graphDetails: res,
        })
      }),
    )
  }
}
