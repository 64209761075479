import { Component, OnInit } from '@angular/core'
import { SeparateDateEnum } from '@app/enums'
import { GetAnalyticsChart } from '@app/store'
import { Store } from '@ngxs/store'
import { CategoryAxis } from '@progress/kendo-angular-charts'
import { NgxSpinnerService } from 'ngx-spinner'
import { Subject } from 'rxjs'

@Component({
  selector: 'app-load',
  templateUrl: './load.component.html',
  styleUrl: './load.component.scss',
})
export class LoadComponent implements OnInit {
  triggerGetUpdatedTime: Subject<any> = new Subject<any>()
  chartData: any[] = []
  categoryAxis: CategoryAxis | CategoryAxis[] = []
  deviceId: any
  param: any = {
    deviceId: 0,
    year: undefined,
    month: undefined,
    day: undefined,
    type: undefined,
  }
  isChartData = false
  showLoader: boolean = true
  isNoRecordFound = false
  roundingFactor: number | any
  constructor(
    private store: Store,
    private spinner: NgxSpinnerService,
  ) {}

  ngOnInit(): void {}

  onAnalyticsFilterChange(event: any) {
    const param = {
      deviceId: this.deviceId,
      year: event.year,
      month: event.month,
      day: event.day,
      type: event.type,
    }
    this.getChartData(param)
  }

  getChartData(param: any): void {
    this.showLoader = true
    this.store.dispatch(new GetAnalyticsChart(param)).subscribe(
      (res: any) => {
        this.showLoader = false
        this.chartData = []
        if (res.analytics.analyticsChartData.length > 0) {
          const dataArray: any = res.analytics.analyticsChartData

          this.chartData = dataArray.map((item: any) => ({
            category: item.months,
            value: parseFloat(item.load),
          }))

          let maxValue = Math.max(
            ...this.chartData.map((item: any) => item.value),
          )

          maxValue += 10
          const roundedValue = Math.ceil(maxValue / 10) * 10

          this.roundingFactor = roundedValue

          this.categoryAxis = {
            baseUnit: 'minutes',
            labels: {
              dateFormats: {
                minutes: 'HH:mm MMM dd',
              },
              rotation: -45,
            },
          }
        }
        this.isNoRecordFound = res.analytics.analyticsChartData.length <= 0
        this.isChartData = true
      },
      (error) => {
        this.spinner.hide()
      },
    )
  }

  getDataByHeaderData(event: any): void {
    this.deviceId = event.deviceId

    this.param.deviceId = this.deviceId

    this.getChartData(this.param)
    this.triggerGetUpdatedTime.next(true)
  }

  onAllButtonClick() {
    this.param = {
      deviceId: this.deviceId,
      year: undefined,
      month: undefined,
      day: undefined,
      type: undefined,
    }

    this.getChartData(this.param)
  }

  getAllYearData(): void {
    this.param = {
      deviceId: this.deviceId,
      year: undefined,
      month: undefined,
      day: undefined,
      type: SeparateDateEnum.YEAR,
    }
    this.getChartData(this.param)
  }
}
