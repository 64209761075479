import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'

import { CreateDatabaseRoutingModule } from './create-database-routing.module'
import { CommonsModule, CreateDatabaseComponent } from '@app/index'
import { SharedModule } from 'src/shared/shared.module'

@NgModule({
  declarations: [CreateDatabaseComponent],
  imports: [
    CommonModule,
    CreateDatabaseRoutingModule,
    CommonsModule,
    SharedModule,
  ],
})
export class CreateDatabaseModule {}
